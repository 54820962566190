import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'cars.newClient';

export const initialState = {
  ...requestInitialState,
  data: {},
  isSuccess: false,
};

const newClientSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    create(state, { payload }) {
      return {
        ...state,
        data: { ...payload },
        isSuccess: true,
      };
    },
    clear() {
      return { ...initialState };
    },
  },
});

export const actions = { ...newClientSlice.actions };
export const requests = { ...newClientSlice.requests };

export default newClientSlice.reducer;
