import './style.scss';
import React from 'react';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import { toAbsoluteUrl } from '_metronic';
import { injectIntl } from 'react-intl';

const installerDashboard = ({ intl }) => {
  /* const visitSorteoLink = intl.formatMessage({ id: 'VISIT_SORTEO_LINK' }); */
  const imgSorteoLink = intl.formatMessage({ id: 'IMG_AUTO' });

  return (
    <>
      <Portlet className="kt-portlet--height-fluid">
        <PortletBody
          style={{
            backgroundRepeat: 'no-repeat, repeat',
            backgroundSize: 'cover',
          }}
        >
          <div>
            {/* <a href={visitSorteoLink} target="_blank"> */}
            <img
              src={imgSorteoLink}
              style={{
                width: '113%',
                height: '113%',
                marginLeft: '-23px',
                marginTop: '-23px',
              }}
            />
            {/* </a> */}
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(installerDashboard);
