import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import get from 'lodash/get';
import moment from 'moment';

const AreaChart = ({ locale, data: values }) => {
  const ref = useRef();
  const data = useMemo(() => {
    moment.locale(locale);
    return values.reduce((acum, actual) => {
      const acumLabels = get(acum, 'labels', []);
      const newLabel = moment(
        `01/${actual.month}/${actual.year}`,
        'DD/MM/YYYY',
      ).format('MMMM YY');
      const actualData = get(acum, 'datasets[0].data', []);
      const newData = actual.clients;
      return {
        ...acum,
        labels: [newLabel, ...acumLabels],
        datasets: [
          {
            backgroundColor: '#73c968',
            data: [newData, ...actualData],
          },
        ],
      };
    }, {});
  }, [values, locale]);

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: 'line',
      options: {
        title: { display: false },
        tooltips: {
          //   intersect: false,
          //   mode: 'nearest',
          //   xPadding: 50,
          //   yPadding: 10,
          //   caretPadding: 10,
        },
        legend: { display: false },
        //   responsive: true,
        //   maintainAspectRatio: false,
        //   barRadius: 4,
        scales: {
          xAxes: [{ display: false, gridLines: false }],
          yAxes: [{ display: false, gridLines: false }],
        },
        layout: { padding: { left: 10, right: 10, top: 0, bottom: 0 } },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={ref} />;
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'en'
      : 'es',
});
export default connect(matStateToProps)(AreaChart);
