import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const TableHeadTypography = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

const DetailsTable = ({ data }) => {
  return (
    <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadTypography color="primary">
              <FormattedMessage id="Marca" />
            </TableHeadTypography>
          </TableCell>
          <TableCell>
            <TableHeadTypography color="primary">
              <FormattedMessage id="Modelo" />
            </TableHeadTypography>
          </TableCell>
          <TableCell align="right">
            <TableHeadTypography color="primary">
              <FormattedMessage id="Cantidad" />
            </TableHeadTypography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.brand}</TableCell>
            <TableCell>{row.model}</TableCell>
            <TableCell align="right">
              <Typography color="primary">{row.amount}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default DetailsTable;
