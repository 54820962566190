import createRequestSlice, { requestInitialState } from 'redux/request/reducer';
import get from 'lodash/get';

export const PREFIX = 'client.clientList';

export const initialState = {
  ...requestInitialState,
  data: [],
  page: 1,
  perPage: 25,
  total: 0,
  q: '',
  isLoading: false,
  hasError: false,
};

const clientListSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    request(state) {
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    },
    failure(state) {
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    },
    fetch(state, { payload }) {
      return {
        ...state,
        ...payload,
        data: get(payload, 'data', []) || [], // TODO Change this
        hasError: false,
        isLoading: false,
      };
    },
    setFilter(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const actions = { ...clientListSlice.actions };
export const requests = { ...clientListSlice.requests };

export default clientListSlice.reducer;
