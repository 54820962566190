import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { metronic } from '../_metronic';
import auth from './auth/reducer';
import profile from './profile/reducer';
import dashboard from './dashboard/reducer';
import states from './states/reducer';
import cities from './cities/reducer';
import brands from './brands/reducer';
import models from './models/reducer';
import distributors from './distributors/reducer';
import userInstallers from './userInstallers/reducer';
import filmsAuto from './filmsAuto/reducer';
import filmsBuilding from './filmsBuilding/reducer';
import reports from './reports/reducer';
import client from './client/reducer';
import contact from './contact/reducer';
import cars from './cars';
import buildings from './buildings';
import arqFinishes from './arqFinishes';
import cookies from './cookies/reducer';
import user from './user/reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    //
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    //
    auth,
    profile,
    dashboard,
    //
    states,
    cities,
    brands,
    models,
    distributors,
    userInstallers,
    filmsAuto,
    filmsBuilding,
    //
    cars,
    buildings,
    arqFinishes,
    client,
    contact,
    reports,
    cookies,
    user,
  });
