import * as Yup from 'yup';

export default (intl, country) =>
  Yup.object().shape({
    installers: Yup.array().of(
      Yup.object().test(
        'hasInstaller',
        intl.formatMessage({ id: 'Debe ingresar un instalador' }),
        (value) => Boolean(value.id),
      ),
    ),
    sheets: Yup.array().of(
      Yup.object().shape({
        sheet: Yup.number()
          .nullable()
          .required(
            intl.formatMessage({ id: 'Debe seleccionar una película' }),
          ),
        run: Yup.string().required(
          intl.formatMessage({ id: 'Número de RUN invalido' }),
        ),
        meters: Yup.number()
          .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
          .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
        distributor: Yup.number()
          .nullable()
          .when('$distributor', (_, schema) => {
            return schema.required(
              intl.formatMessage({
                id: 'Debe seleccionar un distribuidor',
              }),
            );
          }),
        application: Yup.object().test(
          'hasAplication',
          intl.formatMessage({ id: 'Debe seleccionar una opción' }),
          (value) =>
            value.fullFront ||
            value.halfFront ||
            value.bumpers ||
            value.fenders ||
            value.handles ||
            value.doorEdges ||
            value.trunkEdges ||
            value.sideMirror ||
            value.fullCar ||
            value.Other,
        ),
      }),
    ),
  });
