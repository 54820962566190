import { REQUEST_STATUS } from 'enums/request';
import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'reports';

export const initialState = {
  ...requestInitialState,
  fileName: '',
};

const reportsSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    clear() {
      return {
        ...initialState,
      };
    },
    request(state, { meta }) {
      console.log(meta);
      return {
        ...state,
        error: '',
        status: REQUEST_STATUS[meta.method],
        fileName: meta.fileName,
      };
    },
  },
});

export const actions = {
  ...reportsSlice.actions,
};
export const requests = {
  ...reportsSlice.requests,
};

export default reportsSlice.reducer;
