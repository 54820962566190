import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { getSendWarratyData } from 'redux/cars/editClient/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import {
  requests as citiesRequest,
  actions as citiesActions,
} from 'redux/cities/reducer';
import { requests as editClientRequests } from 'redux/cars/editClient/reducer';
import { clientTypeOPTs } from 'enums/formOtps';

import { Field } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import Button from '@material-ui/core/Button';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import PhoneIcon from '@material-ui/icons/Phone';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

export const initialValues = {
  customer: {
    type: '',
    firstname: '',
    lastname: '',
    email: '',
    cellphone: '',
    state: '',
    city: '',
  },
};

export const Step1 = ({
  country,
  fetchStates,
  fetchCities,
  stateOptions,
  citiesOptions,
  clearCities,
  values: {
    customer: { state },
  },
  setFieldValue,
  sendWarranty,
  isSendSuccess,
  code,
  isLoading,
  hasSendError,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    if (state) {
      fetchCities({
        country,
        state: get(
          stateOptions.find((opt) => opt.value === state),
          'id',
          '',
        ),
      });
    } else {
      clearCities();
    }
  }, [country, fetchCities, clearCities, state, stateOptions]);

  useEffect(() => {
    if (!isLoading && isSendSuccess) {
      toast.success(
        <FormattedMessage id="Certificado de garantia enviado correctamente" />,
        toastConfig,
      );
    }
  }, [isLoading, isSendSuccess]);
  useEffect(() => {
    if (!isLoading && hasSendError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasSendError]);

  return (
    <>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Tipo de cliente *" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.type"
            component={Autocomplete}
            helperText="Por favor seleccione el tipo de cliente"
            variant="outlined"
            options={clientTypeOPTs}
            placeholder="Seleccionar"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Nombre: *" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.firstname"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Nombre del cliente"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      {/* <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Apellido: *" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.lastname"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Apellido del cliente"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div> */}
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Correo electronico: *" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.email"
            component={TextField}
            type="text"
            helperText="No compartiremos el Email con nadie mas"
            variant="outlined"
            fullWidth
            placeholder="Ingresar dirección de email"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="Resend">
            <div>
              <FormattedMessage id="¿Volver a enviar el certificado de garantia?" />
            </div>
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={() => sendWarranty({ code })}
            >
              <FormattedMessage id="Enviar" />
            </Button>
          </div>
          <div
            style={{
              fontSize: '0.75rem',
              marginTop: '3px',
              textAlign: 'left',
              fontFamily: '3m-font',
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            <FormattedMessage id='Sólo si has realizado un cambio de email, deberás "Guardar" la modificación y luego hacer clic en "Enviar".' />
            <div style={{ marginTop: '5px' }}>
              <FormattedMessage id="Si el cliente final no recibió el email en bandeja de entrada deberá corroborar en correo no deseado." />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Celular:" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.cellphone"
            component={TextField}
            type="text"
            helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
            variant="outlined"
            fullWidth
            placeholder="Cód. de país + cód. de área + número"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Provincia" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.state"
            component={Autocomplete}
            handleChange={() => {
              setFieldValue('customer.city', null);
            }}
            variant="outlined"
            options={stateOptions}
            placeholder="Seleccionar"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <strong>
            <FormattedMessage id="Ciudad" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.city"
            component={Autocomplete}
            variant="outlined"
            options={citiesOptions}
            placeholder="Seleccionar"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div> */}
    </>
  );
};

export const mapStateToProps = (state) => ({
  ...getSendWarratyData(state),
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  sendWarranty: (data) => dispatch(editClientRequests.send(data)),
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
  clearCities: (data) => dispatch(citiesActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
