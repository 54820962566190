import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'distributors.distributorView';

export const initialState = {
  ...requestInitialState,
  data: {},
  isLoading: false,
  hasError: false,
  isSuccess: false,
};

const distributorViewSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    request(state) {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        isSendSuccess: false,
      };
    },
    failure(state) {
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    },
    fetch(state, { payload }) {
      return {
        ...state,
        data: payload,
        hasError: false,
        isLoading: false,
      };
    },
    clearData() {
      return { ...initialState };
    },
  },
});

export const actions = { ...distributorViewSlice.actions };
export const requests = { ...distributorViewSlice.requests };

export default distributorViewSlice.reducer;
