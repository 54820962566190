import './style.scss';
import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const StarTypography = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '1.8em',
  },
})(Typography);

export default ({ value, maxValue }) => {
  const stars = [];
  for (let x = 1; x <= maxValue; x++) {
    if (x <= value) {
      stars.push(<StarIcon color="primary" fontSize="large" key={x} />);
    } else {
      stars.push(<StarIcon color="disabled" fontSize="large" key={x} />);
    }
  }
  return (
    <div className="Atomovil-Stars">
      <div className="Atomovil-Stars-Icons">{stars}</div>
      <StarTypography color="primary">
        {value.toFixed(2)} / {maxValue}
      </StarTypography>
    </div>
  );
};
