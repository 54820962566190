import React from 'react';
import Layout from '_metronic/layout/Layout';
import ProductCard from './productCard';
import {
  autoProducts,
  autoSecurity,
  autoSolarSecurity,
} from './productCard/auto_prods';
import './productCard/productCard.css';

import { connect } from 'react-redux';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';

function ProductAuto(props) {
  let autoSolarControl = autoProducts;
  let autoSolarSecurityList = autoSolarSecurity;
  let autoSecurityList = autoSecurity;
  return (
    <Layout>
      <div className="container-fluid bg-white">
        <div className="row border-bottom pb-5">
          <div className="col-6">
            <div className="d-flex d-flex  mt-5 ml-2">
              <p className="h3 pt-2 mr-3">
                <FormattedMessage id="Películas para Vidrios de Automóviles 3M™" />
              </p>
            </div>
          </div>
          <div className="row border-bottom pb-5">
            <div className="col-6">
              <div className="d-flex d-flex  mt-5 ml-2">
                <p className="h3 pt-2 mr-3">
                  <FormattedMessage id="PRÓXIMAMENTE" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});
export const matStateToProps = (state) => ({
  lang: get(state, 'i18n.lang') === 'br' ? 'br' : 'es',
});
export default injectIntl(
  connect(matStateToProps, mapDispatchToProps)(ProductAuto),
);
