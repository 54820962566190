import { REQUEST_METHODS } from 'enums/request';
import createRequestSlice, {
  requestInitialState,
  getSliceRequestActionTypes,
  createRequestAction,
} from 'redux/request/reducer';

export const PREFIX = 'cars.editClient';
export const PREFIX_SEND_WARRANTY = 'cars.sendWarrantyClient';
export const PREFIX_REPORT_WARRANTY = 'cars.reportWarrantyClient';
export const PREFIX_APPROVE_WARRANTY = 'cars.approveWarrantyClient';

export const initialState = {
  ...requestInitialState,
  data: {},
  isLoading: false,
  hasError: false,
  hasSendError: false,
  hasReportError: false,
  hasWarrantyError: false,
  isSuccess: false,
  isSendSuccess: false,
  isReportSuccess: false,
  isWarrantySuccess: false,
};

const editClientSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    request(state, { meta }) {
      const hasError = meta.prefix === PREFIX ? false : state.hasError;
      const hasSendError =
        meta.prefix === PREFIX_SEND_WARRANTY ? false : state.hasSendError;
      const hasReportError =
        meta.prefix === PREFIX_REPORT_WARRANTY ? false : state.hasReportError;
      const hasWarrantyError =
        meta.prefix === PREFIX_APPROVE_WARRANTY
          ? false
          : state.hasWarrantyError;
      return {
        ...state,
        hasError,
        hasSendError,
        hasReportError,
        hasWarrantyError,
        isLoading: true,
        isSuccess: false,
        isSendSuccess: false,
        isReportSuccess: false,
        isWarrantySuccess: false,
      };
    },
    failure(state, { meta }) {
      const hasError = meta.prefix === PREFIX ? true : state.hasError;
      const hasSendError =
        meta.prefix === PREFIX_SEND_WARRANTY ? true : state.hasSendError;
      const hasReportError =
        meta.prefix === PREFIX_REPORT_WARRANTY ? true : state.hasReportError;
      const hasWarrantyError =
        meta.prefix === PREFIX_APPROVE_WARRANTY ? true : state.hasWarrantyError;

      return {
        ...state,
        hasError,
        hasSendError,
        hasReportError,
        hasWarrantyError,
        isLoading: false,
      };
    },
    fetch(state, { payload }) {
      return {
        ...state,
        data: payload,
        hasError: false,
        isLoading: false,
      };
    },
    create(state) {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    },
    send(state) {
      return {
        ...state,
        hasSendError: false,
        isLoading: false,
        isSendSuccess: true,
      };
    },
    report(state) {
      return {
        ...state,
        hasReportError: false,
        isLoading: false,
        isReportSuccess: true,
      };
    },
    approve(state) {
      return {
        ...state,
        hasWarrantyError: false,
        isLoading: false,
        isWarrantySuccess: true,
      };
    },
    clearData() {
      return { ...initialState };
    },
  },
});

const actionTypes = getSliceRequestActionTypes(editClientSlice);

const send = createRequestAction({
  prefix: PREFIX_SEND_WARRANTY,
  method: REQUEST_METHODS.Create,
  actions: { ...actionTypes, success: editClientSlice.actions.send.type },
});

const report = createRequestAction({
  prefix: PREFIX_REPORT_WARRANTY,
  method: REQUEST_METHODS.Create,
  actions: { ...actionTypes, success: editClientSlice.actions.report.type },
});

const approve = createRequestAction({
  prefix: PREFIX_APPROVE_WARRANTY,
  method: REQUEST_METHODS.Create,
  actions: { ...actionTypes, success: editClientSlice.actions.approve.type },
});

export const actions = { ...editClientSlice.actions };
export const requests = { ...editClientSlice.requests, send, report, approve };

export default editClientSlice.reducer;
