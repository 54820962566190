// import React, { useEffect, useMemo, useRef } from 'react';
// import { connect } from 'react-redux';
// import { Chart } from 'chart.js';
// import get from 'lodash/get';
// import moment from 'moment';
// import ctl from 'helpers/countries';

// const AreaChart = ({ locale, data: values }) => {
//   const ref = useRef();
//   const data = useMemo(() => {
//     moment.locale(locale);
//     return values.reduce((acum, actual) => {
//       const acumLabels = get(acum, 'labels', []);
//       const newLabel = ctl(actual.country);
//       const actualData = get(acum, 'datasets[0].data', []);
//       const newData = actual.installers;

//       return {
//         ...acum,
//         labels: [newLabel, ...acumLabels],
//         datasets: [
//           {
//             backgroundColor: [
//               '#0074D9',
//               '#FF4136',
//               '#2ECC40',
//               '#FF851B',
//               '#7FDBFF',
//               '#B10DC9',
//               '#FFDC00',
//               '#001f3f',
//               '#39CCCC',
//               '#01FF70',
//               '#85144b',
//               '#F012BE',
//               '#3D9970',
//               '#111111',
//               '#AAAAAA',
//             ],
//             data: [newData, ...actualData],
//           },
//         ],
//       };
//     }, {});
//   }, [values, locale]);

//   useEffect(() => {
//     const chart = new Chart(ref.current, {
//       data,
//       type: 'pie',
//       options: {
//         title: { display: true },
//       },
//     });

//     return () => {
//       chart.destroy();
//     };
//   }, [data]);

//   return <canvas ref={ref} />;
// };

// export const matStateToProps = (state) => ({
//   locale: get(state, 'i18n.lang') === 'br' ? 'pt-br' : 'es',
// });
// export default connect(matStateToProps)(AreaChart);

import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
// import { Chart } from 'chart.js';
import get from 'lodash/get';
// import moment from 'moment';
import COORDS from 'helpers/coords';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  Tooltip,
} from 'react-leaflet';
import './map.scss';
import { FormattedMessage } from 'react-intl';

const Map = ({ locale, data: values }) => {
  const markers = [];

  for (const [index, value] of values.entries()) {
    markers.push(
      // <Marker position={COORDS(value.country)}>
      //   <Popup>
      //     <FormattedMessage id="Instaladores: " /> {value.installers}
      //   </Popup>
      // </Marker>

      <CircleMarker
        center={COORDS(value.country)}
        pathOptions={{ color: 'red' }}
        radius={value.installers / 10}
      >
        <Tooltip>
          <FormattedMessage id="Instaladores: " /> {value.installers}
        </Tooltip>
      </CircleMarker>,
    );
  }

  return (
    <MapContainer
      id="installersMap"
      center={COORDS()}
      zoom={2}
      zoomControl={false}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markers}
    </MapContainer>
  );
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'en'
      : 'es',
});
export default connect(matStateToProps)(Map);
