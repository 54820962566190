import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './reducer';
import saga from './saga';

export const history = createBrowserHistory({
  basename: window.env.PUBLIC_URL,
});

const devTools = process.env.NODE_ENV !== 'production';
const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  routerMiddleware(history),
  sagaMiddleware,
];

const store = configureStore({
  reducer: createRootReducer(history),
  devTools,
  middleware,
});

sagaMiddleware.run(saga);

export const persistor = persistStore(store);

export default store;
