import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Redirect from './redirect';
import { getHasSession } from 'redux/auth/selector';
import { getUserRole } from 'redux/profile/selector';

import indexOf from 'lodash/indexOf';

import { APLICATION_ROLES } from 'enums/roles';

export const CustomRoute = (props) => {
  if (props.redirectPath) {
    return <Redirect pathname={props.redirectPath} />;
  }

  if (!props.secure) {
    return <Route {...props} />;
  }

  if (props.hasSession) {
    if (props.access) {
      if (props.userRole) {
        if (
          indexOf(props.access, props.userRole) >= 0 ||
          props.userRole === APLICATION_ROLES.Owner
        ) {
          return <Route {...props} />;
        } else {
          return <Redirect pathname="/" />; // TODO FORBIDEN PAGE
        }
      } else {
        return <>Loading profile</>;
      }
    } else {
      return <Route {...props} />;
    }
  }

  return <Redirect pathname="/login" />;
};

const mapStateProps = (state) => ({
  ...getHasSession(state),
  ...getUserRole(state),
});

export default connect(mapStateProps)(CustomRoute);
