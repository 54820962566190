import './style.scss';
import React, { useState } from 'react';
import StarIcon from '@material-ui/icons/Star';
import { FormattedMessage } from 'react-intl';

export default ({ value, maxValue, setValue, error }) => {
  const [over, setOver] = useState(0);
  const renderValue = Boolean(over) ? over : value;
  const stars = [];
  for (let x = 1; x <= maxValue; x++) {
    if (x <= renderValue) {
      stars.push(
        <StarIcon
          color="primary"
          fontSize="large"
          key={x}
          onClick={() => setValue(x)}
          onMouseOver={() => setOver(x)}
          onMouseOut={() => setOver(0)}
        />,
      );
    } else {
      stars.push(
        <StarIcon
          color="disabled"
          fontSize="large"
          key={x}
          onClick={() => setValue(x)}
          onMouseOver={() => setOver(x)}
          onMouseOut={() => setOver(0)}
        />,
      );
    }
  }
  return (
    <div className="Experiencia-Stars">
      <div className="Experiencia-Stars-Icons">{stars}</div>
      {Boolean(error) && (
        <div className="error-msg">
          <FormattedMessage id={error} />
        </div>
      )}
    </div>
  );
};
