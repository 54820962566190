import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      postalCode: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un código postal' }),
      ),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),

      state: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una provincia' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar una provincia' }),
        ),
    }),
  });
