import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { getApprovedProjectsWidget } from 'redux/dashboard/selector';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const ValueTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '2em',
    whiteSpace: 'nowrap',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    paddingRight: '1em',
  },
})(Typography);

const ApprovedProjects = ({ data, isLoading }) => (
  <Portlet>
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="M2 registrados" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      <div className="Atomovil-Rank">
        <DescriptionTypography>
          <FormattedMessage id="Este es el total de M2 de películas registradas durante este mes" />
        </DescriptionTypography>
        {!isLoading && (
          <div className="Atomovil-Rank-Value">
            <ValueTypography color="secondary">
              {data} <FormattedMessage id="M2" />
            </ValueTypography>
          </div>
        )}
      </div>
    </PortletBody>
  </Portlet>
);
export default connect(getApprovedProjectsWidget)(ApprovedProjects);
