import * as Yup from 'yup';

export default (intl, country) =>
  Yup.object().shape({
    installer: Yup.object().test(
      'hasInstaller',
      intl.formatMessage({ id: 'Debe ingresar un instalador' }),
      (value) => Boolean(value.id),
    ),

    sheets: Yup.array().of(
      Yup.object().shape({
        sheet: Yup.number()
          .nullable()
          .required(
            intl.formatMessage({ id: 'Debe seleccionar una película' }),
          ),
        run: Yup.string().required(
          intl.formatMessage({ id: 'Número de RUN invalido' }),
        ),
        distributor: Yup.number()
          .nullable()
          .when('$distributor', (_, schema) => {
            return schema.required(
              intl.formatMessage({
                id: 'Debe seleccionar un distribuidor',
              }),
            );
          }),
        application: Yup.object().test(
          'hasAplication',
          intl.formatMessage({ id: 'Debe seleccionar una opción' }),
          (value) =>
            value.front || value.back || value.left || value.right || value.top,
        ),
      }),
    ),
  });
