import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getDinocFilmsBuildingOptions } from 'redux/filmsBuilding/selector';
import { getDistributors } from 'redux/distributors/selector';
import { projectProgressOPTs } from 'enums/formOtps';
import { getStatusNewProject } from 'redux/arqFinishes/newProject/selector';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../step3/previewer.scss';
export const step4 = ({
  dinocFilmsBuildingOptions,
  distributorsOptions,
  values,
  prevStep,
  isLoading,
  hasError,
}) => (
  <>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <PorletHeader>
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="Resumen del cliente" />
          </PorletHeaderTitle>
          <PorletHeaderTitle variant="h6">
            <FormattedMessage id="Por favor revisar todos los datos del proyecto." />
          </PorletHeaderTitle>
        </PorletHeader>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row resumen">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Cliente:" />
                  </PorletHeaderTitle>

                  <div>
                    <strong>
                      <FormattedMessage id="Nombre:" />
                    </strong>
                    <span>{values.contactName}</span>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Email:" />
                    </strong>
                    <span>{values.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
            <div className="row">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Proyecto:" />
                  </PorletHeaderTitle>
                  <div>
                    <strong>
                      <FormattedMessage id="Nombre del proyecto:" />
                    </strong>
                    <span>{values.project.name}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Tipo de proyecto:" />
                    </strong>
                    <span>
                      <FormattedMessage id={values.project.type} />
                    </span>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Código postal:" />
                    </strong>
                    <span>{values.project.postalCode}</span>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Provincia:" />
                    </strong>
                    <span>{values.project.state}</span>
                  </div>
                  {values.installers.map((installer, index) => {
                    return (
                      <div key={index}>
                        <strong>
                          <FormattedMessage id="Instalador" /> {index + 1}:
                        </strong>
                        <span>{installer.name}</span>
                      </div>
                    );
                  })}
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Fecha de instalación" />
                    </strong>
                    <span>{values.installDate}</span>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row resumen">
              <div className="col-12">
                <div className="withmargin">
                  <div className="kt-space-20" />

                  <div className="kt-space-20" />
                  <PorletHeaderTitle variant="h6">
                    <FormattedMessage id="3M™ DI-NOC™" />
                  </PorletHeaderTitle>
                  <div className="kt-space-20" />
                  {Boolean(values.dinocSheets.length)}
                  {values.dinocSheets.map((dinocSheet, index) => {
                    return (
                      <>
                        {console.log(dinocSheet)}
                        <PorletHeaderTitle variant="h5">
                          <FormattedMessage id="Aplicación:" />
                        </PorletHeaderTitle>

                        <div>
                          <strong>
                            <FormattedMessage id="Sustrato: " />
                          </strong>
                          <span className="col-6">{dinocSheet.substrate}</span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Familia de Producto:" />
                          </strong>
                          <span className="col-6">{dinocSheet.family}</span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Producto:" />
                          </strong>
                          <span className="col-6">
                            {
                              /*get(
                              dinocFilmsBuildingOptions.find(
                                (item) => item.films.value == dinocSheet.film,
                              ),
                              'films.label',
                              )*/
                              dinocSheet.film
                            }
                          </span>
                        </div>
                        {dinocSheet.custom != '' ? (
                          <div>
                            <strong>
                              <FormattedMessage id="Observación:" />
                            </strong>
                            <span className="col-6">
                              {
                                /*get(
                              dinocFilmsBuildingOptions.find(
                                (item) => item.films.value == dinocSheet.film,
                              ),
                              'films.label',
                              )*/
                                dinocSheet.custom
                              }
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div>
                          <strong>
                            <FormattedMessage id="M2 Necesarios:" />
                          </strong>
                          <span className="col-6">{dinocSheet.meters}</span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Número de RUN:" />
                          </strong>
                          <span className="col-6">{dinocSheet.run}</span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Distribuidor:" />
                          </strong>
                          <span className="col-6">
                            {get(
                              distributorsOptions.find(
                                (item) => item.value === dinocSheet.distributor,
                              ),
                              'label',
                            )}
                          </span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Vista Previa" />
                          </strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/media/dinoc/' +
                              values.dinocSheets[index].film +
                              '.jpg'
                            }
                            className="img-previewer-summary col-6"
                          />
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="M2 Necesarios:" />
                          </strong>
                          <span className="col-6">{dinocSheet.meters}</span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="kt-space-20" />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8 ">
        <div>
          {hasError && (
            <Typography color="primary">
              <FormattedMessage id="Se a producido un error, vuelva a intentar" />
            </Typography>
          )}
        </div>
        <div className="submit-bottons">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            <FormattedMessage id="Enviar la garantía a su cliente para validación" />
          </Button>
          <Button variant="outlined" onClick={prevStep} disabled={isLoading}>
            <FormattedMessage id="Editar" />
          </Button>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4"></div>
    </div>
  </>
);

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
  dinocFilmsBuildingOptions: [],

  distrbutorsList: getDistributors(state),
  ...getStatusNewProject(state),
});

export default connect(mapStateToProps)(step4);
