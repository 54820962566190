import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { getfasaraFilmsBuildingOptions } from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';
import { requirementTypeOPTs } from 'enums/formOtps';
import {
  glassFinishesCategories,
  glassFinishesFilms,
  glassFinishesFamilies,
} from 'enums/glassFinishesFilms';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import './previewer.scss';

const newSheet = {
  type: '',
  meters: '',
  distributor: '',
  custom: '',
  glassFinish: '',
  film: '',
  family: '',
};

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  installDate: null,
  installers: [{ ...newInstaller }],
  fasaraSheets: [],
  family: {},
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: false,
    });
  }

  return filtered;
};
function clearCustom(state, value, index) {
  let newValue = '';
  if (value == false) {
    state.fasaraSheets[index].custom = newValue;
  }
}
const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Step3 = ({
  values,
  country,
  errors: { fasaraSheets },
  prevStep,
  fetchDistributors,
  fetchUserInstallersRequest,
  distributorsOptions,
  userInstallersOptions,
  fasaraFilmsBuildingOptions,
  fetchFilmsBuildingRequest,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const distributorFiltered = distributorsOptions.filter((distributor) => {
    return distributor.users.some((user) => user.fasara === true);
  });

  /* // Eliminar duplicados basados en el id
  const uniqueDistributorFiltered = distributorFiltered.reduce(
    (uniqueDistributors, distributor) => {
      if (!uniqueDistributors.some((d) => d.id === distributor.id)) {
        uniqueDistributors.push(distributor);
      }
      return uniqueDistributors;
    },
    [],
  ); */

  const filteredOptions = distributorFiltered.filter((option) => {
    const isNLorBE = country === 'NL' || country === 'BE';

    if (isNLorBE == true) {
      return (
        !option.label.toLowerCase().includes('3m') &&
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    } else {
      return (
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    }
  });

  return (
    <>
      <div className="kt-space-20" />
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Glass Finishes" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              {/*<div className="row">
                <div className="col-sm-12 col-md-12 col-lg-2" />
                <div className="col-sm-12 col-md-12 col-lg-8">

                  <div className="row">
                  <div className="kt-space-20" />
                    <div className="col-12">
                      <Field
                        name="project.need"
                        component={Autocomplete}
                        label="Principal necesidad *"
                        variant="outlined"
                        options={requirementTypeOPTs}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4" />
  </div>*/}
              <FieldArray name="fasaraSheets">
                {({ push, remove }) => (
                  <>
                    {values.fasaraSheets.length < 1 ? push(newSheet) : <></>}
                    {values.fasaraSheets.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-sm-12 col-lg-6">
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].glassFinish`}
                                component={Autocomplete}
                                label="Categoría *"
                                variant="outlined"
                                options={glassFinishesCategories}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].family`}
                                component={Autocomplete}
                                label="Familia *"
                                variant="outlined"
                                options={
                                  glassFinishesFamilies.filter(
                                    (e) =>
                                      e.category ==
                                      values.fasaraSheets[index].glassFinish,
                                  ) || 'Custom'
                                }
                              />
                            </div>
                          </div>

                          <div className="Row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].film`}
                                component={Autocomplete}
                                label="Producto *"
                                helperText="Por favor seleccione una película 3M™ Fasara™ "
                                variant="outlined"
                                options={
                                  glassFinishesFilms.filter(
                                    (e) =>
                                      e.family ==
                                      values.fasaraSheets[index].family,
                                  ) || 'Custom'
                                }
                                onBlur={clearCustom(
                                  values,
                                  values.fasaraSheets[index].film == 'Custom' &&
                                    values.fasaraSheets[index].family ==
                                      'Custom',
                                  index,
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].distributor`}
                                component={Autocomplete}
                                label="Distribuidor"
                                helperText="Por favor selecione el distribuidor"
                                variant="outlined"
                                options={filteredOptions}
                                placeholder="Seleccionar"
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].meters`}
                                component={TextField}
                                type="text"
                                label="M2 necesarios *"
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <div className="film-previewer">
                            <PorletHeaderTitle variant="h7">
                              <FormattedMessage id="Vista Previa" />
                            </PorletHeaderTitle>
                            <img
                              className="img-previewer"
                              src={
                                values.fasaraSheets[index].film
                                  ? process.env.PUBLIC_URL +
                                    '/media/fasara/' +
                                    values.fasaraSheets[index].film.toString() +
                                    '.jpg'
                                  : process.env.PUBLIC_URL +
                                    '/media/fasara/Choose.jpg'
                              }
                              alt="values.fasaraSheets[index]"
                            />

                            <Field
                              name={`fasaraSheets[${index}].custom`}
                              component={TextField}
                              type="text"
                              label="Custom 3M™ Glass Finish™"
                              variant="outlined"
                              fullWidth
                              disabled={
                                values.fasaraSheets[index].film == 'Custom' &&
                                values.fasaraSheets[index].family == 'Custom'
                                  ? false
                                  : true
                              }
                              helperText='Por favor complete sólo si eligió una película "CUSTOM"'
                            />
                          </div>
                        </div>
                        {values.fasaraSheets.length > 0 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta película" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.fasaraSheets.length < 6 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newSheet)}
                            >
                              <FormattedMessage id="Agregar nueva lámina" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {typeof fasaraSheets === 'string' && (
                      <div className="row">
                        <div className="col-sm-12">
                          <FormHelperText error={Boolean(fasaraSheets)}>
                            {fasaraSheets}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state, { values: { installers } }) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state).filter(
    (item) => !installers.find((installer) => installer.id === item.value.id),
  ),
  fasaraFilmsBuildingOptions: [
    {
      family: { index: 0, label: 'AE', value: 0 },
      films: [
        {
          index: 0,
          label: 'AE-1632',
          value: 'AE-1632',
        },
      ],
    },
    {
      family: { index: 1, label: 'ee', value: 1 },
      films: [
        {
          index: 0,
          label: 'AE-1633',
          value: 'AE-1633',
        },
        {
          index: 1,
          label: 'AE-1634',
          value: 'AE-1634',
        },
      ],
    },
    {
      family: { index: 2, label: 'CUSTOM', value: 2 },
      films: [
        {
          index: 0,
          label: 'CUSTOM',
          value: 'CUSTOM',
        },
      ],
    },
  ],

  //getfasaraFilmsBuildingOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
