import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

export const WidgetTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
})(Tabs);

export const WidgetTab = withStyles(() => ({
  root: {
    flex: '1 0 auto',
    minWidth: 'auto',
    padding: '0',
    margin: '0',
  },
}))((props) => <Tab disableRipple {...props} />);

export const WidgetTabTitle = withStyles({
  root: {
    padding: '15px 15px 0 15px',
    margin: '0',
    fontSize: '1.3em',
    flex: '2 0 auto',
  },
})(Typography);
