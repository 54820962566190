import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import {
  requests as citiesRequest,
  actions as citiesActions,
} from 'redux/cities/reducer';

import { Field } from 'formik';

import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import Typography from '@material-ui/core/Typography';

import TextField from 'components/form/input';
import PhoneIcon from '@material-ui/icons/Phone';
import Autocomplete from 'components/form/autocomplete';
import { EUROPE, LATAM } from 'enums/regions';

export const Step2 = ({
  country,
  fetchStates,
  fetchCities,
  stateOptions,
  citiesOptions,
  clearCities,
  values: { state },
  setFieldValue,
  errors,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    if (state) {
      fetchCities({
        country,
        state: stateOptions.find((opt) => opt.value === state)
          ? stateOptions.find((opt) => opt.value === state).id
          : '',
      });
    } else {
      clearCities();
    }
  }, [country, fetchCities, clearCities, state, stateOptions]);
  console.log(errors);

  const isLatam = country && LATAM.includes(country);
  const isEurope = country && EUROPE.includes(country);
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-1" />
      <div className="col-sm-12 col-md-12 col-lg-10">
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4" />
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Typography variant="h5">
              <FormattedMessage id="Negocio" />
            </Typography>
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Nombre Del Negocio: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="shopName"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Razon Social: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="name"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        {isLatam && (
          <div className="row FormRow">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <FormattedMessage id="CUIT: *" />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <Field
                name="taxId"
                component={TextField}
                type="text"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        )}
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Calle: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="street"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Número: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="number"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Código Postal: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="postalCode"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Provincia: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="state"
              component={Autocomplete}
              handleChange={() => {
                setFieldValue('city', '');
              }}
              variant="outlined"
              options={stateOptions}
            />
          </div>
        </div>
        {/* <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Ciudad: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="city"
              component={Autocomplete}
              variant="outlined"
              options={citiesOptions}
            />
          </div>
        </div> */}
        {/*<div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Correo electronico: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="email"
              component={TextField}
              type="text"
              helperText="No compartiremos el Email con nadie mas"
              variant="outlined"
              fullWidth
              placeholder="Ingresar dirección de email"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div> */}
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Celular: *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="cellphone"
              component={TextField}
              type="text"
              helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
              variant="outlined"
              fullWidth
              placeholder="Cód. de país + cód. de área + número"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {/* <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Teléfono:" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="phone"
              component={TextField}
              type="text"
              helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
              variant="outlined"
              fullWidth
              placeholder="Cód. de país + cód. de área + número"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div> */}
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Web:" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="web"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Facebook:" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="facebook"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Instagram:" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="instagram"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Linkedin:" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="linkedin"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-1" />
    </div>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
  clearCities: (data) => dispatch(citiesActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
