import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import Seacrch from './search';
import Actions from './actions';

import { actions as usersListActions } from 'redux/user/userList/reducer';
import { getUsersTableData } from 'redux/user/userList/selector';

import DataTable from 'components/dataTable';

import ctn from 'helpers/countries';

import Button from '@material-ui/core/Button';
// import { getStatusFile } from 'redux/reports/selector';
// import { requests as reportsRequests } from 'redux/reports/reducer';

// TODO revisar si van los RUNS
const columns = [
  { prop: 'username', label: 'email' },
  { prop: 'firstname', label: 'Nombre' },
  { prop: 'lastname', label: 'Apellido' },
  {
    prop: 'enabled',
    label: 'habilitado',
    format: (value, row) => (
      <>
        <FormattedMessage id={value ? 'SI' : 'NO'} />
      </>
    ),
  },
  {
    prop: 'uuid',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value }} />,
  },
];

const Table = ({ setFilter, ...props }) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);
  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-2 offset-lg-7">
          <Link to="/backoffice/users/new">
            <Button variant="contained" color="primary">
              Nuevo Usuario
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    ...getUsersTableData(state),
  };
};
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(usersListActions.setFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
