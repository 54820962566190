// Se traducen solo lo labels, pero los labels y los values son keys de traducciones
export const clientTypeOPTs = [
  { label: 'Usuario', value: 'Usuario' },
  { label: 'Concesionario', value: 'Concesionario' },
];

export const substrateTypeOPTs = [
  {
    label:
      'Sealed Drywall (Gypsum Board, Sheetrock®, Plaster Board, etc) - Level 5 Finish required',
    value:
      'Sealed Drywall (Gypsum Board, Sheetrock®, Plaster Board, etc) - Level 5 Finish required',
  },
  {
    label: 'Wood (MDF, plywood (smooth), Wood Veneer, etc.)',
    value: 'Wood (MDF, plywood (smooth), Wood Veneer, etc.)',
  },
  {
    label: 'Metal (Coated Steel, Aluminum, Stainless Steel, etc.)',
    value: 'Metal (Coated Steel, Aluminum, Stainless Steel, etc.)',
  },
  {
    label: 'Plastic Laminate (High Pressure Laminate, Melamine, etc.)',
    value: 'Plastic Laminate (High Pressure Laminate, Melamine, etc.)',
  },
  {
    label:
      'Sealed Stone (Marble, Granite, Quartz, Solid Surface Material (i.e. Corian®), etc.)',
    value:
      'Sealed Stone (Marble, Granite, Quartz, Solid Surface Material (i.e. Corian®), etc.)',
  },
  {
    label:
      'Concrete/Stucco (not recommend unless wrapped with a secondary substrate (i.e. metal enclosure))',
    value:
      'Concrete/Stucco (not recommend unless wrapped with a secondary substrate (i.e. metal enclosure))',
  },
  {
    label: 'Glass (recommend use of DI-NOC™ for glass)',
    value: 'Glass (recommend use of DI-NOC™ for glass)',
  },
];

export const projectTypeOPTsEurope = [
  { label: 'Hoteleria', value: 'Hoteleria' },
  { label: 'Salud', value: 'Salud' },
  { label: 'Educación', value: 'Educación' },
  { label: 'Gubernamental', value: 'Gubernamental' },
  { label: 'Industria Alimenticia', value: 'Industria Alimenticia' },
  { label: 'Restaurants', value: 'Restaurants' },
  {
    label: 'Edificios de oficinas Clase A',
    value: 'Edificios de oficinas Clase A',
  },
  { label: 'Finanzas - Bancos', value: 'Finanzas - Bancos' },
  {
    label: 'Infraestructura de transporte',
    value: 'Infraestructura de transporte',
  },
  { label: 'Residencial', value: 'Residencial' },
  { label: 'Marino', value: 'Marino' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Shopping', value: 'Shopping' },
  { label: 'Otro', value: 'Otro' },
];

export const projectTypeOPTs = [
  { label: 'Hoteleria', value: 'Hoteleria' },
  { label: 'Salud', value: 'Salud' },
  { label: 'Educación', value: 'Educación' },
  { label: 'Gubernamental', value: 'Gubernamental' },
  { label: 'Industria Alimenticia', value: 'Industria Alimenticia' },
  { label: 'Restaurants', value: 'Restaurants' },
  {
    label: 'Edificios de oficinas Clase A',
    value: 'Edificios de oficinas Clase A',
  },
  { label: 'Finanzas - Bancos', value: 'Finanzas - Bancos' },
  {
    label: 'Infraestructura de transporte',
    value: 'Infraestructura de transporte',
  },
  { label: 'Residencial', value: 'Residencial' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Shopping', value: 'Shopping' },
  { label: 'Otro', value: 'Otro' },
];
export const mainNeedDinoc = [
  {
    label:
      'Refresh and remodel - Interiors (walls, columns, doors, cabinets, and more.)',
    value:
      'Refresh and remodel - Interiors (walls, columns, doors, cabinets, and more.)',
  },
  {
    label:
      'New Construction - Interiors (walls, columns, doors, cabinets, and more.)',
    value:
      'New Construction - Interiors (walls, columns, doors, cabinets, and more.)',
  },
  {
    label:
      'Furniture/Fixture/Way Finding – Existing (tables, displays,  pedestals, signage and more)',
    value:
      'Furniture/Fixture/Way Finding – Existing (tables, displays,  pedestals, signage and more)',
  },
  {
    label:
      'Furniture/Fixture/ Way Finding – New (tables, displays,  pedestals, signage and more)',
    value:
      'Furniture/Fixture/ Way Finding – New (tables, displays,  pedestals, signage and more)',
  },
  {
    label:
      'Building Exterior Elements (façades, window frames, doors, columns, walls and more.)',
    value:
      'Building Exterior Elements (façades, window frames, doors, columns, walls and more.)',
  },
  { label: 'Exterior Signage', value: 'Exterior Signage' },
];
export const requirementTypeOPTs = [
  { label: 'Control Climático', value: 'Control Climático' },
  { label: 'Privacidad', value: 'Privacidad' },
  { label: 'Reducir Calor', value: 'Reducir Calor' },
  { label: 'Reducir Reflejo', value: 'Reducir Reflejo' },
  { label: 'Seguridad', value: 'Seguridad' },
  { label: 'Energía', value: 'Energía' },
  { label: 'Ahorro de CO2', value: 'Ahorro de CO2' },
];

export const glassTypeOPTs = [
  { label: 'Vidrio doble - DVH', value: 'Vidrio doble - DVH' },
  { label: 'Vidrio doble tintado', value: 'Vidrio doble tintado' },
  { label: 'Vidrio simple crudo', value: 'Vidrio simple crudo' },
  { label: 'Vidrio simple tintado', value: 'Vidrio simple tintado' },
  { label: 'Vidrio laminado', value: 'Vidrio laminado' },
  { label: 'Vidrio simple templado', value: 'Vidrio simple templado' },

  { label: 'Vidrio Triple', value: 'Vidrio Triple' }, //Triple Glazing
  {
    label: 'Vidrio doble de baja emisividad',
    value: 'Vidrio doble de baja emisividad',
  }, //Double Low-E Glass
  {
    label: 'Vidrio doble de baja emisividad 2',
    value: 'Vidrio doble de baja emisividad 2',
  }, //Double Low-E Glass 2
  {
    label: 'Vidrio doble de baja emisividad 3',
    value: 'Vidrio doble de baja emisividad 3',
  }, // //Double Low-E Glass 3

  { label: 'Otro', value: 'Otro' },
];

export const checkListTypeOPTs = [
  { label: 'SI', value: 'SI' },
  { label: 'NO', value: 'NO' },
];

export const checkUserCaseStudyOPTs = [
  { label: 'SI', value: 'SI' },
  { label: 'NO', value: 'NO' },
];

export const projectStatusOPTs = [
  { label: 'Ganada', value: 'Ganada' },
  { label: 'Perdida', value: 'Perdida' },
  { label: 'Cancelada', value: 'Cancelada' },
  { label: 'Pendiente', value: 'Pendiente' },
];

export const projectProgressOPTs = [
  { label: '10% Relevamiento', value: '10% Relevamiento' },
  {
    label: '30% Presentación de propuesta tecnica',
    value: '30% Presentación de propuesta tecnica',
  },
  {
    label: '60% Presentación de cotización',
    value: '60% Presentación de cotización',
  },
  {
    label: '80% Negociación / definición por parte del cliente',
    value: '80% Negociación / definición por parte del cliente',
  },
  { label: '100% Cierre / Ganada', value: '100% Cierre / Ganada' },
];

export const yearOPTs = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
];
export const monthOPTs = [
  { label: 'Enero', value: '01' },
  { label: 'Febrero', value: '02' },
  { label: 'Marzo', value: '03' },
  { label: 'Abril', value: '04' },
  { label: 'Mayo', value: '05' },
  { label: 'Junio', value: '06' },
  { label: 'Julio', value: '07' },
  { label: 'Agosto', value: '08' },
  { label: 'Septiembre', value: '09' },
  { label: 'Octubre', value: '10' },
  { label: 'Noviembre', value: '11' },
  { label: 'Diciembre', value: '12' },
];

export const countryOPTs = [
  { label: 'Argentina', value: 'AR' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Brasil', value: 'BR' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'República Dominicana', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'México', value: 'MX' },
  { label: 'Panamá', value: 'PA' },
  { label: 'Perú', value: 'PE' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Uruguay', value: 'UY' },
];

export const regionOPTs = [
  { label: 'Andina', value: 'PE,CO,PY,BO,EC' },
  { label: 'Brasil', value: 'BR' },
  { label: 'C&C', value: 'CE,CR,GT,HN,PA,SV' },
  { label: 'México', value: 'MX' },
  { label: 'SoCo', value: 'AR,CL,UY' },
];
