import MESSAGES from './ar';

export default {
  ...MESSAGES,
  'Número de Patente:': 'Número de Placa o de chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número placa o chasis',
  'Número de patente *': 'Número de placa o chasis*',
  'Por favor ingrese el número de patente':
    'Por favor ingrese el número de placa o de chasis',
  'CUIT:': 'RUC:',
  'CUIT: *': 'RUC: *',
  'Debe ingresar un CUIT': 'Debes ingresar un RUC',
  Dominio: 'Placa',
  'Dominio:': 'Placa o chasis si es concesionario:',
  'Número de Patente:': 'Número de placa o chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número de placa o de chasis',
  'Número de patente *': 'Número de placa o chasis si es concesionario *',
  'Por favor ingrese el número de patente':
    'Por favor ingresa el número de placa o de chasis',
};
