import { REQUEST_METHODS } from 'enums/request';
import createRequestSlice, {
  requestInitialState,
  getSliceRequestActionTypes,
  createRequestAction,
} from 'redux/request/reducer';

export const PREFIX = 'profile';
export const PREFIX_EDIT = 'profile.editContact';
export const PREFIX_CHANGE_PASSWORD = 'profile.changePassword';

export const initialState = {
  ...requestInitialState,
  data: {},
  isSaveSuccess: false,
};

const profileSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    clearSave(state) {
      return {
        ...state,
        isSaveSuccess: false,
        error: false,
      };
    },
    fetch(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    },
    editContact(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isSaveSuccess: true,
      };
    },
    changePassword(state) {
      return {
        ...state,
        isSaveSuccess: true,
      };
    },
  },
});

const actionTypes = getSliceRequestActionTypes(profileSlice);

const editContact = createRequestAction({
  prefix: PREFIX_EDIT,
  method: REQUEST_METHODS.Create,
  actions: { ...actionTypes, success: profileSlice.actions.editContact.type },
});

const changePassword = createRequestAction({
  prefix: PREFIX_CHANGE_PASSWORD,
  method: REQUEST_METHODS.Create,
  actions: {
    ...actionTypes,
    success: profileSlice.actions.changePassword.type,
  },
});

export const actions = { ...profileSlice.actions };
export const requests = {
  ...profileSlice.requests,
  editContact,
  changePassword,
};

export default profileSlice.reducer;
