import get from 'lodash/get';

import { PREFIX } from './reducer';

export const getStatusNewProject = (state) => {
  const error = get(state, `${PREFIX}.error`, []) || [];
  var bla;
  if (Array.isArray(error)) {
    bla = error.find((value) => value.message.search('Code') !== -1);
  } else {
    bla = error.message.search('Code') !== -1;
  }
  const codeError = bla;
  return {
    hasError: Boolean(get(state, `${PREFIX}.error`, false)),
    codeError: Boolean(codeError),
    isLoading: Boolean(get(state, `${PREFIX}.status`, false)),
    isSuccess: Boolean(get(state, `${PREFIX}.isSuccess`, false)),
    code: get(state, `${PREFIX}.data.code`, null),
  };
};
