import React from 'react';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

function BreadCrumbs(props) {
  const { title, items } = props;
  if (!items || !items.length) {
    return '';
  }

  const length = items.length;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h4">
        <FormattedMessage id={title} />
      </Typography>
      <Link to={`/dashboard`} style={{ color: '#bbb' }}>
        <HomeOutlinedIcon
          style={{
            fontSize: '20px',
            marginLeft: '10px',
            top: '-2px',
            position: 'relative',
            color: '#bbb',
          }}
        />
      </Link>

      <div style={{ padding: '0 5px', display: 'inline-block', color: '#bbb' }}>
        -
      </div>
      <div>
        {items.map((item, index) => (
          <React.Fragment key={`bc${index}`}>
            <Link
              to={`/${item.page}`}
              style={{ color: '#bbb', fontSize: '15px' }}
            >
              <FormattedMessage id={item.title} />
            </Link>
            {length !== index + 1 && (
              <div
                style={{
                  padding: '0 5px',
                  display: 'inline-block',
                  color: '#bbb',
                }}
              >
                -
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default BreadCrumbs;
