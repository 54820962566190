import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';

const ExtendTextField = injectIntl(
  ({ intl, helperText = '', placeholder = '', label = '', ...props }) => {
    const helper = helperText ? intl.formatMessage({ id: helperText }) : '';
    const place = placeholder ? intl.formatMessage({ id: placeholder }) : '';
    const lbl = label ? intl.formatMessage({ id: label }) : '';
    return (
      <TextField
        helperText={helper}
        placeholder={place}
        label={lbl}
        {...props}
      />
    );
  },
);

export default withStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.8rem',
      marginLeft: 0,
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
    },
    '& .MuiInputBase-root': {
      '& legend': {
        display: 'none',
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      padding: '10px 12px',
    },
  },
}))(ExtendTextField);
