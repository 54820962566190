import React from 'react';
import Layout from '_metronic/layout/Layout';

export default function NewGuarantee(props) {
  return (
    <Layout>
      <div>product</div>
      <code>{JSON.stringify(props)}</code>
    </Layout>
  );
}
