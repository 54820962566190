export const EUROPE = [
  'GB',
  'DK',
  'SE',
  'NO',
  'FI',
  'ES',
  'PT',
  'FR',
  'DE',
  'IT',
  'CH',
  'AT',
  'NL',
  'BE',
];
export const LATAM_ES = [
  'AR',
  'BO',
  'CE',
  'CL',
  'CO',
  'CR',
  'DO',
  'EC',
  'GT',
  'HN',
  'JM',
  'MX',
  'PA',
  'PE',
  'PY',
  'SV',
  'UY',
];
export const LATAM = [
  'AR',
  'BO',
  'BR',
  'CE',
  'CL',
  'CO',
  'CR',
  'DO',
  'EC',
  'GT',
  'HN',
  'JM',
  'MX',
  'PA',
  'PE',
  'PY',
  'SV',
  'UY',
];

export const LATAM_LABEL = [
  { label: 'Argentina', value: 'AR' },
  { label: 'Brasil', value: 'BR' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'República Dominicana', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'México', value: 'MX' },
  { label: 'Panamá', value: 'PA' },
  { label: 'Perú', value: 'PE' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Uruguay', value: 'UY' },
];

export const EUROPE_LABEL = [
  { label: 'England', value: 'GB' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Norway', value: 'NO' },
  { label: 'Finland', value: 'FI' },
  { label: 'España', value: 'ES' },
  { label: 'Portugal', value: 'PT' },
  { label: 'France', value: 'FR' },
  { label: 'Deutschland', value: 'DE' },
  { label: 'Italia', value: 'IT' },
  { label: 'Schweiz', value: 'CH' },
  { label: 'Österreich', value: 'AT' },
  { label: 'Holland', value: 'NL' },
  { label: 'Belgium', value: 'BE' },
];

//para el form de registro
export let EUROPE_FLAGS = {
  GB: 'England',
  DK: 'Denmark',
  SE: 'Sweden',
  NO: 'Norway',
  FI: 'Finland',
  ES: 'España',
  PT: 'Portugal',
  FR: 'France',
  DE: 'Deutschland',
  IT: 'Italia',
  CH: 'Schweiz',
  AT: 'Österreich',
  NL: 'Holland',
  BE: 'Belgium',
};

export const LATAM_FLAGS = {
  AR: 'Argentina',
  BR: 'Brasil',
  BO: 'Bolivia',
  CL: 'Chile',
  CO: 'Colombia',
  CR: 'Costa Rica',
  DO: 'República Dominicana',
  EC: 'Ecuador',
  GT: 'Guatemala',
  HN: 'Honduras',
  MX: 'México',
  PA: 'Panamá',
  PE: 'Perú',
  PY: 'Paraguay',
  SV: 'El Salvador',
  UY: 'Uruguay',
};
