import { combineReducers } from 'redux';
import projectList from './projectList/reducer';
import editProject from './editProject/reducer';
import newProject from './newProject/reducer';

export default combineReducers({
  projectList,
  editProject,
  newProject,
});
