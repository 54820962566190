import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Chart from './chart';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { getGFProjectsByCountryWidget } from 'redux/dashboard/selector';

import Button from '@material-ui/core/Button';

const Projects = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--fit">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Proyectos por país" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      <div>{!isLoading && <Chart data={data} />}</div>
      <div className="kt-space-20" />
      {/* <div>
        <Button variant="outlined">
          <FormattedMessage id="Export..." />
        </Button>
      </div> */}
    </PortletBody>
  </Portlet>
);

export default connect(getGFProjectsByCountryWidget)(Projects);
