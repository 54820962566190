import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import {
  requests as citiesRequest,
  actions as citiesActions,
} from 'redux/cities/reducer';

import { Field } from 'formik';
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';

import { RadioGroup } from 'formik-material-ui';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import PhoneIcon from '@material-ui/icons/Phone';

import { clientTypeOPTs } from 'enums/formOtps';

export const initialValues = {
  subtype: 'WRAP',
  type: 'Usuario',
  dealerName: '',
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  state: null,
  city: null,
  sendInformation: 'si',
};

export const Step1 = ({
  country,
  fetchStates,
  fetchCities,
  clearCities,
  stateOptions,
  citiesOptions,
  values: { state, type },
  setFieldValue,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    if (state) {
      fetchCities({
        country,
        state: stateOptions.find((opt) => opt.value === state)
          ? stateOptions.find((opt) => opt.value === state).id
          : '',
      });
    } else {
      clearCities();
    }
  }, [country, fetchCities, clearCities, state, stateOptions]);
  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="type"
            component={Autocomplete}
            label="Tipo de cliente *"
            helperText="Por favor seleccione el tipo de cliente"
            variant="outlined"
            options={clientTypeOPTs}
            placeholder="Seleccionar"
            InputLabelProps={{ shrink: true }}
            handleChange={() => {
              setFieldValue('dealerName', '');
            }}
          />
          <Field
            name="dealerName"
            component={TextField}
            type="text"
            label="Concesionario"
            variant="outlined"
            disabled={type === 'Usuario'}
            fullWidth
            placeholder="Nombre del concesionario"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="firstName"
            component={TextField}
            type="text"
            label="Nombre *"
            variant="outlined"
            fullWidth
            placeholder="Nombre del cliente"
            helperText=" "
            InputLabelProps={{ shrink: true }}
          />
          <Field
            name="email"
            component={TextField}
            type="text"
            label="Correo electronico *"
            helperText="No compartiremos el Email con nadie mas"
            variant="outlined"
            fullWidth
            placeholder="Ingresar dirección de email"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
      {/* <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="sendInformation"
            component={RadioGroup}
            className="sendInformation"
          >
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label={<FormattedMessage id="Desea recibir información" />}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={<FormattedMessage id="No desea recibir información" />}
            />
          </Field>
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div
          className="col-sm-12 col-md-12 col-lg-8"
          style={{ fontSize: '0.5em' }}
        >
          Cuando incluya SI su información, se almacenará en un servidor en los
          Estados Unidos. Al elegir no, esta información solo se utilizará para
          responder a sus solicitudes; para obtener más información, consulte
          nuestra Política de privacidad. Si no está de acuerdo con este uso de
          su información personal, no use la plataforma de soporte. Para mas
          detalles sobre nuestra Política de Privacidad consulte en nuestra web
          https://www.3m.com/3M/en_US/select-location/ en la locación que usted
          se encuentre
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div> */}
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
  clearCities: (data) => dispatch(citiesActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
