import * as Yup from 'yup';
import 'utils/phoneValidator';

export default (intl) =>
  Yup.object().shape({
    customer: Yup.object().shape({
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      firstname: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      // lastname: Yup.string().required(
      //   intl.formatMessage({ id: 'Debe ingresar un apellido' }),
      // ),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
        .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
      // cellphone: Yup.string()
      //   .phone(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }))
      //   .required(
      //     intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }),
      //   ),
    }),
  });
