import './style.scss';
import React from 'react';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { toAbsoluteUrl } from '_metronic';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

// const WhiteButton = withStyles({
//   root: {
//     color: '#FFF',
//   },
//   outlined: {
//     borderColor: '#FFF',
//   },
// })(Button);

const LabelTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1em',
    color: '#FFF',
  },
})(Typography);

const TitleTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '4em',
    color: '#FFF',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1.5em',
    color: '#FFF',
  },
})(Typography);

export default () => (
  <Portlet className="kt-portlet--height-fluid">
    <PortletBody
      style={{
        background: `url("${toAbsoluteUrl('/media/bg/red-box.png')}")`,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundSize: 'cover',
      }}
    >
      <div>
        <LabelTypography>
          <FormattedMessage id="Mensaje / Anuncio" />
        </LabelTypography>
      </div>
      <div>
        <TitleTypography variant="h1">
          <FormattedMessage id="Automotriz" />
        </TitleTypography>
        <DescriptionTypography>
          <FormattedMessage id="ANUNCIO_INSTALADOR_AUTO" />
        </DescriptionTypography>
      </div>
      <div className="kt-space-20" />
      {/* <div>
        <WhiteButton variant="outlined">
          <FormattedMessage id="Ver más" />
        </WhiteButton>
      </div> */}
    </PortletBody>
  </Portlet>
);
