import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getHasSession = (state) => ({
  hasSession: get(state, 'auth.hasSession'),
});

export const getIsTokenExpired = (state) => ({
  isTokenExpired: get(state, 'auth.isTokenExpired'),
});

export const getStatusLogin = (state) => ({
  hasError: Boolean(get(state, 'auth.error', false)),
  isLoading: Boolean(get(state, 'auth.status', false)),
});

export const getToken = (state) => ({
  accessToken: get(state, 'auth.accessToken'),
  // accessToken: window.localStorage.accessToken,
});

export const getRefreshToken = (state) => ({
  refreshToken: get(state, 'auth.refreshToken'),
  // refreshToken: window.localStorage.refreshToken,
});

export const getAuthorizationHeader = createSelector(
  getToken,
  ({ accessToken }) =>
    accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
);

export const getRequestHeaders = createSelector(
  getAuthorizationHeader,
  Object.assign,
);
