import React from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import Table from './table';
import { withStyles } from '@material-ui/core/styles';

const WidgetTitleTypography = withStyles({
  root: {
    padding: '30px',
    margin: '0',
    fontSize: '1.3em',
    borderBottom: '1px solid #e8e8e8',
  },
})(Typography);

const breadcrumb = [{ page: '3m/distributor', title: 'Distribuidores' }];

export default function DistributorList() {
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Distribuidores" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Portlet className="kt-portlet--height-fluid">
            <WidgetTitleTypography variant="h4">
              <FormattedMessage id="Distribuidores" />
            </WidgetTitleTypography>
            <PortletBody>
              <div className="kt-space-20" />
              <div>
                <Table />
              </div>
              <div className="kt-space-20" />
            </PortletBody>
          </Portlet>
        </div>
      </div>
    </Layout>
  );
}
