import './style.scss';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';

const LabelTypography = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '1em',
  },
})(Typography);

const ValueTypography = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
})(Typography);

export default ({ value, label }) => {
  return (
    <div className="Atomovil-Progress">
      <div className="Atomovil-Progress-Head">
        <ValueTypography>{`${value.toFixed(2)} %`}</ValueTypography>
        <LabelTypography>
          <FormattedMessage id={label} />
        </LabelTypography>
      </div>
      <LinearProgress variant="determinate" value={value} />
    </div>
  );
};
