import { combineReducers } from 'redux';
import clientList from './clientList/reducer';
import editClient from './editClient/reducer';
import newClient from './newClient/reducer';

export default combineReducers({
  clientList,
  editClient,
  newClient,
});
