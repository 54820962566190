import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

export const Warranty = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12 ViewOrEdit">
        <div>
          <strong>
            <FormattedMessage id="Código de garantía:" />
          </strong>
          <span>{get(values, 'code', '-')}</span>
        </div>
      </div>
    </div>
  </>
);

export default Warranty;

// import React from 'react';
// import { FormattedMessage } from 'react-intl';

// import get from 'lodash/get';
// import Button from '@material-ui/core/Button';

// import { connect } from 'react-redux';
// import { push } from 'connected-react-router';

// //values en el estado se encuentra en cars.editCLient.data
// export const Warranty = ({ push, values }) => (
//   <>
//     <div className="row">
//       <div className="col-12 ViewOrEdit">
//         <div>
//           <strong>
//             <FormattedMessage id="Código de garantía:" />
//           </strong>
//           <span>{get(values, 'code', '-')}</span>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-12 ViewOrEdit">
//           {values.code == null && values.isClosed ? (
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={() => {
//                 console.log(values);
//                 push(`/distributor/building/warranty/${values.id}`);
//               }}
//             >
//               <FormattedMessage id="Otorgar certificado de garantía" />
//             </Button>
//           ) : (
//             <></>
//           )}
//         </div>
//       </div>
//     </div>
//   </>
// );

// export default connect(null, { push })(Warranty);
