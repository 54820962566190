// import { createSlice } from '@reduxjs/toolkit';
import { REQUEST_METHODS } from 'enums/request';
import createRequestSlice, {
  requestInitialState,
  getSliceRequestActionTypes,
  createRequestAction,
} from 'redux/request/reducer';

export const PREFIX = 'auth';
export const PREFIX_REFRESH_TOKEN = `${PREFIX}/refreshToken`;

export const initialState = {
  ...requestInitialState,
  isTokenExpired: false,
  hasSession: false,
  accessToken: '',
  refreshToken: '',
  user: {
    fullname: 'Juan Neyra',
  },
};

const autnSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    logOut() {
      return;
    },
    setAuth(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    setTokenExpire(state, { payload }) {
      return {
        ...state,
        isTokenExpired: payload,
      };
    },
    refresh(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

const actionTypes = getSliceRequestActionTypes(autnSlice);

const refresh = createRequestAction({
  prefix: PREFIX_REFRESH_TOKEN,
  method: REQUEST_METHODS.Create,
  actions: { ...actionTypes, success: autnSlice.actions.refresh.type },
});

export const actions = { ...autnSlice.actions };
export const requests = { ...autnSlice.requests, refresh };

export default autnSlice.reducer;
