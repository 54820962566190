import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { getCarWidget } from 'redux/dashboard/selector';

import Button from '@material-ui/core/Button';

import Table from './table';

const RankingCar = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Ranking de automóviles" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      {!isLoading && (
        <>
          <Table data={data} />
          <div className="kt-space-20" />
          {/* <div>
            <Button variant="outlined">
              <FormattedMessage id="Export..." />
            </Button>
          </div> */}
        </>
      )}
    </PortletBody>
  </Portlet>
);

export default connect(getCarWidget)(RankingCar);
