export const autoProducts = [
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Serie Crystalline',
    description:
      'Altamente superior en calidad, la línea Crystalline marca un claro avance en protección contra el calor en automóviles, ya que su transparencia no afecta la visión, brindando mayor seguridad al conducir. ',
    img: 'crystalline.jpg',
    degrade: 'red',
    flyer_url: 'Crystalline_esp.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 15 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Serie Color Stable',
    description:
      'La línea premium de películas tonalizadas de 3M. No se decoloran, brindan un alto rechazo de calor y rayos UV y una mejor imagen para su auto. Además permiten una clara visión hacia el exterior, a través del vidrio, sin defectos.  ',
    img: 'color_stable.jpg',
    degrade: 'blue',
    flyer_url: 'Color_Stable_WF.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Serie Black Chrome',
    description:
      'Levemente reflectivas y compuestas por un laminado metalizado, brindan máximo rechazo de calor y rayos UV. Mayor confort en su vehículo, rechazo de hasta el 72% de la energía solar y una imagen exterior única. ',
    img: 'black_chrome.jpg',
    degrade: 'black',
    flyer_url: 'BlackCrome.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Serie FX-ST',
    description:
      'Esta línea de películas tonalizadas pone la calidad de 3M al alcance de todos. Una amplia variedad de tonos color carbono con un óptimo rechazo de calor y rayos UV. ',
    img: 'fx_st.jpg',
    degrade: 'green',
    flyer_url: 'FX_ST_WF.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 3 años*',
    es: true,
    br: true,
  },
];

export const autoSolarSecurity = [
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Serie Scotchshield™ SAS',
    description:
      'Mantenga su auto fresco y seguro con las laminas 2 en 1 ScotchshieldTM. Protegen sus objetos del calor, brindan privacidad y sostienen el vidrio roto luego de cualquier impacto, aumentando de esta manera el tiempo y esfuerzo que se requiere para entrar al vehiculo.',
    img: 'scotchshield.jpg',
    degrade: 'orange',
    flyer_url: 'Scotchshield_WF.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 3 años*',
    es: true,
    br: true,
  },
];

export const autoSecurity = [
  {
    type: 'Película para Vidrios de Automóviles 3M™',
    name: 'Safety Crystalline',
    description:
      'Claramente protegido, claramente cómodo. Las películas serie Safety Crystalline crean una barrera de protección transparente sin cambiar la apariencia de su vehículo.',
    img: 'safety_crystalline.jpg',
    degrade: 'yellow',
    flyer_url: 'Scotchshield.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 15 años*',
    es: true,
    br: false,
  },
];
