import React from 'react';
import Layout from '_metronic/layout/Layout';
import ProductCard from './productCard';
import {
  arqSolProducts,
  arqClimateCont,
  arqSecurity,
  arqSpecial,
} from './productCard/arq_prods';
import './productCard/productCard.css';

import { connect } from 'react-redux';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';

function ProductAuto(props) {
  console.log(props);

  let arqSolProds = arqSolProducts;
  let arqClimCont = arqClimateCont;
  let arqSec = arqSecurity;
  let arqSpec = arqSpecial;
  return (
    <Layout>
      <div className="container-fluid bg-white">
        <div className="row border-bottom pb-5">
          <div className="col-6">
            <div className="d-flex d-flex  mt-5 ml-2">
              <p className="h3 pt-2 mr-3">
                <FormattedMessage id="Películas para ventanas de hogar y edificios 3M™" />
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex d-flex flex-row-reverse mt-5 mr-5">
              <img
                height="40px"
                className="justify-self-end img-responsive"
                src={
                  process.env.PUBLIC_URL + './media/product_imgs/arq_logo.jpg'
                }
              ></img>
              <p className="h3 pt-2 mr-3">
                <FormattedMessage id="Arquitectura" />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mb-5 mt-5 ">
          <img
            height="40px"
            className="ml-3 img-responsive"
            src={
              process.env.PUBLIC_URL + './media/product_imgs/solar_control.jpg'
            }
          ></img>
          <p className="h5 pt-4 ml-2">
            <FormattedMessage id="Películas de control Solar" />
          </p>
        </div>
        <div className="row bg-white">
          {arqSolProds.map((e) => {
            return (
              <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
                <ProductCard
                  name={e.name}
                  type={e.type}
                  description2={e.description2}
                  description={e.description}
                  img={e.img}
                  years={e.years}
                  degrade={e.degrade}
                  flyer_url={e.flyer_url}
                ></ProductCard>
              </div>
            );
          })}
        </div>

        {props.lang !== 'br' && (
          <>
            <div className="d-flex mb-5 mt-5 ">
              <img
                height="40px"
                className="ml-3 img-responsive"
                src={
                  process.env.PUBLIC_URL +
                  './media/product_imgs/weather_logo.jpg'
                }
              ></img>
              <p className="h5 pt-4 ml-2">
                <FormattedMessage id="Películas de Control Climático" />
              </p>
            </div>
            <div className="row bg-white">
              {arqClimCont.map((e) => {
                return (
                  <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
                    <ProductCard
                      name={e.name}
                      type={e.type}
                      description2={e.description2}
                      description={e.description}
                      img={e.img}
                      years={e.years}
                      degrade={e.degrade}
                      flyer_url={e.flyer_url}
                    ></ProductCard>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className="d-flex mb-5 mt-5 ">
          <img
            height="40px"
            className="ml-3 img-responsive"
            src={
              process.env.PUBLIC_URL + './media/product_imgs/shield_logo.jpg'
            }
          ></img>
          <p className="h5 pt-4 ml-2">
            <FormattedMessage id="Películas de Seguridad" />
          </p>
        </div>
        <div className="row bg-white">
          {arqSec.map((e) => {
            return (
              <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
                <ProductCard
                  name={e.name}
                  type={e.type}
                  description2={e.description2}
                  description={e.description}
                  img={e.img}
                  years={e.years}
                  degrade={e.degrade}
                  flyer_url={e.flyer_url}
                ></ProductCard>
              </div>
            );
          })}
        </div>
        <div className="d-flex mb-5 mt-5 ">
          <img
            height="40px"
            className="ml-3 img-responsive"
            src={process.env.PUBLIC_URL + './media/product_imgs/star_logo.jpg'}
          ></img>
          <p className="h5 pt-4 ml-2">
            <FormattedMessage id="Películas especiales" />
          </p>
        </div>
        <div className="row bg-white">
          {arqSpec.map((e) => {
            return (
              <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
                <ProductCard
                  name={e.name}
                  type={e.type}
                  description2={e.description2}
                  description={e.description}
                  img={e.img}
                  years={e.years}
                  degrade={e.degrade}
                  flyer_url={e.flyer_url}
                ></ProductCard>
              </div>
            );
          })}
        </div>
        <div className="d-flex mb-4 mt-5 ">
          <img
            height="40px"
            className="ml-3 img-responsive"
            src={process.env.PUBLIC_URL + './media/tech/check_logo.png'}
          ></img>
          <p className="h3 pt-3 ml-2">
            <FormattedMessage id="Glass Checklist - Información Técnica " />
          </p>
        </div>
        <div className="row bg-white">
          <div className="col-xs-12 col-md-6 col-xl-6 mb-2">
            <ul>
              <li className="h4">
                <FormattedMessage id="Glass Checklist 3M - " />{' '}
                <a href="./media/tech/3M_Verificacion.pdf" target="_blank">
                  <FormattedMessage id="Verificación" />
                </a>
              </li>
              <li className="h4">
                <FormattedMessage id="Glass Checklist - " />{' '}
                <a href="./media/tech/3M_Cuestionario.pdf" target="_blank">
                  <FormattedMessage id="Cuestionario Instaladores" />
                </a>{' '}
              </li>
              <li className="h4">
                <FormattedMessage id="Acceso a WEB 3M Glass Checklist: " />
                <a
                  href="https://windowfilmadvantage.3m.com/#/login/"
                  target="_blank"
                >
                  windowfilmadvantage.3m.com
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});

export const matStateToProps = (state) => ({
  lang: get(state, 'i18n.lang') === 'br' ? 'br' : 'es',
});
export default injectIntl(
  connect(matStateToProps, mapDispatchToProps)(ProductAuto),
);
