import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';

export const Sheets = ({ values }) => {
  const Sheets = get(values, 'data.applications', []) || [];
  const Seguridad = Sheets.filter((sheet) => sheet.film.type === 2);
  const Solar = Sheets.filter((sheet) => sheet.film.type === 1);
  return (
    <div>
      {Boolean(Seguridad.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="Seguridad" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Seguridad.map((sheet, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Película de seguridad:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="M2 Necesarios:" />
                    </strong>
                    <span>{sheet.meters}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>
                  {Seguridad.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      {Boolean(Seguridad.length) && Boolean(Solar.length) && (
        <div className="kt-space-20" />
      )}
      {Boolean(Solar.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="Control Solar" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Solar.map((sheet, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Película de control solar:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="M2 Necesarios:" />
                    </strong>
                    <span>{sheet.meters}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>
                  {Solar.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Sheets;
