import get from 'lodash/get';

import { PREFIX } from './reducer';

export const getClientData = (state) => ({
  clientData: get(state, `${PREFIX}.data`, []),
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasError: get(state, `${PREFIX}.hasError`, false),
  isSuccess: get(state, `${PREFIX}.isSuccess`, false),
});
