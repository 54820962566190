import get from 'lodash/get';
import moment from 'moment';

import { PREFIX } from './reducer';

export const getProjectData = (state) => {
  const projectData = get(state, `${PREFIX}.data`, {});
  return Boolean(get(state, `${PREFIX}.data.data.ecd`, false))
    ? {
        projectData: {
          ...projectData,
          data: {
            ...get(projectData, `data`, {}),
            finish: {
              month: moment.utc(get(projectData, `data.ecd`)).format('MM'),
              year: moment.utc(get(projectData, `data.ecd`)).format('YYYY'),
            },
          },
        },
        isLoading: get(state, `${PREFIX}.isLoading`, false),
        hasError: get(state, `${PREFIX}.hasError`, false),
        isSuccess: get(state, `${PREFIX}.isSuccess`, false),
      }
    : {
        projectData,
        isLoading: get(state, `${PREFIX}.isLoading`, false),
        hasError: get(state, `${PREFIX}.hasError`, false),
        isSuccess: get(state, `${PREFIX}.isSuccess`, false),
      };
};
