/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
// import * as gPhoneNumber from 'google-libphonenumber';

// const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const YUP_PHONE_METHOD = 'phone';
const CLDR_REGION_CODE_SIZE = 2;

const isValidCountryCode = (countryCode) =>
  typeof countryCode === 'string' &&
  countryCode.length === CLDR_REGION_CODE_SIZE;

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function yupPhone(
  msg = '',
  countryCode,
  strict = false,
) {
  const errMsg =
    msg ||
    (isValidCountryCode(countryCode)
      ? `\${path} must be a valid phone number for region ${countryCode}`
      : '${path} must be a valid phone number.');

  return this.test(YUP_PHONE_METHOD, errMsg, (value) => {
    if (!value) return true; // Devuelve true, poruqe se debe validar con el ".required" de YUP
    try {
      if (!isValidCountryCode(countryCode)) {
        // if not valid countryCode, then set default country to India (IN)
        countryCode = 'AR';
        strict = false;
      }

      return /^\+?\d{6,}$/gi.test(value);

      // if (!/^\+?\d*$/gi.test(value)) return false; // work arround to Possiible number

      // const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);

      // return phoneUtil.isPossibleNumber(phoneNumber);

      // if (!phoneUtil.isPossibleNumber(phoneNumber)) {
      //   return false;
      // }

      // const regionCodeFromPhoneNumber = phoneUtil.getRegionCodeForNumber(
      //   phoneNumber,
      // );

      /* check if the countryCode provided should be used as
             default country code or strictly followed
           */
      // return strict
      //   ? phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
      //   : phoneUtil.isValidNumberForRegion(
      //       phoneNumber,
      //       regionCodeFromPhoneNumber,
      //     );
    } catch {
      return false;
    }
  });
});
