const CodeToName = (value, row) => {
  switch (value) {
    case 'AR':
      return 'Argentina';
    case 'BO':
      return 'Bolivia';
    case 'CL':
      return 'Chile';
    case 'CO':
      return 'Colombia';
    case 'CR':
      return 'Costa Rica';
    case 'DO':
      return 'Republica Dominicana';
    case 'EC':
      return 'Ecudor';
    case 'GT':
      return 'Guatemala';
    case 'HN':
      return 'Honduras';
    case 'MX':
      return 'México';
    case 'PA':
      return 'Panamá';
    case 'PE':
      return 'Perú';
    case 'SV':
      return 'El Salvador';
    case 'UY':
      return 'Uruguay';
    default:
      return value;
  }
};

export default CodeToName;
