import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getReportWarratyData } from 'redux/cars/editClient/selector';
import {
  actions as editClientActions,
  requests as editClientRequests,
} from 'redux/cars/editClient/reducer';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const ResponsiveDialog = ({
  open,
  onClose,
  reportWarranty,
  clearData,
  isLoading,
  isReportSuccess,
  hasReportError,
  code,
}) => {
  useEffect(() => {
    if (isReportSuccess) {
      onClose();
    }
    return () => clearData();
  }, [isReportSuccess, onClose, clearData]);

  useEffect(() => {
    console.log('Toast OK effect', isLoading, isReportSuccess);

    if (!isLoading && isReportSuccess) {
      toast.success(
        <FormattedMessage id="La información ha sido actualizada." />,
        toastConfig,
      );
    }
  }, [isLoading, isReportSuccess]);

  useEffect(() => {
    console.log('Toast ERR effect', isLoading, hasReportError);
    if (!isLoading && hasReportError) {
      toast.error(
        <FormattedMessage id="Se ha producido un error, vuelva a intentar más tarde." />,
        toastConfig,
      );
    }
  }, [isLoading, hasReportError]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      disableBackdropClick={isLoading}
    >
      <DialogTitle>
        <FormattedMessage id="Denunciar RUN" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="¿Esta seguro que quiere denunciar el número de RUN?" />
        </DialogContentText>
        {hasReportError && (
          <DialogContentText color="primary">
            <FormattedMessage id="Se a producido un error, vuelva a intentar" />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => reportWarranty({ code })}
          color="primary"
          disabled={isLoading}
        >
          <FormattedMessage id="Si" />
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="primary"
          disabled={isLoading}
        >
          <FormattedMessage id="No" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(getReportWarratyData, (dispatch) => ({
  reportWarranty: (data) => dispatch(editClientRequests.report(data)),
  clearData: () => dispatch(editClientActions.clearData()),
}))(ResponsiveDialog);
