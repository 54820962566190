import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import {
  requests as editClientRequests,
  actions as editClientActions,
} from 'redux/cars/editClient/reducer';
import { getCountryProfile } from 'redux/profile/selector';

import { getClientData } from 'redux/cars/editClient/selector';

import get from 'lodash/get';

import { Formik, Form } from 'formik';
import Client, { initialValues } from './client';
import Shhets from './sheets';
import Warranty from './warranty';
import Vehicle from './vehicle';
import validationSchema from './client/validate';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const breadcrumb = [
  { page: 'installer/car', title: 'Automóvil' },
  { page: 'installer/car', title: 'Mis Clientes' },
];

export const EditClient = ({
  intl,
  getClient,
  saveClient,
  clearData,
  clientData,
  isLoading,
  isSuccess,
  hasError,
  country,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  useEffect(() => {
    if (Boolean(Object.keys(clientData).length)) {
      const isClosed =
        clientData.subtype === 'Residential' || clientData.isClosed;
      const isLost = get(clientData, 'data.status', '') === 'Perdida';
      const isCancelled = get(clientData, 'data.status', '') === 'Cancelada';
      if (get(clientData, 'type', '') === 'BUILDING') {
        if (isClosed || isLost || isCancelled) {
          history.push(`/installer/building/view/${code}`);
        } else {
          history.push(`/installer/building/edit/${code}`);
        }
      }
    }
  }, [history, clientData, code]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success(
        <FormattedMessage id="Información actualizada correctamente" />,
        toastConfig,
      );
      history.push('/installer/car');
    }
  }, [isLoading, isSuccess, history]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const handleSubmit = (values, bag) => {
    console.log(values, bag);

    bag.setSubmitting(false);
    return saveClient({ code, body: values });
  };
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Automóvil" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(clientData).length) && (
        <Formik
          initialValues={{ ...initialValues, ...clientData }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema(intl)}
        >
          {(fromProps) => (
            <Form className="EditClientForm">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Cliente" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Client {...props} {...fromProps} code={code} />
                    </PortletBody>
                  </Portlet>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Aplicación" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Shhets {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Vehículo" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Vehicle {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Garantía" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Warranty {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PortletBody>
                      <div className="submit-bottons">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={isLoading}
                        >
                          <FormattedMessage id="Guardar" />
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => history.goBack()}
                        >
                          <FormattedMessage id="Volver" />
                        </Button>
                      </div>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
              {/* <pre>{JSON.stringify(fromProps.values, null, '\t')}</pre> */}
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getClient: (data) => dispatch(editClientRequests.fetch(data)),
  saveClient: (data) => dispatch(editClientRequests.create(data)),
  clearData: () => dispatch(editClientActions.clearData()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getClientData(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(EditClient),
);
