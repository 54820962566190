import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { push } from 'connected-react-router';
import get from 'lodash/get';

import Dialog from './dialog';

const API_URL = window.env.API_URL;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const Actions = ({ push, value, row }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isClosed = row.isClosed;

  console.log('esta cerrado: ', isClosed);

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        &bull;&bull;&bull;
      </Button>
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {isClosed ? (
            <>
              <MenuItem onClick={() => push(`/installer/car/edit/${value}`)}>
                <VisibilityIcon className={classes.icon} />
                <FormattedMessage id="Ver" />
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem onClick={() => push(`/installer/car/edit/${value}`)}>
                <EditIcon className={classes.icon} />
                <FormattedMessage id="Editar" />
              </MenuItem>
              <MenuItem onClick={handleOpenDialog}>
                <MailOutlineIcon className={classes.icon} />
                <FormattedMessage id="Volver a enviar cert de garantía" />
              </MenuItem>
            </>
          )}
        </Menu>
        {openDialog && (
          <Dialog open={openDialog} onClose={handleCloseDialog} code={value} />
        )}
      </>
    </>
  );
};

export default connect(undefined, { push })(Actions);
