import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Chart from './chart';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import { PorletHeaderTitle } from 'components/porletHeader';
import { getIngresadosWidget } from 'redux/dashboard/selector';

import Button from '@material-ui/core/Button';

const Ingresados = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid">
    <PortletBody>
      <div>
        <PorletHeaderTitle variant="h4">
          <FormattedMessage id="Proyectos Ingresados" />
        </PorletHeaderTitle>
        <span>
          <FormattedMessage id="Colocate sobre cada barra para ver más información" />
        </span>
      </div>
      <div className="kt-space-20" />
      <div>
        <div>{!isLoading && <Chart data={data} />}</div>
      </div>
      <div className="kt-space-20" />
      {/* <div>
        <Button variant="outlined">
          <FormattedMessage id="Export..." />
        </Button>
      </div> */}
    </PortletBody>
  </Portlet>
);
export default connect(getIngresadosWidget)(Ingresados);
