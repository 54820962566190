import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.1"
        id="Isolation_Mode"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 57.26 57.26"
        enable-background="new 0 0 57.26 57.26"
      >
        <g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M55.929,30.319c-0.159,1.084-0.279,2.174-0.474,3.25
		c-1.122,6.156-4.044,11.32-8.682,15.492c-4.095,3.686-8.903,5.918-14.356,6.69c-12.482,1.772-24.48-5.125-29.256-16.849
		c-1.045-2.564-1.678-5.232-1.883-7.996c-0.01-0.116-0.063-0.229-0.098-0.34c0-1.268,0-2.533,0-3.799
		c0.176-1.186,0.264-2.391,0.544-3.55c2.729-11.342,9.743-18.479,21.08-21.311c1.257-0.312,2.566-0.415,3.85-0.616
		c1.267,0,2.535,0,3.798,0c0.137,0.034,0.272,0.088,0.412,0.099c4.644,0.382,8.923,1.841,12.781,4.449
		c6.385,4.315,10.35,10.271,11.801,17.858c0.208,1.097,0.323,2.21,0.482,3.314C55.929,28.118,55.929,29.218,55.929,30.319z
		 M2.759,28.652c0,14.203,11.531,25.786,25.678,25.794c14.32,0.009,25.887-11.496,25.9-25.758
		C54.345,14.457,42.816,2.881,28.623,2.872C14.304,2.863,2.759,14.373,2.759,28.652z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M45.024,40.878c2.227,0.014,2.438,0.905,2.351,2.491
		c-0.026,0.509,0.013,1.023-0.011,1.53c-0.037,0.84-0.648,1.441-1.484,1.484c-0.16,0.01-0.324,0.004-0.487,0.004
		c-11.06,0-22.12-0.016-33.186,0.02c-1.535,0.006-2.108-0.828-2.032-2.023c0.028-0.53,0-1.061,0.009-1.593
		c0.009-1.29,0.565-1.845,1.866-1.877c0.14-0.003,0.279-0.021,0.493-0.032c0-0.252,0-0.492,0-0.728c0-9.634,0-19.265,0.001-28.897
		c0-1.562,0.477-2.03,2.052-2.03c9.469,0,18.935,0,28.407,0c1.527,0,2.021,0.488,2.021,1.995c0,9.632,0,19.264,0,28.898
		C45.024,40.358,45.024,40.603,45.024,40.878z M43.563,10.758c-9.887,0-19.689,0-29.557,0c0,10.564,0,21.085,0,31.687
		c-0.805,0-1.548,0-2.266,0c0,0.865,0,1.648,0,2.41c3.939,0,7.826,0,11.736,0c0-2.928-0.004-5.806,0-8.684
		c0-0.907,0.247-1.151,1.166-1.151c2.815-0.004,5.635-0.002,8.451,0c1.011,0,1.256,0.242,1.256,1.264c0.003,2.633,0,5.267,0,7.899
		c0,0.219,0,0.439,0,0.689c3.867,0,7.674,0,11.507,0c0-0.813,0-1.598,0-2.458c-0.769,0-1.515,0-2.294,0
		C43.563,31.832,43.563,21.324,43.563,10.758z M29.657,36.835c0,2.717,0,5.374,0,8.031c1.085,0,2.137,0,3.189,0
		c0-2.695,0-5.354,0-8.031C31.772,36.835,30.737,36.835,29.657,36.835z M24.978,36.835c0,2.711,0,5.37,0,8.033c1.07,0,2.1,0,3.135,0
		c0-2.695,0-5.354,0-8.033C27.053,36.835,26.043,36.835,24.978,36.835z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.001,18.077c-0.715,0-1.427,0.004-2.141,0
		c-0.717-0.009-1.004-0.283-1.006-0.985c-0.01-1.079-0.01-2.157,0-3.237c0.002-0.676,0.281-0.98,0.959-0.986
		c1.486-0.017,2.972-0.02,4.457,0c0.667,0.008,0.95,0.318,0.954,0.996c0.009,1.078,0.009,2.157,0,3.236
		c-0.004,0.664-0.304,0.965-0.964,0.972C21.508,18.083,20.753,18.077,20.001,18.077z M20.015,13.41
		c-0.713,0-1.426,0.008-2.137-0.007c-0.361-0.006-0.529,0.122-0.524,0.494c0.009,1.059,0.007,2.117,0,3.178
		c0,0.368,0.19,0.501,0.536,0.501c1.426-0.005,2.852-0.009,4.276,0c0.386,0.002,0.563-0.163,0.559-0.548
		c-0.007-1.036-0.007-2.077,0-3.115c0.005-0.357-0.144-0.516-0.511-0.51C21.478,13.418,20.747,13.41,20.015,13.41z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.032,25.79c0.753,0,1.506-0.006,2.259,0.003
		c0.618,0.009,0.927,0.32,0.934,0.946c0.009,1.1,0.009,2.201,0,3.299c-0.007,0.631-0.297,0.963-0.925,0.973
		c-1.502,0.023-3.014,0.021-4.516,0c-0.631-0.008-0.927-0.337-0.93-0.963c-0.01-1.098-0.011-2.2,0-3.299
		c0.006-0.658,0.309-0.95,0.977-0.956C18.567,25.786,19.298,25.79,20.032,25.79z M20.001,30.46c0.736,0,1.468-0.009,2.201,0.004
		c0.357,0.009,0.527-0.133,0.522-0.496c-0.007-1.039-0.007-2.077,0-3.115c0.005-0.381-0.16-0.559-0.548-0.559
		c-1.426,0.006-2.852,0.006-4.275,0c-0.385,0-0.553,0.164-0.549,0.554c0.01,1.039,0.01,2.077,0,3.115
		c-0.004,0.364,0.152,0.51,0.512,0.501C18.579,30.451,19.29,30.46,20.001,30.46z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.889,25.79c0.733,0,1.465-0.006,2.198,0.003
		c0.62,0.009,0.947,0.32,0.951,0.938c0.012,1.099,0.012,2.196,0,3.297c-0.004,0.634-0.297,0.974-0.916,0.979
		c-1.525,0.027-3.055,0.027-4.58,0c-0.587-0.008-0.873-0.346-0.879-0.945c-0.014-1.1-0.014-2.201,0-3.299
		c0.004-0.675,0.299-0.961,0.967-0.97C27.384,25.784,28.136,25.79,28.889,25.79z M28.772,30.46c0.732,0,1.466-0.014,2.199,0.006
		c0.381,0.012,0.567-0.121,0.564-0.52c-0.008-1.041-0.004-2.077-0.002-3.117c0-0.387-0.194-0.537-0.575-0.535
		c-1.402,0.008-2.806,0.008-4.213,0c-0.407-0.005-0.592,0.148-0.588,0.576c0.015,1.017,0.015,2.035,0,3.055
		c-0.004,0.387,0.137,0.553,0.538,0.541C27.388,30.446,28.081,30.46,28.772,30.46z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.689,25.79c0.71,0,1.425-0.002,2.136,0.003
		c0.699,0.004,1.02,0.312,1.028,0.989c0.011,1.078,0.011,2.158,0,3.236c-0.009,0.65-0.323,0.984-0.976,0.994
		c-1.486,0.017-2.973,0.019-4.458,0c-0.655-0.01-0.943-0.33-0.951-1.005c-0.01-1.079-0.01-2.16,0-3.239
		c0.008-0.702,0.296-0.972,1.021-0.976C36.221,25.788,36.955,25.79,37.689,25.79z M37.616,30.46c0.715,0,1.431-0.017,2.141,0.008
		c0.438,0.016,0.578-0.166,0.572-0.576c-0.014-0.999-0.01-2.001,0-2.998c0.002-0.4-0.131-0.606-0.566-0.602
		c-1.409,0.013-2.814,0.008-4.225,0.002c-0.371,0-0.568,0.124-0.563,0.532c0.015,1.041,0.015,2.084,0,3.124
		c-0.01,0.416,0.183,0.524,0.561,0.514C36.229,30.451,36.921,30.46,37.616,30.46z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.821,18.077c-0.732,0-1.464,0.006-2.195-0.005
		c-0.676-0.007-0.959-0.288-0.963-0.968c-0.014-1.078-0.014-2.158,0-3.236c0.004-0.689,0.274-0.989,0.945-1
		c1.486-0.02,2.971-0.016,4.461,0c0.666,0.006,0.965,0.321,0.971,0.982c0.01,1.08,0.01,2.158,0,3.236
		c-0.006,0.686-0.318,0.981-1.018,0.99C30.286,18.081,29.554,18.077,28.821,18.077z M28.819,13.41c-0.714,0-1.422,0.008-2.137-0.007
		c-0.357-0.006-0.527,0.122-0.521,0.494c0.01,1.059,0.006,2.117,0.002,3.178c-0.002,0.37,0.186,0.501,0.531,0.501
		c1.428-0.005,2.852-0.009,4.277,0c0.386,0.004,0.563-0.165,0.563-0.544c-0.002-1.04-0.004-2.078,0-3.117
		c0.002-0.402-0.199-0.522-0.575-0.512C30.245,13.418,29.532,13.41,28.819,13.41z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.612,18.077c-0.732,0-1.465,0.008-2.201-0.005
		c-0.643-0.008-0.934-0.286-0.942-0.925c-0.01-1.121-0.013-2.239,0-3.36c0.009-0.601,0.298-0.908,0.902-0.917
		c1.53-0.019,3.053-0.021,4.583,0.002c0.588,0.01,0.891,0.345,0.896,0.936c0.018,1.117,0.018,2.24,0,3.357
		c-0.011,0.58-0.344,0.899-0.918,0.907C39.158,18.085,38.384,18.077,37.612,18.077z M37.649,17.573c0.695,0,1.389-0.015,2.083,0.005
		c0.415,0.011,0.599-0.149,0.597-0.569c-0.01-1.021-0.01-2.043-0.002-3.064c0.004-0.376-0.133-0.547-0.542-0.543
		c-1.428,0.017-2.854,0.017-4.284,0c-0.41-0.004-0.531,0.167-0.525,0.552c0.015,1.02,0.015,2.041,0,3.062
		c-0.01,0.437,0.191,0.572,0.594,0.562C36.264,17.563,36.955,17.573,37.649,17.573z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.634,24.56c-0.713,0-1.427,0.004-2.14-0.004
		c-0.708-0.01-1.018-0.301-1.025-0.99c-0.01-1.1-0.01-2.2,0-3.301c0.009-0.616,0.325-0.945,0.942-0.951
		c1.486-0.017,2.977-0.017,4.461-0.002c0.646,0.008,0.973,0.35,0.981,0.992c0.011,1.1,0.015,2.201-0.003,3.301
		c-0.006,0.605-0.344,0.938-0.957,0.951C39.141,24.57,38.388,24.56,37.634,24.56z M37.63,19.848c-0.692,0-1.388,0.006-2.082-0.003
		c-0.407-0.008-0.582,0.15-0.572,0.582c0.02,1.001,0.015,2,0,3c-0.01,0.409,0.133,0.61,0.55,0.61c1.43-0.005,2.858-0.005,4.288,0
		c0.359,0,0.516-0.171,0.51-0.528c-0.004-1.041-0.007-2.084,0.006-3.124c0.002-0.396-0.175-0.548-0.557-0.54
		C39.06,19.854,38.345,19.848,37.63,19.848z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.032,24.56c-0.734,0-1.465,0.008-2.201-0.004
		c-0.654-0.012-0.974-0.33-0.977-0.977c-0.01-1.101-0.01-2.201,0-3.299c0.003-0.613,0.32-0.959,0.927-0.967
		c1.505-0.017,3.017-0.017,4.524,0c0.604,0.01,0.915,0.361,0.919,0.977c0.009,1.102,0.009,2.201,0,3.302
		c-0.007,0.634-0.333,0.952-0.995,0.964C21.498,24.567,20.765,24.56,20.032,24.56z M20.042,24.037c0.691,0,1.385-0.009,2.076,0
		c0.438,0.009,0.619-0.181,0.611-0.626c-0.018-0.997-0.018-1.995,0-2.992c0.008-0.428-0.188-0.576-0.586-0.574
		c-1.406,0.009-2.814,0.009-4.215,0c-0.412-0.002-0.586,0.16-0.58,0.588c0.02,0.995,0.02,1.992,0,2.99
		c-0.012,0.455,0.187,0.623,0.617,0.614C18.657,24.028,19.351,24.037,20.042,24.037z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.849,24.56c-0.734,0-1.465,0.008-2.203-0.004
		c-0.66-0.012-0.977-0.317-0.982-0.969c-0.014-1.1-0.014-2.2,0-3.299c0.004-0.645,0.326-0.973,0.978-0.979
		c1.47-0.013,2.934-0.013,4.401,0c0.674,0.006,0.993,0.348,1,1.034c0.004,1.06,0.004,2.117,0,3.177
		c-0.007,0.716-0.326,1.027-1.059,1.035C30.273,24.563,29.562,24.56,28.849,24.56z M28.849,19.85c-0.717,0-1.428,0.004-2.142-0.002
		c-0.376-0.003-0.553,0.147-0.549,0.548c0.015,1.038,0.013,2.077,0.003,3.115c-0.006,0.367,0.174,0.521,0.516,0.521
		c1.445,0.005,2.891,0.002,4.336,0.002c0.348,0,0.523-0.168,0.521-0.523c-0.004-1.041-0.006-2.077,0.002-3.117
		c0.003-0.389-0.171-0.553-0.553-0.549C30.27,19.854,29.557,19.85,28.849,19.85z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.784,14.321c0,0.768,0,1.529,0,2.362c-1.105,0-2.182,0.006-3.256-0.016
		c-0.098,0-0.266-0.188-0.271-0.295c-0.028-0.669-0.016-1.339-0.016-2.052C19.444,14.321,20.595,14.321,21.784,14.321z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.276,29.564c0-0.806,0-1.55,0-2.329c1.18,0,2.324,0,3.506,0
		c0,0.791,0,1.551,0,2.329C20.604,29.564,19.469,29.564,18.276,29.564z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.573,29.55c-1.16,0-2.309,0-3.496,0c0-0.769,0-1.512,0-2.306
		c1.144,0,2.293,0,3.496,0C30.573,28.003,30.573,28.759,30.573,29.55z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M39.384,27.231c0,0.797,0,1.541,0,2.32c-1.168,0-2.301,0-3.484,0
		c0-0.762,0-1.521,0-2.32C37.048,27.231,38.181,27.231,39.384,27.231z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.589,14.313c0,0.779,0,1.526,0,2.322c-1.165,0-2.311,0-3.506,0
		c0-0.768,0-1.527,0-2.322C28.257,14.313,29.406,14.313,30.589,14.313z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.882,14.305c1.191,0,2.324,0,3.493,0c0,0.786,0,1.546,0,2.341
		c-1.169,0-2.315,0-3.493,0C35.882,15.858,35.882,15.114,35.882,14.305z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.886,20.786c1.158,0,2.291,0,3.479,0c0,0.746,0,1.504,0,2.301
		c-1.157,0-2.3,0-3.479,0C35.886,22.306,35.886,21.564,35.886,20.786z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.786,20.803c0,0.776,0,1.519,0,2.292c-1.173,0-2.31,0-3.498,0
		c0-0.758,0-1.498,0-2.292C19.432,20.803,20.579,20.803,21.786,20.803z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.074,23.087c0-0.777,0-1.52,0-2.3c1.176,0,2.325,0,3.511,0
		c0,0.772,0,1.516,0,2.3C29.406,23.087,28.257,23.087,27.074,23.087z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
