import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getFasaraFilmsBuildingOptions } from 'redux/filmsBuilding/selector';
import { getDistributors } from 'redux/distributors/selector';
import { projectProgressOPTs } from 'enums/formOtps';
import { getStatusNewProject } from 'redux/arqFinishes/newProject/selector';
import { actions as newProjectActions } from 'redux/arqFinishes/newProject/reducer';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../step3/previewer.scss';
export const Step4 = ({
  country,
  fasaraFilmsBuildingOptions,
  distributorsOptions,
  values,
  prevStep,
  isLoading,
  hasError,
}) => (
  <>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <PorletHeader>
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="Resumen del cliente" />
          </PorletHeaderTitle>
          <PorletHeaderTitle variant="h6">
            <FormattedMessage id="Por favor revisar todos los datos del proyecto." />
          </PorletHeaderTitle>
        </PorletHeader>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row resumen">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Cliente:" />
                  </PorletHeaderTitle>

                  <div>
                    <div className="w-50">
                      <strong>
                        <FormattedMessage id="Nombre:" />
                      </strong>
                    </div>
                    <div className="w-50">
                      <span>{values.contactName}</span>
                    </div>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Email:" />
                    </strong>
                    <span>{values.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
            <div className="row">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Proyecto:" />
                  </PorletHeaderTitle>
                  <div>
                    <strong>
                      <FormattedMessage id="Nombre del proyecto:" />
                    </strong>
                    <span>{values.project.name}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Tipo de proyecto:" />
                    </strong>
                    <span>{values.project.type}</span>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Código postal:" />
                    </strong>
                    <span>{values.project.postalCode}</span>
                  </div>

                  <div>
                    <strong>
                      <FormattedMessage id="Provincia:" />
                    </strong>
                    <span>{values.project.state}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row resumen">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Aplicación:" />
                  </PorletHeaderTitle>
                  {values.installers.map((installer, index) => {
                    return (
                      <div key={index}>
                        <strong>
                          <FormattedMessage id="Instalador" /> {index + 1}:
                        </strong>
                        <span className="col-6">{installer.name}</span>
                      </div>
                    );
                  })}

                  <div className="kt-space-20" />

                  <div className="kt-space-20" />
                  <PorletHeaderTitle variant="h6">
                    <FormattedMessage id="3M™ Architectural Finishes™" />
                  </PorletHeaderTitle>
                  <div className="kt-space-20" />
                  {Boolean(values.fasaraSheets.length)}
                  {values.fasaraSheets.map((fasaraSheet, index) => {
                    return (
                      <>
                        <div className="withmargin">
                          <strong>
                            <FormattedMessage id="Categoría:" />
                          </strong>
                          <span className="col-6">
                            {values.fasaraSheets[index].glassFinish}
                          </span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Familia de Producto:" />
                          </strong>
                          <span className="col-6">
                            {values.fasaraSheets[index].family}
                          </span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="Producto:" />
                          </strong>
                          <span className="col-6">
                            {
                              /*get(
                            fasaraFilmsBuildingOptions.find(
                              (item) => item.films.value == fasaraSheet.film,
                            ),
                            'films.label',
                            )*/
                              fasaraSheet.film
                            }
                          </span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="M2 Necesarios:" />
                          </strong>
                          <span className="col-6">{fasaraSheet.meters}</span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Número de RUN:" />
                          </strong>
                          <span className="col-6">{fasaraSheet.run}</span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Distribuidor:" />
                          </strong>
                          <span className="col-6">
                            {get(
                              distributorsOptions.find(
                                (item) =>
                                  item.value === fasaraSheet.distributor,
                              ),
                              'label',
                            )}
                          </span>
                        </div>
                        <div>
                          <div className="w-100">
                            <strong>
                              <FormattedMessage id="Vista Previa" />
                            </strong>
                          </div>

                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/media/fasara/' +
                              values.fasaraSheets[index].film +
                              '.jpg'
                            }
                            className="img-previewer-summary col-6"
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="kt-space-20" />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8 ">
        <div>
          {hasError && (
            <Typography color="primary">
              <FormattedMessage id="Se a producido un error, vuelva a intentar" />
            </Typography>
          )}
        </div>
        <div className="submit-bottons">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            <FormattedMessage id="Enviar la garantía a su cliente para validación" />
          </Button>
          <Button variant="outlined" onClick={prevStep} disabled={isLoading}>
            <FormattedMessage id="Editar" />
          </Button>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4"></div>
    </div>
  </>
);

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
  ...getStatusNewProject(state),
});

export default connect(mapStateToProps, (dispatch) => ({
  clear: () => dispatch(newProjectActions.clear()),
}))(Step4);
