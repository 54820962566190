import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/ar';
import '@formatjs/intl-relativetimeformat/locale-data/br';

import arMessages from './messages/ar';
import boMessages from './messages/bo';
import ceMessages from './messages/ce';
import clMessages from './messages/cl';
import coMessages from './messages/co';
import ecMessages from './messages/ec';
import mxMessages from './messages/mx';
import paMessages from './messages/pa';
import peMessages from './messages/pe';
import uyMessages from './messages/uy';
import brMessages from './messages/br';
import crMessages from './messages/cr';
import gtMessages from './messages/gt';
import hnMessages from './messages/hn';
import pyMessages from './messages/py';
import svMessages from './messages/sv';
import doMessages from './messages/do';
import jmMessages from './messages/jm';
/*Europe countries*/
import gbMessages from './messages/en';
import esMessages from './messages/es';
import ptMessages from './messages/pt';
import frMessages from './messages/fr';
import deMessages from './messages/de';
import itMessages from './messages/it';
import nlMessages from './messages/nl';
import chMessages from './messages/ch';
import atMessages from './messages/at';
import noMessages from './messages/no';
import beMessages from './messages/be';

const allMessages = {
  ar: arMessages,
  bo: boMessages,
  br: brMessages,
  ce: ceMessages,
  cl: clMessages,
  co: coMessages,
  cr: crMessages,
  do: doMessages,
  ec: ecMessages,
  gt: gtMessages,
  hn: hnMessages,
  jm: jmMessages,
  mx: mxMessages,
  pa: paMessages,
  pe: peMessages,
  py: pyMessages,
  sv: svMessages,
  uy: uyMessages,
  dk: gbMessages,
  gb: gbMessages,
  se: gbMessages,
  bv: gbMessages,
  fi: gbMessages,
  es: esMessages,
  pt: ptMessages,
  de: deMessages,
  fr: frMessages,
  it: itMessages,
  nl: nlMessages,
  ch: chMessages,
  at: atMessages,
  no: noMessages,
  be: beMessages,
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
