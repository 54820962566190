import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import {
  requests as editClientRequests,
  actions as editClientActions,
} from 'redux/cars/editClient/reducer';
import { getClientData } from 'redux/cars/editClient/selector';

import Client from './client';
import Glass from './glass';
import Project from './project';
import Status from './status';
import Shhets from './sheets';
import Warranty from './warranty';
import Installer from './installer';

const breadcrumb = [
  { page: 'installer/arqFinishes', title: '3M™ DI-NOC™' },
  { page: 'installer/arqFinishes', title: 'Proyectos registrados' },
];

export const ViewProject = ({ getClient, clearData, clientData, ...props }) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="3M™ DI-NOC™" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(clientData).length) && (
        <div className="ViewProjectForm">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Cliente" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Client {...props} values={clientData} code={code} />
                </PortletBody>
              </Portlet>

              <Portlet className="kt-portlet">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Películas" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Shhets {...props} values={clientData} />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Proyecto" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Project {...props} values={clientData} />
                </PortletBody>
              </Portlet>
              <Portlet className="kt-portlet">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Colocación" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Installer {...props} values={clientData} />
                </PortletBody>
              </Portlet>
              <Portlet className="kt-portlet">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Garantía" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Warranty {...props} values={clientData} />
                </PortletBody>
              </Portlet>
              {clientData.subtype !== 'DinocSmall' && (
                <Portlet className="kt-portlet">
                  <PorletHeader>
                    <PorletHeaderTitle variant="h4">
                      <FormattedMessage id="Etapa" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                  <PortletBody>
                    <Status
                      {...props}
                      values={clientData}
                      sampleOrProto={clientData.prototype || clientData.sample}
                    />
                  </PortletBody>
                </Portlet>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Portlet className="kt-portlet--height-fluid">
                <PortletBody className="submit-bottons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    <FormattedMessage id="Volver" />
                  </Button>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getClient: (data) => dispatch(editClientRequests.fetch(data)),
  saveClient: (data) => dispatch(editClientRequests.create(data)),
  clearData: () => dispatch(editClientActions.clearData()),
});

export default connect(getClientData, mapDispatchToProps)(ViewProject);
