import React from 'react';
import { connect } from 'react-redux';
import { getUserRole } from 'redux/profile/selector';
import { APLICATION_ROLES } from 'enums/roles';
import Instaler from './installer';
import Distributor from './distributor';
import MMM from './mmm';
import DEFAULT from './default';

const Dashboard = ({ userRole }) => {
  let View = null;
  switch (userRole) {
    case APLICATION_ROLES['Distributor']:
      View = <Distributor />;
      break;
    case APLICATION_ROLES['Installer']:
      View = <Instaler />;
      break;
    case APLICATION_ROLES['Admin']:
      View = <MMM />;
      break;
    default:
      View = <DEFAULT />;
  }
  return View;
};

export default connect(getUserRole)(Dashboard);
