import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import get from 'lodash/get';
import Form from '../../newClient/form';
import styled from 'styled-components';

const formatDate = 'DD/MM/YYYY';

export const Warranty = ({ values }) => {
  const applications = get(values, 'data.applications', []);
  const isEquivalentToTwo = applications.length === 2;

  return (
    <>
      {console.log('values', values.data.applications)}
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Código de garantía:" />
            </strong>
            <span>{get(values, 'code', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Fecha de creación:" />
            </strong>
            <span>{moment(get(values, 'created', '')).format(formatDate)}</span>
          </div>
          <div className="kt-space-20" />
          <div className="row">
            <div className="col-12 ViewOrEdit">
              <div>
                <strong>
                  <FormattedMessage id="Fecha de vencimiento lamina 1" />
                </strong>
                <span>
                  {moment(applications[0].expires).format(formatDate)}
                </span>
              </div>
              <div className="kt-space-20" />
              {isEquivalentToTwo && (
                <div>
                  <strong>
                    <FormattedMessage id="Fecha de vencimiento lamina 2" />
                  </strong>
                  <span>
                    {moment(applications[1].expires).format(formatDate)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warranty;
