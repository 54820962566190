import * as Yup from 'yup';

export default (intl, country) =>
  Yup.object().shape({
    glass: Yup.object().shape({
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      checkList: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
    }),
    installDate: Yup.date()
      .required(intl.formatMessage({ id: 'Debe seleccionar una fecha' }))
      .typeError(intl.formatMessage({ id: 'Debe ingresar una fecha válida' })),
    installers: Yup.array().of(
      Yup.object().test(
        'hasInstaller',
        intl.formatMessage({ id: 'Debe ingresar un instalador' }),
        (value) => Boolean(value.id),
      ),
    ),
    solarSheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          run: Yup.string()
            .required(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            )
            .typeError(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            ),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          distributor: Yup.number()
            .nullable()
            .when('$distributor', (_, schema) => {
              return schema.required(
                intl.formatMessage({
                  id: 'Debe seleccionar un distribuidor',
                }),
              );
            }),
        }),
      )
      .when('$securitySheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),

    securitySheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          run: Yup.mixed()
            .required(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            )
            .typeError(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            ),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          distributor: Yup.number()
            .nullable()
            .when('$distributor', (_, schema) => {
              return schema.required(
                intl.formatMessage({
                  id: 'Debe seleccionar un distribuidor',
                }),
              );
            }),
        }),
      )
      .when('$solarSheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),
  });
