/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';

import { push } from 'connected-react-router';

import Dialog from './dialog';

const API_URL = window.env.API_URL;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Actions = ({ push, value, row }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isClosed = row.data.status.toUpperCase() === 'GANADA';
  const { code } = row;

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        &bull;&bull;&bull;
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => push(`/distributor/arqFinishes/view/${value}`)}
        >
          <VisibilityIcon className={classes.icon} />
          <FormattedMessage id="Ver" />
        </MenuItem>
        {/* {isClosed && Boolean(code) && (
          <a
            href={`${API_URL}/warranties/${value}/download`}
            target="_blank"
            style={{ color: '#212529' }}
          >
            <MenuItem onClick={handleClose}>
              <ArrowDownwardIcon className={classes.icon} />
              <FormattedMessage id="Descargar certificado de garantia" />
            </MenuItem>
          </a>
        )} */}
        {/*isClosed && !Boolean(code) && (
          <MenuItem onClick={handleOpenDialog}>
            <MailOutlineIcon className={classes.icon} />
            <FormattedMessage id="Denunciar RUN" />
          </MenuItem>
        )*/}
        {isClosed && !Boolean(code) && (
          <MenuItem
            onClick={() => push(`/distributor/arqFinishes/warranty/${value}`)}
          >
            <AssignmentTurnedInIcon className={classes.icon} />
            <FormattedMessage id="Otorgar certificado de garantia" />
          </MenuItem>
        )}
      </Menu>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleCloseDialog} code={value} />
      )}
    </>
  );
};

export default connect(undefined, { push })(Actions);
