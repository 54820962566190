import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getArqClub3mCategoryWidget } from 'redux/dashboard/selector';

const ValueTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '2em',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    width: '50%',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    paddingRight: '1em',
    width: '50%',
  },
})(Typography);

const Club3m = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid-half">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Club 3M Window Films" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      <div className="Atomovil-Club">
        <DescriptionTypography>
          <FormattedMessage id="¡Felicitaciones! Tu categoría en nuestro club es:" />
        </DescriptionTypography>
        {!isLoading && (
          <ValueTypography color="primary">{data}</ValueTypography>
        )}
      </div>
    </PortletBody>
  </Portlet>
);

export default connect(getArqClub3mCategoryWidget)(Club3m);
