import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { PREFIX } from './reducer';

export const getUserInstallers = (state) => get(state, `${PREFIX}.data`);

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: {
          id: act.id,
          name: act.name,
        },
      },
    ];
  }, []);

export const getUserInstallersOptions = createSelector(
  getUserInstallers,
  makeAtoCompleteOptions,
);

export const getStatusInstallers = (state) => ({
  hasError: Boolean(get(state, `${PREFIX}.error`, false)),
  isLoading: Boolean(get(state, `${PREFIX}.status`, false)),
  isSuccess: Boolean(get(state, `${PREFIX}.isSuccess`, false)),
  isSaveSuccess: Boolean(get(state, `${PREFIX}.isSaveSuccess`, false)),
});
