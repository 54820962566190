import './index.scss';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { toAbsoluteUrl } from '_metronic';
import Cookie from '_metronic/layout/cookie-consent/Cookie';
import '_metronic/_assets/sass/pages/login/login-1.scss';
import { requests as authRequests } from 'redux/auth/reducer';
import { getStatusLogin } from 'redux/auth/selector';
import ReactFlagsSelect from 'react-flags-select';
import { EUROPE_FLAGS, LATAM_FLAGS } from 'enums/regions';
import { actions as i18nActions } from '_metronic/ducks/i18n';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PreRegister(props) {
  const queryParams = useQuery();

  const LATAM_BG = toAbsoluteUrl('/media/bg/bg-3.jpg');
  const EUROPE_BG = toAbsoluteUrl('/media/bg/bg-5.jpg');
  let isLatam = true;
  let backgroundImg = LATAM_BG;

  if (
    [
      'staging.garantiawf.com',
      'localhost',
      'www.myafprojecttool.com',
      'myafprojecttool.com',
    ].includes(window.location.hostname)
  ) {
    isLatam = false;
    backgroundImg = EUROPE_BG;
  }

  console.log(window.location.hostname);

  const { intl, login, hasError, isLoading, setLanguage } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  useEffect(() => {
    const defaultLang = isLatam ? 'ar' : 'gb';
    const lang = queryParams.get('lang') ?? defaultLang;
    if (lang == 'br') {
      setLanguage('br');
    } else if (lang == 'en' || lang == 'gb') {
      setLanguage('gb');
    } else {
      setLanguage(lang.toLowerCase());
    }
  }, [queryParams, setLanguage, isLatam]);

  useEffect(() => {
    if (isLoading) {
      enableLoading();
    } else {
      disableLoading();
    }
  }, [isLoading]);

  const [selected, setSelected] = useState('');

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')})`,
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-5.jpg')})`,
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/xmas/bg-xmas.jpg',)})`, //xmas remove
                backgroundImage: `url(${backgroundImg})`,
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    width="250"
                    height="44"
                    alt="Logo"
                    // src={toAbsoluteUrl('/media/logos/logo-es-blanco.png')}
                    src={toAbsoluteUrl(
                      intl.formatMessage({ id: 'LOGO-BLANCO' }),
                    )}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    {isLatam && <FormattedMessage id="3M Window Films" />}

                    {!isLatam && (
                      <>
                        3M <span className="architectural">architectural</span>
                        <span className="finishes">finishes</span>
                      </>
                    )}
                  </h3>
                  <h4 className="kt-login__subtitle">
                    {/* <FormattedMessage id="La mejor solución en control solar, confort, seguridad y privacidad para tu automóvil, empresa y hogar" /> */}
                    <FormattedMessage id="La mejor solución en control solar, confort, seguridad y privacidad para tu automóvil, empresa y hogar" />
                  </h4>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <div className="kt-login__head">
                {/* <img
                  src={`${toAbsoluteUrl('/media/bg/xmas/trees.png')}`}
                  className="santa"
                  style={
                    //xmas remove
                    {
                      width: '100px',
                      display: 'flex',
                      marginLeft: 'auto',
                    }
                  }
                /> */}
              </div>

              <div className="kt-login__body">
                <div className="kt-login__form">
                  <div className="kt-login__title">
                    <h3>
                      <FormattedMessage id="Elige tu pais para registrarte" />
                    </h3>
                  </div>

                  {isLatam ? (
                    <ReactFlagsSelect
                      countries={Object.keys(LATAM_FLAGS)}
                      customLabels={LATAM_FLAGS}
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                    />
                  ) : (
                    <ReactFlagsSelect
                      countries={Object.keys(EUROPE_FLAGS)}
                      customLabels={EUROPE_FLAGS}
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                    />
                  )}
                  <Link to={`/register?lang=${selected.toLowerCase()}`}>
                    <Button className="ml-4">
                      <FormattedMessage id="Enviar" />
                    </Button>
                  </Link>
                </div>
              </div>
              <Cookie />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(authRequests.create(payload)),
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});

export default injectIntl(
  connect(getStatusLogin, mapDispatchToProps)(PreRegister),
);
