import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

export const Vehicle = ({ values }) => (
  <div className="row">
    <div className="col-12 ViewOrEdit">
      <div>
        <strong>
          <FormattedMessage id="Marca:" />
        </strong>
        <span>{get(values, 'data.car.brand', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Modelo:" />
        </strong>
        <span>{get(values, 'data.car.model', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Número de Patente:" />
        </strong>
        <span>{get(values, 'data.licensePlate', '-')}</span>
      </div>
    </div>
  </div>
);

export default Vehicle;
