import MESSAGES from './ar';

export default {
  ...MESSAGES,
  'Número de Patente:': 'Número de Chapa o chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número de chapa o de chasis',
  'Número de patente *': 'Número de chapa o chasis *',
  'Por favor ingrese el número de patente':
    'Por favor ingresa el número de chapa o chasis',
  'CUIT:': 'RUC:',
  'CUIT: *': 'RUC: *',
  'Debe ingresar un CUIT': 'Debes ingresar un RUC',
  Provincia: 'Departamento',
  'Debe seleccionar una provincia': 'Debes seleccionar un departamento',
  'Provincia *': 'Departamento *',
  'Provincia:': 'Departamento:',
  'Provincia: *': 'Departamento: *',
};
