export const dinocFamilies = [
  { value: 'AE - Earth and Stone', label: 'AE - Earth and Stone' },
  { value: 'AM - Advanced Metallic', label: 'AM - Advanced Metallic' },
  { value: 'AR - Abrasion Resistant', label: 'AR - Abrasion Resistant' },
  { value: 'BW - Entertainment', label: 'BW - Entertainment' },
  { value: 'CA - Carbon', label: 'CA - Carbon' },
  { value: 'CH - Metallic Hairline', label: 'CH - Metallic Hairline' },
  { value: 'CN - Concrete _ Mortar', label: 'CN - Concrete / Mortar' },
  { value: 'DW - Dry Wood', label: 'DW - Dry Wood' },
  { value: 'ET - Effect', label: 'ET - Effect' },
  { value: 'EX - Exterior', label: 'EX - Exterior' },
  { value: 'FA - Multiple Categories', label: 'FA - Multiple Categories' },
  { value: 'FE - Metal Leaf _ Textile', label: 'FE - Metal Leaf / Textile' },
  { value: 'FW - Fine Wood', label: 'FW - Fine Wood' },
  { value: 'HG - High Gloss', label: 'HG - High Gloss' },
  { value: 'HS - Mono Contrast', label: 'HS - Mono Contrast' },
  { value: 'LE - Leather', label: 'LE - Leather' },
  { value: 'LW - Entertainment', label: 'LW - Entertainment' },
  { value: 'LZ - Abstract', label: 'LZ - Abstract' },
  { value: 'ME - Metallic', label: 'ME - Metallic' },
  { value: 'NU - Nuno Textile', label: 'NU - Nuno / Textile' },
  { value: 'PA - Metallic', label: 'PA - Metallic' },
  { value: 'PC - Sand', label: 'PC - Sand' },
  { value: 'PG - Abstract', label: 'PG - Abstract' },
  { value: 'PS - Single Color', label: 'PS - Single Color' },
  { value: 'PT - Abstract', label: 'PT - Abstract' },
  { value: 'PW - Premium Wood', label: 'PW - Premium Wood' },
  { value: 'PWF - Whiteboard', label: 'PWF - Whiteboard' },
  { value: 'RS - Entertainment', label: 'RS - Entertainment' },
  { value: 'RT - Aged Metal', label: 'RT - Aged Metal' },
  { value: 'SE - Abstract', label: 'SE - Abstract' },
  { value: 'SI - Silk', label: 'SI - Silk' },
  { value: 'ST - Stone', label: 'ST - Stone' },
  { value: 'SU - Suede', label: 'SU - Suede' },
  { value: 'TE - Advanced Metallic', label: 'TE - Advanced Metallic' },
  { value: 'WM - Metallic', label: 'WM - Metallic' },
  { value: 'WG - Wood Grain', label: 'WG - Wood Grain' },
  { value: 'WH - Whiteboard', label: 'WH - Whiteboard' },
  { value: 'Custom', label: 'Custom' },
].sort(compareLabel);

export const dinocFilms = [
  {
    label: 'BW-1313',
    family: 'BW - Entertainment',
    value: 'BW-1313',
  },
  {
    label: 'BW-1316',
    family: 'BW - Entertainment',
    value: 'BW-1316',
  },
  {
    label: 'BW-1311',
    family: 'BW - Entertainment',
    value: 'BW-1311',
  },
  {
    label: 'BW-1315',
    family: 'BW - Entertainment',
    value: 'BW-1315',
  },
  {
    label: 'BW-1310',
    family: 'BW - Entertainment',
    value: 'BW-1310',
  },
  {
    label: 'BW-1312',
    family: 'BW - Entertainment',
    value: 'BW-1312',
  },
  {
    label: 'BW-1314',
    family: 'BW - Entertainment',
    value: 'BW-1314',
  },
  {
    label: 'WH-111',
    family: 'WH - Whiteboard',
    value: 'WH-111',
  },
  {
    label: 'WG-7024',
    family: 'WG - Wood Grain',
    value: 'WG-7024',
  },
  {
    label: 'WG-1845',
    family: 'WG - Wood Grain',
    value: 'WG-1845',
  },
  {
    label: 'WG-2088',
    family: 'WG - Wood Grain',
    value: 'WG-2088',
  },
  {
    label: 'WG1147',
    family: 'WG - Wood Grain',
    value: 'WG1147',
  },
  {
    label: 'WG-1848',
    family: 'WG - Wood Grain',
    value: 'WG-1848',
  },
  {
    label: 'WG-7022',
    family: 'WG - Wood Grain',
    value: 'WG-7022',
  },
  {
    label: 'WG833',
    family: 'WG - Wood Grain',
    value: 'WG833',
  },
  {
    label: 'WG-664',
    family: 'WG - Wood Grain',
    value: 'WG-664',
  },
  {
    label: 'WG-1704',
    family: 'WG - Wood Grain',
    value: 'WG-1704',
  },
  {
    label: 'WG-1372AR',
    family: 'WG - Wood Grain',
    value: 'WG-1372AR',
  },
  {
    label: 'WG-2084H',
    family: 'WG - Wood Grain',
    value: 'WG-2084H',
  },
  {
    label: 'WG156',
    family: 'WG - Wood Grain',
    value: 'WG156',
  },
  {
    label: 'WG1143',
    family: 'WG - Wood Grain',
    value: 'WG1143',
  },
  {
    label: 'WG1196',
    family: 'WG - Wood Grain',
    value: 'WG1196',
  },
  {
    label: 'WG1144',
    family: 'WG - Wood Grain',
    value: 'WG1144',
  },
  {
    label: 'WG-2078',
    family: 'WG - Wood Grain',
    value: 'WG-2078',
  },
  {
    label: 'WG-1368',
    family: 'WG - Wood Grain',
    value: 'WG-1368',
  },
  {
    label: 'WG-1364',
    family: 'WG - Wood Grain',
    value: 'WG-1364',
  },
  {
    label: 'WG-243',
    family: 'WG - Wood Grain',
    value: 'WG-243',
  },
  {
    label: 'WG-846',
    family: 'WG - Wood Grain',
    value: 'WG-846',
  },
  {
    label: 'WG-946',
    family: 'WG - Wood Grain',
    value: 'WG-946',
  },
  {
    label: 'WG-2072',
    family: 'WG - Wood Grain',
    value: 'WG-2072',
  },
  {
    label: 'WG-1392H',
    family: 'WG - Wood Grain',
    value: 'WG-1392H',
  },
  {
    label: 'WG-1380',
    family: 'WG - Wood Grain',
    value: 'WG-1380',
  },
  {
    label: 'WG-709',
    family: 'WG - Wood Grain',
    value: 'WG-709',
  },
  {
    label: 'WG-1382',
    family: 'WG - Wood Grain',
    value: 'WG-1382',
  },
  {
    label: 'WG-1057',
    family: 'WG - Wood Grain',
    value: 'WG-1057',
  },
  {
    label: 'WG_157',
    family: 'WG - Wood Grain',
    value: 'WG_157',
  },
  {
    label: 'WG-159-curved',
    family: 'WG - Wood Grain',
    value: 'WG-159-curved',
  },
  {
    label: 'WG_657',
    family: 'WG - Wood Grain',
    value: 'WG_657',
  },
  {
    label: 'WG242',
    family: 'WG - Wood Grain',
    value: 'WG242',
  },
  {
    label: 'WG-1705',
    family: 'WG - Wood Grain',
    value: 'WG-1705',
  },
  {
    label: 'WG-697',
    family: 'WG - Wood Grain',
    value: 'WG-697',
  },
  {
    label: 'WG-2077',
    family: 'WG - Wood Grain',
    value: 'WG-2077',
  },
  {
    label: 'WG-1344',
    family: 'WG - Wood Grain',
    value: 'WG-1344',
  },
  {
    label: 'WG-831',
    family: 'WG - Wood Grain',
    value: 'WG-831',
  },
  {
    label: 'WG-2080H',
    family: 'WG - Wood Grain',
    value: 'WG-2080H',
  },
  {
    label: 'WG-1353',
    family: 'WG - Wood Grain',
    value: 'WG-1353',
  },
  {
    label: 'WG-1711GN',
    family: 'WG - Wood Grain',
    value: 'WG-1711GN',
  },
  {
    label: 'WG-2075',
    family: 'WG - Wood Grain',
    value: 'WG-2075',
  },
  {
    label: 'WG-629',
    family: 'WG - Wood Grain',
    value: 'WG-629',
  },
  {
    label: 'WG-836',
    family: 'WG - Wood Grain',
    value: 'WG-836',
  },
  {
    label: 'WG-2085',
    family: 'WG - Wood Grain',
    value: 'WG-2085',
  },
  {
    label: 'WG-2082H',
    family: 'WG - Wood Grain',
    value: 'WG-2082H',
  },
  {
    label: 'WG-2073',
    family: 'WG - Wood Grain',
    value: 'WG-2073',
  },
  {
    label: 'WG-1340',
    family: 'WG - Wood Grain',
    value: 'WG-1340',
  },
  {
    label: 'WG-1373',
    family: 'WG - Wood Grain',
    value: 'WG-1373',
  },
  {
    label: 'WG-1375',
    family: 'WG - Wood Grain',
    value: 'WG-1375',
  },
  {
    label: 'WG_763',
    family: 'WG - Wood Grain',
    value: 'WG_763',
  },
  {
    label: 'WG-1376',
    family: 'WG - Wood Grain',
    value: 'WG-1376',
  },
  {
    label: 'WG-376',
    family: 'WG - Wood Grain',
    value: 'WG-376',
  },
  {
    label: 'WG-2944',
    family: 'WG - Wood Grain',
    value: 'WG-2944',
  },
  {
    label: 'WG-2047',
    family: 'WG - Wood Grain',
    value: 'WG-2047',
  },
  {
    label: 'WG-453',
    family: 'WG - Wood Grain',
    value: 'WG-453',
  },
  {
    label: 'WG-2019',
    family: 'WG - Wood Grain',
    value: 'WG-2019',
  },
  {
    label: 'WG-1360',
    family: 'WG - Wood Grain',
    value: 'WG-1360',
  },
  {
    label: 'WG1220',
    family: 'WG - Wood Grain',
    value: 'WG1220',
  },
  {
    label: 'WG-1370',
    family: 'WG - Wood Grain',
    value: 'WG-1370',
  },
  {
    label: 'WG-1351',
    family: 'WG - Wood Grain',
    value: 'WG-1351',
  },
  {
    label: 'WG-250',
    family: 'WG - Wood Grain',
    value: 'WG-250',
  },
  {
    label: 'WG-1070',
    family: 'WG - Wood Grain',
    value: 'WG-1070',
  },
  {
    label: 'WG-428',
    family: 'WG - Wood Grain',
    value: 'WG-428',
  },
  {
    label: 'WG-857',
    family: 'WG - Wood Grain',
    value: 'WG-857',
  },
  {
    label: 'WG-248',
    family: 'WG - Wood Grain',
    value: 'WG-248',
  },
  {
    label: 'WG_467',
    family: 'WG - Wood Grain',
    value: 'WG_467',
  },
  {
    label: 'WG-943',
    family: 'WG - Wood Grain',
    value: 'WG-943',
  },
  {
    label: 'WG-1044',
    family: 'WG - Wood Grain',
    value: 'WG-1044',
  },
  {
    label: 'WG-879',
    family: 'WG - Wood Grain',
    value: 'WG-879',
  },
  {
    label: 'WG_693',
    family: 'WG - Wood Grain',
    value: 'WG_693',
  },
  {
    label: 'WG-837',
    family: 'WG - Wood Grain',
    value: 'WG-837',
  },
  {
    label: 'WG-2246',
    family: 'WG - Wood Grain',
    value: 'WG-2246',
  },
  {
    label: 'WG-2076',
    family: 'WG - Wood Grain',
    value: 'WG-2076',
  },
  {
    label: 'WG-2839',
    family: 'WG - Wood Grain',
    value: 'WG-2839',
  },
  {
    label: 'WG-1342',
    family: 'WG - Wood Grain',
    value: 'WG-1342',
  },
  {
    label: 'WG-947',
    family: 'WG - Wood Grain',
    value: 'WG-947',
  },
  {
    label: 'WG-696',
    family: 'WG - Wood Grain',
    value: 'WG-696',
  },
  {
    label: 'WG-841',
    family: 'WG - Wood Grain',
    value: 'WG-841',
  },
  {
    label: 'WG-1365',
    family: 'WG - Wood Grain',
    value: 'WG-1365',
  },
  {
    label: 'WG-2707',
    family: 'WG - Wood Grain',
    value: 'WG-2707',
  },
  {
    label: 'WG-2244',
    family: 'WG - Wood Grain',
    value: 'WG-2244',
  },
  {
    label: 'WG-1837',
    family: 'WG - Wood Grain',
    value: 'WG-1837',
  },
  {
    label: 'WG-1050',
    family: 'WG - Wood Grain',
    value: 'WG-1050',
  },
  {
    label: 'WG-699',
    family: 'WG - Wood Grain',
    value: 'WG-699',
  },
  {
    label: 'WG-1838',
    family: 'WG - Wood Grain',
    value: 'WG-1838',
  },
  {
    label: 'WG-1336-1',
    family: 'WG - Wood Grain',
    value: 'WG-1336-1',
  },
  {
    label: 'WG-1846',
    family: 'WG - Wood Grain',
    value: 'WG-1846',
  },
  {
    label: 'WG-2070',
    family: 'WG - Wood Grain',
    value: 'WG-2070',
  },
  {
    label: 'WG-1374',
    family: 'WG - Wood Grain',
    value: 'WG-1374',
  },
  {
    label: 'WG-1835',
    family: 'WG - Wood Grain',
    value: 'WG-1835',
  },
  {
    label: 'WG417',
    family: 'WG - Wood Grain',
    value: 'WG417',
  },
  {
    label: 'WG-1221',
    family: 'WG - Wood Grain',
    value: 'WG-1221',
  },
  {
    label: 'WG-1841',
    family: 'WG - Wood Grain',
    value: 'WG-1841',
  },
  {
    label: 'WG-1708',
    family: 'WG - Wood Grain',
    value: 'WG-1708',
  },
  {
    label: 'WG1142',
    family: 'WG - Wood Grain',
    value: 'WG1142',
  },
  {
    label: 'WG_1064',
    family: 'WG - Wood Grain',
    value: 'WG_1064',
  },
  {
    label: 'WG-2087',
    family: 'WG - Wood Grain',
    value: 'WG-2087',
  },
  {
    label: 'WG-1348',
    family: 'WG - Wood Grain',
    value: 'WG-1348',
  },
  {
    label: 'WG-835',
    family: 'WG - Wood Grain',
    value: 'WG-835',
  },
  {
    label: 'WG1058',
    family: 'WG - Wood Grain',
    value: 'WG1058',
  },
  {
    label: 'WG-1219',
    family: 'WG - Wood Grain',
    value: 'WG-1219',
  },
  {
    label: 'WG-1703',
    family: 'WG - Wood Grain',
    value: 'WG-1703',
  },
  {
    label: 'WG-865',
    family: 'WG - Wood Grain',
    value: 'WG-865',
  },
  {
    label: 'WG-1710',
    family: 'WG - Wood Grain',
    value: 'WG-1710',
  },
  {
    label: 'WG940',
    family: 'WG - Wood Grain',
    value: 'WG940',
  },
  {
    label: 'WG-1391',
    family: 'WG - Wood Grain',
    value: 'WG-1391',
  },
  {
    label: 'WG-1369',
    family: 'WG - Wood Grain',
    value: 'WG-1369',
  },
  {
    label: 'WG-1359',
    family: 'WG - Wood Grain',
    value: 'WG-1359',
  },
  {
    label: 'WG-1046',
    family: 'WG - Wood Grain',
    value: 'WG-1046',
  },
  {
    label: 'WG1146',
    family: 'WG - Wood Grain',
    value: 'WG1146',
  },
  {
    label: 'WG-2074',
    family: 'WG - Wood Grain',
    value: 'WG-2074',
  },
  {
    label: 'WG-1350',
    family: 'WG - Wood Grain',
    value: 'WG-1350',
  },
  {
    label: 'WG1812',
    family: 'WG - Wood Grain',
    value: 'WG1812',
  },
  {
    label: 'WG-2115',
    family: 'WG - Wood Grain',
    value: 'WG-2115',
  },
  {
    label: 'WG-2860',
    family: 'WG - Wood Grain',
    value: 'WG-2860',
  },
  {
    label: 'WG-1067',
    family: 'WG - Wood Grain',
    value: 'WG-1067',
  },
  {
    label: 'WG-2081H',
    family: 'WG - Wood Grain',
    value: 'WG-2081H',
  },
  {
    label: 'WG-845',
    family: 'WG - Wood Grain',
    value: 'WG-845',
  },
  {
    label: 'WG-1346',
    family: 'WG - Wood Grain',
    value: 'WG-1346',
  },
  {
    label: 'WG-1349',
    family: 'WG - Wood Grain',
    value: 'WG-1349',
  },
  {
    label: 'WG-1343',
    family: 'WG - Wood Grain',
    value: 'WG-1343',
  },
  {
    label: 'WG-878',
    family: 'WG - Wood Grain',
    value: 'WG-878',
  },
  {
    label: 'WG-1712GN',
    family: 'WG - Wood Grain',
    value: 'WG-1712GN',
  },
  {
    label: 'WG1816',
    family: 'WG - Wood Grain',
    value: 'WG1816',
  },
  {
    label: 'WG-854',
    family: 'WG - Wood Grain',
    value: 'WG-854',
  },
  {
    label: 'WG-364GN',
    family: 'WG - Wood Grain',
    value: 'WG-364GN',
  },
  {
    label: 'WG-765GN',
    family: 'WG - Wood Grain',
    value: 'WG-765GN',
  },
  {
    label: 'WG-2049',
    family: 'WG - Wood Grain',
    value: 'WG-2049',
  },
  {
    label: 'WG_1140',
    family: 'WG - Wood Grain',
    value: 'WG_1140',
  },
  {
    label: 'WG-1339',
    family: 'WG - Wood Grain',
    value: 'WG-1339',
  },
  {
    label: 'WG-1063',
    family: 'WG - Wood Grain',
    value: 'WG-1063',
  },
  {
    label: 'WG-1336',
    family: 'WG - Wood Grain',
    value: 'WG-1336',
  },
  {
    label: 'WG_1052',
    family: 'WG - Wood Grain',
    value: 'WG_1052',
  },
  {
    label: 'WG1141',
    family: 'WG - Wood Grain',
    value: 'WG1141',
  },
  {
    label: 'WG-2862',
    family: 'WG - Wood Grain',
    value: 'WG-2862',
  },
  {
    label: 'WG-2705',
    family: 'WG - Wood Grain',
    value: 'WG-2705',
  },
  {
    label: 'WG-7023',
    family: 'WG - Wood Grain',
    value: 'WG-7023',
  },
  {
    label: 'WG-832',
    family: 'WG - Wood Grain',
    value: 'WG-832',
  },
  {
    label: 'WG-1840',
    family: 'WG - Wood Grain',
    value: 'WG-1840',
  },
  {
    label: 'WG-1337',
    family: 'WG - Wood Grain',
    value: 'WG-1337',
  },
  {
    label: 'WG-1066',
    family: 'WG - Wood Grain',
    value: 'WG-1066',
  },
  {
    label: 'WG-941',
    family: 'WG - Wood Grain',
    value: 'WG-941',
  },
  {
    label: 'WG-1706',
    family: 'WG - Wood Grain',
    value: 'WG-1706',
  },
  {
    label: 'WG-1071',
    family: 'WG - Wood Grain',
    value: 'WG-1071',
  },
  {
    label: 'WG-1358',
    family: 'WG - Wood Grain',
    value: 'WG-1358',
  },
  {
    label: 'WG-478',
    family: 'WG - Wood Grain',
    value: 'WG-478',
  },
  {
    label: 'WG-2033',
    family: 'WG - Wood Grain',
    value: 'WG-2033',
  },
  {
    label: 'WG-1390',
    family: 'WG - Wood Grain',
    value: 'WG-1390',
  },
  {
    label: 'WG-960',
    family: 'WG - Wood Grain',
    value: 'WG-960',
  },
  {
    label: 'WG-2071',
    family: 'WG - Wood Grain',
    value: 'WG-2071',
  },
  {
    label: 'WG-763GN',
    family: 'WG - Wood Grain',
    value: 'WG-763GN',
  },
  {
    label: 'WG1815',
    family: 'WG - Wood Grain',
    value: 'WG1815',
  },
  {
    label: 'WG-2083H',
    family: 'WG - Wood Grain',
    value: 'WG-2083H',
  },
  {
    label: 'WG-247',
    family: 'WG - Wood Grain',
    value: 'WG-247',
  },
  {
    label: 'WG-964_NEW_2011_rgb',
    family: 'WG - Wood Grain',
    value: 'WG-964_NEW_2011_rgb',
  },
  {
    label: 'WG-2042',
    family: 'WG - Wood Grain',
    value: 'WG-2042',
  },
  {
    label: 'WG-1371',
    family: 'WG - Wood Grain',
    value: 'WG-1371',
  },
  {
    label: 'WG-877',
    family: 'WG - Wood Grain',
    value: 'WG-877',
  },
  {
    label: 'WG-1709',
    family: 'WG - Wood Grain',
    value: 'WG-1709',
  },
  {
    label: 'WG_694',
    family: 'WG - Wood Grain',
    value: 'WG_694',
  },
  {
    label: 'WG-1378',
    family: 'WG - Wood Grain',
    value: 'WG-1378',
  },
  {
    label: 'WG-256',
    family: 'WG - Wood Grain',
    value: 'WG-256',
  },
  {
    label: 'WG_364GN',
    family: 'WG - Wood Grain',
    value: 'WG_364GN',
  },
  {
    label: 'WG695',
    family: 'WG - Wood Grain',
    value: 'WG695',
  },
  {
    label: 'WG-2086',
    family: 'WG - Wood Grain',
    value: 'WG-2086',
  },
  {
    label: 'WG166',
    family: 'WG - Wood Grain',
    value: 'WG166',
  },
  {
    label: 'WG-7029',
    family: 'WG - Wood Grain',
    value: 'WG-7029',
  },
  {
    label: 'WG-2041',
    family: 'WG - Wood Grain',
    value: 'WG-2041',
  },
  {
    label: 'WG-2048',
    family: 'WG - Wood Grain',
    value: 'WG-2048',
  },
  {
    label: 'RS1190',
    family: 'RS - Entertainment',
    value: 'RS1190',
  },
  {
    label: 'RS_1189',
    family: 'RS - Entertainment',
    value: 'RS_1189',
  },
  {
    label: 'ME-2254MT',
    family: 'ME - Metallic',
    value: 'ME-2254MT',
  },
  {
    label: 'ME-1961',
    family: 'ME - Metallic',
    value: 'ME-1961',
  },
  {
    label: 'ME-391_NEW_2011_rgb',
    family: 'ME - Metallic',
    value: 'ME-391_NEW_2011_rgb',
  },
  {
    label: 'ME-433',
    family: 'ME - Metallic',
    value: 'ME-433',
  },
  {
    label: 'ME-2026',
    family: 'ME - Metallic',
    value: 'ME-2026',
  },
  {
    label: 'ME-1175_NEW_2011_rgb_01',
    family: 'ME - Metallic',
    value: 'ME-1175_NEW_2011_rgb_01',
  },
  {
    label: 'ME-1997',
    family: 'ME - Metallic',
    value: 'ME-1997',
  },
  {
    label: 'ME-486',
    family: 'ME - Metallic',
    value: 'ME-486',
  },
  {
    label: 'ME-904',
    family: 'ME - Metallic',
    value: 'ME-904',
  },
  {
    label: 'ME-1716',
    family: 'ME - Metallic',
    value: 'ME-1716',
  },
  {
    label: 'ME-396',
    family: 'ME - Metallic',
    value: 'ME-396',
  },
  {
    label: 'ME-2252MT',
    family: 'ME - Metallic',
    value: 'ME-2252MT',
  },
  {
    label: 'ME-1778',
    family: 'ME - Metallic',
    value: 'ME-1778',
  },
  {
    label: 'ME1224',
    family: 'ME - Metallic',
    value: 'ME1224',
  },
  {
    label: 'ME-2027',
    family: 'ME - Metallic',
    value: 'ME-2027',
  },
  {
    label: 'ME-388',
    family: 'ME - Metallic',
    value: 'ME-388',
  },
  {
    label: 'ME-2255MT',
    family: 'ME - Metallic',
    value: 'ME-2255MT',
  },
  {
    label: 'ME1223',
    family: 'ME - Metallic',
    value: 'ME1223',
  },
  {
    label: 'ME-1469',
    family: 'ME - Metallic',
    value: 'ME-1469',
  },
  {
    label: 'ME-1684AR',
    family: 'ME - Metallic',
    value: 'ME-1684AR',
  },
  {
    label: 'ME-2273',
    family: 'ME - Metallic',
    value: 'ME-2273',
  },
  {
    label: 'ME-2023',
    family: 'ME - Metallic',
    value: 'ME-2023',
  },
  {
    label: 'ME-2251MT',
    family: 'ME - Metallic',
    value: 'ME-2251MT',
  },
  {
    label: 'ME-432',
    family: 'ME - Metallic',
    value: 'ME-432',
  },
  {
    label: 'ME-1435',
    family: 'ME - Metallic',
    value: 'ME-1435',
  },
  {
    label: 'ME-2020',
    family: 'ME - Metallic',
    value: 'ME-2020',
  },
  {
    label: 'ME-2025',
    family: 'ME - Metallic',
    value: 'ME-2025',
  },
  {
    label: 'ME-2272',
    family: 'ME - Metallic',
    value: 'ME-2272',
  },
  {
    label: 'ME-1174',
    family: 'ME - Metallic',
    value: 'ME-1174',
  },
  {
    label: 'ME-1466',
    family: 'ME - Metallic',
    value: 'ME-1466',
  },
  {
    label: 'ME-377',
    family: 'ME - Metallic',
    value: 'ME-377',
  },
  {
    label: 'ME-2022',
    family: 'ME - Metallic',
    value: 'ME-2022',
  },
  {
    label: 'ME-2174',
    family: 'ME - Metallic',
    value: 'ME-2174',
  },
  {
    label: 'ME-1685AR',
    family: 'ME - Metallic',
    value: 'ME-1685AR',
  },
  {
    label: 'ME-431',
    family: 'ME - Metallic',
    value: 'ME-431',
  },
  {
    label: 'ME-1434',
    family: 'ME - Metallic',
    value: 'ME-1434',
  },
  {
    label: 'ME-2274',
    family: 'ME - Metallic',
    value: 'ME-2274',
  },
  {
    label: 'ME-2261',
    family: 'ME - Metallic',
    value: 'ME-2261',
  },
  {
    label: 'ME-1781',
    family: 'ME - Metallic',
    value: 'ME-1781',
  },
  {
    label: 'ME-379',
    family: 'ME - Metallic',
    value: 'ME-379',
  },
  {
    label: 'ME-2263',
    family: 'ME - Metallic',
    value: 'ME-2263',
  },
  {
    label: 'ME-2024',
    family: 'ME - Metallic',
    value: 'ME-2024',
  },
  {
    label: 'ME-1467',
    family: 'ME - Metallic',
    value: 'ME-1467',
  },
  {
    label: 'ME-1225',
    family: 'ME - Metallic',
    value: 'ME-1225',
  },
  {
    label: 'ME-2265',
    family: 'ME - Metallic',
    value: 'ME-2265',
  },
  {
    label: 'ME-380',
    family: 'ME - Metallic',
    value: 'ME-380',
  },
  {
    label: 'ME-2173',
    family: 'ME - Metallic',
    value: 'ME-2173',
  },
  {
    label: 'ME-2264',
    family: 'ME - Metallic',
    value: 'ME-2264',
  },
  {
    label: 'ME-147',
    family: 'ME - Metallic',
    value: 'ME-147',
  },
  {
    label: 'ME-2262',
    family: 'ME - Metallic',
    value: 'ME-2262',
  },
  {
    label: 'ME-2172',
    family: 'ME - Metallic',
    value: 'ME-2172',
  },
  {
    label: 'ME-2253MT',
    family: 'ME - Metallic',
    value: 'ME-2253MT',
  },
  {
    label: 'ME-2271',
    family: 'ME - Metallic',
    value: 'ME-2271',
  },
  {
    label: 'PW-2306MT',
    family: 'PW - Premium Wood',
    value: 'PW-2306MT',
  },
  {
    label: 'PW-2317MT',
    family: 'PW - Premium Wood',
    value: 'PW-2317MT',
  },
  {
    label: 'PW-2309MT',
    family: 'PW - Premium Wood',
    value: 'PW-2309MT',
  },
  {
    label: 'PW-2314MT',
    family: 'PW - Premium Wood',
    value: 'PW-2314MT',
  },
  {
    label: 'PW-2311MT',
    family: 'PW - Premium Wood',
    value: 'PW-2311MT',
  },
  {
    label: 'PW-2310MT',
    family: 'PW - Premium Wood',
    value: 'PW-2310MT',
  },
  {
    label: 'PW-2319MT',
    family: 'PW - Premium Wood',
    value: 'PW-2319MT',
  },
  {
    label: 'PW-2313MT',
    family: 'PW - Premium Wood',
    value: 'PW-2313MT',
  },
  {
    label: 'PW-2316MT',
    family: 'PW - Premium Wood',
    value: 'PW-2316MT',
  },
  {
    label: 'PW-2312MT',
    family: 'PW - Premium Wood',
    value: 'PW-2312MT',
  },
  {
    label: 'PW-2307MT',
    family: 'PW - Premium Wood',
    value: 'PW-2307MT',
  },
  {
    label: 'PW-2318MT',
    family: 'PW - Premium Wood',
    value: 'PW-2318MT',
  },
  {
    label: 'PW-2305MT',
    family: 'PW - Premium Wood',
    value: 'PW-2305MT',
  },
  {
    label: 'PW-2308MT',
    family: 'PW - Premium Wood',
    value: 'PW-2308MT',
  },
  {
    label: 'PW-2315MT',
    family: 'PW - Premium Wood',
    value: 'PW-2315MT',
  },
  {
    label: 'PA_038',
    family: 'PA - Metallic',
    value: 'PA_038',
  },
  {
    label: 'PA-183',
    family: 'PA - Metallic',
    value: 'PA-183',
  },
  {
    label: 'PA-046',
    family: 'PA - Metallic',
    value: 'PA-046',
  },
  {
    label: 'PA-184',
    family: 'PA - Metallic',
    value: 'PA-184',
  },
  {
    label: 'PA-185',
    family: 'PA - Metallic',
    value: 'PA-185',
  },
  {
    label: 'PA-178',
    family: 'PA - Metallic',
    value: 'PA-178',
  },
  {
    label: 'PA-187',
    family: 'PA - Metallic',
    value: 'PA-187',
  },
  {
    label: 'PA-045',
    family: 'PA - Metallic',
    value: 'PA-045',
  },
  {
    label: 'PA-683',
    family: 'PA - Metallic',
    value: 'PA-683',
  },
  {
    label: 'PA-039',
    family: 'PA - Metallic',
    value: 'PA-039',
  },
  {
    label: 'PA-390',
    family: 'PA - Metallic',
    value: 'PA-390',
  },
  {
    label: 'PA-175',
    family: 'PA - Metallic',
    value: 'PA-175',
  },
  {
    label: 'PA-389',
    family: 'PA - Metallic',
    value: 'PA-389',
  },
  {
    label: 'PA-180',
    family: 'PA - Metallic',
    value: 'PA-180',
  },
  {
    label: 'PA-177',
    family: 'PA - Metallic',
    value: 'PA-177',
  },
  {
    label: 'PA-181',
    family: 'PA - Metallic',
    value: 'PA-181',
  },
  {
    label: 'PA-320',
    family: 'PA - Metallic',
    value: 'PA-320',
  },
  {
    label: 'PA-036',
    family: 'PA - Metallic',
    value: 'PA-036',
  },
  {
    label: 'HG-1205',
    family: 'HG - High Gloss',
    value: 'HG-1205',
  },
  {
    label: 'HG-1201',
    family: 'HG - High Gloss',
    value: 'HG-1201',
  },
  {
    label: 'HG-1994',
    family: 'HG - High Gloss',
    value: 'HG-1994',
  },
  {
    label: 'HG-1995',
    family: 'HG - High Gloss',
    value: 'HG-1995',
  },
  {
    label: 'HG-1996',
    family: 'HG - High Gloss',
    value: 'HG-1996',
  },
  {
    label: 'HG-1511',
    family: 'HG - High Gloss',
    value: 'HG-1511',
  },
  {
    label: 'MW1244',
    family: 'MW - Metallic Wood',
    value: 'MW1244',
  },
  {
    label: 'MW-1783',
    family: 'MW - Metallic Wood',
    value: 'MW-1783',
  },
  {
    label: 'MW-1416',
    family: 'MW - Metallic Wood',
    value: 'MW-1416',
  },
  {
    label: 'MW-776',
    family: 'MW - Metallic Wood',
    value: 'MW-776',
  },
  {
    label: 'MW-1834',
    family: 'MW - Metallic Wood',
    value: 'MW-1834',
  },
  {
    label: 'MW-1417',
    family: 'MW - Metallic Wood',
    value: 'MW-1417',
  },
  {
    label: 'MW-777',
    family: 'MW - Metallic Wood',
    value: 'MW-777',
  },
  {
    label: 'MW-1418',
    family: 'MW - Metallic Wood',
    value: 'MW-1418',
  },
  {
    label: 'MW-1782',
    family: 'MW - Metallic Wood',
    value: 'MW-1782',
  },
  {
    label: 'MW1242',
    family: 'MW - Metallic Wood',
    value: 'MW1242',
  },
  {
    label: 'MW-1833',
    family: 'MW - Metallic Wood',
    value: 'MW-1833',
  },
  {
    label: 'MW-1832',
    family: 'MW - Metallic Wood',
    value: 'MW-1832',
  },
  {
    label: 'MW-1419',
    family: 'MW - Metallic Wood',
    value: 'MW-1419',
  },
  {
    label: 'MW-1177',
    family: 'MW - Metallic Wood',
    value: 'MW-1177',
  },
  {
    label: 'PC-1178',
    family: 'PC - Sand',
    value: 'PC-1178',
  },
  {
    label: 'PC-1179',
    family: 'PC - Sand',
    value: 'PC-1179',
  },
  {
    label: 'PC-758',
    family: 'PC - Sand',
    value: 'PC-758',
  },
  {
    label: 'PC-672',
    family: 'PC - Sand',
    value: 'PC-672',
  },
  {
    label: 'PC-1181',
    family: 'PC - Sand',
    value: 'PC-1181',
  },
  {
    label: 'PC-760',
    family: 'PC - Sand',
    value: 'PC-760',
  },
  {
    label: 'PC-491',
    family: 'PC - Sand',
    value: 'PC-491',
  },
  {
    label: 'FA_1163',
    family: 'FA - Multilple Categories',
    value: 'FA_1163',
  },
  {
    label: 'FA-2169',
    family: 'FA - Multilple Categories',
    value: 'FA-2169',
  },
  {
    label: 'FA-1167',
    family: 'FA - Multilple Categories',
    value: 'FA-1167',
  },
  {
    label: 'FA-1098',
    family: 'FA - Multilple Categories',
    value: 'FA-1098',
  },
  {
    label: 'FA-1099',
    family: 'FA - Multilple Categories',
    value: 'FA-1099',
  },
  {
    label: 'FA-1531',
    family: 'FA - Multilple Categories',
    value: 'FA-1531',
  },
  {
    label: 'FA_592',
    family: 'FA - Multilple Categories',
    value: 'FA_592',
  },
  {
    label: 'FA-1528',
    family: 'FA - Multilple Categories',
    value: 'FA-1528',
  },
  {
    label: 'FA-1679',
    family: 'FA - Multilple Categories',
    value: 'FA-1679',
  },
  {
    label: 'FA-1963',
    family: 'FA - Multilple Categories',
    value: 'FA-1963',
  },
  {
    label: 'FA-1153',
    family: 'FA - Multilple Categories',
    value: 'FA-1153',
  },
  {
    label: 'FA-690',
    family: 'FA - Multilple Categories',
    value: 'FA-690',
  },
  {
    label: 'FA-1678AR',
    family: 'FA - Multilple Categories',
    value: 'FA-1678AR',
  },
  {
    label: 'FA-1150',
    family: 'FA - Multilple Categories',
    value: 'FA-1150',
  },
  {
    label: 'FA-688',
    family: 'FA - Multilple Categories',
    value: 'FA-688',
  },
  {
    label: 'FA-1530AR',
    family: 'FA - Multilple Categories',
    value: 'FA-1530AR',
  },
  {
    label: 'FA_1161-1',
    family: 'FA - Multilple Categories',
    value: 'FA_1161-1',
  },
  {
    label: 'FA_1164',
    family: 'FA - Multilple Categories',
    value: 'FA_1164',
  },
  {
    label: 'FA-1962',
    family: 'FA - Multilple Categories',
    value: 'FA-1962',
  },
  {
    label: 'FA-1680',
    family: 'FA - Multilple Categories',
    value: 'FA-1680',
  },
  {
    label: 'FA-1526',
    family: 'FA - Multilple Categories',
    value: 'FA-1526',
  },
  {
    label: 'FA_1166',
    family: 'FA - Multilple Categories',
    value: 'FA_1166',
  },
  {
    label: 'FA-1521',
    family: 'FA - Multilple Categories',
    value: 'FA-1521',
  },
  {
    label: 'FA-1527',
    family: 'FA - Multilple Categories',
    value: 'FA-1527',
  },
  {
    label: 'PS_1187',
    family: 'PS - Single Color',
    value: 'PS_1187',
  },
  {
    label: 'PS-3098MT',
    family: 'PS - Single Color',
    value: 'PS-3098MT',
  },
  {
    label: 'PS-668',
    family: 'PS - Single Color',
    value: 'PS-668',
  },
  {
    label: 'PS-3869MT',
    family: 'PS - Single Color',
    value: 'PS-3869MT',
  },
  {
    label: 'PS-151',
    family: 'PS - Single Color',
    value: 'PS-151',
  },
  {
    label: 'PS-1450',
    family: 'PS - Single Color',
    value: 'PS-1450',
  },
  {
    label: 'PS-3866MT',
    family: 'PS - Single Color',
    value: 'PS-3866MT',
  },
  {
    label: 'PS-281',
    family: 'PS - Single Color',
    value: 'PS-281',
  },
  {
    label: 'PS-1439',
    family: 'PS - Single Color',
    value: 'PS-1439',
  },
  {
    label: 'PS-093',
    family: 'PS - Single Color',
    value: 'PS-093',
  },
  {
    label: 'PS-293',
    family: 'PS - Single Color',
    value: 'PS-293',
  },
  {
    label: 'PS-1452',
    family: 'PS - Single Color',
    value: 'PS-1452',
  },
  {
    label: 'PS-1826',
    family: 'PS - Single Color',
    value: 'PS-1826',
  },
  {
    label: 'PS-949',
    family: 'PS - Single Color',
    value: 'PS-949',
  },
  {
    label: 'PS-073',
    family: 'PS - Single Color',
    value: 'PS-073',
  },
  {
    label: 'PS-950',
    family: 'PS - Single Color',
    value: 'PS-950',
  },
  {
    label: 'PS-1823',
    family: 'PS - Single Color',
    value: 'PS-1823',
  },
  {
    label: 'PS-976',
    family: 'PS - Single Color',
    value: 'PS-976',
  },
  {
    label: 'PS-1454',
    family: 'PS - Single Color',
    value: 'PS-1454',
  },
  {
    label: 'PS-983',
    family: 'PS - Single Color',
    value: 'PS-983',
  },
  {
    label: 'PS-294',
    family: 'PS - Single Color',
    value: 'PS-294',
  },
  {
    label: 'PS-1822',
    family: 'PS - Single Color',
    value: 'PS-1822',
  },
  {
    label: 'PS-3093MT',
    family: 'PS - Single Color',
    value: 'PS-3093MT',
  },
  {
    label: 'PS_1185',
    family: 'PS - Single Color',
    value: 'PS_1185',
  },
  {
    label: 'PS-042',
    family: 'PS - Single Color',
    value: 'PS-042',
  },
  {
    label: 'PS-1821',
    family: 'PS - Single Color',
    value: 'PS-1821',
  },
  {
    label: 'PS-027',
    family: 'PS - Single Color',
    value: 'PS-027',
  },
  {
    label: 'PS_1186',
    family: 'PS - Single Color',
    value: 'PS_1186',
  },
  {
    label: 'PS-3099MT',
    family: 'PS - Single Color',
    value: 'PS-3099MT',
  },
  {
    label: 'PS-140',
    family: 'PS - Single Color',
    value: 'PS-140',
  },
  {
    label: 'PS-3097MT',
    family: 'PS - Single Color',
    value: 'PS-3097MT',
  },
  {
    label: 'PS_1183MT',
    family: 'PS - Single Color',
    value: 'PS_1183MT',
  },
  {
    label: 'PS-074',
    family: 'PS - Single Color',
    value: 'PS-074',
  },
  {
    label: 'PS-665',
    family: 'PS - Single Color',
    value: 'PS-665',
  },
  {
    label: 'PS-1825',
    family: 'PS - Single Color',
    value: 'PS-1825',
  },
  {
    label: 'PS-1443',
    family: 'PS - Single Color',
    value: 'PS-1443',
  },
  {
    label: 'PS-912',
    family: 'PS - Single Color',
    value: 'PS-912',
  },
  {
    label: 'PS-107',
    family: 'PS - Single Color',
    value: 'PS-107',
  },
  {
    label: 'PS-141',
    family: 'PS - Single Color',
    value: 'PS-141',
  },
  {
    label: 'PS-982',
    family: 'PS - Single Color',
    value: 'PS-982',
  },
  {
    label: 'PS-3101MT',
    family: 'PS - Single Color',
    value: 'PS-3101MT',
  },
  {
    label: 'PS-116',
    family: 'PS - Single Color',
    value: 'PS-116',
  },
  {
    label: 'PS-948',
    family: 'PS - Single Color',
    value: 'PS-948',
  },
  {
    label: 'PS_135',
    family: 'PS - Single Color',
    value: 'PS_135',
  },
  {
    label: 'PS-1446',
    family: 'PS - Single Color',
    value: 'PS-1446',
  },
  {
    label: 'PS-920',
    family: 'PS - Single Color',
    value: 'PS-920',
  },
  {
    label: 'PS-914',
    family: 'PS - Single Color',
    value: 'PS-914',
  },
  {
    label: 'PS-1870MT',
    family: 'PS - Single Color',
    value: 'PS-1870MT',
  },
  {
    label: 'PS-139',
    family: 'PS - Single Color',
    value: 'PS-139',
  },
  {
    label: 'PS-3864MT',
    family: 'PS - Single Color',
    value: 'PS-3864MT',
  },
  {
    label: 'PS-3091MT',
    family: 'PS - Single Color',
    value: 'PS-3091MT',
  },
  {
    label: 'PS-885',
    family: 'PS - Single Color',
    value: 'PS-885',
  },
  {
    label: 'PS-1437',
    family: 'PS - Single Color',
    value: 'PS-1437',
  },
  {
    label: 'PS-1005',
    family: 'PS - Single Color',
    value: 'PS-1005',
  },
  {
    label: 'PS-957',
    family: 'PS - Single Color',
    value: 'PS-957',
  },
  {
    label: 'PS-1444',
    family: 'PS - Single Color',
    value: 'PS-1444',
  },
  {
    label: 'PS-992',
    family: 'PS - Single Color',
    value: 'PS-992',
  },
  {
    label: 'PS-3096MT',
    family: 'PS - Single Color',
    value: 'PS-3096MT',
  },
  {
    label: 'PS-1438',
    family: 'PS - Single Color',
    value: 'PS-1438',
  },
  {
    label: 'PS-3092MT',
    family: 'PS - Single Color',
    value: 'PS-3092MT',
  },
  {
    label: 'PS-3095MT',
    family: 'PS - Single Color',
    value: 'PS-3095MT',
  },
  {
    label: 'PS-1824',
    family: 'PS - Single Color',
    value: 'PS-1824',
  },
  {
    label: 'PS-1457',
    family: 'PS - Single Color',
    value: 'PS-1457',
  },
  {
    label: 'PS-959',
    family: 'PS - Single Color',
    value: 'PS-959',
  },
  {
    label: 'PS1008',
    family: 'PS - Single Color',
    value: 'PS1008',
  },
  {
    label: 'PS-1436',
    family: 'PS - Single Color',
    value: 'PS-1436',
  },
  {
    label: 'PS-3870MT',
    family: 'PS - Single Color',
    value: 'PS-3870MT',
  },
  {
    label: 'PS-719',
    family: 'PS - Single Color',
    value: 'PS-719',
  },
  {
    label: 'PS-504',
    family: 'PS - Single Color',
    value: 'PS-504',
  },
  {
    label: 'PS-3094MT',
    family: 'PS - Single Color',
    value: 'PS-3094MT',
  },
  {
    label: 'PS-075',
    family: 'PS - Single Color',
    value: 'PS-075',
  },
  {
    label: 'PS-954SR',
    family: 'PS - Single Color',
    value: 'PS-954SR',
  },
  {
    label: 'PS-910',
    family: 'PS - Single Color',
    value: 'PS-910',
  },
  {
    label: 'PS-3867MT',
    family: 'PS - Single Color',
    value: 'PS-3867MT',
  },
  {
    label: 'PS-1442',
    family: 'PS - Single Color',
    value: 'PS-1442',
  },
  {
    label: 'PS-3100MT',
    family: 'PS - Single Color',
    value: 'PS-3100MT',
  },
  {
    label: 'PS-1448',
    family: 'PS - Single Color',
    value: 'PS-1448',
  },
  {
    label: 'PS-1455',
    family: 'PS - Single Color',
    value: 'PS-1455',
  },
  {
    label: 'PS-998',
    family: 'PS - Single Color',
    value: 'PS-998',
  },
  {
    label: 'PS-031',
    family: 'PS - Single Color',
    value: 'PS-031',
  },
  {
    label: 'PS-503',
    family: 'PS - Single Color',
    value: 'PS-503',
  },
  {
    label: 'PS-112',
    family: 'PS - Single Color',
    value: 'PS-112',
  },
  {
    label: 'PS-1184',
    family: 'PS - Single Color',
    value: 'PS-1184',
  },
  {
    label: 'PS-292',
    family: 'PS - Single Color',
    value: 'PS-292',
  },
  {
    label: 'PS-134',
    family: 'PS - Single Color',
    value: 'PS-134',
  },
  {
    label: 'PS-713',
    family: 'PS - Single Color',
    value: 'PS-713',
  },
  {
    label: 'PS-1447',
    family: 'PS - Single Color',
    value: 'PS-1447',
  },
  {
    label: 'PS-952',
    family: 'PS - Single Color',
    value: 'PS-952',
  },
  {
    label: 'PS-091',
    family: 'PS - Single Color',
    value: 'PS-091',
  },
  {
    label: 'PS-975',
    family: 'PS - Single Color',
    value: 'PS-975',
  },
  {
    label: 'PS-3904MT',
    family: 'PS - Single Color',
    value: 'PS-3904MT',
  },
  {
    label: 'PS-034',
    family: 'PS - Single Color',
    value: 'PS-034',
  },
  {
    label: 'PS-096',
    family: 'PS - Single Color',
    value: 'PS-096',
  },
  {
    label: 'PS-1441',
    family: 'PS - Single Color',
    value: 'PS-1441',
  },
  {
    label: 'PS-3865MT',
    family: 'PS - Single Color',
    value: 'PS-3865MT',
  },
  {
    label: 'PS-506',
    family: 'PS - Single Color',
    value: 'PS-506',
  },
  {
    label: 'PS-1010',
    family: 'PS - Single Color',
    value: 'PS-1010',
  },
  {
    label: 'PS-121',
    family: 'PS - Single Color',
    value: 'PS-121',
  },
  {
    label: 'PS1009',
    family: 'PS - Single Color',
    value: 'PS1009',
  },
  {
    label: 'PS-1459',
    family: 'PS - Single Color',
    value: 'PS-1459',
  },
  {
    label: 'PS-2060',
    family: 'PS - Single Color',
    value: 'PS-2060',
  },
  {
    label: 'PS_1188',
    family: 'PS - Single Color',
    value: 'PS_1188',
  },
  {
    label: 'PS-955',
    family: 'PS - Single Color',
    value: 'PS-955',
  },
  {
    label: 'PS-971',
    family: 'PS - Single Color',
    value: 'PS-971',
  },
  {
    label: 'PS-048',
    family: 'PS - Single Color',
    value: 'PS-048',
  },
  {
    label: 'PS-090',
    family: 'PS - Single Color',
    value: 'PS-090',
  },
  {
    label: 'PS-1820',
    family: 'PS - Single Color',
    value: 'PS-1820',
  },
  {
    label: 'PS-3989MT',
    family: 'PS - Single Color',
    value: 'PS-3989MT',
  },
  {
    label: 'PS-3863MT',
    family: 'PS - Single Color',
    value: 'PS-3863MT',
  },
  {
    label: 'PS-1440',
    family: 'PS - Single Color',
    value: 'PS-1440',
  },
  {
    label: 'PS-022',
    family: 'PS - Single Color',
    value: 'PS-022',
  },
  {
    label: 'PS-900',
    family: 'PS - Single Color',
    value: 'PS-900',
  },
  {
    label: 'SI-1613',
    family: 'SI - Silk',
    value: 'SI-1613',
  },
  {
    label: 'SI1234',
    family: 'SI - Silk',
    value: 'SI1234',
  },
  {
    label: 'SI-1689',
    family: 'SI - Silk',
    value: 'SI-1689',
  },
  {
    label: 'SI-1235',
    family: 'SI - Silk',
    value: 'SI-1235',
  },
  {
    label: 'SI1232',
    family: 'SI - Silk',
    value: 'SI1232',
  },
  {
    label: 'SI-1686',
    family: 'SI - Silk',
    value: 'SI-1686',
  },
  {
    label: 'SI-1688',
    family: 'SI - Silk',
    value: 'SI-1688',
  },
  {
    label: 'VM-1488',
    family: 'VM - Metallic',
    value: 'VM-1488',
  },
  {
    label: 'VM-306',
    family: 'VM - Metallic',
    value: 'VM-306',
  },
  {
    label: 'VM-2037',
    family: 'VM - Metallic',
    value: 'VM-2037',
  },
  {
    label: 'VM-1692',
    family: 'VM - Metallic',
    value: 'VM-1692',
  },
  {
    label: 'VM-800',
    family: 'VM - Metallic',
    value: 'VM-800',
  },
  {
    label: 'VM-1693',
    family: 'VM - Metallic',
    value: 'VM-1693',
  },
  {
    label: 'VM-381',
    family: 'VM - Metallic',
    value: 'VM-381',
  },
  {
    label: 'VM-2036',
    family: 'VM - Metallic',
    value: 'VM-2036',
  },
  {
    label: 'VM-2122',
    family: 'VM - Metallic',
    value: 'VM-2122',
  },
  {
    label: 'VM-1990MT',
    family: 'VM - Metallic',
    value: 'VM-1990MT',
  },
  {
    label: 'VM-2038',
    family: 'VM - Metallic',
    value: 'VM-2038',
  },
  {
    label: 'VM-2039',
    family: 'VM - Metallic',
    value: 'VM-2039',
  },
  {
    label: 'VM-2034',
    family: 'VM - Metallic',
    value: 'VM-2034',
  },
  {
    label: 'VM452',
    family: 'VM - Metallic',
    value: 'VM452',
  },
  {
    label: 'VM-2090',
    family: 'VM - Metallic',
    value: 'VM-2090',
  },
  {
    label: 'VM-1691',
    family: 'VM - Metallic',
    value: 'VM-1691',
  },
  {
    label: 'VM-1489',
    family: 'VM - Metallic',
    value: 'VM-1489',
  },
  {
    label: 'VM383',
    family: 'VM - Metallic',
    value: 'VM383',
  },
  {
    label: 'VM-1694',
    family: 'VM - Metallic',
    value: 'VM-1694',
  },
  {
    label: 'VM-2035',
    family: 'VM - Metallic',
    value: 'VM-2035',
  },
  {
    label: 'VM-1487',
    family: 'VM - Metallic',
    value: 'VM-1487',
  },
  {
    label: 'VM305',
    family: 'VM - Metallic',
    value: 'VM305',
  },
  {
    label: 'VM-1862MT',
    family: 'VM - Metallic',
    value: 'VM-1862MT',
  },
  {
    label: 'VM-1695',
    family: 'VM - Metallic',
    value: 'VM-1695',
  },
  {
    label: 'VM-1855MT',
    family: 'VM - Metallic',
    value: 'VM-1855MT',
  },
  {
    label: 'VM-2121',
    family: 'VM - Metallic',
    value: 'VM-2121',
  },
  {
    label: 'DW-2214MT',
    family: 'DW - Dry Wood',
    value: 'DW-2214MT',
  },
  {
    label: 'DW-2228MT',
    family: 'DW - Dry Wood',
    value: 'DW-2228MT',
  },
  {
    label: 'DW-2218MT',
    family: 'DW - Dry Wood',
    value: 'DW-2218MT',
  },
  {
    label: 'DW-2202MT',
    family: 'DW - Dry Wood',
    value: 'DW-2202MT',
  },
  {
    label: 'DW-1883MT',
    family: 'DW - Dry Wood',
    value: 'DW-1883MT',
  },
  {
    label: 'DW-1872MT',
    family: 'DW - Dry Wood',
    value: 'DW-1872MT',
  },
  {
    label: 'DW-1900HMT',
    family: 'DW - Dry Wood',
    value: 'DW-1900HMT',
  },
  {
    label: 'DW-1893MT',
    family: 'DW - Dry Wood',
    value: 'DW-1893MT',
  },
  {
    label: 'DW-1896MT',
    family: 'DW - Dry Wood',
    value: 'DW-1896MT',
  },
  {
    label: 'DW-1885MT',
    family: 'DW - Dry Wood',
    value: 'DW-1885MT',
  },
  {
    label: 'DW-1902MT',
    family: 'DW - Dry Wood',
    value: 'DW-1902MT',
  },
  {
    label: 'DW-1884MT',
    family: 'DW - Dry Wood',
    value: 'DW-1884MT',
  },
  {
    label: 'DW-1899MT',
    family: 'DW - Dry Wood',
    value: 'DW-1899MT',
  },
  {
    label: 'DW-2198MT',
    family: 'DW - Dry Wood',
    value: 'DW-2198MT',
  },
  {
    label: 'DW-1881MT',
    family: 'DW - Dry Wood',
    value: 'DW-1881MT',
  },
  {
    label: 'DW-2217MT',
    family: 'DW - Dry Wood',
    value: 'DW-2217MT',
  },
  {
    label: 'DW-1876MT',
    family: 'DW - Dry Wood',
    value: 'DW-1876MT',
  },
  {
    label: 'DW-2227MT',
    family: 'DW - Dry Wood',
    value: 'DW-2227MT',
  },
  {
    label: 'DW-2224MT',
    family: 'DW - Dry Wood',
    value: 'DW-2224MT',
  },
  {
    label: 'DW-1878MT',
    family: 'DW - Dry Wood',
    value: 'DW-1878MT',
  },
  {
    label: 'DW-1888MT_LA',
    family: 'DW - Dry Wood',
    value: 'DW-1888MT_LA',
  },
  {
    label: 'DW-2206MT',
    family: 'DW - Dry Wood',
    value: 'DW-2206MT',
  },
  {
    label: 'DW-1877MT',
    family: 'DW - Dry Wood',
    value: 'DW-1877MT',
  },
  {
    label: 'DW-2216MT',
    family: 'DW - Dry Wood',
    value: 'DW-2216MT',
  },
  {
    label: 'DW-2210MT',
    family: 'DW - Dry Wood',
    value: 'DW-2210MT',
  },
  {
    label: 'DW-1992MT',
    family: 'DW - Dry Wood',
    value: 'DW-1992MT',
  },
  {
    label: 'DW-2215MT',
    family: 'DW - Dry Wood',
    value: 'DW-2215MT',
  },
  {
    label: 'DW-2211MT',
    family: 'DW - Dry Wood',
    value: 'DW-2211MT',
  },
  {
    label: 'DW-2222MT',
    family: 'DW - Dry Wood',
    value: 'DW-2222MT',
  },
  {
    label: 'DW-1903MT',
    family: 'DW - Dry Wood',
    value: 'DW-1903MT',
  },
  {
    label: 'DW-2204MT',
    family: 'DW - Dry Wood',
    value: 'DW-2204MT',
  },
  {
    label: 'DW-1879MT',
    family: 'DW - Dry Wood',
    value: 'DW-1879MT',
  },
  {
    label: 'DW-2207MT',
    family: 'DW - Dry Wood',
    value: 'DW-2207MT',
  },
  {
    label: 'DW-1888MT',
    family: 'DW - Dry Wood',
    value: 'DW-1888MT',
  },
  {
    label: 'DW-1875MT',
    family: 'DW - Dry Wood',
    value: 'DW-1875MT',
  },
  {
    label: 'DW-2203MT',
    family: 'DW - Dry Wood',
    value: 'DW-2203MT',
  },
  {
    label: 'DW-1898MT',
    family: 'DW - Dry Wood',
    value: 'DW-1898MT',
  },
  {
    label: 'DW-2226MT',
    family: 'DW - Dry Wood',
    value: 'DW-2226MT',
  },
  {
    label: 'DW-1889MT',
    family: 'DW - Dry Wood',
    value: 'DW-1889MT',
  },
  {
    label: 'DW-2209MT',
    family: 'DW - Dry Wood',
    value: 'DW-2209MT',
  },
  {
    label: 'DW-1891MT',
    family: 'DW - Dry Wood',
    value: 'DW-1891MT',
  },
  {
    label: 'DW-2219MT',
    family: 'DW - Dry Wood',
    value: 'DW-2219MT',
  },
  {
    label: 'DW-1890MT',
    family: 'DW - Dry Wood',
    value: 'DW-1890MT',
  },
  {
    label: 'DW-2197MT',
    family: 'DW - Dry Wood',
    value: 'DW-2197MT',
  },
  {
    label: 'DW-2205MT',
    family: 'DW - Dry Wood',
    value: 'DW-2205MT',
  },
  {
    label: 'DW-1993MT',
    family: 'DW - Dry Wood',
    value: 'DW-1993MT',
  },
  {
    label: 'DW-2208MT',
    family: 'DW - Dry Wood',
    value: 'DW-2208MT',
  },
  {
    label: 'DW-1887MT',
    family: 'DW - Dry Wood',
    value: 'DW-1887MT',
  },
  {
    label: 'DW-2225MT',
    family: 'DW - Dry Wood',
    value: 'DW-2225MT',
  },
  {
    label: 'DW-1882MT',
    family: 'DW - Dry Wood',
    value: 'DW-1882MT',
  },
  {
    label: 'DW-2221MT',
    family: 'DW - Dry Wood',
    value: 'DW-2221MT',
  },
  {
    label: 'DW-2213MT',
    family: 'DW - Dry Wood',
    value: 'DW-2213MT',
  },
  {
    label: 'DW-1895MT',
    family: 'DW - Dry Wood',
    value: 'DW-1895MT',
  },
  {
    label: 'DW-1901MT',
    family: 'DW - Dry Wood',
    value: 'DW-1901MT',
  },
  {
    label: 'DW-2199MT',
    family: 'DW - Dry Wood',
    value: 'DW-2199MT',
  },
  {
    label: 'DW-1886MT',
    family: 'DW - Dry Wood',
    value: 'DW-1886MT',
  },
  {
    label: 'DW-1874MT',
    family: 'DW - Dry Wood',
    value: 'DW-1874MT',
  },
  {
    label: 'DW-2223MT',
    family: 'DW - Dry Wood',
    value: 'DW-2223MT',
  },
  {
    label: 'DW-2229MT',
    family: 'DW - Dry Wood',
    value: 'DW-2229MT',
  },
  {
    label: 'DW-1894MT',
    family: 'DW - Dry Wood',
    value: 'DW-1894MT',
  },
  {
    label: 'DW-2200MT',
    family: 'DW - Dry Wood',
    value: 'DW-2200MT',
  },
  {
    label: 'DW-2212MT',
    family: 'DW - Dry Wood',
    value: 'DW-2212MT',
  },
  {
    label: 'DW-1892MT',
    family: 'DW - Dry Wood',
    value: 'DW-1892MT',
  },
  {
    label: 'DW-2220MT',
    family: 'DW - Dry Wood',
    value: 'DW-2220MT',
  },
  {
    label: 'DW-1897MT',
    family: 'DW - Dry Wood',
    value: 'DW-1897MT',
  },
  {
    label: 'DW-2201MT',
    family: 'DW - Dry Wood',
    value: 'DW-2201MT',
  },
  {
    label: 'FE-1731',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1731',
  },
  {
    label: 'FE-1964',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1964',
  },
  {
    label: 'FE805',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE805',
  },
  {
    label: 'FE813',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE813',
  },
  {
    label: 'FE-1729',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1729',
  },
  {
    label: 'FE-1966',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1966',
  },
  {
    label: 'FE-1730',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1730',
  },
  {
    label: 'FE-1967',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE-1967',
  },
  {
    label: 'FE804',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE804',
  },
  {
    label: 'FE801',
    family: 'FE - Metal Leaf _ Textile',
    value: 'FE801',
  },
  {
    label: 'LZ-461',
    family: 'LZ - Abstract',
    value: 'LZ-461',
  },
  {
    label: 'LZ-462',
    family: 'LZ - Abstract',
    value: 'LZ-462',
  },
  {
    label: 'LZ-587',
    family: 'LZ - Abstract',
    value: 'LZ-587',
  },
  {
    label: 'CN-1958',
    family: 'CN - Concrete _ Mortar',
    value: 'CN-1958',
  },
  {
    label: 'CN-1621',
    family: 'CN - Concrete _ Mortar',
    value: 'CN-1621',
  },
  {
    label: 'CN-1622',
    family: 'CN - Concrete _ Mortar',
    value: 'CN-1622',
  },
  {
    label: 'CN-1623',
    family: 'CN - Concrete _ Mortar',
    value: 'CN-1623',
  },
  {
    label: 'AE-1954',
    family: 'AE - Earth and Stone',
    value: 'AE-1954',
  },
  {
    label: 'AE-1952',
    family: 'AE - Earth and Stone',
    value: 'AE-1952',
  },
  {
    label: 'AE-1632AR_LA',
    family: 'AE - Earth and Stone',
    value: 'AE-1632AR_LA',
  },
  {
    label: 'AE-1917MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1917MT',
  },
  {
    label: 'AE-1959MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1959MT',
  },
  {
    label: 'AE-2167MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2167MT',
  },
  {
    label: 'AE-1637',
    family: 'AE - Earth and Stone',
    value: 'AE-1637',
  },
  {
    label: 'AE-2150',
    family: 'AE - Earth and Stone',
    value: 'AE-2150',
  },
  {
    label: 'AE-1717',
    family: 'AE - Earth and Stone',
    value: 'AE-1717',
  },
  {
    label: 'AE-1960MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1960MT',
  },
  {
    label: 'AE-1639',
    family: 'AE - Earth and Stone',
    value: 'AE-1639',
  },
  {
    label: 'AE-2160',
    family: 'AE - Earth and Stone',
    value: 'AE-2160',
  },
  {
    label: 'AE-1921MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1921MT',
  },
  {
    label: 'AE-1645',
    family: 'AE - Earth and Stone',
    value: 'AE-1645',
  },
  {
    label: 'AE-1953',
    family: 'AE - Earth and Stone',
    value: 'AE-1953',
  },
  {
    label: 'AE-1956',
    family: 'AE - Earth and Stone',
    value: 'AE-1956',
  },
  {
    label: 'AE-1957',
    family: 'AE - Earth and Stone',
    value: 'AE-1957',
  },
  {
    label: 'AE-1636AR_LA',
    family: 'AE - Earth and Stone',
    value: 'AE-1636AR_LA',
  },
  {
    label: 'AE-1641',
    family: 'AE - Earth and Stone',
    value: 'AE-1641',
  },
  {
    label: 'AE-1644',
    family: 'AE - Earth and Stone',
    value: 'AE-1644',
  },
  {
    label: 'AE-2157',
    family: 'AE - Earth and Stone',
    value: 'AE-2157',
  },
  {
    label: 'AE-1719',
    family: 'AE - Earth and Stone',
    value: 'AE-1719',
  },
  {
    label: 'AE-1928MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1928MT',
  },
  {
    label: 'AE-2165MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2165MT',
  },
  {
    label: 'AE-1932MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1932MT',
  },
  {
    label: 'AE-2162MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2162MT',
  },
  {
    label: 'AE-2168',
    family: 'AE - Earth and Stone',
    value: 'AE-2168',
  },
  {
    label: 'AE-2163MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2163MT',
  },
  {
    label: 'AE-1880MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1880MT',
  },
  {
    label: 'AE-2149',
    family: 'AE - Earth and Stone',
    value: 'AE-2149',
  },
  {
    label: 'AE-1636AR',
    family: 'AE - Earth and Stone',
    value: 'AE-1636AR',
  },
  {
    label: 'AE-1642',
    family: 'AE - Earth and Stone',
    value: 'AE-1642',
  },
  {
    label: 'AE-2155',
    family: 'AE - Earth and Stone',
    value: 'AE-2155',
  },
  {
    label: 'AE-1640',
    family: 'AE - Earth and Stone',
    value: 'AE-1640',
  },
  {
    label: 'AE-1930MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1930MT',
  },
  {
    label: 'AE-2164MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2164MT',
  },
  {
    label: 'AE-2161',
    family: 'AE - Earth and Stone',
    value: 'AE-2161',
  },
  {
    label: 'AE-1636',
    family: 'AE - Earth and Stone',
    value: 'AE-1636',
  },
  {
    label: 'AE-2159',
    family: 'AE - Earth and Stone',
    value: 'AE-2159',
  },
  {
    label: 'AE-1926MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1926MT',
  },
  {
    label: 'AE-1929MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1929MT',
  },
  {
    label: 'AE-1944MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1944MT',
  },
  {
    label: 'AE-1951',
    family: 'AE - Earth and Stone',
    value: 'AE-1951',
  },
  {
    label: 'AE-1638',
    family: 'AE - Earth and Stone',
    value: 'AE-1638',
  },
  {
    label: 'AE-1913MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1913MT',
  },
  {
    label: 'AE-1933MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1933MT',
  },
  {
    label: 'AE-1955',
    family: 'AE - Earth and Stone',
    value: 'AE-1955',
  },
  {
    label: 'AE-1635EX',
    family: 'AE - Earth and Stone',
    value: 'AE-1635EX',
  },
  {
    label: 'AE-2156',
    family: 'AE - Earth and Stone',
    value: 'AE-2156',
  },
  {
    label: 'AE-2152MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2152MT',
  },
  {
    label: 'AE-2153MT',
    family: 'AE - Earth and Stone',
    value: 'AE-2153MT',
  },
  {
    label: 'AE-2154',
    family: 'AE - Earth and Stone',
    value: 'AE-2154',
  },
  {
    label: 'AE-1643',
    family: 'AE - Earth and Stone',
    value: 'AE-1643',
  },
  {
    label: 'AE-2158',
    family: 'AE - Earth and Stone',
    value: 'AE-2158',
  },
  {
    label: 'AE-1646',
    family: 'AE - Earth and Stone',
    value: 'AE-1646',
  },
  {
    label: 'AE-1931MT',
    family: 'AE - Earth and Stone',
    value: 'AE-1931MT',
  },
  {
    label: 'AE-1718',
    family: 'AE - Earth and Stone',
    value: 'AE-1718',
  },
  {
    label: 'AE-2151',
    family: 'AE - Earth and Stone',
    value: 'AE-2151',
  },
  {
    label: 'SE567',
    family: 'SE - Abstract',
    value: 'SE567',
  },
  {
    label: 'SE-568',
    family: 'SE - Abstract',
    value: 'SE-568',
  },
  {
    label: 'SE-570',
    family: 'SE - Abstract',
    value: 'SE-570',
  },
  {
    label: 'ET-1776',
    family: 'ET - Effect',
    value: 'ET-1776',
  },
  {
    label: 'ET-1772',
    family: 'ET - Effect',
    value: 'ET-1772',
  },
  {
    label: 'ET-1773',
    family: 'ET - Effect',
    value: 'ET-1773',
  },
  {
    label: 'LW-1083',
    family: 'LW - Entertainment',
    value: 'LW-1083',
  },
  {
    label: 'LW-1081',
    family: 'LW - Entertainment',
    value: 'LW-1081',
  },
  {
    label: 'LW-1084',
    family: 'LW - Entertainment',
    value: 'LW-1084',
  },
  {
    label: 'LW-1085',
    family: 'LW - Entertainment',
    value: 'LW-1085',
  },
  {
    label: 'LE-1228',
    family: 'LE - Leather',
    value: 'LE-1228',
  },
  {
    label: 'LE-1552',
    family: 'LE - Leather',
    value: 'LE-1552',
  },
  {
    label: 'LE-783',
    family: 'LE - Leather',
    value: 'LE-783',
  },
  {
    label: 'LE-1104',
    family: 'LE - Leather',
    value: 'LE-1104',
  },
  {
    label: 'LE-2782',
    family: 'LE - Leather',
    value: 'LE-2782',
  },
  {
    label: 'LE-1109',
    family: 'LE - Leather',
    value: 'LE-1109',
  },
  {
    label: 'LE-1555',
    family: 'LE - Leather',
    value: 'LE-1555',
  },
  {
    label: 'LE-1231',
    family: 'LE - Leather',
    value: 'LE-1231',
  },
  {
    label: 'LE-1171',
    family: 'LE - Leather',
    value: 'LE-1171',
  },
  {
    label: 'LE-018',
    family: 'LE - Leather',
    value: 'LE-018',
  },
  {
    label: 'LE-517',
    family: 'LE - Leather',
    value: 'LE-517',
  },
  {
    label: 'LE-703',
    family: 'LE - Leather',
    value: 'LE-703',
  },
  {
    label: 'LE-1105',
    family: 'LE - Leather',
    value: 'LE-1105',
  },
  {
    label: 'LE-1229',
    family: 'LE - Leather',
    value: 'LE-1229',
  },
  {
    label: 'LE-2128',
    family: 'LE - Leather',
    value: 'LE-2128',
  },
  {
    label: 'LE-1108',
    family: 'LE - Leather',
    value: 'LE-1108',
  },
  {
    label: 'LE-2741',
    family: 'LE - Leather',
    value: 'LE-2741',
  },
  {
    label: 'LE-2367',
    family: 'LE - Leather',
    value: 'LE-2367',
  },
  {
    label: 'LE-1106',
    family: 'LE - Leather',
    value: 'LE-1106',
  },
  {
    label: 'LE-1551',
    family: 'LE - Leather',
    value: 'LE-1551',
  },
  {
    label: 'FW-887EX',
    family: 'EX - Exterior',
    value: 'FW-887EX',
  },
  {
    label: 'AE-1632AR_LA',
    family: 'EX - Exterior',
    value: 'AE-1632AR_LA',
  },
  {
    label: 'FW-7008EX',
    family: 'EX - Exterior',
    value: 'FW-7008EX',
  },
  {
    label: 'WG-695EX',
    family: 'EX - Exterior',
    value: 'WG-695EX',
  },
  {
    label: 'ME-003EX',
    family: 'EX - Exterior',
    value: 'ME-003EX',
  },
  {
    label: 'WG-1140EX',
    family: 'EX - Exterior',
    value: 'WG-1140EX',
  },
  {
    label: 'PA-1854EX',
    family: 'EX - Exterior',
    value: 'PA-1854EX',
  },
  {
    label: 'FW-236EX',
    family: 'EX - Exterior',
    value: 'FW-236EX',
  },
  {
    label: 'WG-960EX',
    family: 'EX - Exterior',
    value: 'WG-960EX',
  },
  {
    label: 'FW-7011EX',
    family: 'EX - Exterior',
    value: 'FW-7011EX',
  },
  {
    label: 'PA-389EX',
    family: 'EX - Exterior',
    value: 'PA-389EX',
  },
  {
    label: 'WG-1144EX',
    family: 'EX - Exterior',
    value: 'WG-1144EX',
  },
  {
    label: 'PS-090EX',
    family: 'EX - Exterior',
    value: 'PS-090EX',
  },
  {
    label: 'FW-522EX',
    family: 'EX - Exterior',
    value: 'FW-522EX',
  },
  {
    label: 'FW-643EX',
    family: 'EX - Exterior',
    value: 'FW-643EX',
  },
  {
    label: 'FW-1124EX',
    family: 'EX - Exterior',
    value: 'FW-1124EX',
  },
  {
    label: 'FW-640EX',
    family: 'EX - Exterior',
    value: 'FW-640EX',
  },
  {
    label: 'AE-1636AR_LA',
    family: 'EX - Exterior',
    value: 'AE-1636AR_LA',
  },
  {
    label: 'FW-1801EX',
    family: 'EX - Exterior',
    value: 'FW-1801EX',
  },
  {
    label: 'PA-181EX',
    family: 'EX - Exterior',
    value: 'PA-181EX',
  },
  {
    label: 'ME-001EX',
    family: 'EX - Exterior',
    value: 'ME-001EX',
  },
  {
    label: 'ME-007EX',
    family: 'EX - Exterior',
    value: 'ME-007EX',
  },
  {
    label: 'WG-1143EX',
    family: 'EX - Exterior',
    value: 'WG-1143EX',
  },
  {
    label: 'PA-187EX',
    family: 'EX - Exterior',
    value: 'PA-187EX',
  },
  {
    label: 'FW-1122EX',
    family: 'EX - Exterior',
    value: 'FW-1122EX',
  },
  {
    label: 'ME-002EX',
    family: 'EX - Exterior',
    value: 'ME-002EX',
  },
  {
    label: 'FW-233EX',
    family: 'EX - Exterior',
    value: 'FW-233EX',
  },
  {
    label: 'PA-390EX',
    family: 'EX - Exterior',
    value: 'PA-390EX',
  },
  {
    label: 'FW-651EX',
    family: 'EX - Exterior',
    value: 'FW-651EX',
  },
  {
    label: 'ME-004EX',
    family: 'EX - Exterior',
    value: 'ME-004EX',
  },
  {
    label: 'FW-1805EX',
    family: 'EX - Exterior',
    value: 'FW-1805EX',
  },
  {
    label: 'FW-618EX',
    family: 'EX - Exterior',
    value: 'FW-618EX',
  },
  {
    label: 'FW-1214EX',
    family: 'EX - Exterior',
    value: 'FW-1214EX',
  },
  {
    label: 'PS-976EX',
    family: 'EX - Exterior',
    value: 'PS-976EX',
  },
  {
    label: 'PA-039EX',
    family: 'EX - Exterior',
    value: 'PA-039EX',
  },
  {
    label: 'FW-625EX',
    family: 'EX - Exterior',
    value: 'FW-625EX',
  },
  {
    label: 'FW-1022AR_LA',
    family: 'EX - Exterior',
    value: 'FW-1022AR_LA',
  },
  {
    label: 'FW-650EX',
    family: 'EX - Exterior',
    value: 'FW-650EX',
  },
  {
    label: 'FW-1129EX',
    family: 'EX - Exterior',
    value: 'FW-1129EX',
  },
  {
    label: 'FW-627EX',
    family: 'EX - Exterior',
    value: 'FW-627EX',
  },
  {
    label: 'FW-324EX',
    family: 'EX - Exterior',
    value: 'FW-324EX',
  },
  {
    label: 'PS-959EX',
    family: 'EX - Exterior',
    value: 'PS-959EX',
  },
  {
    label: 'FW-1023EX',
    family: 'EX - Exterior',
    value: 'FW-1023EX',
  },
  {
    label: 'ST-442EX',
    family: 'EX - Exterior',
    value: 'ST-442EX',
  },
  {
    label: 'FW-330EX',
    family: 'EX - Exterior',
    value: 'FW-330EX',
  },
  {
    label: 'AE-1635EX',
    family: 'EX - Exterior',
    value: 'AE-1635EX',
  },
  {
    label: 'FW-7006EX',
    family: 'EX - Exterior',
    value: 'FW-7006EX',
  },
  {
    label: 'ST-736EX',
    family: 'EX - Exterior',
    value: 'ST-736EX',
  },
  {
    label: 'WG-657EX',
    family: 'EX - Exterior',
    value: 'WG-657EX',
  },
  {
    label: 'FW-609HEX',
    family: 'EX - Exterior',
    value: 'FW-609HEX',
  },
  {
    label: 'WG-7024EX',
    family: 'EX - Exterior',
    value: 'WG-7024EX',
  },
  {
    label: 'AE-1634EX',
    family: 'EX - Exterior',
    value: 'AE-1634EX',
  },
  {
    label: 'PS-957EX',
    family: 'EX - Exterior',
    value: 'PS-957EX',
  },
  {
    label: 'FW-1113EX',
    family: 'EX - Exterior',
    value: 'FW-1113EX',
  },
  {
    label: 'LE-703EX',
    family: 'EX - Exterior',
    value: 'LE-703EX',
  },
  {
    label: 'HS-1656',
    family: 'HS - Mono Contrast',
    value: 'HS-1656',
  },
  {
    label: 'HS-1655',
    family: 'HS - Mono Contrast',
    value: 'HS-1655',
  },
  {
    label: 'WG-7024AR',
    family: 'AR - Abrasion Resistant',
    value: 'WG-7024AR',
  },
  {
    label: 'FW-7017AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-7017AR',
  },
  {
    label: 'ME-1467AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-1467AR',
  },
  {
    label: 'WG-1372AR',
    family: 'AR - Abrasion Resistant',
    value: 'WG-1372AR',
  },
  {
    label: 'PA-187AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-187AR',
  },
  {
    label: 'ST-736AR',
    family: 'AR - Abrasion Resistant',
    value: 'ST-736AR',
  },
  {
    label: 'PS-007AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-007AR',
  },
  {
    label: 'ME-1466AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-1466AR',
  },
  {
    label: 'FW-1121HAR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1121HAR',
  },
  {
    label: 'PA-181AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-181AR',
  },
  {
    label: 'PS-004AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-004AR',
  },
  {
    label: 'AE-1633AR',
    family: 'AR - Abrasion Resistant',
    value: 'AE-1633AR',
  },
  {
    label: 'PS-008AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-008AR',
  },
  {
    label: 'FW-330AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-330AR',
  },
  {
    label: 'FW-627AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-627AR',
  },
  {
    label: 'PA-683AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-683AR',
  },
  {
    label: 'SE-567AR',
    family: 'AR - Abrasion Resistant',
    value: 'SE-567AR',
  },
  {
    label: 'PA-389AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-389AR',
  },
  {
    label: 'FA-1527AR_LA',
    family: 'AR - Abrasion Resistant',
    value: 'FA-1527AR_LA',
  },
  {
    label: 'FW-1280AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1280AR',
  },
  {
    label: 'PS-006AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-006AR',
  },
  {
    label: 'ME-396AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-396AR',
  },
  {
    label: 'ME-1684AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-1684AR',
  },
  {
    label: 'SE-568AR',
    family: 'AR - Abrasion Resistant',
    value: 'SE-568AR',
  },
  {
    label: 'AE-1632AR',
    family: 'AR - Abrasion Resistant',
    value: 'AE-1632AR',
  },
  {
    label: 'FW-1122AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1122AR',
  },
  {
    label: 'FW-625AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-625AR',
  },
  {
    label: 'FA-1526AR',
    family: 'AR - Abrasion Resistant',
    value: 'FA-1526AR',
  },
  {
    label: 'PA-183AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-183AR',
  },
  {
    label: 'PA-045AR',
    family: 'AR - Abrasion Resistant',
    value: 'PA-045AR',
  },
  {
    label: 'ME-432AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-432AR',
  },
  {
    label: 'FW-1294AR_LA',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1294AR_LA',
  },
  {
    label: 'ME-431AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-431AR',
  },
  {
    label: 'FA-1678AR',
    family: 'AR - Abrasion Resistant',
    value: 'FA-1678AR',
  },
  {
    label: 'FW-1280AR_LA',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1280AR_LA',
  },
  {
    label: 'PT-345AR',
    family: 'AR - Abrasion Resistant',
    value: 'PT-345AR',
  },
  {
    label: 'PS-005AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-005AR',
  },
  {
    label: 'PS-003AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-003AR',
  },
  {
    label: 'AE-1636AR',
    family: 'AR - Abrasion Resistant',
    value: 'AE-1636AR',
  },
  {
    label: 'ME-1685AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-1685AR',
  },
  {
    label: 'FW-1129AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1129AR',
  },
  {
    label: 'FW-338AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-338AR',
  },
  {
    label: 'CH-1628AR',
    family: 'AR - Abrasion Resistant',
    value: 'CH-1628AR',
  },
  {
    label: 'ME-433AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-433AR',
  },
  {
    label: 'FW-236AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-236AR',
  },
  {
    label: 'FW-1293AR_LA',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1293AR_LA',
  },
  {
    label: 'FA-1530AR',
    family: 'AR - Abrasion Resistant',
    value: 'FA-1530AR',
  },
  {
    label: 'FW-1130HAR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1130HAR',
  },
  {
    label: 'ST-011AR',
    family: 'AR - Abrasion Resistant',
    value: 'ST-011AR',
  },
  {
    label: 'ME-1781AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-1781AR',
  },
  {
    label: 'ME-904AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-904AR',
  },
  {
    label: 'CH-1631AR',
    family: 'AR - Abrasion Resistant',
    value: 'CH-1631AR',
  },
  {
    label: 'ME-009AR',
    family: 'AR - Abrasion Resistant',
    value: 'ME-009AR',
  },
  {
    label: 'FW-233AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-233AR',
  },
  {
    label: 'FW-1293AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1293AR',
  },
  {
    label: 'CH-1630AR',
    family: 'AR - Abrasion Resistant',
    value: 'CH-1630AR',
  },
  {
    label: 'PS-001AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-001AR',
  },
  {
    label: 'SE-010AR',
    family: 'AR - Abrasion Resistant',
    value: 'SE-010AR',
  },
  {
    label: 'FW-1022AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1022AR',
  },
  {
    label: 'FW-337AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-337AR',
  },
  {
    label: 'FW-1138AR',
    family: 'AR - Abrasion Resistant',
    value: 'FW-1138AR',
  },
  {
    label: 'CH-1629AR',
    family: 'AR - Abrasion Resistant',
    value: 'CH-1629AR',
  },
  {
    label: 'PS-002AR',
    family: 'AR - Abrasion Resistant',
    value: 'PS-002AR',
  },
  {
    label: 'CA_422',
    family: 'CA - Carbon',
    value: 'CA_422',
  },
  {
    label: 'CA_420',
    family: 'CA - Carbon',
    value: 'CA_420',
  },
  {
    label: 'CA_421',
    family: 'CA - Carbon',
    value: 'CA_421',
  },
  {
    label: 'CA_1170',
    family: 'CA - Carbon',
    value: 'CA_1170',
  },
  {
    label: 'CA_419',
    family: 'CA - Carbon',
    value: 'CA_419',
  },
  {
    label: 'CA_418',
    family: 'CA - Carbon',
    value: 'CA_418',
  },
  {
    label: 'SU-2234MT',
    family: 'SU - Suede',
    value: 'SU-2234MT',
  },
  {
    label: 'SU-2232MT',
    family: 'SU - Suede',
    value: 'SU-2232MT',
  },
  {
    label: 'SU-2233MT',
    family: 'SU - Suede',
    value: 'SU-2233MT',
  },
  {
    label: 'SU-2231MT',
    family: 'SU - Suede',
    value: 'SU-2231MT',
  },
  {
    label: 'SU-2235MT',
    family: 'SU - Suede',
    value: 'SU-2235MT',
  },
  {
    label: 'NU1241',
    family: 'NU - Nuno Textile',
    value: 'NU1241',
  },
  {
    label: 'NU-1934MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1934MT',
  },
  {
    label: 'NU-2003',
    family: 'NU - Nuno Textile',
    value: 'NU-2003',
  },
  {
    label: 'NU1788',
    family: 'NU - Nuno Textile',
    value: 'NU1788',
  },
  {
    label: 'NU-1784',
    family: 'NU - Nuno Textile',
    value: 'NU-1784',
  },
  {
    label: 'NU1793',
    family: 'NU - Nuno Textile',
    value: 'NU1793',
  },
  {
    label: 'NU-1939MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1939MT',
  },
  {
    label: 'NU1790',
    family: 'NU - Nuno Textile',
    value: 'NU1790',
  },
  {
    label: 'NU-2008',
    family: 'NU - Nuno Textile',
    value: 'NU-2008',
  },
  {
    label: 'NU1799',
    family: 'NU - Nuno Textile',
    value: 'NU1799',
  },
  {
    label: 'NU-1938MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1938MT',
  },
  {
    label: 'NU-1785',
    family: 'NU - Nuno Textile',
    value: 'NU-1785',
  },
  {
    label: 'NU-2011',
    family: 'NU - Nuno Textile',
    value: 'NU-2011',
  },
  {
    label: 'NU-1937MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1937MT',
  },
  {
    label: 'NU-2004',
    family: 'NU - Nuno Textile',
    value: 'NU-2004',
  },
  {
    label: 'NU-2006',
    family: 'NU - Nuno Textile',
    value: 'NU-2006',
  },
  {
    label: 'NU-2005',
    family: 'NU - Nuno Textile',
    value: 'NU-2005',
  },
  {
    label: 'NU1791',
    family: 'NU - Nuno Textile',
    value: 'NU1791',
  },
  {
    label: 'NU-1943MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1943MT',
  },
  {
    label: 'NU-1940MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1940MT',
  },
  {
    label: 'NU1787',
    family: 'NU - Nuno Textile',
    value: 'NU1787',
  },
  {
    label: 'NU-2176MT',
    family: 'NU - Nuno Textile',
    value: 'NU-2176MT',
  },
  {
    label: 'NU-2177',
    family: 'NU - Nuno Textile',
    value: 'NU-2177',
  },
  {
    label: 'NU-2178',
    family: 'NU - Nuno Textile',
    value: 'NU-2178',
  },
  {
    label: 'NU-2179',
    family: 'NU - Nuno Textile',
    value: 'NU-2179',
  },
  {
    label: 'NU-2012',
    family: 'NU - Nuno Textile',
    value: 'NU-2012',
  },
  {
    label: 'NU-2013',
    family: 'NU - Nuno Textile',
    value: 'NU-2013',
  },
  {
    label: 'NU-1936MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1936MT',
  },
  {
    label: 'NU1795',
    family: 'NU - Nuno Textile',
    value: 'NU1795',
  },
  {
    label: 'NU-1786',
    family: 'NU - Nuno Textile',
    value: 'NU-1786',
  },
  {
    label: 'NU-2009',
    family: 'NU - Nuno Textile',
    value: 'NU-2009',
  },
  {
    label: 'NU-2017',
    family: 'NU - Nuno Textile',
    value: 'NU-2017',
  },
  {
    label: 'NU-2001',
    family: 'NU - Nuno Textile',
    value: 'NU-2001',
  },
  {
    label: 'NU-1941MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1941MT',
  },
  {
    label: 'NU-1942MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1942MT',
  },
  {
    label: 'NU-2175MT',
    family: 'NU - Nuno Textile',
    value: 'NU-2175MT',
  },
  {
    label: 'NU1239',
    family: 'NU - Nuno Textile',
    value: 'NU1239',
  },
  {
    label: 'NU-2007',
    family: 'NU - Nuno Textile',
    value: 'NU-2007',
  },
  {
    label: 'NU1792',
    family: 'NU - Nuno Textile',
    value: 'NU1792',
  },
  {
    label: 'NU1798',
    family: 'NU - Nuno Textile',
    value: 'NU1798',
  },
  {
    label: 'NU1237',
    family: 'NU - Nuno Textile',
    value: 'NU1237',
  },
  {
    label: 'NU1789',
    family: 'NU - Nuno Textile',
    value: 'NU1789',
  },
  {
    label: 'NU-1935MT',
    family: 'NU - Nuno Textile',
    value: 'NU-1935MT',
  },
  {
    label: 'NU1794',
    family: 'NU - Nuno Textile',
    value: 'NU1794',
  },
  {
    label: 'NU1238',
    family: 'NU - Nuno Textile',
    value: 'NU1238',
  },
  {
    label: 'NU-2002',
    family: 'NU - Nuno Textile',
    value: 'NU-2002',
  },
  {
    label: 'NU-2010',
    family: 'NU - Nuno Textile',
    value: 'NU-2010',
  },
  {
    label: 'NU1240',
    family: 'NU - Nuno Textile',
    value: 'NU1240',
  },
  {
    label: 'NU-2015',
    family: 'NU - Nuno Textile',
    value: 'NU-2015',
  },
  {
    label: 'NU-2014',
    family: 'NU - Nuno Textile',
    value: 'NU-2014',
  },
  {
    label: 'PG-189',
    family: 'PG - Abstract',
    value: 'PG-189',
  },
  {
    label: 'PG-194',
    family: 'PG - Abstract',
    value: 'PG-194',
  },
  {
    label: 'PG-195',
    family: 'PG - Abstract',
    value: 'PG-195',
  },
  {
    label: 'PG-197',
    family: 'PG - Abstract',
    value: 'PG-197',
  },
  {
    label: 'PG-193',
    family: 'PG - Abstract',
    value: 'PG-193',
  },
  {
    label: 'PG-190',
    family: 'PG - Abstract',
    value: 'PG-190',
  },
  {
    label: 'RT_1112',
    family: 'RT - Aged Metal',
    value: 'RT_1112',
  },
  {
    label: 'RT_1111',
    family: 'RT - Aged Metal',
    value: 'RT_1111',
  },
  {
    label: 'RT-1827',
    family: 'RT - Aged Metal',
    value: 'RT-1827',
  },
  {
    label: 'TE-1714',
    family: 'TE - Advanced Metallic',
    value: 'TE-1714',
  },
  {
    label: 'TE-1713',
    family: 'TE - Advanced Metallic',
    value: 'TE-1713',
  },
  {
    label: 'FW-1213',
    family: 'FW - Fine Wood',
    value: 'FW-1213',
  },
  {
    label: 'FW-609H',
    family: 'FW - Fine Wood',
    value: 'FW-609H',
  },
  {
    label: 'FW_337',
    family: 'FW - Fine Wood',
    value: 'FW_337',
  },
  {
    label: 'FW_336',
    family: 'FW - Fine Wood',
    value: 'FW_336',
  },
  {
    label: 'FW-1740',
    family: 'FW - Fine Wood',
    value: 'FW-1740',
  },
  {
    label: 'FW-522',
    family: 'FW - Fine Wood',
    value: 'FW-522',
  },
  {
    label: 'FW_789',
    family: 'FW - Fine Wood',
    value: 'FW_789',
  },
  {
    label: 'FW-1214',
    family: 'FW - Fine Wood',
    value: 'FW-1214',
  },
  {
    label: 'FW-1266',
    family: 'FW - Fine Wood',
    value: 'FW-1266',
  },
  {
    label: 'FW-1738',
    family: 'FW - Fine Wood',
    value: 'FW-1738',
  },
  {
    label: 'FW_640',
    family: 'FW - Fine Wood',
    value: 'FW_640',
  },
  {
    label: 'FW-1985',
    family: 'FW - Fine Wood',
    value: 'FW-1985',
  },
  {
    label: 'FW-1751',
    family: 'FW - Fine Wood',
    value: 'FW-1751',
  },
  {
    label: 'FW-1971',
    family: 'FW - Fine Wood',
    value: 'FW-1971',
  },
  {
    label: 'FW-1306',
    family: 'FW - Fine Wood',
    value: 'FW-1306',
  },
  {
    label: 'FW-1746',
    family: 'FW - Fine Wood',
    value: 'FW-1746',
  },
  {
    label: 'FW-607H',
    family: 'FW - Fine Wood',
    value: 'FW-607H',
  },
  {
    label: 'FW_887',
    family: 'FW - Fine Wood',
    value: 'FW_887',
  },
  {
    label: 'FW-1278',
    family: 'FW - Fine Wood',
    value: 'FW-1278',
  },
  {
    label: 'FW_641',
    family: 'FW - Fine Wood',
    value: 'FW_641',
  },
  {
    label: 'FW-1683',
    family: 'FW - Fine Wood',
    value: 'FW-1683',
  },
  {
    label: 'FW-1286',
    family: 'FW - Fine Wood',
    value: 'FW-1286',
  },
  {
    label: 'FW-606H',
    family: 'FW - Fine Wood',
    value: 'FW-606H',
  },
  {
    label: 'FW_1801',
    family: 'FW - Fine Wood',
    value: 'FW_1801',
  },
  {
    label: 'FW-1771H',
    family: 'FW - Fine Wood',
    value: 'FW-1771H',
  },
  {
    label: 'FW_655',
    family: 'FW - Fine Wood',
    value: 'FW_655',
  },
  {
    label: 'FW-1762',
    family: 'FW - Fine Wood',
    value: 'FW-1762',
  },
  {
    label: 'FW-1288',
    family: 'FW - Fine Wood',
    value: 'FW-1288',
  },
  {
    label: 'FW_7011',
    family: 'FW - Fine Wood',
    value: 'FW_7011',
  },
  {
    label: 'FW_627',
    family: 'FW - Fine Wood',
    value: 'FW_627',
  },
  {
    label: 'FW-1212',
    family: 'FW - Fine Wood',
    value: 'FW-1212',
  },
  {
    label: 'FW_327',
    family: 'FW - Fine Wood',
    value: 'FW_327',
  },
  {
    label: 'FW_1137',
    family: 'FW - Fine Wood',
    value: 'FW_1137',
  },
  {
    label: 'FW_1123',
    family: 'FW - Fine Wood',
    value: 'FW_1123',
  },
  {
    label: 'FW_650',
    family: 'FW - Fine Wood',
    value: 'FW_650',
  },
  {
    label: 'FW-1257',
    family: 'FW - Fine Wood',
    value: 'FW-1257',
  },
  {
    label: 'FW_1126',
    family: 'FW - Fine Wood',
    value: 'FW_1126',
  },
  {
    label: 'FW_1036',
    family: 'FW - Fine Wood',
    value: 'FW_1036',
  },
  {
    label: 'FW-1297',
    family: 'FW - Fine Wood',
    value: 'FW-1297',
  },
  {
    label: 'FW_651',
    family: 'FW - Fine Wood',
    value: 'FW_651',
  },
  {
    label: 'FW_1810',
    family: 'FW - Fine Wood',
    value: 'FW_1810',
  },
  {
    label: 'FW_1124',
    family: 'FW - Fine Wood',
    value: 'FW_1124',
  },
  {
    label: 'FW-1983',
    family: 'FW - Fine Wood',
    value: 'FW-1983',
  },
  {
    label: 'FW-1737',
    family: 'FW - Fine Wood',
    value: 'FW-1737',
  },
  {
    label: 'FW_1805',
    family: 'FW - Fine Wood',
    value: 'FW_1805',
  },
  {
    label: 'FW-1208',
    family: 'FW - Fine Wood',
    value: 'FW-1208',
  },
  {
    label: 'FW-1988',
    family: 'FW - Fine Wood',
    value: 'FW-1988',
  },
  {
    label: 'FW-1745',
    family: 'FW - Fine Wood',
    value: 'FW-1745',
  },
  {
    label: 'FW-1291',
    family: 'FW - Fine Wood',
    value: 'FW-1291',
  },
  {
    label: 'FW_618',
    family: 'FW - Fine Wood',
    value: 'FW_618',
  },
  {
    label: 'FW_656',
    family: 'FW - Fine Wood',
    value: 'FW_656',
  },
  {
    label: 'FW_1135',
    family: 'FW - Fine Wood',
    value: 'FW_1135',
  },
  {
    label: 'FW_1040H',
    family: 'FW - Fine Wood',
    value: 'FW_1040H',
  },
  {
    label: 'FW_1125',
    family: 'FW - Fine Wood',
    value: 'FW_1125',
  },
  {
    label: 'FW-1331',
    family: 'FW - Fine Wood',
    value: 'FW-1331',
  },
  {
    label: 'FW-510',
    family: 'FW - Fine Wood',
    value: 'FW-510',
  },
  {
    label: 'FW-1978',
    family: 'FW - Fine Wood',
    value: 'FW-1978',
  },
  {
    label: 'FW-1735H',
    family: 'FW - Fine Wood',
    value: 'FW-1735H',
  },
  {
    label: 'FW-1976',
    family: 'FW - Fine Wood',
    value: 'FW-1976',
  },
  {
    label: 'FW-608H',
    family: 'FW - Fine Wood',
    value: 'FW-608H',
  },
  {
    label: 'FW_613',
    family: 'FW - Fine Wood',
    value: 'FW_613',
  },
  {
    label: 'FW_1138',
    family: 'FW - Fine Wood',
    value: 'FW_1138',
  },
  {
    label: 'FW-1273',
    family: 'FW - Fine Wood',
    value: 'FW-1273',
  },
  {
    label: 'FW-501',
    family: 'FW - Fine Wood',
    value: 'FW-501',
  },
  {
    label: 'FW-1744',
    family: 'FW - Fine Wood',
    value: 'FW-1744',
  },
  {
    label: 'FW_1136H',
    family: 'FW - Fine Wood',
    value: 'FW_1136H',
  },
  {
    label: 'FW-1752H',
    family: 'FW - Fine Wood',
    value: 'FW-1752H',
  },
  {
    label: 'FW-1280AR',
    family: 'FW - Fine Wood',
    value: 'FW-1280AR',
  },
  {
    label: 'FW_1133',
    family: 'FW - Fine Wood',
    value: 'FW_1133',
  },
  {
    label: 'FW_1122',
    family: 'FW - Fine Wood',
    value: 'FW_1122',
  },
  {
    label: 'FW_330',
    family: 'FW - Fine Wood',
    value: 'FW_330',
  },
  {
    label: 'FW-1765',
    family: 'FW - Fine Wood',
    value: 'FW-1765',
  },
  {
    label: 'FW-1275',
    family: 'FW - Fine Wood',
    value: 'FW-1275',
  },
  {
    label: 'FW-1975',
    family: 'FW - Fine Wood',
    value: 'FW-1975',
  },
  {
    label: 'FW_338',
    family: 'FW - Fine Wood',
    value: 'FW_338',
  },
  {
    label: 'FW-1753H',
    family: 'FW - Fine Wood',
    value: 'FW-1753H',
  },
  {
    label: 'FW-1734H',
    family: 'FW - Fine Wood',
    value: 'FW-1734H',
  },
  {
    label: 'FW-1218',
    family: 'FW - Fine Wood',
    value: 'FW-1218',
  },
  {
    label: 'FW_653',
    family: 'FW - Fine Wood',
    value: 'FW_653',
  },
  {
    label: 'FW-1272',
    family: 'FW - Fine Wood',
    value: 'FW-1272',
  },
  {
    label: 'FW_1113',
    family: 'FW - Fine Wood',
    value: 'FW_1113',
  },
  {
    label: 'FW-1269',
    family: 'FW - Fine Wood',
    value: 'FW-1269',
  },
  {
    label: 'FW_237',
    family: 'FW - Fine Wood',
    value: 'FW_237',
  },
  {
    label: 'FW_7013',
    family: 'FW - Fine Wood',
    value: 'FW_7013',
  },
  {
    label: 'FW-1761',
    family: 'FW - Fine Wood',
    value: 'FW-1761',
  },
  {
    label: 'FW-1255',
    family: 'FW - Fine Wood',
    value: 'FW-1255',
  },
  {
    label: 'FW_7015',
    family: 'FW - Fine Wood',
    value: 'FW_7015',
  },
  {
    label: 'FW-1217',
    family: 'FW - Fine Wood',
    value: 'FW-1217',
  },
  {
    label: 'FW_233',
    family: 'FW - Fine Wood',
    value: 'FW_233',
  },
  {
    label: 'FW-1767',
    family: 'FW - Fine Wood',
    value: 'FW-1767',
  },
  {
    label: 'FW_332',
    family: 'FW - Fine Wood',
    value: 'FW_332',
  },
  {
    label: 'FW-1987',
    family: 'FW - Fine Wood',
    value: 'FW-1987',
  },
  {
    label: 'FW-1216',
    family: 'FW - Fine Wood',
    value: 'FW-1216',
  },
  {
    label: 'FW-1296',
    family: 'FW - Fine Wood',
    value: 'FW-1296',
  },
  {
    label: 'FW-1261',
    family: 'FW - Fine Wood',
    value: 'FW-1261',
  },
  {
    label: 'FW-1736H',
    family: 'FW - Fine Wood',
    value: 'FW-1736H',
  },
  {
    label: 'FW_625',
    family: 'FW - Fine Wood',
    value: 'FW_625',
  },
  {
    label: 'FW_234',
    family: 'FW - Fine Wood',
    value: 'FW_234',
  },
  {
    label: 'FW_1037',
    family: 'FW - Fine Wood',
    value: 'FW_1037',
  },
  {
    label: 'FW-1271',
    family: 'FW - Fine Wood',
    value: 'FW-1271',
  },
  {
    label: 'FW-1981',
    family: 'FW - Fine Wood',
    value: 'FW-1981',
  },
  {
    label: 'FW_1127',
    family: 'FW - Fine Wood',
    value: 'FW_1127',
  },
  {
    label: 'FW_643',
    family: 'FW - Fine Wood',
    value: 'FW_643',
  },
  {
    label: 'FW_324',
    family: 'FW - Fine Wood',
    value: 'FW_324',
  },
  {
    label: 'FW-1307',
    family: 'FW - Fine Wood',
    value: 'FW-1307',
  },
  {
    label: 'FW_886',
    family: 'FW - Fine Wood',
    value: 'FW_886',
  },
  {
    label: 'FW_1121H',
    family: 'FW - Fine Wood',
    value: 'FW_1121H',
  },
  {
    label: 'FW_1022',
    family: 'FW - Fine Wood',
    value: 'FW_1022',
  },
  {
    label: 'FW-1766',
    family: 'FW - Fine Wood',
    value: 'FW-1766',
  },
  {
    label: 'FW_677',
    family: 'FW - Fine Wood',
    value: 'FW_677',
  },
  {
    label: 'FW-1754',
    family: 'FW - Fine Wood',
    value: 'FW-1754',
  },
  {
    label: 'FW-1279',
    family: 'FW - Fine Wood',
    value: 'FW-1279',
  },
  {
    label: 'FW-1294AR',
    family: 'FW - Fine Wood',
    value: 'FW-1294AR',
  },
  {
    label: 'FW-1763',
    family: 'FW - Fine Wood',
    value: 'FW-1763',
  },
  {
    label: 'FW-1750',
    family: 'FW - Fine Wood',
    value: 'FW-1750',
  },
  {
    label: 'FW-1215',
    family: 'FW - Fine Wood',
    value: 'FW-1215',
  },
  {
    label: 'FW-1977',
    family: 'FW - Fine Wood',
    value: 'FW-1977',
  },
  {
    label: 'FW_1020',
    family: 'FW - Fine Wood',
    value: 'FW_1020',
  },
  {
    label: 'FW-1281',
    family: 'FW - Fine Wood',
    value: 'FW-1281',
  },
  {
    label: 'FW_889',
    family: 'FW - Fine Wood',
    value: 'FW_889',
  },
  {
    label: 'FW-1770',
    family: 'FW - Fine Wood',
    value: 'FW-1770',
  },
  {
    label: 'FW-1986',
    family: 'FW - Fine Wood',
    value: 'FW-1986',
  },
  {
    label: 'FW-1974',
    family: 'FW - Fine Wood',
    value: 'FW-1974',
  },
  {
    label: 'FW_619',
    family: 'FW - Fine Wood',
    value: 'FW_619',
  },
  {
    label: 'FW-1262',
    family: 'FW - Fine Wood',
    value: 'FW-1262',
  },
  {
    label: 'FW-1289',
    family: 'FW - Fine Wood',
    value: 'FW-1289',
  },
  {
    label: 'FW-1301',
    family: 'FW - Fine Wood',
    value: 'FW-1301',
  },
  {
    label: 'FW-1210',
    family: 'FW - Fine Wood',
    value: 'FW-1210',
  },
  {
    label: 'FW_888',
    family: 'FW - Fine Wood',
    value: 'FW_888',
  },
  {
    label: 'FW-1282',
    family: 'FW - Fine Wood',
    value: 'FW-1282',
  },
  {
    label: 'FW-1743',
    family: 'FW - Fine Wood',
    value: 'FW-1743',
  },
  {
    label: 'FW_1023',
    family: 'FW - Fine Wood',
    value: 'FW_1023',
  },
  {
    label: 'FW-1984',
    family: 'FW - Fine Wood',
    value: 'FW-1984',
  },
  {
    label: 'FW_7006',
    family: 'FW - Fine Wood',
    value: 'FW_7006',
  },
  {
    label: 'FW-1758',
    family: 'FW - Fine Wood',
    value: 'FW-1758',
  },
  {
    label: 'FW_7017',
    family: 'FW - Fine Wood',
    value: 'FW_7017',
  },
  {
    label: 'FW-1980',
    family: 'FW - Fine Wood',
    value: 'FW-1980',
  },
  {
    label: 'FW_7008',
    family: 'FW - Fine Wood',
    value: 'FW_7008',
  },
  {
    label: 'FW_240',
    family: 'FW - Fine Wood',
    value: 'FW_240',
  },
  {
    label: 'FW-1277',
    family: 'FW - Fine Wood',
    value: 'FW-1277',
  },
  {
    label: 'FW-1972',
    family: 'FW - Fine Wood',
    value: 'FW-1972',
  },
  {
    label: 'FW_7007',
    family: 'FW - Fine Wood',
    value: 'FW_7007',
  },
  {
    label: 'FW-1265',
    family: 'FW - Fine Wood',
    value: 'FW-1265',
  },
  {
    label: 'FW_1130H',
    family: 'FW - Fine Wood',
    value: 'FW_1130H',
  },
  {
    label: 'FW-1757',
    family: 'FW - Fine Wood',
    value: 'FW-1757',
  },
  {
    label: 'FW-1287',
    family: 'FW - Fine Wood',
    value: 'FW-1287',
  },
  {
    label: 'FW_1039H',
    family: 'FW - Fine Wood',
    value: 'FW_1039H',
  },
  {
    label: 'FW_612',
    family: 'FW - Fine Wood',
    value: 'FW_612',
  },
  {
    label: 'FW_7009',
    family: 'FW - Fine Wood',
    value: 'FW_7009',
  },
  {
    label: 'FW-1970',
    family: 'FW - Fine Wood',
    value: 'FW-1970',
  },
  {
    label: 'FW-1209',
    family: 'FW - Fine Wood',
    value: 'FW-1209',
  },
  {
    label: 'FW-1259',
    family: 'FW - Fine Wood',
    value: 'FW-1259',
  },
  {
    label: 'FW_795',
    family: 'FW - Fine Wood',
    value: 'FW_795',
  },
  {
    label: 'FW_7016',
    family: 'FW - Fine Wood',
    value: 'FW_7016',
  },
  {
    label: 'FW-1290',
    family: 'FW - Fine Wood',
    value: 'FW-1290',
  },
  {
    label: 'FW_1129',
    family: 'FW - Fine Wood',
    value: 'FW_1129',
  },
  {
    label: 'FW_7014',
    family: 'FW - Fine Wood',
    value: 'FW_7014',
  },
  {
    label: 'FW_1021',
    family: 'FW - Fine Wood',
    value: 'FW_1021',
  },
  {
    label: 'FW-1293AR',
    family: 'FW - Fine Wood',
    value: 'FW-1293AR',
  },
  {
    label: 'FW_232',
    family: 'FW - Fine Wood',
    value: 'FW_232',
  },
  {
    label: 'FW-1755',
    family: 'FW - Fine Wood',
    value: 'FW-1755',
  },
  {
    label: 'FW_1139H',
    family: 'FW - Fine Wood',
    value: 'FW_1139H',
  },
  {
    label: 'FW-1285',
    family: 'FW - Fine Wood',
    value: 'FW-1285',
  },
  {
    label: 'FW-1276',
    family: 'FW - Fine Wood',
    value: 'FW-1276',
  },
  {
    label: 'FW_236',
    family: 'FW - Fine Wood',
    value: 'FW_236',
  },
  {
    label: 'FW-1283',
    family: 'FW - Fine Wood',
    value: 'FW-1283',
  },
  {
    label: 'FW_334',
    family: 'FW - Fine Wood',
    value: 'FW_334',
  },
  {
    label: 'FW_1134',
    family: 'FW - Fine Wood',
    value: 'FW_1134',
  },
  {
    label: 'FW-1258',
    family: 'FW - Fine Wood',
    value: 'FW-1258',
  },
  {
    label: 'FW-1211',
    family: 'FW - Fine Wood',
    value: 'FW-1211',
  },
  {
    label: 'FW_788',
    family: 'FW - Fine Wood',
    value: 'FW_788',
  },
  {
    label: 'FW-1979',
    family: 'FW - Fine Wood',
    value: 'FW-1979',
  },
  {
    label: 'FW-1207',
    family: 'FW - Fine Wood',
    value: 'FW-1207',
  },
  {
    label: 'FW-1681',
    family: 'FW - Fine Wood',
    value: 'FW-1681',
  },
  {
    label: 'FW-502',
    family: 'FW - Fine Wood',
    value: 'FW-502',
  },
  {
    label: 'FW-1304',
    family: 'FW - Fine Wood',
    value: 'FW-1304',
  },
  {
    label: 'FW_7001',
    family: 'FW - Fine Wood',
    value: 'FW_7001',
  },
  {
    label: 'PT-735',
    family: 'PT - Abstract',
    value: 'PT-735',
  },
  {
    label: 'PT_345',
    family: 'PT - Abstract',
    value: 'PT_345',
  },
  {
    label: 'PT_347',
    family: 'PT - Abstract',
    value: 'PT_347',
  },
  {
    label: 'PT-346',
    family: 'PT - Abstract',
    value: 'PT-346',
  },
  {
    label: 'ST-1911MT',
    family: 'ST - Stone',
    value: 'ST-1911MT',
  },
  {
    label: 'ST-1830',
    family: 'ST - Stone',
    value: 'ST-1830',
  },
  {
    label: 'ST-1927MT',
    family: 'ST - Stone',
    value: 'ST-1927MT',
  },
  {
    label: 'ST-1912MT',
    family: 'ST - Stone',
    value: 'ST-1912MT',
  },
  {
    label: 'ST-1588',
    family: 'ST - Stone',
    value: 'ST-1588',
  },
  {
    label: 'ST-1828',
    family: 'ST - Stone',
    value: 'ST-1828',
  },
  {
    label: 'ST-1829',
    family: 'ST - Stone',
    value: 'ST-1829',
  },
  {
    label: 'ST_1195',
    family: 'ST - Stone',
    value: 'ST_1195',
  },
  {
    label: 'ST_736',
    family: 'ST - Stone',
    value: 'ST_736',
  },
  {
    label: 'ST-2170MT',
    family: 'ST - Stone',
    value: 'ST-2170MT',
  },
  {
    label: 'ST-1918MT',
    family: 'ST - Stone',
    value: 'ST-1918MT',
  },
  {
    label: 'ST-1920MT',
    family: 'ST - Stone',
    value: 'ST-1920MT',
  },
  {
    label: 'ST-737',
    family: 'ST - Stone',
    value: 'ST-737',
  },
  {
    label: 'ST-1914MT',
    family: 'ST - Stone',
    value: 'ST-1914MT',
  },
  {
    label: 'ST-1916MT',
    family: 'ST - Stone',
    value: 'ST-1916MT',
  },
  {
    label: 'ST-1831',
    family: 'ST - Stone',
    value: 'ST-1831',
  },
  {
    label: 'ST-1587',
    family: 'ST - Stone',
    value: 'ST-1587',
  },
  {
    label: 'ST-2171MT',
    family: 'ST - Stone',
    value: 'ST-2171MT',
  },
  {
    label: 'ST-1915MT',
    family: 'ST - Stone',
    value: 'ST-1915MT',
  },
  {
    label: 'ST-1919MT',
    family: 'ST - Stone',
    value: 'ST-1919MT',
  },
  {
    label: 'ST-1586',
    family: 'ST - Stone',
    value: 'ST-1586',
  },
  {
    label: 'WH-111',
    family: 'PWF - Whiteboard',
    value: 'WH-111',
  },
  {
    label: 'AM1698',
    family: 'AM - Advanced Metallic',
    value: 'AM1698',
  },
  {
    label: 'AM1696',
    family: 'AM - Advanced Metallic',
    value: 'AM1696',
  },
  {
    label: 'AM1700',
    family: 'AM - Advanced Metallic',
    value: 'AM1700',
  },
  {
    label: 'AM1722',
    family: 'AM - Advanced Metallic',
    value: 'AM1722',
  },
  {
    label: 'AM1720',
    family: 'AM - Advanced Metallic',
    value: 'AM1720',
  },
  {
    label: 'AM1701',
    family: 'AM - Advanced Metallic',
    value: 'AM1701',
  },
  {
    label: 'AM1699',
    family: 'AM - Advanced Metallic',
    value: 'AM1699',
  },
  {
    label: 'CH-2116',
    family: 'CH - Metallic Hairline',
    value: 'CH-2116',
  },
  {
    label: 'CH-2120',
    family: 'CH - Metallic Hairline',
    value: 'CH-2120',
  },
  {
    label: 'CH-1630',
    family: 'CH - Metallic Hairline',
    value: 'CH-1630',
  },
  {
    label: 'CH-1677',
    family: 'CH - Metallic Hairline',
    value: 'CH-1677',
  },
  {
    label: 'CH-2118',
    family: 'CH - Metallic Hairline',
    value: 'CH-2118',
  },
  {
    label: 'CH-1631',
    family: 'CH - Metallic Hairline',
    value: 'CH-1631',
  },
  {
    label: 'CH-1676',
    family: 'CH - Metallic Hairline',
    value: 'CH-1676',
  },
  {
    label: 'CH-1628',
    family: 'CH - Metallic Hairline',
    value: 'CH-1628',
  },
  {
    label: 'CH-1629',
    family: 'CH - Metallic Hairline',
    value: 'CH-1629',
  },
  {
    label: 'CH-2119',
    family: 'CH - Metallic Hairline',
    value: 'CH-2119',
  },
  {
    label: 'CH-1627',
    family: 'CH - Metallic Hairline',
    value: 'CH-1627',
  },
  {
    label: 'CH-2117',
    family: 'CH - Metallic Hairline',
    value: 'CH-2117',
  },
  {
    label: 'PWF-500',
    family: 'PWF - Whiteboard',
    value: 'PWF-500',
    image: 'PWF-500.jpg',
  },
  { family: 'Custom', value: 'Custom', label: 'Custom' },
].sort(compareLabel);

function compareLabel(a, b) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}
