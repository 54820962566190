import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({ pathname }) => (
  <Route
    render={({ location }) => (
      <Redirect
        to={{
          pathname,
          state: { from: location },
        }}
      />
    )}
  />
);
