import { all, put, call, takeEvery } from 'redux-saga/effects';
import { actions } from './reducer';
import { requests as profileRequest } from '../profile/reducer';
import { push } from 'connected-react-router';

const ACCESS_TOKEN = window.env.ACCESS_TOKEN;
const REFRESH_TOKEN = window.env.REFRESH_TOKEN;

export function* authorize() {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

  const hasSession = accessToken && refreshToken ? true : false;

  yield put(
    actions.setAuth({
      hasSession,
      accessToken,
      refreshToken,
      error: null,
    }),
  );

  if (hasSession) {
    yield put(profileRequest.fetch());
  }
}

export function* logInEffect(action) {
  try {
    window.localStorage.setItem(ACCESS_TOKEN, action.payload[ACCESS_TOKEN]);
    window.localStorage.setItem(REFRESH_TOKEN, action.payload[REFRESH_TOKEN]);
    yield call(authorize);
    yield put(push('/'));
  } catch (e) {
    yield put(actions.setError(true));
  }
}

export function refreshTokenEffect(action) {
  // TODO Check if update tokens on localStorage
  console.log('Refresh Token');

  window.localStorage.setItem(ACCESS_TOKEN, action.payload[ACCESS_TOKEN]);
  window.localStorage.setItem(REFRESH_TOKEN, action.payload[REFRESH_TOKEN]);
}

export function* logOutEffect() {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  yield put(push('/login'));
  yield call(authorize);
}

export default function* () {
  yield all([
    authorize(),
    takeEvery(actions.create.type, logInEffect),
    takeEvery(actions.refresh.type, refreshTokenEffect),
    takeEvery(actions.logOut.type, logOutEffect),
  ]);
}
