import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getBrandsOptions } from 'redux/brands/selector';
import { getModelsOptions } from 'redux/models/selector';
import { requests as brandsRequest } from 'redux/brands/reducer';
import {
  requests as modelsRequest,
  actions as modelsActions,
} from 'redux/models/reducer';

import { Field } from 'formik';
import Button from '@material-ui/core/Button';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';

export const initialValues = {
  brand: '',
  model: '',
  patent: '',
};

export const Step2 = ({
  fetchBrands,
  fetchModels,
  clearModels,
  brandsOptions,
  modelsOptions,
  setFieldValue,
  values: { brand },
  prevStep,
}) => {
  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);
  useEffect(() => {
    if (brand) {
      fetchModels({ brand });
    } else {
      clearModels();
    }
  }, [fetchModels, clearModels, brand]);

  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="brand"
            component={Autocomplete}
            handleChange={() => {
              setFieldValue('model', null);
            }}
            label="Marca *"
            helperText="Por favor seleccione una marca de vehículo"
            variant="outlined"
            options={brandsOptions}
          />
          <div className="kt-space-20" />
          <Field
            name="model"
            component={Autocomplete}
            label="Modelo *"
            helperText="Por favor seleccione el modelo del vehículo"
            variant="outlined"
            options={modelsOptions}
          />
          <div className="kt-space-20" />
          <Field
            name="patent"
            component={TextField}
            type="text"
            label="Número de patente *"
            helperText="Por favor ingrese el número de patente"
            variant="outlined"
            fullWidth
          />
          <div className="kt-space-20" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  brandsOptions: getBrandsOptions(state),
  modelsOptions: getModelsOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchBrands: () => dispatch(brandsRequest.fetch()),
  fetchModels: (data) => dispatch(modelsRequest.fetch(data)),
  clearModels: (data) => dispatch(modelsActions.clear(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
