import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';
import { mainNeedDinoc, substrateTypeOPTs } from 'enums/formOtps';
import { getDistributorsOptions } from 'redux/distributors/selector';

import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';
import { dinocFamilies, dinocFilms } from 'enums/dinocFilms';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import '../../newDinoc/form/step3/previewer.scss';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const newSheet = (type) => ({
  type,
  substrate: '',
  need: '',
  family: '',
  meters: '',
  run: '',
  film: null,
  distributor: {},
});

export const initialValues = {
  data: {
    ...newSheet(''),
  },
};

function buildFilePath(dinocFilms, family, film) {
  let films = dinocFilms.filter((e) => e.family == family);
  let matchedFilms = films.filter((e) => e.value == film);
  if (matchedFilms.length != 1) {
    return 'custom.jpg';
  } else {
    return film + '.jpg';
  }
}
export const Sheets = ({
  values,
  fetchDistributors,
  distributorsOptions,

  fetchFilmsBuildingRequest,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);
  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const Sheets = get(values, 'data.applications', []) || [];

  return (
    <>
      <PorletHeaderTitle variant="h5">
        <FormattedMessage id="3M™ DI-NOC™" />
      </PorletHeaderTitle>
      <FieldArray name="data.applications">
        {({ push, remove }) => (
          <>
            {Sheets.map((sheet, index) => {
              return (
                <>
                  <div className="row FormRow">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <FormattedMessage id="Sustrato: *" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Field
                        name={`data.applications[${index}].substrate`}
                        component={Autocomplete}
                        options={substrateTypeOPTs}
                        type="text"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className="row FormRow">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <FormattedMessage id="Necesidad Principal: *" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Field
                        name={`data.applications[${index}].need`}
                        options={mainNeedDinoc}
                        component={Autocomplete}
                        type="text"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>

                  <div key={index}>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Categoría" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].family`}
                          component={Autocomplete}
                          variant="outlined"
                          options={dinocFamilies}
                        />
                      </div>
                    </div>

                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Producto" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].film`}
                          component={Autocomplete}
                          variant="outlined"
                          options={dinocFilms.filter(
                            (e) =>
                              e.family ==
                              values.data.applications[index].family,
                          )}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="M2 Necesarios: *" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].meters`}
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>

                    {sheet.custom === '' ? (
                      <></>
                    ) : (
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Descripción" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].custom`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Vista Previa" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                              '/media/dinoc/' +
                              buildFilePath(
                                dinocFilms,
                                values.data.applications[index].family,
                                values.data.applications[index].film,
                              ) || 'custom.jpg'
                          }
                          className="img-previewer-summary w-100 "
                        />
                      </div>
                    </div>

                    {values.installer.country !== 'AR' ? (
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Distribuidor: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].distributor.id`}
                            component={Autocomplete}
                            variant="outlined"
                            options={distributorsOptions}
                            placeholder="Seleccionar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Distribuidor: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <div className="kt-space-10" />
                          <span>{sheet.distributor.name}</span>
                        </div>
                      </div>
                    )}
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Número de RUN: *" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].run`}
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      {Sheets.length > 1 && (
                        <>
                          <div className="col-12 kt-space-20" />
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon color="primary" />
                              <FormattedMessage id="Eliminar esta lámina" />
                            </Button>
                            <div className="col-12 kt-space-12" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            {Sheets.length < 4 && (
              <>
                <div className="kt-space-20" />
                <div className="row">
                  <div className="col-12">
                    <Button
                      size="small"
                      variant="outlined"
                      fullWidth
                      onClick={() => push(newSheet(2))}
                    >
                      <FormattedMessage id="Agregar nueva lámina" />
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </FieldArray>
      <div className="kt-space-20" />

      <div className="kt-space-20" />
    </>
  );
};

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sheets);
