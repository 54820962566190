import get from 'lodash/get';

import { PREFIX } from './reducer';

export const getInstallersTableData = (state) => ({
  rows: get(state, `${PREFIX}.data`, []),
  page: get(state, `${PREFIX}.page`, 0),
  perPage: get(state, `${PREFIX}.perPage`, 0),
  total: get(state, `${PREFIX}.total`, 0),
  isLoading: get(state, `${PREFIX}.isLoading`, 0),
  hasError: get(state, `${PREFIX}.hasError`, 0),
});

export const getInstallersListQuery = (state) => ({
  page: get(state, `${PREFIX}.page`, 0),
  perPage: get(state, `${PREFIX}.perPage`, 0),
  q: get(state, `${PREFIX}.q`, ''),
});
