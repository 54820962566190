import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const TableHeadTypography = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

const NpsTable = ({ data }) => {
  return (
    <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadTypography color="secondary">
              <FormattedMessage id="País" />
            </TableHeadTypography>
          </TableCell>
          <TableCell>
            <TableHeadTypography color="secondary">
              <FormattedMessage id="Instalador" />
            </TableHeadTypography>
          </TableCell>
          <TableCell align="right">
            <TableHeadTypography color="secondary">
              <FormattedMessage id="Calificación" />
            </TableHeadTypography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <>
            {index == 0 && (
              <TableRow>
                <TableCell colSpan="3">
                  <TableHeadTypography color="secondary">
                    <FormattedMessage id="Calificaciones Positivas" />
                  </TableHeadTypography>
                </TableCell>
              </TableRow>
            )}
            {index == 3 && (
              <TableRow>
                <TableCell colSpan="3">
                  <TableHeadTypography color="secondary">
                    <FormattedMessage id="Calificaciones Negativas" />
                  </TableHeadTypography>
                </TableCell>
              </TableRow>
            )}
            <TableRow key={index}>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">
                <Typography color="secondary">
                  {Number(row.nps).toLocaleString()}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};
export default NpsTable;
