import './index.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  getCountryProfile,
  getProfile,
  getStatusProfile,
} from 'redux/profile/selector';
import {
  requests as profileRequest,
  actions as profileActions,
} from 'redux/profile/reducer';

import ProfileNav from '../profileNav';
import FomrBusiness from './FormBusiness';

const initialValues = {
  name: '',
  shopName: '',
  taxId: '',
  street: '',
  number: '',
  postalCode: '',
  state: '',
  city: '',
  //email: '',
  cellphone: '',
  // phone: '',
  web: '',
  facebook: '',
  instagram: '',
  linkedin: '',
};

const validationSchema = (intl) =>
  Yup.object().shape({
    shopName: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un nombre' }),
    ),
    name: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar la razon social' }),
    ),
    taxId: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un CUIT' }),
    ),
    street: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar una calle' }),
    ),

    number: Yup.number()
      .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
      .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
    postalCode: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un código postal' }),
    ),
    state: Yup.string()
      .required(intl.formatMessage({ id: 'Debe seleccionar una provincia' }))
      .typeError(intl.formatMessage({ id: 'Debe seleccionar una provincia' })),
    // city: Yup.string()
    //   .required(intl.formatMessage({ id: 'Debe seleccionar una ciudad' }))
    //   .typeError(intl.formatMessage({ id: 'Debe seleccionar una ciudad' })),

    //email: Yup.string()
    //  .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
    //  .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
    cellphone: Yup.string()
      .phone(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }))
      .required(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' })),
    // phone: Yup.string().phone('Debe ingresar un teléfono válido'),
  });
const breadcrumb = [{ page: 'profile/resume', title: 'Mis datos' }];

export const BusinessView = ({
  intl,
  onSubmit,
  user,
  hasError,
  isLoading,
  isSaveSuccess,
  clearSave,
}) => {
  const history = useHistory();
  useEffect(() => () => clearSave(), [clearSave]);
  useEffect(() => {
    if (!isLoading && isSaveSuccess) {
      toast.success(
        <FormattedMessage id="La información ha sido actualizada." />,
        toastConfig,
      );
    }
  }, [isLoading, isSaveSuccess]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se ha producido un error, vuelva a intentar más tarde." />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return onSubmit(values);
  };

  return (
    <Layout>
      {Boolean(Object.keys(user).length) && (
        <Formik
          initialValues={{ ...initialValues, ...user }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema(intl)}
        >
          {(props) => (
            <Form>
              <div className="BusinessView">
                <div className="kt-space-20" />
                <div className="row">
                  <div className="col-12">
                    <div className="kt-space-20" />
                    <Breadcrumbs title="Mis datos" items={breadcrumb} />
                    <div className="kt-space-20" />
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Portlet className="kt-portlet--height-fluid">
                        <PortletBody>
                          <ProfileNav active="business" />
                        </PortletBody>
                      </Portlet>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Portlet className="kt-portlet--height-fluid">
                        <PorletHeader>
                          <PorletHeaderTitle variant="h4">
                            <FormattedMessage id="Información de negocio" />
                          </PorletHeaderTitle>
                        </PorletHeader>
                        <PortletBody>
                          <FomrBusiness {...props} />
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Portlet className="kt-portlet--height-fluid">
                      <PortletBody>
                        <div className="submit-bottons">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Guardar" />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.goBack()}
                          >
                            <FormattedMessage id="Volver" />
                          </Button>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(profileRequest.editContact({ body: values })),
  clearSave: () => dispatch(profileActions.clearSave()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getProfile(state),
      ...getStatusProfile(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(BusinessView),
);
