import { all, call } from 'redux-saga/effects';
import { default as xhrWatcher } from './request/saga';
import { default as authWatcher } from './auth/saga';
import { default as profileWatcher } from './profile/saga';
import { default as clientListWatcher } from './cars/clientList/saga';
import { default as projectListWatcher } from './buildings/projectList/saga';
import { default as projectArqFinishesListWatcher } from './arqFinishes/projectList/saga';
import { default as installersListWatcher } from './client/clientList/saga';
import { default as usersListWatcher } from './user/userList/saga';
import { default as distributorListWatcher } from './distributors/distributorList/saga';
import { default as reportsWatcher } from './reports/saga';

export default function* () {
  yield all([
    call(xhrWatcher),
    call(authWatcher),
    call(profileWatcher),
    call(clientListWatcher),
    call(projectListWatcher),
    call(projectArqFinishesListWatcher),
    call(installersListWatcher),
    call(distributorListWatcher),
    call(reportsWatcher),
    call(usersListWatcher),
  ]);
}
