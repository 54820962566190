import * as Yup from 'yup';
export default (intl, country) => {
  return Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      requirement: Yup.mixed()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar un tipo válido' }),
        ),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar un tipo válido' }),
        ),
      postalCode: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un código postal' }),
      ),
      state: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un país' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un país' })),
    }),
  });
};
