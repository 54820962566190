import React, { useEffect } from 'react';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { getFilmsAutoOptions } from 'redux/filmsAuto/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsAutoRequest } from 'redux/filmsAuto/reducer';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { CheckboxWithLabel } from 'formik-material-ui';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { countryOPTs } from 'enums/formOtps';

export const newSheet = {
  sheet: null,
  application: {
    front: false,
    back: false,
    left: false,
    right: false,
    top: false,
    desc: '',
  },
  run: '',
  distributor: null,
  promoCode: '',
  comment: '',
};

export const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  installer: { ...newInstaller },
  sheets: [{ ...newSheet }],
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: true,
    });
  }

  return filtered;
};

const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Step3 = ({
  country,
  values,
  prevStep,
  fetchDistributors,
  distributorsOptions,
  fetchUserInstallersRequest,
  userInstallersOptions,
  fetchFilmsAutoRequest,
  filmsAutoOptions,
  errors,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  useEffect(() => {
    fetchFilmsAutoRequest();
  }, [fetchFilmsAutoRequest]);

  const filteredByCountry = countryOPTs.filter(
    (item) => item.value === country,
  );

  const distributorFiltered = distributorsOptions.filter((distributor) => {
    return distributor.users.some((user) => user.auto === true);
  });

  /* // Eliminar duplicados basados en el id
  const uniqueDistributorFiltered = distributorFiltered.reduce(
    (uniqueDistributors, distributor) => {
      if (!uniqueDistributors.some((d) => d.id === distributor.id)) {
        uniqueDistributors.push(distributor);
      }
      return uniqueDistributors;
    },
    [],
  ); */

  const filteredAutoOptions = filmsAutoOptions.filter(
    (option) => option.type != 3 && option.type != 4,
  );

  const filteredOptions = distributorFiltered.filter(
    (option) =>
      !option.label.toLowerCase().includes('test') &&
      !/^[a-z]+distributor/.test(option.label.toLowerCase()),
  );

  console.log(filmsAutoOptions);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Aplicación" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="installer"
            component={Autocomplete}
            emptyValue={newInstaller}
            autoCompleteProps={autoCompleteProps}
            label="Instalador *"
            helperText="Por favor seleccione la persona que realizó la colocación"
            variant="outlined"
            options={userInstallersOptions}
            placeholder="Seleccionar"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="promoCode"
            component={TextField}
            type="text"
            label="Código promocional"
            helperText="Si tiene un código promocional, ingreselo aquí"
            placeholder="Ingresar"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader className="noBorder">
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Película colocada" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <FieldArray name="sheets">
        {({ push, remove }) => (
          <>
            {values.sheets.map((sheet, index) => {
              const aplicationError = get(
                errors,
                `sheets[${index}].application`,
                false,
              );
              return (
                <div className="row" key={index}>
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-8 borderTop">
                    {values.sheets.length > 1 && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon color="primary" />
                      </Button>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <Field
                      name={`sheets[${index}].sheet`}
                      component={Autocomplete}
                      label="Película *"
                      helperText="Por favor seleccione la película de seguridad colocada"
                      variant="outlined"
                      options={filteredAutoOptions}
                      placeholder="Seleccionar"
                      InputLabelProps={{ shrink: true }}
                    />
                    <div className="row application">
                      <div className="col-sm-12">
                        <InputLabel error={Boolean(aplicationError)}>
                          <FormattedMessage id="Aplicación *" />
                        </InputLabel>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <Field
                          name={`sheets[${index}].application.front`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Parabrisas" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                        <Field
                          name={`sheets[${index}].application.right`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Lateral derecho" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                        <Field
                          name={`sheets[${index}].application.left`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Lateral izquierdo" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <Field
                          name={`sheets[${index}].application.top`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Techo solar" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                        <Field
                          name={`sheets[${index}].application.back`}
                          component={CheckboxWithLabel}
                          Label={{ label: <FormattedMessage id="Luneta" /> }}
                          type="checkbox"
                          color="primary"
                        />
                      </div>
                      <div className="col-sm-12">
                        <FormHelperText error={Boolean(aplicationError)}>
                          {aplicationError ? (
                            <FormattedMessage id={aplicationError} />
                          ) : (
                            <FormattedMessage id="* Por favor verifica el cumplimiento de las normativas" />
                          )}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <Field
                      name={`sheets[${index}].run`}
                      component={TextField}
                      type="text"
                      label="Número de RUN *"
                      helperText="Por favor ingresa el número de RUN de la película colocada"
                      variant="outlined"
                      fullWidth
                      placeholder="Ingresar"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Field
                      name={`sheets[${index}].distributor`}
                      component={Autocomplete}
                      label="Distribuidor *"
                      helperText="Por favor selecione el distribuidor"
                      variant="outlined"
                      options={filteredOptions}
                      placeholder="Seleccionar"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="col-12">
                    <div className="kt-space-20" />
                  </div>
                </div>
              );
            })}
            {values.sheets.length < 4 && (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-2" />
                <div className="col-sm-12 col-md-12 col-lg-8">
                  <Button
                    size="small"
                    variant="outlined"
                    fullWidth
                    onClick={() => push(newSheet)}
                  >
                    <FormattedMessage id="Agregar nueva película" />
                  </Button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-2" />
              </div>
            )}
          </>
        )}
      </FieldArray>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="comment"
            component={TextField}
            type="text"
            label="Comentarios:"
            rows={7}
            multiline
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state),
  filmsAutoOptions: getFilmsAutoOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsAutoRequest: () => dispatch(filmsAutoRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
