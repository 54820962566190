import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import Header from './header/Header';
import HeaderMobile from './header/HeaderMobile';
import Footer from './footer/Footer';
import Cookie from './cookie-consent/Cookie';
import HTMLClassService from './HTMLClassService';
import AsideLeft from './aside/AsideLeft';

// import SubHeader from './sub-header/SubHeader';
import KtContent from './KtContent';
import './assets/Base.scss';

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  // subheaderDisplay,
  contentExtended,
  layoutConfig,
  contentContainerClasses,
  contentClasses,
}) {
  htmlClassService.setConfig(layoutConfig);
  contentClasses = htmlClassService.classes.content.join(' ');
  contentContainerClasses = htmlClassService.classes.content_container.join(
    ' ',
  );

  return (
    <>
      {/* <!-- begin:: Header Mobile --> */}

      <HeaderMobile htmlClassService={htmlClassService} />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />

            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}

            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              {asideDisplay ? (
                <div
                  className={`kt-container ${contentContainerClasses} kt-grid kt-grid--ver`}
                >
                  <AsideLeft />
                  <div
                    className={`kt-content ${contentClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                    id="kt_content"
                  >
                    {/* {subheaderDisplay && <SubHeader />} */}
                    {contentExtended ? (
                      { children }
                    ) : (
                      <div
                        className={`kt-container ${contentContainerClasses} kt-grid__item kt-grid__item--fluid`}
                      >
                        {children}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`kt-content ${contentClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  id="kt_content"
                >
                  {/* {subheaderDisplay && <SubHeader />} */}
                  {contentExtended ? (
                    { children }
                  ) : (
                    <KtContent>{children}</KtContent>
                  )}
                </div>
              )}
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Cookie />
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
    </>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  // subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  contentExtended: objectPath.get(layoutConfig, 'content.extended'),
});

export default connect(mapStateToProps)(Layout);
