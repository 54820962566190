/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCookiesAcceptance } from '../../../redux/cookies/actions';
import { FormattedMessage, injectIntl } from 'react-intl';

const Cookie = (props) => {
  const [visible, setVisibility] = useState(true);
  const [cookieAccepted, setCookie] = useState(false);
  function handleClick() {
    setVisibility(false);
    props.setCookiesAcceptance(true);
  }
  useEffect(() => {
    setCookie(props.cookies.isCookieAccepted);
  }, []);

  return visible && !cookieAccepted ? (
    <Navbar className="w-100 bg-dark" fixed="bottom">
      {' '}
      <span className="w-100 text-center">
        {' '}
        <span className="text-white">
          <FormattedMessage id="COOKIE_CONCENT" />{' '}
        </span>
        <a
          className="ml-4 text-small"
          href="https://www.3m.com.es/3M/es_ES/empresa-es/politica-de-privacidad/"
          target="_blank"
        >
          <FormattedMessage id="Más Información" />
        </a>{' '}
        <Button className="ml-4" onClick={handleClick}>
          <FormattedMessage id="Entendido" />
        </Button>
      </span>
    </Navbar>
  ) : (
    <></>
  );
};
const mapDispatchToProps = {
  setCookiesAcceptance,
};
const mapStateToProps = (reducers) => {
  return reducers;
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Cookie));

/* import React from 'react';
import { Navbar, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';


class Cookie extends React.Component {
    render() {
        const { cookieClasses } = this.props;
        return (
            <div
                className={`kt-cookie ${cookieClasses} kt-grid__item`}
                id="kt_cookie"
            ><Navbar className="w-100 bg-dark" fixed="bottom"> <span className="w-100 text-center"> <span className="text-white">Al navegar este sitio aceptás las cookies que utilizamos para mejorar tu experiencia. </span>
                <Link className="ml-4 text-small">Más Información</Link> <Button className="ml-4">Entendido</Button></span>
                </Navbar>
                <div className="kt-cookie__bottom"></div>
            </div>


        );
    }
}

const mapStateToProps = (store) => ({
    cookieClasses: builder.selectors.getClasses(store, {
        path: 'cookie',
        toString: true,
    }),
});

export default connect(mapStateToProps)(Cookie);

 */
