import './index.scss';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { getProfile } from 'redux/profile/selector';

import ProfileNav from '../profileNav';

const breadcrumb = [{ page: 'profile/resume', title: 'Mis datos' }];

export const ResumeView = ({ user }) => {
  const history = useHistory();

  return (
    <Layout>
      <div className="ResumeView">
        <div className="kt-space-20" />
        <div className="row">
          <div className="col-12">
            <div className="kt-space-20" />
            <Breadcrumbs title="Mis datos" items={breadcrumb} />
            <div className="kt-space-20" />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Portlet className="kt-portlet--height-fluid">
                <PortletBody>
                  <ProfileNav active="resume" />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Resumen de cuenta" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-1" />
                    <div className="col-sm-12 col-md-12 col-lg-10">
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Avatar:" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <div className="badgeContainer">
                            <span className="badge">
                              {get(user, 'firstname', '')[0]
                                ? get(user, 'firstname', '')[0]
                                : 'U'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="kt-space-20" />
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4" />
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Typography variant="h5">
                            <FormattedMessage id="Negocio" />
                          </Typography>
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Nombre Del Negocio: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'shopName')}
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Razon Social: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'name')}
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="CUIT: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'taxId')}
                        </div>
                      </div>
                      {/* <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Ciudad: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'city')}
                        </div>
                      </div> */}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-1" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-1" />
                    <div className="col-sm-12 col-md-12 col-lg-10">
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4" />
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Typography variant="h5">
                            <FormattedMessage id="Contacto" />
                          </Typography>
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Nombre: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'firstname')}
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Apellido: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'lastname')}
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Correo electronico: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'email')}
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Celular: *" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'cellphone')}
                        </div>
                      </div>
                      {/* <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <strong>
                            <FormattedMessage id="Teléfono:" />
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          {get(user, 'phone')}
                        </div>
                      </div> */}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-1" />
                  </div>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Portlet className="kt-portlet--height-fluid">
              <PortletBody>
                <div>
                  <Button variant="outlined" onClick={() => history.goBack()}>
                    <FormattedMessage id="Volver" />
                  </Button>
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect((state) => ({
  ...getProfile(state),
}))(ResumeView);
