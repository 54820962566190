import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import { useIntl } from 'react-intl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { EUROPE } from 'enums/regions';
import { DropzoneDialog } from 'material-ui-dropzone';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

const Glass = ({ values }) => {
  const intl = useIntl();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  // Identificador único para el proyecto o garantía
  const projectId = values.data.id; // Asegúrate de tener un ID único aquí
  const localStorageKey = `glassCheckUrl_${projectId}`;

  const isEuropeCountry = EUROPE.includes(values.data.country);

  // Al cargar el componente, verificamos si ya hay un archivo subido previamente en localStorage
  useEffect(() => {
    const glassCheckUrl = localStorage.getItem(localStorageKey);
    if (glassCheckUrl) {
      setFileUploaded(true);
      setFileName(glassCheckUrl.split('/').pop());
    } else {
      setFileUploaded(false);
    }
  }, [localStorageKey]);

  // Función para descargar el archivo
  const handleDownloadFile = () => {
    const glassCheckUrl = localStorage.getItem(localStorageKey);
    if (glassCheckUrl) {
      window.open(glassCheckUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  // Función para subir el archivo y guardar la URL en localStorage
  const handleSave = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      const originalFileName = file.name;
      const newFileName = `end_user-${originalFileName}`;

      const blob = new Blob([file], { type: file.type });
      const newFile = new File([blob], newFileName, { type: file.type });

      const data = new FormData();
      data.append('file', newFile);

      try {
        const url = `${API_URL}/bulk/upload`;
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              ACCESS_TOKEN,
            )}`,
          },
        });

        if (response.status === 200) {
          localStorage.setItem(localStorageKey, response.data.url);
          setFileUploaded(true);
          setFileName(originalFileName);
          console.log('Archivo subido con éxito:', response.data.url);
          setOpen(false);
        } else {
          throw new Error('Error en la respuesta del servidor');
        }
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Tipo de vidrio:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.glass', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Realizó el Glass Checklist:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.Checklist', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />

          {isEuropeCountry && (
            <div>
              {!fileUploaded && values.data.Checklist?.trim() === 'SI' ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => setOpen(true)}
                  >
                    <FormattedMessage id="Subir archivo a Amazon" />
                  </Button>
                  <DropzoneDialog
                    acceptedFiles={['application/pdf']}
                    cancelButtonText={intl.formatMessage({ id: 'Cancel' })}
                    submitButtonText={intl.formatMessage({ id: 'Send' })}
                    dialogTitle={intl.formatMessage({ id: 'Upload file' })}
                    dropzoneText={intl.formatMessage({
                      id: 'Drag and drop a file here or click',
                    })}
                    maxFileSize={5000000}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={handleSave}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    showFileNames={true}
                    filesLimit={1}
                  />
                </>
              ) : fileUploaded && values.data.Checklist?.trim() === 'SI' ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={handleDownloadFile}
                >
                  <FormattedMessage id="Descargar certificado glass" />
                </Button>
              ) : values.data.Checklist?.trim() === 'NO' ? (
                <div></div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Glass;
