import * as Yup from 'yup';
import 'utils/phoneValidator';

export default (intl, country) =>
  Yup.object().shape({
    company: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar la compañia' }),
    ),
    firstName: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un nombre' }),
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
      .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
  });
