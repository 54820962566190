import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import moment from 'moment';
export const Installer = ({ values }) => {
  const formatDate = 'DD/MM/YYYY';
  const employess = get(values, 'data.employees', []) || [];
  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Fecha de instalación" />:
            </strong>
            <span>
              {moment(get(values, 'installed', '')).format(formatDate)}
            </span>
          </div>
        </div>

        {employess.map((employe, index) => (
          <div className="col-12 ViewOrEdit" key={index}>
            <div>
              <strong>
                <FormattedMessage id="Instalador" /> {index + 1}:
              </strong>
              <span>{get(employe, 'name', '-')}</span>
            </div>
            {employess.length - 1 !== index && <div className="kt-space-20" />}
          </div>
        ))}
      </div>
    </>
  );
};

export default Installer;
