import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const PREFIX = 'filmsBuilding';

export const getFilmsBuilding = (state) => get(state, `${PREFIX}.data`);

export const filterOptionsByType = (type) =>
  createSelector(getFilmsBuilding, (data) =>
    data.filter((item) => item.type === type),
  );

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: act.id,
      },
    ];
  }, []);

export const getSecurityFilmsBuildingOptions = createSelector(
  filterOptionsByType(2),
  makeAtoCompleteOptions,
);
export const getSolarControlFilmsBuildingOptions = createSelector(
  filterOptionsByType(1),
  makeAtoCompleteOptions,
);
export const getDinocFilmsBuildingOptions = createSelector(
  filterOptionsByType(3),
  makeAtoCompleteOptions,
);
export const getFasaraFilmsBuildingOptions = createSelector(
  filterOptionsByType(4),
  makeAtoCompleteOptions,
);
