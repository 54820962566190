import React from 'react';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import get from 'lodash/get';

import { DatePicker } from 'formik-material-ui-pickers';

const MyDatePicker = withStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.8rem',
      marginLeft: 0,
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
    },
    '& .MuiInputBase-root': {
      '& legend': {
        display: 'none',
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      padding: '10px 12px',
    },
  },
}))(DatePicker);

const CustomPicker = ({ locale, ...props }) => {
  console.log(locale);
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <MyDatePicker format="DD/MM/YYYY" {...props} />
    </MuiPickersUtilsProvider>
  );
};

const localeMap = {
  de: 'de',
  br: 'pt',
  jm: 'en',
  gb: 'en',
  dk: 'en',
  fi: 'en',
  no: 'en',
  ch: 'de',
  fr: 'en',
  nl: 'en',
  at: 'en',
  it: 'en',
  pt: 'pt-br',
  be: 'en',
  default: 'es',
};

export const matStateToProps = (state) => ({
  locale: localeMap[state.i18n.lang] || localeMap.default,
});
export default connect(matStateToProps)(CustomPicker);
