import React, { useEffect } from 'react';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import {
  wrappingColors,
  wrappingFinishes,
  wrappingFilms,
} from 'enums/wrappingFilms';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { getFilmsAutoOptions } from 'redux/filmsAuto/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsAutoRequest } from 'redux/filmsAuto/reducer';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { CheckboxWithLabel } from 'formik-material-ui';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

export const newSheet = {
  sheet: null,
  application: {
    fullWrap: false,
    stripes: false,
    customDesign: false,
    desc: '',
  },
  run: '',
  meters: '',
  distributor: null,
  promoCode: '',
  comment: '',
};

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  installers: [{ ...newInstaller }],
  sheets: [{ ...newSheet }],
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: false,
    });
  }

  return filtered;
};

const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Step3 = ({
  country,
  values,
  prevStep,
  fetchDistributors,
  distributorsOptions,
  fetchUserInstallersRequest,
  userInstallersOptions,
  fetchFilmsAutoRequest,
  filmsAutoOptions,
  errors,
}) => {
  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchFilmsAutoRequest();
  }, [fetchFilmsAutoRequest]);

  // Filtrar las opciones de la base de datos por tipo 4
  const filmsWrapOptions = filmsAutoOptions.filter((e) => e.type === 4);

  // Resto del código para la fusión
  const lowercaseLabel = (label) => label.toLowerCase();

  const mergedArray = wrappingFilms.reduce((result, film) => {
    const existingFilm = result.find(
      (item) => lowercaseLabel(item.label) === lowercaseLabel(film.label),
    );

    if (existingFilm) {
      Object.assign(existingFilm, film);
    } else {
      result.push(film);
    }

    return result;
  }, filmsWrapOptions.slice());

  return (
    <>
      {console.log('Merged Array:', mergedArray)}
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-12">
              <PorletHeader>
                <PorletHeaderTitle variant="h6">
                  <FormattedMessage id="Aplicación" />
                </PorletHeaderTitle>
              </PorletHeader>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="col-12">
                <FieldArray name="installers">
                  {({ push, remove }) => (
                    <>
                      {values.installers.map((_, index) => (
                        <div className="row" key={index}>
                          <div className="col-12">
                            <Field
                              name={`installers[${index}]`}
                              component={Autocomplete}
                              emptyValue={newInstaller}
                              autoCompleteProps={autoCompleteProps}
                              label="Instalador *"
                              helperText="Por favor seleccione la persona que realizó la colocación"
                              variant="outlined"
                              options={userInstallersOptions}
                              placeholder="Seleccionar"
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                          {values.installers.length > 1 && (
                            <>
                              <div className="col-12 kt-space-20" />
                              <div className="col-12">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon color="primary" />
                                  <FormattedMessage id="Eliminar este instalador" />
                                </Button>
                                <div className="col-12 kt-space-12" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      {values.installers.length <= 4 && (
                        <>
                          <div className="kt-space-20" />

                          <div className="row">
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => push(newInstaller)}
                              >
                                <FormattedMessage id="Agregar nuevo instalador" />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="kt-space-20" />
                    </>
                  )}
                </FieldArray>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Field
                name="promoCode"
                component={TextField}
                type="text"
                label="Código promocional"
                helperText="Si tiene un código promocional, ingreselo aquí"
                placeholder="Ingresar"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader className="noBorder">
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Auto Wrap : Linea 2080" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <FieldArray name="sheets">
        {({ push, remove }) => (
          <>
            {console.log(values.sheets)}
            {values.sheets.map((sheet, index) => {
              const aplicationError = get(
                errors,
                `sheets[${index}].application`,
                false,
              );
              return (
                <div className="row" key={index}>
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-8 borderTop">
                    {values.sheets.length > 1 && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon color="primary" />
                      </Button>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-2" />
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="row">
                      <div className="col-12">
                        <Field
                          name={`sheets[${index}].sheet`}
                          component={Autocomplete}
                          translate="false"
                          label="Película *"
                          helperText="Por favor seleccione una película 3M™ Wrap"
                          variant="outlined"
                          options={filmsWrapOptions}
                        />{' '}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          name={`sheets[${index}].color`}
                          component={Autocomplete}
                          label="Color *"
                          variant="outlined"
                          translate="false"
                          options={
                            values.sheets[index].sheet
                              ? wrappingColors.filter((color) =>
                                  mergedArray
                                    .filter(
                                      (film) =>
                                        film.value ===
                                        values.sheets[index].sheet,
                                    )
                                    .some((film) => film.color === color.value),
                                )
                              : []
                          }
                        />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="col-12">
                        <Field
                          name={`sheets[${index}].finish`}
                          component={Autocomplete}
                          label="Terminacion *"
                          variant="outlined"
                          translate="false"
                          options={
                            values.sheets[index].sheet
                              ? wrappingFinishes.filter((finish) =>
                                  mergedArray
                                    .filter(
                                      (film) =>
                                        film.value ===
                                        values.sheets[index].sheet,
                                    )
                                    .some(
                                      (film) =>
                                        film.finish === finish.value &&
                                        film.color === finish.color,
                                    ),
                                )
                              : []
                          }
                        />
                      </div>
                    </div>
                    <div className="row application">
                      <div className="col-sm-12">
                        <InputLabel error={Boolean(aplicationError)}>
                          <FormattedMessage id="Aplicación *" />
                        </InputLabel>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <Field
                          name={`sheets[${index}].application.fullWrap`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Full Wrap" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                        <Field
                          name={`sheets[${index}].application.stripes`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: <FormattedMessage id="Franjas" />,
                          }}
                          type="checkbox"
                          color="primary"
                        />
                        <Field
                          name={`sheets[${index}].application.customDesign`}
                          component={CheckboxWithLabel}
                          Label={{
                            label: (
                              <FormattedMessage id="Diseño personalizado + Otros" />
                            ),
                          }}
                          type="checkbox"
                          color="primary"
                        />
                      </div>
                      <div className="col-sm-12">
                        <FormHelperText error={Boolean(aplicationError)}>
                          {aplicationError ? (
                            <FormattedMessage id={aplicationError} />
                          ) : (
                            <FormattedMessage id="* Por favor verifica el cumplimiento de las normativas" />
                          )}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="film-previewer">
                      <PorletHeaderTitle variant="h7">
                        <FormattedMessage id="Vista Previa" />
                      </PorletHeaderTitle>
                      {console.log(values.sheets)}
                      <img
                        className="img-previewer"
                        src={
                          values.sheets[index].sheet
                            ? process.env.PUBLIC_URL +
                              '/media/wrapping/' +
                              values.sheets[index].sheet.toString() +
                              '.png'
                            : process.env.PUBLIC_URL +
                              '/media/fasara/Choose.jpg'
                        }
                        alt="imagen de Vista Previa"
                      />
                    </div>
                    <Field
                      name={`sheets[${index}].run`}
                      component={TextField}
                      type="text"
                      label="Número de RUN *"
                      helperText="Por favor ingresa el número de RUN de la película colocada"
                      variant="outlined"
                      fullWidth
                      placeholder="Ingresar"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Field
                      name={`sheets[${index}].meters`}
                      component={TextField}
                      type="text"
                      label="M2 totales utilizados* "
                      variant="outlined"
                      fullWidth
                      placeholder="Ingresar"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Field
                      name={`sheets[${index}].distributor`}
                      component={Autocomplete}
                      label="Distribuidor *"
                      helperText="Por favor selecione el distribuidor"
                      variant="outlined"
                      options={distributorsOptions}
                      placeholder="Seleccionar"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="col-12">
                    <div className="kt-space-20" />
                  </div>
                </div>
              );
            })}
            {values.sheets.length < 4 && (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-2" />
                <div className="col-sm-12 col-md-12 col-lg-8">
                  <Button
                    size="small"
                    variant="outlined"
                    fullWidth
                    onClick={() => push(newSheet)}
                  >
                    <FormattedMessage id="Agregar nuevo Wrap" />
                  </Button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-2" />
              </div>
            )}
          </>
        )}
      </FieldArray>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="comment"
            component={TextField}
            type="text"
            label="Comentarios:"
            rows={7}
            multiline
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4"></div>
      </div>
    </>
  );
};

export const mapStateToProps = (state, { values: { installers } }) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state).filter(
    (item) => !installers.find((installer) => installer.id === item.value.id),
  ),
  filmsAutoOptions: getFilmsAutoOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsAutoRequest: () => dispatch(filmsAutoRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
