import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Field, FieldArray, ErrorMessage } from 'formik';
import { connect } from 'react-redux';

import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';

import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import get from 'lodash/get';
import DatePicker from 'components/datePicker';
import { TextField } from 'formik-material-ui';

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  data: {
    employees: [{ ...newInstaller }],
  },
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: 0,
        name: params.inputValue,
      },
      label: `Agregar A: "${params.inputValue}"`,
      useValue: true,
    });
  }

  return filtered;
};

const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Installer = ({
  employees,
  fetchUserInstallersRequest,
  userInstallersOptions,
  values,
}) => {
  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  const employess = get(values, 'data.employees', []) || [];

  return (
    <>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Fecha de instalación:" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            component={DatePicker}
            fullWidth
            name="data.installed"
            inputVariant="outlined"
          />
        </div>
      </div>
      <FieldArray name="data.employees">
        {({ push, remove }) => (
          <>
            {employess.length === 0
              ? push(newInstaller)
              : employess.map((_, index) => (
                  <div className="row FormRow" key={index}>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <FormattedMessage id="Instalador" /> {index + 1}: *
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Field
                        name={`data.employees[${index}]`}
                        component={Autocomplete}
                        emptyValue={newInstaller}
                        autoCompleteProps={autoCompleteProps}
                        variant="outlined"
                        placeholder="Seleccionar"
                        InputLabelProps={{ shrink: true }}
                        options={userInstallersOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Instalador ${index + 1}`}
                          />
                        )}
                      />
                    </div>

                    {employess.length > 1 && (
                      <>
                        <div className="col-12 kt-space-20" />
                        <div className="col-12">
                          <Button
                            size="small"
                            variant="outlined"
                            fullWidth
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon color="primary" />
                            <FormattedMessage id="Eliminar este instalador" />
                          </Button>
                          <div className="col-12 kt-space-12" />
                        </div>
                      </>
                    )}
                  </div>
                ))}
            {employess.length <= 4 && (
              <>
                <div className="kt-space-20" />

                <div className="row">
                  <div className="col-12">
                    <Button
                      size="small"
                      variant="outlined"
                      fullWidth
                      onClick={() => push(newInstaller)}
                    >
                      <FormattedMessage id="Agregar nuevo instalador" />
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className="kt-space-20" />
          </>
        )}
      </FieldArray>
    </>
  );
};

export const mapStateToProps = (state) => ({
  userInstallersOptions: getUserInstallersOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Installer);
