import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

export const Warranty = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12">
        <div>
          <FormattedMessage id="Código de garantía:" />{' '}
          {get(values, 'code', '-')}
        </div>
      </div>
      <div className="col-12">
        <div className="kt-space-20" />
      </div>
    </div>
  </>
);

export default Warranty;
