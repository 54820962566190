import { REQUEST_METHODS } from 'enums/request';
import createRequestSlice, {
  requestInitialState,
  getSliceRequestActionTypes,
  createRequestAction,
} from 'redux/request/reducer';

export const PREFIX = 'userInstallers';
export const PREFIX_EDIT = 'userInstallers.edit';

//TODO refactor this
export const initialState = {
  ...requestInitialState,
  data: [],
  isSuccess: false,
};

const userInstallersSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    clearSave(state) {
      return {
        ...state,
        isSaveSuccess: false,
        error: false,
      };
    },
    fetch(state, { payload }) {
      return {
        ...state,
        data: [...payload.employees],
        isSuccess: true,
      };
    },
    editInstallers(state) {
      return {
        ...state,
        isSaveSuccess: true,
      };
    },
  },
});

const actionTypes = getSliceRequestActionTypes(userInstallersSlice);

const editInstallers = createRequestAction({
  prefix: PREFIX_EDIT,
  method: REQUEST_METHODS.Create,
  actions: {
    ...actionTypes,
    success: userInstallersSlice.actions.editInstallers.type,
  },
});

export const actions = { ...userInstallersSlice.actions };
export const requests = { ...userInstallersSlice.requests, editInstallers };

export default userInstallersSlice.reducer;
