import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'contact';

export const initialState = {
  ...requestInitialState,
  data: {},
  isSuccess: false,
};

const contactSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    create(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
  },
});

export const actions = { ...contactSlice.actions };
export const requests = { ...contactSlice.requests };

export default contactSlice.reducer;
