import './index.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';

import TextField from 'components/form/input';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  getUserInstallers,
  getStatusInstallers,
} from 'redux/userInstallers/selector';
import {
  requests as userInstallersRequest,
  actions as userInstallersActions,
} from 'redux/userInstallers/reducer';

import ProfileNav from '../profileNav';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const newInstaller = {
  name: '',
  id: -1,
};

const initialValues = {
  installers: [newInstaller],
};

const validationSchema = (intl) =>
  Yup.object().shape({
    installers: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(
          intl.formatMessage({ id: 'Debe ingresar un nombre' }),
        ),
      }),
    ),
  });
const breadcrumb = [{ page: 'profile/resume', title: 'Mis datos' }];

export const InstallersViews = ({
  intl,
  fetch,
  clear,
  onSubmit,
  installers,
  isLoading,
  hasError,
  isSuccess,
  isSaveSuccess,
  clearSave,
}) => {
  useEffect(() => {
    fetch();
    return () => clear();
  }, [fetch, clear]);

  useEffect(() => () => clearSave(), [clearSave]);
  useEffect(() => {
    if (!isLoading && isSaveSuccess) {
      toast.success(
        <FormattedMessage id="La información ha sido actualizada." />,
        toastConfig,
      );
    }
  }, [isLoading, isSaveSuccess]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se ha producido un error, vuelva a intentar más tarde." />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const history = useHistory();

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return onSubmit(values);
  };

  return (
    <Layout>
      {Boolean(isSuccess) && (
        <Formik
          initialValues={{ ...initialValues, installers }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema(intl)}
        >
          {({ values }) => (
            <Form>
              <div className="InstallersViews">
                <div className="kt-space-20" />
                <div className="row">
                  <div className="col-12">
                    <div className="kt-space-20" />
                    <Breadcrumbs title="Mis datos" items={breadcrumb} />
                    <div className="kt-space-20" />
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Portlet className="kt-portlet--height-fluid">
                        <PortletBody>
                          <ProfileNav active="installers" />
                        </PortletBody>
                      </Portlet>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Portlet className="kt-portlet--height-fluid">
                        <PorletHeader>
                          <PorletHeaderTitle variant="h4">
                            <FormattedMessage id="Instaladores" />
                          </PorletHeaderTitle>
                        </PorletHeader>
                        <PortletBody>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-1" />
                            <div className="col-sm-12 col-md-12 col-lg-10">
                              <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4" />
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Typography variant="h5">
                                    <FormattedMessage id="Listado de Instaladores" />
                                  </Typography>
                                </div>
                              </div>
                              <div className="row FormRow">
                                <div className="col-12" />
                              </div>

                              <FieldArray name="installers">
                                {({ push, remove }) => (
                                  <>
                                    {values.installers.map(
                                      (installer, index) => {
                                        return (
                                          <div
                                            className="row FormRow"
                                            key={index}
                                          >
                                            <div className="col-sm-12 col-md-12 col-lg-4">
                                              <strong>
                                                <FormattedMessage id="Nombre: *" />
                                              </strong>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                              <Field
                                                name={`installers[${index}].name`}
                                                component={TextField}
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Nombre del instalador"
                                                className="customPadding"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-2">
                                              {values.installers.length > 1 && (
                                                <Button
                                                  variant="outlined"
                                                  size="large"
                                                  fullWidth
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon color="primary" />

                                                  <FormattedMessage id="Eliminar" />
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                    <div className="row FormRow">
                                      <div className="col-sm-12 col-md-12 col-lg-4" />
                                      <div className="col-sm-12 col-md-12 col-lg-8">
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          onClick={() => push(newInstaller)}
                                        >
                                          <FormattedMessage id="Agregar nuevo instalador" />
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-1" />
                          </div>
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Portlet className="kt-portlet--height-fluid">
                      <PortletBody>
                        <div className="submit-bottons">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Guardar" />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.goBack()}
                          >
                            <FormattedMessage id="Volver" />
                          </Button>
                        </div>
                        {hasError && (
                          <Typography color="primary">
                            <br />
                            <FormattedMessage id="Se a producido un error, vuelva a intentar" />
                          </Typography>
                        )}
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(userInstallersRequest.fetch()),
  clear: () => dispatch(userInstallersActions.clear()),
  clearSave: () => dispatch(userInstallersActions.clearSave()),
  onSubmit: (values) =>
    dispatch(userInstallersRequest.editInstallers({ body: values })),
});

export default injectIntl(
  connect(
    (state) => ({
      installers: getUserInstallers(state),
      ...getStatusInstallers(state),
    }),
    mapDispatchToProps,
  )(InstallersViews),
);
