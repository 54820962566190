import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';
import moment from 'moment';

const formatDate = 'DD/MM/YYYY';
export const Status = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12 ViewOrEdit">
        <div>
          <strong>
            <FormattedMessage id="Estado del proyecto:" />
          </strong>
          <span>
            <FormattedMessage id={get(values, 'data.status', '-')} />
          </span>
        </div>
        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Fecha estimada de cierre:" />
          </strong>
          <span>{moment(get(values, 'data.ecd', '')).format(formatDate)}</span>
        </div>
      </div>
    </div>
  </>
);

export default Status;
