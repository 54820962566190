import * as Yup from 'yup';
import 'utils/phoneValidator';

export default (intl) =>
  Yup.object().shape({
    type: Yup.string().required(
      intl.formatMessage({ id: 'Debe seleccionar un tipo' }),
    ),
    firstName: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un nombre' }),
    ),
    // lastName: Yup.string().required(
    //   intl.formatMessage({ id: 'Debe ingresar un apellido' }),
    // ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
      .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
    dealerName: Yup.string().when('type', {
      is: (type) => type != 'Usuario',
      then: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
    }),
    // cellphone: Yup.string()
    //   .phone(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }))
    //   .required(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' })),
    // state: Yup.string()
    //   .required(intl.formatMessage({ id: 'Debe seleccionar una provincia' }))
    //   .typeError(intl.formatMessage({ id: 'Debe seleccionar una provincia' })),
    // city: Yup.string()
    //   .required(intl.formatMessage({ id: 'Debe seleccionar una ciudad' }))
    //   .typeError(intl.formatMessage({ id: 'Debe seleccionar una ciudad' })),
  });
