import './exp.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { toAbsoluteUrl } from '_metronic';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actions as i18nActions } from '_metronic/ducks/i18n';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const API_URL = window.env.API_URL;

function ExperienciaBuildingThanks({ intl, setLanguage }) {
  const queryParams = useQuery();

  useEffect(() => {
    const lang = queryParams.get('lang');
    if (lang) {
      setLanguage(lang);
    }
  }, [queryParams, setLanguage]);

  return (
    <>
      <div className="experiencia-bk">
        <div id="auto" className="experiencia">
          <div className="header">
            <img
              width="250"
              height="44"
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/logo-es-blanco.png')}
              // src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-BLANCO' }))}
            />
            <img
              height="44"
              alt="Logo"
              src={toAbsoluteUrl('/mails/auto-blanco.png')}
            />
          </div>
          <div className="row">
            <div className="container">
              <div className="titulo">
                <h1>
                  <FormattedMessage id="¡Gracias por calificar a tu instalador!" />
                </h1>
              </div>
              <div className="content">
                <h1 className="colored">
                  <FormattedMessage id="¡Recibimos tu respuesta!" />
                </h1>
                <h4>
                  <FormattedMessage id="Tu opinión nos ayuda a seguir mejorando." />
                </h4>
                <h4>
                  <FormattedMessage id="Ya protegiste tu automóvil, ahora conoce las soluciones de" />
                  <br />
                  <FormattedMessage id="Window Films 3M™ para tu arquitectura" />
                </h4>
              </div>
              <div className="form">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-1" />
                  <div className="col-sm-12 col-md-12 col-lg-10">
                    <div className="row FormRow">
                      <div className="col-12">
                        <a
                          href={intl.formatMessage({
                            id: 'VISIT_WEBSITE_AUTO_EXPERIENCE_LINK',
                          })}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            className="SubmitForm"
                          >
                            <FormattedMessage id="Visitar sitio web" />
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(i18nActions.setLanguage(lang)),
});
export default injectIntl(
  connect(undefined, mapDispatchToProps)(ExperienciaBuildingThanks),
);

// export default ExperienciaBuildingThanks;
