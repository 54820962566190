import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

export const Contact = ({ values }) => (
  <div className="row">
    <div className="col-12 ViewOrEdit">
      <div>
        <strong>
          <FormattedMessage id="Nombre:" />
        </strong>
        <span>{get(values, 'users[0].firstname', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Correo Electronico:" />
        </strong>
        <span>{get(values, 'users[0].email', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Celular:" />
        </strong>
        <span>{get(values, 'cellphone', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Telefono Fijo:" />
        </strong>
        <span>{get(values, 'phone', '-')}</span>
      </div>
    </div>
  </div>
);

export default Contact;
