/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import UserProfile from 'components/partials/layout/UserProfile';
import { getUserRole } from 'redux/profile/selector';
import { connect } from 'react-redux';
import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';

// import tawkTo from 'tawkto-react';
// const tawkToPropertyId = '605ea01bf7ce182709346dd5';
// const tawkToKey = '1f1oq2rpd';

// const tawkToPropertyIdBr = '60a2429cb1d5182476b98df9';
// const tawkToKeyBr = '1f5st8dbu';

class Topbar extends React.Component {
  // componentDidMount() {
  //   tawkTo(tawkToPropertyId, tawkToKey);
  // }

  componentDidMount() {
    let lang = 'es';

    if (this.props.country === 'ES' || this.props.country === 'es') {
      lang = 'es';
    } else if (this.props.country === 'PT' || this.props.country === 'pt') {
      lang = 'pt';
    } else if (this.props.country === 'FR' || this.props.country === 'fr') {
      lang = 'fr';
    } else if (this.props.country === 'DE' || this.props.country === 'DE') {
      lang = 'de';
    } else if (indexOf(EUROPE, this.props.country) >= 0) {
      lang = 'en';
    } else if (this.props.country === 'BR' || this.props.country === 'br') {
      lang = 'pt';
    } else {
      lang = 'es';
    }

    console.log('Language', lang);
    // Include the Crisp code here, without the <script></script> tags
    if (window.location.pathname == '/dashboard') {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '4bc3c3c3-f49a-48ae-bed5-04fca6df6f3e';
      window.CRISP_RUNTIME_CONFIG = {
        locale: lang,
      };

      /*const ACCESS_TOKEN = window.env.ACCESS_TOKEN;
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN); // su access_token JWT

    // Decodificar el token y obtener el payload
    const Decode = jwt_decode(accessToken);
    const payload = Decode.user.uuid;

    // Extraer el UUID del payload
    const userId = payload;

    // Establecer el identificador del usuario en Crisp
    window.$crisp.push(["set", "user:sessionid", userId])*/

      window.CRISP_READY_TRIGGER = function () {
        if (window.$crisp.is('chat:opened') == true) {
          console.log('chat abierto');
          window.$crisp.push(['do', 'session:reset']);
          var userId = window.$crisp.get('session:identifier');
          console.log(userId);
        }
      };

      (function () {
        var d = document;
        var s = d.createElement('script');

        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }
  componentWillUnmount() {
    /* window.$crisp.push(['do', 'chat:hide']);
    window.$crisp.push(['do', 'session:reset']);*/
    if (window.location.pathname == '/login') {
      window.location.reload(false);
    }
    console.log('Adios');
  }

  render() {
    return (
      <div className="kt-header__topbar">
        <UserProfile showAvatar={false} showHi={true} showBadge={true} />
      </div>
    );
  }
}

const mapStateProps = (state) => ({
  ...getUserRole(state),
});

export default connect(mapStateProps)(Topbar);
