import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';
import moment from 'moment';

const formatDate = 'DD/MM/YYYY';
export const Status = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12">
        <div>
          <FormattedMessage id="Estado del proyecto:" />{' '}
          <FormattedMessage id={get(values, 'data.status', '-')} />
        </div>
        <div className="kt-space-20" />
        <div>
          <FormattedMessage id="Fecha estimada de cierre:" />{' '}
          {moment(get(values, 'data.ecd', '')).format(formatDate)}
        </div>
      </div>
      <div className="col-12">
        <div className="kt-space-20" />
      </div>
    </div>
  </>
);

export default Status;
