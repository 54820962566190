import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { requests as dashboardRequests } from 'redux/dashboard/reducer';
import { getDashboardStatus } from 'redux/dashboard/selector';
import moment from 'moment';
import Range from 'components/rangePicker';

const formatDate = 'YYYY-MM-DD';
const RangePicker = ({ isLoading, fetchDashboard, ...props }) => {
  const [value, setValue] = useState([moment().startOf('year'), moment()]);

  useEffect(() => {
    const query = {
      from: value[0].format(formatDate),
      to: value[1].format(formatDate),
    };
    fetchDashboard({ query });
  }, [fetchDashboard, value]);
  return <Range value={value} onChange={setValue} {...props} />;
};
export default connect(getDashboardStatus, (dispatch) => ({
  fetchDashboard: (data) => dispatch(dashboardRequests.fetch(data)),
}))(RangePicker);
