import get from 'lodash/get';
import noop from 'lodash/noop';

import {
  take,
  put,
  takeEvery,
  call,
  all,
  select,
  cancelled,
  cancel,
  race,
} from 'redux-saga/effects';

import { XHR_STATUS_CODE } from 'enums/xhr';
import { makeRequest } from 'services/request';
import { xhrAction, getRequestActions } from './reducer';
import {
  getIsTokenExpired,
  getRefreshToken,
  getRequestHeaders,
} from '../auth/selector';
import {
  actions as authActions,
  requests as authRequests,
} from '../auth/reducer';
import { getNextCursor, getQuery } from './selector';

// TODO default pagination
export function* getOptions(action) {
  return {
    headers: yield select(getRequestHeaders),
    params: {
      cursor: get(action, 'meta.append')
        ? yield select(getNextCursor(action))
        : noop(),
      ...(yield select(getQuery(action.meta))),
    },
  };
}

export function* validateSession(action) {
  const { isTokenExpired } = yield select(getIsTokenExpired);
  if (!isTokenExpired) {
    yield put(authActions.setTokenExpire(true));
    const refreshToken = yield select(getRefreshToken);
    yield put(authRequests.refresh({ body: refreshToken }));
  }

  const { success, fail } = yield race({
    fail: take(authActions.failure.type),
    success: take(authActions.refresh.type),
  });

  yield take(authActions.fulfill.type);
  if (fail) yield put(authActions.logOut());
  if (success) yield call(xhrRequest, action);
}

// TODO Create Error pages
// export function* setErrorPage(error): Generator {
//   const statusCode = get(error, 'response.status');
//   const statusText = get(error, 'response.statusText');
//   const errorMessage = get(error, 'response.data.messages[0]');

//   switch (statusCode) {
//     case XHR_STATUS_CODE.Forbidden:
//       yield put(setForbidden({ error: statusText, info: errorMessage }));
//       break;
//     default:
//       yield put(setServerError({ error: statusText, info: errorMessage }));
//   }
// }

export function* xhrRequest(action) {
  const actions = yield call(getRequestActions, action);
  const { request, success, failure, fulfill } = actions;
  const { payload, meta } = action;
  const trigger = { payload, meta };

  try {
    yield put(request(noop(), { trigger }));
    const options = yield call(getOptions, action);
    const response = yield call(makeRequest, options, action);

    yield put(success(response.data, { trigger }));
  } catch (error) {
    const statusCode = get(error, 'response.status');
    const data = get(error, 'response.data');
    if (
      statusCode === XHR_STATUS_CODE.Unauthorized &&
      action.meta.actions.success !== authActions.refresh.type &&
      action.meta.actions.success !== authActions.create.type
    ) {
      yield call(validateSession, action);
      yield cancel();
      // } else {
      // TODO: CREATE ERROR PAGES
      // yield call(setErrorPage, error, action);
    }

    yield put(failure(data, { trigger }));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(fulfill(noop(), { trigger }));
      const { isTokenExpired } = yield select(getIsTokenExpired);
      if (isTokenExpired) {
        yield put(authActions.setTokenExpire(false));
      }
    }
  }
}

export default function* () {
  yield all([takeEvery(xhrAction.type, xhrRequest)]);
}
