export const glassFinishesCategories = [
  {
    label: '3M™ CRYSTAL Dusted and Frosted Glass Finishes',
    value: '3M™ CRYSTAL Dusted and Frosted',
  },
  { label: '3M™ DICHROIC™', value: '3M™ DICHROIC™' },
  { label: '3M™ DI-NOC™ Glass Finishes', value: '3M™ DI-NOC™ for glass' },
  { label: '3M™ FASARA™ Glass Finishes', value: '3M™ FASARA™ Glass Finishes' },
  { label: 'Custom', value: 'Custom' },
].sort(compareLabel);

export const glassFinishesFamilies = [
  { label: 'Custom', value: 'Custom', category: 'Custom' },
  {
    label: '3M™ CRYSTAL Dusted and Frosted',
    value: '3M™ CRYSTAL Dusted and Frosted',
    category: '3M™ CRYSTAL Dusted and Frosted',
  },
  {
    label: '3M™ DICHROIC™',
    value: '3M™ DICHROIC™',
    category: '3M™ DICHROIC™',
  },
  {
    label: '3M™ DI-NOC™ Glass Finishes',
    value: '3M™ DI-NOC™ for glass',
    category: '3M™ DI-NOC™ for glass',
  },
  {
    value: 'Fabric',
    label: 'Fabric',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
  {
    value: 'Frost_Matte',
    label: 'Frost Matte',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },

  {
    value: 'Washi',
    label: 'Washi',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
  {
    value: 'Reflect/Hairline',
    label: 'Reflect/Hairline',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
  {
    value: 'Gradation',
    label: 'Gradation',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
  {
    value: 'Natural',
    label: 'Natural',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
  {
    value: 'Stripe',
    label: 'Stripe',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },

  {
    value: 'Border/Dot/Geometric',
    label: 'Border/Dot/Geometric',
    category: '3M\u2122 FASARA\u2122 Glass Finishes',
  },
].sort(compareLabel);

export const glassFinishesFilms = [
  {
    family: '3M™ DICHROIC™',
    value: 'PA-Blaze',
    label: 'DICHROIC PA Blaze',
    image: 'PA-Blaze.jpg',
  },
  {
    family: '3M™ DICHROIC™',
    value: 'PA-Chill',
    label: 'DICHROIC PA Chill',
    image: 'PA-Chill.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '7725-324',
    label: 'Frosted White 7725 324',
    image: '7725-324.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '7725-331',
    label: 'Frosted Gold 7725 331',
    image: '7725-331.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: 'dusted_crystal_',
    label: 'Dusted White 7725 314',
    image: 'dusted_crystal_.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '5525-314_Haze',
    label: 'Crystal Haze 5525 314',
    image: '5525-314_Haze.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '5525-324_Sandblast',
    label: 'Crystal Sandblast 5525 324',
    image: '5525-324_Sandblast.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'AE-1719DG_TH',
    label: 'AE-1719DG',
    image: 'AE-1719DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-1716_l',
    label: 'ME-1716DG',
    image: 'DG-1716_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-379_l',
    label: 'ME-379DG',
    image: 'DG-379_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-432_l',
    label: 'ME-432DG',
    image: 'DG-432_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1156DG_TH',
    label: 'FA-1156DG',
    image: 'FA-1156DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1161DG_TH',
    label: 'FA-1161DG',
    image: 'FA-1161DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1528DG_TH',
    label: 'FA-1528DG',
    image: 'FA-1528DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1678DG_TH',
    label: 'FA-1678DG',
    image: 'FA-1678DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1022DG_TH',
    label: 'FW-1022DG',
    image: 'FW-1022DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1129DG_TH',
    label: 'FW-1129DG',
    image: 'FW-1129DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1751DG_TH',
    label: 'FW-1751DG',
    image: 'FW-1751DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'NU-1786DG_TH',
    label: 'NU-1786DG',
    image: 'NU-1786DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'PS-503DG_TH',
    label: 'PS-503DG',
    image: 'PS-503DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'PS-504DG_TH',
    label: 'PS-504DG',
    image: 'PS-504DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'SI-1613DG_TH',
    label: 'SI-1613DG',
    image: 'SI-1613DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'SI-1689DG_TH',
    label: 'SI-1689DG',
    image: 'SI-1689DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'ST-1587DG_TH',
    label: 'ST-1587DG',
    image: 'ST-1587DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'ST-1831DG_TH',
    label: 'ST-1831DG',
    image: 'ST-1831DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'MW-1419DG',
    label: 'MW-1419DG',
    image: 'MW-1419DG.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'ME-1434DG_TH',
    label: 'ME-1434DG',
    image: 'ME-1434DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'PA-180AR_TH',
    label: 'PA-180DG',
    image: 'PA-180AR_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'RT-1827DG_TH',
    label: 'RT-1827DG',
    image: 'RT-1827DG_TH.jpg',
  },
  { family: 'Custom', value: 'Custom', label: 'Custom', image: 'Custom.jpg' },
  {
    family: 'Gradation',
    value: 'SH2FGCNB',
    label: 'Cloud Narrow SH2FGCNB',
    image: 'SH2FGCNB.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGCND',
    label: 'Cloud Narrow SH2FGCND',
    image: 'SH2FGCND.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGCNL',
    label: 'Cloud Narrow SH2FGCNL',
    image: 'SH2FGCNL.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2FGVN',
    label: 'Venetian SH2FGVN',
    image: 'FAS-SH2FGVN.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2SIIM',
    label: 'Illumina SH2SIIM',
    image: 'FAS-SH2SIIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2BKIM',
    label: 'Illumina SH2BKIM',
    image: 'SH2BKIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGAR',
    label: 'Aerina SH2FGAR',
    image: 'SH2FGAR.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGCN',
    label: 'Cloud Narrow SH2FGCN',
    image: 'SH2FGCN.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGDM',
    label: 'Diamond SH2FGDM',
    image: 'SH2FGDM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGIM-G',
    label: 'Illumina-g SH2FGIM G',
    image: 'SH2FGIM-G.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGIM',
    label: 'Illumina SH2FGIM',
    image: 'SH2FGIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGLO',
    label: 'Lontano SH2FGLO',
    image: 'SH2FGLO.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGSB',
    label: 'Sabrina SH2FGSB',
    image: 'SH2FGSB.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGTG',
    label: 'Tsurugi SH2FGTG',
    image: 'SH2FGTG.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-W',
    label: 'Illumina SH2SSIM-W',
    image: 'SH2SSIM-W.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgmi-w-la',
    label: 'Mist-W SH2FGMI-W',
    image: 'sh2fgmi-w-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgmi-s-la',
    label: 'Mist-S SH2FGMI-S',
    image: 'sh2fgmi-s-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgmi-pr-la',
    label: 'Mist-PR SH2FGMI-PR',
    image: 'sh2fgmi-pr-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgpp-w-la',
    label: 'Pop-W SH2FGPP-W',
    image: 'sh2fgpp-w-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgpp-s-la',
    label: 'Pop-S SH2FGPP-S',
    image: 'sh2fgpp-s-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgov-la',
    label: 'Oval SH2FGOV',
    image: 'sh2fgov-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgsd-la',
    label: 'Sand SH2FGSD',
    image: 'sh2fgsd-la.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgcl-th',
    label: 'Cloud SH2FGCL',
    image: 'sh2fgcl-th.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-S',
    label: 'Ilumina SH2SSIM-S',
    image: 'SH2SSIM-S.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-W2',
    label: 'Illumina SH2SSIM-W2',
    image: 'SH2SSIM-W2.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-S2',
    label: 'Illumina SH2SSIM-S2',
    image: 'SH2SSIM-S2.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-PR',
    label: 'Ilumina SH2SSIM-PR',
    image: 'SH2SSIM-PR.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH4FGIMX',
    label: 'Ilumina a SH4FGIMX',
    image: 'FAS-SH4FGIMX.jpg',
  },
  {
    family: 'Gradation',
    value: 'sh2fgim-pr-th',
    label: 'Ilumina-PR SH2FGIM-PR',
    image: 'sh2fgim-pr-th.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2PTSG',
    label: 'Shigure SH2PTSG',
    image: 'SH2PTSG.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2GRSG',
    label: 'Shigure Gray SH2GRSG',
    image: 'SH2GRSG.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2BKSG',
    label: 'Shigure Black SH2BKSG',
    image: 'SH2BKSG.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2PTFB',
    label: 'Fibril SH2PTFB',
    image: 'SH2PTFB.jpg',
  },
  {
    family: 'Natural',
    value: 'sh2fgbr-th',
    label: 'Brushed SH2FGBR',
    image: 'sh2fgbr-th.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2BKBR',
    label: 'Brushed Black SH2BKBR',
    image: 'SH2BKBR.jpg',
  },
  {
    family: 'Natural',
    value: 'sh2fgtv-th',
    label: 'Travertine SH2FGTV',
    image: 'sh2fgtv-th.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2BKTV',
    label: 'Travertine Black SH2BKTV',
    image: 'SH2BKTV.jpg',
  },
  {
    family: 'Natural',
    value: 'sh2fgtz-th',
    label: 'Terrazo SH2FGTZ',
    image: 'sh2fgtz-th.jpg',
  },
  {
    family: 'Natural',
    value: 'SH2BKTZ',
    label: 'Terrazo Black SH2BKTZ',
    image: 'SH2BKTZ.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAOW',
    label: 'Opaque SH2MAOW',
    image: 'SH2MAOW.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMCH',
    label: 'Chamonix SH2EMCH',
    image: 'SH2EMCH.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMES',
    label: 'Essen SH2EMES',
    image: 'SH2EMES.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMLA',
    label: 'Lausanne SH2EMLA',
    image: 'SH2EMLA.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMOS',
    label: 'Oslo SH2EMOS',
    image: 'SH2EMOS.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGCE',
    label: 'Cielo SH2FGCE',
    image: 'SH2FGCE.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGLU',
    label: 'Luce SH2FGLU',
    image: 'SH2FGLU.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGMR',
    label: 'Mare SH2FGMR',
    image: 'SH2FGMR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FNCR',
    label: 'Fine Crystal SH2FNCR',
    image: 'SH2FNCR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MACR-I',
    label: 'Matte Crystal-i SH2MACR-I',
    image: 'SH2MACR-I.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MACRX',
    label: 'Matte Crystal 2 SH2MACRX2',
    image: 'SH2MACRX.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAGL',
    label: 'Glace SH2MAGL',
    image: 'SH2MAGL.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAML',
    label: 'Milky White SH2MAML',
    image: 'SH2MAML.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAMM',
    label: 'Milky Milky SH2MAMM',
    image: 'SH2MAMM.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MLCRX',
    label: 'Milky Crystal SH2MLCRX',
    image: 'SH2MLCRX.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FRMAX',
    label: 'Frost Matte SH2FRMAX',
    image: 'SH2FRMAX.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FRSCX',
    label: 'Frost Sucre SH2FRSCX',
    image: 'SH2FRSCX.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'WH-111-G',
    label: 'Whiteboard WH-111-G',
    image: 'WH-111-G.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2MAMML',
    label: 'Milky Milky SH2MAMML',
    image: 'SH2MAMML.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2MAMMD',
    label: 'Milky Milky SH2MAMMD',
    image: 'SH2MAMMD.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2MAMMB',
    label: 'Milky Milky SH2MAMMB',
    image: 'SH2MAMMB.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2BKOP',
    label: 'Opaque SH2BKOP',
    image: 'SH2BKOP.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2HLMA',
    label: 'Hairline SH2HLMA',
    image: 'SH2HLMA.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'FAS-RE1SIAR',
    label: 'Reflect RE1SIAR',
    image: 'FAS-RE1SIAR.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2SICMR',
    label: 'Chain Mesh SH2SICMR',
    image: 'SH2SICMR.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2CHMA',
    label: 'Cross Hairline SH2CHMA',
    image: 'SH2CHMA.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2CHSC',
    label: 'Cross Hairline SH2CHSC',
    image: 'SH2CHSC.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2CHML',
    label: 'Cross Hairline SH2CHML',
    image: 'SH2CHML.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'FAS-SH2PTFW',
    label: 'Frost Walnut SH2PTFW',
    image: 'FAS-SH2PTFW.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'FAS-SH2PTFWS',
    label: 'Frost Walnut SH2PTFWS',
    image: 'FAS-SH2PTFWS.jpg',
  },
  {
    family: 'Reflect/Hairline',
    value: 'SH2CHMAL',
    label: 'Cross Hairline SH2CHMAL',
    image: 'SH2CHMAL.jpg',
  },
  {
    family: 'Washi',
    value: 'FAS-SH2FGAT',
    label: 'Altair SH2FGAT',
    image: 'FAS-SH2FGAT.jpg',
  },
  {
    family: 'Washi',
    value: 'FAS-SH2PTPG',
    label: 'Pyrgos SH2PTPG',
    image: 'FAS-SH2PTPG.jpg',
  },
  {
    family: 'Washi',
    value: 'FAS-SH2PTRK',
    label: 'Rikyu SH2PTRK',
    image: 'FAS-SH2PTRK.jpg',
  },
  {
    family: 'Washi',
    value: 'FAS-SH2PTSA2',
    label: 'Sagano SH2PTSA2',
    image: 'FAS-SH2PTSA2.jpg',
  },
  {
    family: 'Washi',
    value: 'FAS-SH2PTYA',
    label: 'Yamato SH2PTYA',
    image: 'FAS-SH2PTYA.jpg',
  },
  {
    family: 'Washi',
    value: 'SH2FGSW',
    label: 'Straight Washi SH2FGSW',
    image: 'SH2FGSW.jpg',
  },
  {
    family: 'Washi',
    value: 'SH2FGVG',
    label: 'Vega SH2FGVG',
    image: 'SH2FGVG.jpg',
  },
  {
    family: 'Washi',
    value: 'SH2PTSF',
    label: 'Safu SH2PTSF',
    image: 'SH2PTSF.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2EMWG',
    label: 'Weave SH2EMWG',
    image: 'FAS-SH2EMWG.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2FGLN',
    label: 'Linen SH2FGLN',
    image: 'FAS-SH2FGLN.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2CSCVC',
    label: 'Canvas SH2CSCVC',
    image: 'SH2CSCVC.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2CSSEC',
    label: 'Sensai Linen SH2CSSEC',
    image: 'SH2CSSEC.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2EMWP',
    label: 'Weave SH2EMWP',
    image: 'SH2EMWP.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGBU',
    label: 'Buckram SH2FGBU',
    image: 'SH2FGBU.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGBUG',
    label: 'Buckram SH2FGBUG',
    image: 'SH2FGBUG.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGCV',
    label: 'Canvas SH2FGCV',
    image: 'SH2FGCV.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGSE',
    label: 'Sensai Linen SH2FGSE',
    image: 'SH2FGSE.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2LNCR',
    label: 'Linen Crytal SH2LNCR',
    image: 'SH2LNCR.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2CSGC',
    label: 'Grain SH2CSGC',
    image: 'SH2CSGC.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGKN',
    label: 'Kanon SH2FGKN',
    image: 'FAS-SH2FGKN.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGSK',
    label: 'Shizuku SH2FGSK',
    image: 'FAS-SH2FGSK.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGVI',
    label: 'Vista SH2FGVI',
    image: 'FAS-SH2FGVI.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2PCA9',
    label: 'Aura 9 SH2PCA9',
    image: 'FAS-SH2PCA9.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2PCL6',
    label: 'Luna 6 SH2PCL6',
    image: 'FAS-SH2PCL6.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2PCL9',
    label: 'Luna 9 SH2PCL9',
    image: 'FAS-SH2PCL9.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2CSC',
    label: 'Cut Glass SH2CSC',
    image: 'FAS-SH2CSC.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2CSCP',
    label: 'Cut Glass SH2CSCP',
    image: 'FAS-SH2CSCP.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGLS',
    label: 'Leise SH2FGLS',
    image: 'FAS-SH2FGLS.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGPR',
    label: 'Paracell SH2FGPR',
    image: 'FAS-SH2FGPR.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'FAS-SH2FGSL-G',
    label: 'Slat-g SH2FGSL G',
    image: 'FAS-SH2FGSL-G.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'SH2FGSL',
    label: 'Slat SH2FGSL',
    image: 'SH2FGSL.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'SH2CSAS',
    label: 'Astral Silver SH2CSAS',
    image: 'SH2CSAS.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'SH2FGLT',
    label: 'Lattice SH2FGLT',
    image: 'SH2FGLT.jpg',
  },
  {
    family: 'Border/Dot/Geometric',
    value: 'SH2FGPX',
    label: 'Pixela SH2FGPX',
    image: 'SH2FGPX.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2BKAP',
    label: 'Arpa SH2BKAP',
    image: 'FAS-SH2BKAP.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2BKST',
    label: 'Shutie SH2BKST',
    image: 'FAS-SH2BKST.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2DGST-F',
    label: 'Seattle Fine SH2DGST-f',
    image: 'FAS-SH2DGST-F.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2DGST',
    label: 'Seattle SH2DGST',
    image: 'FAS-SH2DGST.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGAP',
    label: 'Arpa SH2FGAP',
    image: 'FAS-SH2FGAP.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGFN',
    label: 'Fine SH2FGFN',
    image: 'FAS-SH2FGFN.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGST',
    label: 'Shutie SH2FGST',
    image: 'FAS-SH2FGST.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2APCR',
    label: 'Arpa Crystal SH2APCR',
    image: 'SH2APCR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2CSFP',
    label: 'Fusion SH2CSFP',
    image: 'SH2CSFP.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTFS',
    label: 'Fine String SH2PTFS',
    image: 'SH2PTFS.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTSR',
    label: 'Safari SH2PTSR',
    image: 'SH2PTSR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTST',
    label: 'String SH2PTST',
    image: 'SH2PTST.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTSTR',
    label: 'String Reverse SH2PTSTR',
    image: 'SH2PTSTR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTWD',
    label: 'Wind SH2PTWD',
    image: 'SH2PTWD.jpg',
  },
].sort(compareLabel);
function compareLabel(a, b) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}
/*
[
  {
    family: '3M™ DICHROIC ™',
    value: 'PA-Blaze',
    label: 'DICHROIC PA Blaze',
    iamge: 'PA-Blaze.jpg',
  },
  {
    family: '3M™ DICHROIC ™',
    value: 'PA-Chill',
    label: 'DICHROIC PA Chill',
    iamge: 'PA-Chill.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '7725-324',
    label: 'Frosted White 7725 324',
    iamge: '7725-324.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '7725-331',
    label: 'Frosted Gold 7725 331',
    iamge: '7725-331.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: 'dusted_crystal_',
    label: 'Dusted Crystal 7725 314',
    iamge: 'dusted_crystal_.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '5525-314_Haze',
    label: 'Crystal Haze 5525 314',
    iamge: '5525-314_Haze.jpg',
  },
  {
    family: '3M™ CRYSTAL Dusted and Frosted',
    value: '5525-324_Sandblast',
    label: 'Crystal Haze 5525 324',
    iamge: '5525-324_Sandblast.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'AE-1719DG_TH',
    label: 'AE 1719DG',
    image: 'AE-1719DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-1716_l',
    label: 'ME-1716DG',
    image: 'DG-1716_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-379_l',
    label: 'ME-379DG',
    image: 'DG-379_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'DG-432_l',
    label: 'ME-432DG',
    image: 'DG-432_l.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1156DG_TH',
    label: 'FA-1156DG',
    image: 'FA-1156DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1161DG_TH',
    label: 'FA-1161DG',
    image: 'FA-1161DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1528DG_TH',
    label: 'FA-1528DG',
    image: 'FA-1528DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FA-1678DG_TH',
    label: 'FA-1678DG',
    image: 'FA-1678DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1022DG_TH',
    label: 'FW-1022DG',
    image: 'FW-1022DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1129DG_TH',
    label: 'FW-1129DG',
    image: 'FW-1129DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'FW-1751DG_TH',
    label: 'FW-1751DG',
    image: 'FW-1751DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'NU-1786DG_TH',
    label: 'NU-1786DG',
    image: 'NU-1786DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'PS-503DG_TH',
    label: 'PS-503DG',
    image: 'PS-503DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'PS-504DG_TH',
    label: 'PS-504DG',
    image: 'PS-504DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'SI-1613DG_TH',
    label: 'SI-1613DG',
    image: 'SI-1613DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'SI-1689DG_TH',
    label: 'SI-1689DG',
    image: 'SI-1689DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'ST-1587DG_TH',
    label: 'ST-1587DG',
    image: 'ST-1587DG_TH.jpg',
  },
  {
    family: '3M™ DI-NOC™ for glass',
    value: 'ST-1831DG_TH',
    label: 'ST-1831DG',
    image: 'ST-1831DG_TH.jpg',
  },
  { family: 'Custom', value: 'Custom', label: 'Custom', image: 'custom.jpg' },
  {
    family: 'Dot',
    value: 'FAS-SH2FGKN',
    label: 'Kanon SH2FGKN',
    image: 'FAS-SH2FGKN.jpg',
  },
  {
    family: 'Dot',
    value: 'FAS-SH2FGSK',
    label: 'Shizuku SH2FGSK',
    image: 'FAS-SH2FGSK.jpg',
  },
  {
    family: 'Dot',
    value: 'FAS-SH2FGVI',
    label: 'Vista SH2FGVI',
    image: 'FAS-SH2FGVI.jpg',
  },
  {
    family: 'Dot',
    value: 'FAS-SH2PCA9',
    label: 'Aura 9 SH2PCA9',
    image: 'FAS-SH2PCA9.jpg',
  },
  {
    family: 'Dot',
    value: 'FAS-SH2PCL6',
    label: 'Luna 6 SH2PCL6',
    image: 'FAS-SH2PCL6.jpg',
  },
  {
    family: 'Dot',
    value: 'FAS-SH2PCL9',
    label: 'Luna 9 SH2PCL9',
    image: 'FAS-SH2PCL9.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2CSBLC',
    label: 'Burlap SH2CSBLC',
    image: 'FAS-SH2CSBLC.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2EMWG',
    label: 'Weave SH2EMWG',
    image: 'FAS-SH2EMWG.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2FGBL',
    label: 'Burlap SH2FGBL',
    image: 'FAS-SH2FGBL.jpg',
  },
  {
    family: 'Fabric',
    value: 'FAS-SH2FGLN',
    label: 'Linen SH2FGLN',
    image: 'FAS-SH2FGLN.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2CSCVC',
    label: 'Canvas SH2CSCVC',
    image: 'SH2CSCVC.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2CSSEC',
    label: 'Sensai Linen SH2CSSEC',
    image: 'SH2CSSEC.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2EMWP',
    label: 'Weave SH2EMWP',
    image: 'SH2EMWP.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGBU',
    label: 'Buckram SH2FGBU',
    image: 'SH2FGBU.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGBUG',
    label: 'Buckram SH2FGBUG',
    image: 'SH2FGBUG.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGCV',
    label: 'Canvas SH2FGCV',
    image: 'SH2FGCV.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2FGSE',
    label: 'Sensai Linen SH2FGSE',
    image: 'SH2FGSE.jpg',
  },
  {
    family: 'Fabric',
    value: 'SH2LNCR',
    label: 'Linen Crytal SH2LNCR',
    image: 'SH2LNCR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'FAS-RE1SIAR',
    label: 'Reflect RE1SIAR',
    image: 'FAS-RE1SIAR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2BKOP',
    label: 'Opaque SH2BKOP',
    image: 'SH2BKOP.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMCH',
    label: 'Chamonix SH2EMCH',
    image: 'SH2EMCH.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMES',
    label: 'Essen SH2EMES',
    image: 'SH2EMES.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMLA',
    label: 'Lausanne SH2EMLA',
    image: 'SH2EMLA.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2EMOS',
    label: 'Oslo SH2EMOS',
    image: 'SH2EMOS.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGCE',
    label: 'Cielo SH2FGCE',
    image: 'SH2FGCE.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGLU',
    label: 'Luce SH2FGLU',
    image: 'SH2FGLU.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FGMR',
    label: 'Mare SH2FGMR',
    image: 'SH2FGMR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2FNCR',
    label: 'Fine Crystal SH2FNCR',
    image: 'SH2FNCR.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MACR-I',
    label: 'Matte Crystal-i SH2MACR I',
    image: 'SH2MACR-I.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MACRX',
    label: 'Matte Crystal 2 SH2MACRX',
    image: 'SH2MACRX.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAGL',
    label: 'Glace SH2MAGL',
    image: 'SH2MAGL.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAML',
    label: 'Milky White SH2MAML',
    image: 'SH2MAML.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAMM',
    label: 'Milky SH2MAMM',
    image: 'SH2MAMM.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAMMB',
    label: 'Milky Milky SH2MAMMB',
    image: 'SH2MAMMB.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAMMD',
    label: 'Milky Milky SH2MAMMD',
    image: 'SH2MAMMD.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAMML',
    label: 'Milky Milky SH2MAMML',
    image: 'SH2MAMML.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MAOW',
    label: 'Opaque SH2MAOW',
    image: 'SH2MAOW.jpg',
  },
  {
    family: 'Frost_Matte',
    value: 'SH2MLCRX',
    label: 'Milky Crystal SH2MLCRX',
    image: 'SH2MLCRX.jpg',
  },
  {
    family: 'Geometric',
    value: 'FAS-SH2CSC',
    label: 'Cut Glass SH2CSC',
    image: 'FAS-SH2CSC.jpg',
  },
  {
    family: 'Geometric',
    value: 'FAS-SH2CSCP',
    label: 'Cut Glass SH2CSCP',
    image: 'FAS-SH2CSCP.jpg',
  },
  {
    family: 'Geometric',
    value: 'FAS-SH2CSCS',
    label: 'Cut Glass SH2CSCS',
    image: 'FAS-SH2CSCS.jpg',
  },
  {
    family: 'Geometric',
    value: 'SH2FGGD',
    label: 'Grid SH2FGGD',
    image: 'SH2FGGD.jpg',
  },
  {
    family: 'Geometric',
    value: 'SH2SICMR',
    label: ' Chain Mesh SH2SICMR',
    image: 'SH2SICMR.jpg',
  },
  {
    family: 'Geometric',
    value: 'SH2SIGDR',
    label: 'Grid SH2SIGDR',
    image: 'SH2SIGDR.jpg',
  },
  {
    family: 'Glass Finishes Gradation',
    value: 'SH2FGCNB',
    label: 'Cloud Narrow SH2FGCNB',
    image: 'SH2FGCNB.jpg',
  },
  {
    family: 'Glass Finishes Gradation',
    value: 'SH2FGCND',
    label: 'Cloud Narrow SH2FGCND',
    image: 'SH2FGCND.jpg',
  },
  {
    family: 'Glass Finishes Gradation',
    value: 'SH2FGCNL',
    label: 'Cloud Narrow SH2FGCNL',
    image: 'SH2FGCNL.jpg',
  },
  {
    family: 'Emboss',
    value: 'Cross Hairline SH2CHMA',
    label: 'Emboss SH2CHMA',
    image: 'SH2CHMA.jpg',
  },
  {
    family: 'Emboss',
    value: 'Cross Hairline SH2CHMAB',
    label: 'Emboss SH2CHMAB',
    image: 'SH2CHMAB.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2CHMAL',
    label: 'Cross Hairline SH2CHMAL',
    image: 'SH2CHMAL.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2CHML',
    label: 'Cross Hairline SH2CHML',
    image: 'SH2CHML.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2CHSC',
    label: 'Cross Hairline SH2CHSC',
    image: 'SH2CHSC.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2HLMA',
    label: 'Hairline SH2HLMA',
    image: 'SH2HLMA.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2LWMA',
    label: 'Wave SH2LWMA',
    image: 'SH2LWMA.jpg',
  },
  {
    family: 'Emboss',
    value: 'SH2SMMA',
    label: 'Mosaic SH2SMMA',
    image: 'SH2SMMA.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2FGVN',
    label: 'Venetian SH2FGVN',
    image: 'FAS-SH2FGVN.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2SIDGR',
    label: 'Diamond Ghost SH2SIDGR',
    image: 'FAS-SH2SIDGR.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2SIDR',
    label: 'Diamon SH2SIDR',
    image: 'FAS-SH2SIDR.jpg',
  },
  {
    family: 'Gradation',
    value: 'FAS-SH2SIIM',
    label: 'Illumina SH2SIIM',
    image: 'FAS-SH2SIIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2BKIM',
    label: 'Illumina SH2BKIM',
    image: 'SH2BKIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGAR',
    label: 'Aerina SH2FGAR',
    image: 'SH2FGAR.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGCN',
    label: 'Cloud Narrow SH2FGCN',
    image: 'SH2FGCN.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGDM',
    label: 'Diamond SH2FGDM',
    image: 'SH2FGDM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGIM-G',
    label: 'Illumina-g SH2FGIM G',
    image: 'SH2FGIM-G.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGIM',
    label: 'Illumina SH2FGIM',
    image: 'SH2FGIM.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGLO',
    label: 'Lonteno SH2FGLO',
    image: 'SH2FGLO.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGSB',
    label: 'Sabrina SH2FGSB',
    image: 'SH2FGSB.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2FGTG',
    label: 'Tsurugi SH2FGTG',
    image: 'SH2FGTG.jpg',
  },
  {
    family: 'Gradation',
    value: 'SH2SSIM-W',
    label: 'Illumina SH2SSIM W',
    image: 'SH2SSIM-W.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2FGAT',
    label: 'Altair SH2FGAT',
    image: 'FAS-SH2FGAT.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTFW',
    label: 'Frost Walnut SH2PTFW',
    image: 'FAS-SH2PTFW.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTFWS',
    label: 'Frost Walnut SH2PTFWS',
    image: 'FAS-SH2PTFWS.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTPG',
    label: 'Prygos SH2PTPG',
    image: 'FAS-SH2PTPG.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTRK',
    label: 'Rikyu SH2PTRK',
    image: 'FAS-SH2PTRK.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTSA2',
    label: 'Sagano SH2PTSA2',
    image: 'FAS-SH2PTSA2.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'FAS-SH2PTYA',
    label: 'Yamato SH2PTYA',
    image: 'FAS-SH2PTYA.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'SH2CSGC',
    label: 'Grain SH2CSGC',
    image: 'SH2CSGC.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'SH2CSGS',
    label: 'Grain SH2CSGS',
    image: 'SH2CSGS.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'SH2FGSW',
    label: 'Straight Washi SH2FGSW',
    image: 'SH2FGSW.jpg',
  },
  {
    family: 'Natural_Washi',
    value: 'SH2FGVG',
    label: 'Vega SH2FGVG',
    image: 'SH2FGVG.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2BKAP',
    label: 'Arp SH2BKAP',
    image: 'FAS-SH2BKAP.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2BKST',
    label: 'Shutie SH2BKST',
    image: 'FAS-SH2BKST.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2DGST-F',
    label: 'Seattle Fine SH2DGST F',
    image: 'FAS-SH2DGST-F.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2DGST',
    label: 'Seattle SH2DGST',
    image: 'FAS-SH2DGST.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGAP',
    label: 'Arpa SH2FGAP',
    image: 'FAS-SH2FGAP.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGFN',
    label: 'Fine SH2FGFN',
    image: 'FAS-SH2FGFN.jpg',
  },
  {
    family: 'Stripe',
    value: 'FAS-SH2FGST',
    label: 'Shutie SH2FGST',
    image: 'FAS-SH2FGST.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2APCR',
    label: 'Arpa Crystal SH2APCR',
    image: 'SH2APCR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2CSFP',
    label: 'Fusion SH2CSFP',
    image: 'SH2CSFP.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTFS',
    label: 'Fine String SH2PTFS',
    image: 'SH2PTFS.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTSR',
    label: 'Safari SH2PTSR',
    image: 'SH2PTSR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTST',
    label: 'String SH2PTST',
    image: 'SH2PTST.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTSTR',
    label: 'String Reverse SH2PTSTR',
    image: 'SH2PTSTR.jpg',
  },
  {
    family: 'Stripe',
    value: 'SH2PTWD',
    label: 'Wind SH2PTWD',
    image: 'SH2PTWD.jpg',
  },
  {
    family: 'Stripe_Border',
    value: 'FAS-SH2FGLS',
    label: 'Leise SH2FGLS',
    image: 'FAS-SH2FGLS.jpg',
  },
  {
    family: 'Stripe_Border',
    value: 'FAS-SH2FGPR',
    label: 'Paracell SH2FGPR',
    image: 'FAS-SH2FGPR.jpg',
  },
  {
    family: 'Stripe_Border',
    value: 'FAS-SH2FGSL-G',
    label: 'Slat-g SH2FGSL G',
    image: 'FAS-SH2FGSL-G.jpg',
  },
  {
    family: 'Stripe_Border',
    value: 'SH2FGSL',
    label: 'Slat SH2FGSL',
    image: 'SH2FGSL.jpg',
  },
];*/
