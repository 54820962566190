import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'brands';

export const initialState = {
  ...requestInitialState,
  data: [],
};

const brandsSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    fetch(state, { payload }) {
      return {
        ...state,
        data: [...payload],
      };
    },
  },
});

export const actions = { ...brandsSlice.actions };
export const requests = { ...brandsSlice.requests };

export default brandsSlice.reducer;
