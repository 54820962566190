import React from 'react';
import Layout from '_metronic/layout/Layout';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import RangePicker from './rangePicker';

import Breadcrumbs from 'components/breadcrumb';

/////////////////////////////AUTOMOVIL///////////////////////////////////////////
import Car from './icons/car';
import AutoClientesWidget from './widgets/automovil/clientes';
import AutoRankingGarantiasWidget from './widgets/automovil/rankingGarantias';
import AutoMensajeWidget from './widgets/automovil/mensaje';
import AutoRankingCalificacionesWidget from './widgets/automovil/rankingCalificaciones';
import AutoRankingLaminasWidget from './widgets/automovil/rankingLaminas';
import AutoRankingCarWidget from './widgets/automovil/rankingCar';
/////////////////////////////AUTOMOVIL///////////////////////////////////////////
/////////////////////////////ARQUITECTURA////////////////////////////////////////
import Arq from './icons/arq';
import ArqIngresadosWidget from './widgets/arquitectura/ingresados';
import ArqTopClientesProyectosWidget from './widgets/arquitectura/topClientesProyectos';
import ArqMensajeWidget from './widgets/arquitectura/mensaje';
import ArqProyectoEstadosWidget from './widgets/arquitectura/estado';
import ArqLaminasWidget from './widgets/arquitectura/laminas';
import ArqGarantiasWidget from './widgets/arquitectura/garantias';
/////////////////////////////ARQUITECTURA////////////////////////////////////////
/////////////////////////////arqFinishes////////////////////////////////////////
import GF from './icons/arqFinishes';
import GFIngresadosWidget from './widgets/arqFinishes/ingresados';
import GFTopClientesProyectosWidget from './widgets/arqFinishes/topClientesProyectos';
import GFMensajeWidget from './widgets/arqFinishes/mensaje';
import GFProyectoEstadosWidget from './widgets/arqFinishes/estado';
import GFLaminasWidget from './widgets/arqFinishes/laminas';
import GFGarantiasWidget from './widgets/arqFinishes/garantias';
/////////////////////////////arqFinishes////////////////////////////////////////

import { connect } from 'react-redux';
import { getUserRole } from 'redux/profile/selector';
import { getCountryProfile } from 'redux/profile/selector';

const breadcrumb = [{ page: 'dashboard', title: 'Panel principal' }];

const Dashboard = (props) => {
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-10">
          <div className="kt-space-20" />
          <Breadcrumbs title="Panel principal" items={breadcrumb} />
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-2"
          style={{ textAlign: 'right' }}
        >
          <div className="kt-space-20" />
          <RangePicker style={{ width: '100%' }} />
        </div>
      </div>

      {Boolean(props.auto) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Automóvil" />
                <Car
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoClientesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoRankingGarantiasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoMensajeWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoRankingCalificacionesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoRankingLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoRankingCarWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* -------------------------------------------------------------- */}
      {Boolean(props.building) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Arquitectura" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqIngresadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqTopClientesProyectosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqMensajeWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqProyectoEstadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqGarantiasWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* -------------------------------------------------------------- */}
      {Boolean(props.dinoc || props.fasara) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Architectural Finishes" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFIngresadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFTopClientesProyectosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFMensajeWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFProyectoEstadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFGarantiasWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default connect((state) => {
  return {
    ...getUserRole(state),
    country: getCountryProfile(state),
  };
})(Dashboard);

// export default Dashboard;
