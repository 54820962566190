import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import { actions as clientListActions } from 'redux/cars/clientList/reducer';
import { getClientListQuery } from 'redux/cars/clientList/selector';

import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f4f5f9',
    padding: '7px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

export const CustomizedInputBase = ({ intl, setFilter, q }) => {
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    if (searchText === null) {
      setSearchText(q);
    } else if (searchText !== q) {
      setFilter(searchText);
    }
  }, [setFilter, q, searchText]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SearchIcon />
      <InputBase
        value={searchText || ''}
        className={classes.input}
        placeholder={intl.formatMessage({ id: 'Buscar' })}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </div>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  setFilter: debounce((q) => dispatch(clientListActions.setFilter({ q })), 300),
});

export default injectIntl(
  connect(getClientListQuery, mapDispatchToProps)(CustomizedInputBase),
);
