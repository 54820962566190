/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';

class Footer extends React.Component {
  render() {
    const { footerClasses } = this.props;
    return (
      <div
        className={`kt-footer ${footerClasses} kt-grid__item`}
        id="kt_footer"
      >
        <div className="kt-footer__bottom"></div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
