import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import get from 'lodash/get';
import moment from 'moment';
import ctl from 'helpers/countries';
import { injectIntl } from 'react-intl';

const AreaChart = ({ intl, locale, data: values }) => {
  const APROVADO = intl.formatMessage({ id: 'Aprobado' });
  const PENDIENTE = intl.formatMessage({ id: 'Pendiente' });

  const ref = useRef();

  const data = useMemo(() => {
    return {
      labels: [APROVADO, PENDIENTE],
      datasets: [
        {
          backgroundColor: ['#73C968', '#FF4136'],
          data: [values.approved, values.pending],
        },
      ],
    };
  }, [values, locale]);

  useEffect(() => {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    const chart = new Chart(ref.current, {
      data,
      type: 'pie',
      options: {
        title: { display: true },
        aspectRatio: 1.25,
        legend: {
          position: 'bottom',
          labels: {
            padding: 20,
            usePointStyle: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={ref} />;
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'gb'
      : 'es',
});
export default injectIntl(connect(matStateToProps)(AreaChart));
