import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import store, { persistor, history } from 'redux/store';

import Routes from 'routes/';
import routes from 'routes/routes';
import { I18nProvider, ThemeProvider } from '_metronic';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import 'moment/locale/de';

import ReactGA from 'react-ga';

const trackingId = 'UA-174993710-1'; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId, { debug: true });
ReactGA.initialize(trackingId);

// ReactGA.set({
//   // userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })
// Initialize google analytics page view tracking

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

moment.locale('es');

export default () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeProvider>
          <I18nProvider>
            <Routes routes={routes} />
            <ToastContainer />
          </I18nProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
