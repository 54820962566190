import React from 'react';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import { push } from 'connected-react-router';
const formatDate = 'DD/MM/YYYY';

export const Warranty = ({ values }) => (
  <div className="row">
    <div className="col-12 ViewOrEdit">
      <div>
        <strong>
          <FormattedMessage id="Código de garantía:" />
        </strong>
        <span>{get(values, 'code', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Fecha de creación:" />
        </strong>
        <span>{moment(get(values, 'created', '')).format(formatDate)}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Fecha de vencimiento:" />
        </strong>
        <span>{moment(get(values, 'expires', '')).format(formatDate)}</span>
      </div>
    </div>
  </div>
);

export default Warranty;
