import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import { requests as citiesRequest } from 'redux/cities/reducer';
import { projectTypeOPTs } from 'enums/formOtps';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { Field } from 'formik';

export const initialValues = {
  data: {
    name: '',
    type: '',
    address: '',
    number: '',
    floor: '',
    department: '',
    postalCode: '',
    state: '',
    city: '',
  },
};

export const Project = ({
  country,
  fetchStates,
  fetchCities,
  stateOptions,
  citiesOptions,
  values: {
    data: { state },
  },
  setFieldValue,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    fetchCities({ country, state });
  }, [country, fetchCities, state, setFieldValue]);

  return (
    <>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Nombre del proyecto: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.name"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Tipo de proyecto: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.type"
            component={Autocomplete}
            variant="outlined"
            options={projectTypeOPTs}
          />
        </div>
      </div>
      {/* <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Calle: " />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.address"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Número: " />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.number"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Piso:" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.floor"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Departamento:" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.department"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div> */}
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Código postal: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.postalCode"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Provincia: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.state"
            component={Autocomplete}
            handleChange={() => {
              setFieldValue('data.city', '');
            }}
            variant="outlined"
            options={stateOptions}
          />
        </div>
      </div>
      {/* <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Ciudad: " />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="data.city"
            component={TextField}
            variant="outlined"
            className="w-100"
            disabled={true}
          />
        </div>
      </div> */}
    </>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
