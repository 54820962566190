import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Dialog from '../viewProject/dialog';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field, FieldArray } from 'formik';
import get from 'lodash/get';

import TextField from 'components/form/input';

import {
  requests as editClientRequests,
  actions as editClientActions,
} from 'redux/cars/editClient/reducer';
import { getApproveData } from 'redux/cars/editClient/selector';

export const schema = Yup.object().shape({});

const breadcrumb = [
  { page: 'distributor/building', title: 'Arquitectura' },
  { page: 'distributor/building', title: 'Proyectos registrados' },
];

export const NewWarranty = ({
  getClient,
  clearData,
  clientData,
  approveWarranty,
  hasWarrantyError,
  isWarrantySuccess,
  isLoading,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  useEffect(() => {
    if (isWarrantySuccess) history.push('/distributor/building');
  }, [isWarrantySuccess, history]);

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    approveWarranty({ code, body: values });
  };

  let aplications = get(clientData, 'data.applications', []);
  if (aplications.length) {
    const security = aplications.filter((sheet) => sheet.film.type === 2);
    if (security.length === 0) {
      aplications = [...aplications, { film: { type: 2 } }];
    }
    const solar = aplications.filter((sheet) => sheet.film.type === 1);
    if (solar.length === 0) {
      aplications = [...aplications, { film: { type: 1 } }];
    }
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Arquitectura" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(aplications).length) && (
        <Formik
          initialValues={{ aplications }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schema}
        >
          {({ values }) => {
            return (
              <Form className="NewWarranryView">
                <Portlet>
                  <PortletBody>
                    <div className="row text">
                      <div className="col-sm-12 col-md-12 col-lg-4" />
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <PorletHeaderTitle
                          variant="h4"
                          style={{ paddingBottom: '10px' }}
                        >
                          <FormattedMessage id="Certificado de garantía" />
                        </PorletHeaderTitle>
                        <Typography
                          variant="h4"
                          color="secondary"
                          style={{ paddingBottom: '10px' }}
                        >
                          <FormattedMessage id="Generar certificado de garantía" />
                        </Typography>
                        <Typography variant="h6">
                          <FormattedMessage id="Para poder generar el certificado de garantía deberás controlar los siguientes datos" />
                        </Typography>
                        <div className="kt-space-20" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-2" />
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-6">
                            <FieldArray name="aplications">
                              {() => (
                                <>
                                  {values.aplications.map(
                                    (sheet, index) =>
                                      Boolean(sheet.film.type === 2) && (
                                        <div className="row" key={index}>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].film.name`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="Lámina de seguridad"
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].meters`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="M2 necesarios"
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].run`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="Número de RUN"
                                              variant="outlined"
                                              fullWidth
                                            />
                                            {aplications[index].film.name ? (
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClick={handleOpenDialog}
                                              >
                                                <MailOutlineIcon />
                                                <FormattedMessage id="Denunciar RUN" />
                                              </Button>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      ),
                                  )}
                                </>
                              )}
                            </FieldArray>
                            <div className="kt-space-20" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-6">
                            <FieldArray name="aplications">
                              {() => (
                                <>
                                  {values.aplications.map(
                                    (sheet, index) =>
                                      Boolean(sheet.film.type === 1) && (
                                        <div className="row" key={index}>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].film.name`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="Lámina de control solar"
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].meters`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="M2 necesarios"
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                          <div className="col-12">
                                            <Field
                                              name={`aplications[${index}].run`}
                                              disabled
                                              component={TextField}
                                              type="text"
                                              label="Número de RUN"
                                              variant="outlined"
                                              fullWidth
                                            />
                                            {aplications[index].film.name ? (
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClick={handleOpenDialog}
                                              >
                                                <MailOutlineIcon />
                                                <FormattedMessage id="Denunciar RUN" />
                                              </Button>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      ),
                                  )}
                                </>
                              )}
                            </FieldArray>
                            <div className="kt-space-20" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-2" />
                    </div>
                    <div className="kt-space-20" />
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-2" />
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="submit-bottons">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Generar Certificado" />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.goBack()}
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Volver" />
                          </Button>
                        </div>
                        {hasWarrantyError && (
                          <Typography color="primary">
                            <br />
                            <FormattedMessage id="Se a producido un error, vuelva a intentar" />
                          </Typography>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-2" />
                    </div>
                  </PortletBody>
                </Portlet>
              </Form>
            );
          }}
        </Formik>
      )}
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          close={handleClose}
          code={parseInt(code)}
        />
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getClient: (data) => dispatch(editClientRequests.fetch(data)),
  approveWarranty: (data) => dispatch(editClientRequests.approve(data)),
  clearData: () => dispatch(editClientActions.clearData()),
});

export default connect(getApproveData, mapDispatchToProps)(NewWarranty);
