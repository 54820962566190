import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import {
  requests as editProjectsRequests,
  actions as editProjectsActions,
} from 'redux/arqFinishes/editProject/reducer';
import { getCountryProfile } from 'redux/profile/selector';
import { getProjectData } from 'redux/arqFinishes/editProject/selector';
import get from 'lodash/get';
import { Formik, Form, validateYupSchema, yupToFormErrors } from 'formik';
import Client, { initialValues as clientValues } from './client';
import Application, { initialValues as glassValues } from './application';
import Project, { initialValues as projectValues } from './project';
import Status, { initialValues as statusValues } from './status';
import Shhets, { initialValues as sheetsValues } from './sheets';
import Installer, { initialValues as installerValues } from './installer';
import Warranty from './warranty';

import validationSchema from './validate';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const initialValues = {
  ...clientValues,
  data: {
    ...glassValues.data,
    ...projectValues.data,
    ...sheetsValues.data,
    ...statusValues.data,
  },
  installer: {
    ...installerValues.installer,
  },
};

const breadcrumb = [
  { page: 'installer/arqFinishes', title: '3M™ DI-NOC™' },
  { page: 'installer/arqFinishes', title: 'Proyectos registrados' },
];

export const ViewProject = ({
  intl,
  getProject,
  saveProject,
  clearData,
  projectData,
  isLoading,
  isSuccess,
  hasError,
  country,
  ...props
}) => {
  const { code, approve } = useParams();
  const history = useHistory();

  useEffect(() => {
    getProject({ code });
    return () => clearData();
  }, [clearData, getProject, code]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success(
        <FormattedMessage id="Información actualizada correctamente" />,
        toastConfig,
      );
      history.push('/installer/arqFinishes');
    }
  }, [isLoading, isSuccess, history]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    if (Boolean(Object.keys(projectData).length)) {
      const isClosed =
        /*projectData.subtype === 'DinocSmall' ||*/ projectData.isClosed;
      const isLost = get(projectData, 'data.status', '') === 'Perdida';
      const isCancelled = get(projectData, 'data.status', '') === 'Cancelada';
      if (get(projectData, 'type', '') === 'AUTO') {
        history.push(`/installer/car/edit/${code}`);
        return;
      }
      if (isClosed || isLost || isCancelled) {
        history.push(`/installer/arqFinishes/dinoc/view/${code}`);
        return;
      }
    }
  }, [history, projectData, code]);

  const handleSubmit = (values, bag) => {
    console.log(values.data);
    bag.setSubmitting(false);
    if (
      values.data.status ===
      'Ganada' /*||
      values.data.progress.substring(0, 4) === '100%'*/
    ) {
      history.push('/installer/arqFinishes/dinoc/win/done');
    }
    return saveProject({ code, body: values });
  };
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="3M™ DI-NOC™" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(projectData).length) && (
        <Formik
          initialValues={{ ...initialValues, ...projectData }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(value) => {
            try {
              validateYupSchema(
                value,
                validationSchema(intl, country),
                true,
                value,
              );
            } catch (err) {
              console.log(projectData);
              console.log(err);
              let t = 0;
              err.inner.forEach((i) => {
                if (i.path.slice(0, -6) === 'data.employees') {
                  err.inner[t].path = `data.employees[0]`;
                  t++;
                } else {
                  t++;
                }
              });

              return yupToFormErrors(err);
            }
            return {};
          }}
        >
          {(fromProps) => (
            <Form className="ViewProjectForm">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Cliente" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Client
                        {...props}
                        {...fromProps}
                        code={code}
                        companyName={projectData.data.companyName}
                      />
                    </PortletBody>
                  </Portlet>

                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Películas" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Shhets {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Proyecto" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Project {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Instalador" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Installer
                        {...props}
                        {...fromProps}
                        employees={projectData.data.employees}
                      />
                    </PortletBody>
                  </Portlet>

                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Garantía" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Warranty {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  {projectData.subtype !== 'DinocSmall' && (
                    <Portlet className="kt-portlet">
                      <PorletHeader>
                        <PorletHeaderTitle variant="h4">
                          <FormattedMessage id="Etapa" />
                        </PorletHeaderTitle>
                      </PorletHeader>
                      <PortletBody>
                        <Status
                          {...props}
                          {...fromProps}
                          approve={Boolean(approve)}
                        />
                      </PortletBody>
                    </Portlet>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6"></div>
                <div className="col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PortletBody className="submit-bottons">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                      >
                        <FormattedMessage id="Guardar" />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => history.goBack()}
                      >
                        <FormattedMessage id="Volver" />
                      </Button>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
              {/* <pre>{JSON.stringify(fromProps.values, null, '\t')}</pre> */}
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getProject: (data) => dispatch(editProjectsRequests.fetch(data)),
  saveProject: (data) => dispatch(editProjectsRequests.create(data)),
  clearData: () => dispatch(editProjectsActions.clearData()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getProjectData(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(ViewProject),
);
