import { APLICATION_ROLES } from 'enums/roles';

import Login from 'views/login';
// import ResetPassword from 'views/login/resetPassword';
import ExperienciaArqFinishes from 'views/experiencia/ExperienciaArqFinishes';
import ExperienciaArqFinishesThanks from 'views/experiencia/ExperienciaArqFinishesThanks';
import ExperienciaBuilding from 'views/experiencia/ExperienciaBuilding';
import ExperienciaBuildingThanks from 'views/experiencia/ExperienciaBuildingThanks';
import ExperienciaAuto from 'views/experiencia/ExperienciaAuto';
import ExperienciaAutoThanks from 'views/experiencia/ExperienciaAutoThanks';

import Dashboard from 'views/dashboard';

// 3M
import TresMInstallerList from 'views/3m/installer/list';
import TresMInstallerView from 'views/3m/installer/view';

import TresMDistributorList from 'views/3m/distributor/list';
import TresMDistributorView from 'views/3m/distributor/view';

// Installer
import InstallerClientList from 'views/installer/car/clientList';
import InstallerNewClient from 'views/installer/car/newClient';
import InstallerPPFNewClient from 'views/installer/car/ppfNewClient';
import InstallerWrappingNewClient from 'views/installer/car/wrappingNewClient';
import InstallerEditClient from 'views/installer/car/editClient';

import InstallerProjectList from 'views/installer/building/projectList';
import InstallerNewComercial from 'views/installer/building/newComercial';

import InstallerArqFinishesProjectList from 'views/installer/arqFinishes/projectList';

import InstallerNewDinoc from 'views/installer/arqFinishes/dinoc/newDinoc';
import InstallerNewDinocExpress from 'views/installer/arqFinishes/dinoc/newDinocExpress';
import InstallerDinocEditProject from 'views/installer/arqFinishes/dinoc/editProject';
import InstallerDinocViewProject from 'views/installer/arqFinishes/dinoc/viewProject';
import InstallerDinocWinProject from 'views/installer/arqFinishes/dinoc/winProject';
import InstallerDinocWinProjectDone from 'views/installer/arqFinishes/dinoc/winProject/done';

import InstallerNewFasara from 'views/installer/arqFinishes/glassFinishes/newFasara';
import InstallerNewFasaraExpress from 'views/installer/arqFinishes/glassFinishes/newFasaraExpress';
import InstallerFasaraEditProject from 'views/installer/arqFinishes/glassFinishes/editProject';
import InstallerFasaraViewProject from 'views/installer/arqFinishes/glassFinishes/viewProject';
import InstallerFasaraWinProject from 'views/installer/arqFinishes/glassFinishes/winProject';
import InstallerFasaraWinProjectDone from 'views/installer/arqFinishes/glassFinishes/winProject/done';

import InstallerNewResidential from 'views/installer/building/newResidential';
import InstallerEditProject from 'views/installer/building/editProject';
import InstallerViewProject from 'views/installer/building/viewProject';
import InstallerWinProject from 'views/installer/building/winProject';
import InstallerWinProjectDone from 'views/installer/building/winProject/done';

// Distributor
import DistributorClientList from 'views/distributor/client/clientList';
import DistributorClientView from 'views/distributor//client/viewClient';

import DistributorWarrantyList from 'views/distributor/car/warrantyList';
import DistributorViewWarranty from 'views/distributor/car/viewWarranty';

import DistributorProjectList from 'views/distributor/building/projectList';
import DistributorViewProject from 'views/distributor/building/viewProject';
import DistributorNewWarranty from 'views/distributor/building/newWarranty';

import DistributorArqFinishesProjectList from 'views/distributor/arqFinishes/projectList';
import DistributorArqFinishesViewProject from 'views/distributor/arqFinishes/viewProject';
import DistributorArqFinishesNewWarranty from 'views/distributor/arqFinishes/newWarranty';

// Statics
import Contact from 'views/contact';
import Product from 'views/product';

import ProductAuto from 'views/product/productAuto';
import ProductArq from 'views/product/productArq';

// profile
import BusinessProfile from 'views/profile/business';
import ContactProfile from 'views/profile/contact';
import InstallersProfile from 'views/profile/installers';
import PasswordProfile from 'views/profile/password';
import ResumeProfile from 'views/profile/resume';

import ResetPassword from 'views/login/resetPassword';

//endUSer
import endUserGlassfinish from 'views/endUserForm/glassfinish';
import endUserDinoc from 'views/endUserForm/dinoc';
import endUserBuilding from 'views/endUserForm/building';
import endUserAuto from 'views/endUserForm/auto';

//backoffice
import BackofficeUsersList from 'views/admin/users/list';
import BackofficeUsersEdit from 'views/admin/users/edit';

//register
import register from 'views/registerForm/index';
import preRegister from 'views/login/preRegister';
import productPpf from 'views/product/productPpf';
import productWrap from 'views/product/productWrap';

export default [
  {
    path: '/',
    redirectPath: '/dashboard',
    exact: true,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    secure: true,
    exact: true,
  },
  {
    path: '/profile',
    redirectPath: '/profile/resume',
    exact: true,
  },
  {
    path: '/profile/resume',
    component: ResumeProfile,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/password',
    component: PasswordProfile,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/installers',
    component: InstallersProfile,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/contact',
    component: ContactProfile,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/business',
    component: BusinessProfile,
    secure: true,
    exact: true,
  },
  {
    path: '/installer',
    redirectPath: '/dashboard',
    secure: true,
    exact: true,
  },
  {
    path: '/installer/car',
    component: InstallerClientList,
    secure: true,
    access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
    routes: [
      {
        path: '/installer/car/new',
        component: InstallerNewClient,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/car/ppfNew',
        component: InstallerPPFNewClient,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/car/wrappingNew',
        component: InstallerWrappingNewClient,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/car/edit/:code',
        component: InstallerEditClient,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
    ],
  },
  {
    path: '/installer/arqFinishes',
    component: InstallerArqFinishesProjectList,
    secure: true,
    access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
    routes: [
      {
        path: '/installer/arqFinishes/dinoc/new/large',
        component: InstallerNewDinoc,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/new/small',
        component: InstallerNewDinocExpress,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/edit/:code',
        component: InstallerDinocEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/edit/:code/:approve',
        component: InstallerDinocEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/view/:code',
        component: InstallerDinocViewProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/win/done',
        component: InstallerDinocWinProjectDone,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/dinoc/win/:code',
        component: InstallerDinocWinProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/new/large',
        component: InstallerNewFasara,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/new/small',
        component: InstallerNewFasaraExpress,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/edit/:code',
        component: InstallerFasaraEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/edit/:code/:approve',
        component: InstallerFasaraEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/view/:code',
        component: InstallerFasaraViewProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/win/done',
        component: InstallerFasaraWinProjectDone,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/arqFinishes/fasara/win/:code',
        component: InstallerFasaraWinProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
    ],
  },

  {
    path: '/installer/building',
    component: InstallerProjectList,
    secure: true,
    access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
    routes: [
      {
        path: '/installer/building/new/comercial',
        component: InstallerNewComercial,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/new/residential',
        component: InstallerNewResidential,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/edit/:code',
        component: InstallerEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/edit/:code/:approve',
        component: InstallerEditProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/view/:code',
        component: InstallerViewProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/win/done',
        component: InstallerWinProjectDone,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
      {
        path: '/installer/building/win/:code',
        component: InstallerWinProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Installer],
      },
    ],
  },

  {
    path: '/distributor',
    redirectPath: '/dashboard',
    exact: true,
  },
  {
    path: '/distributor/client',
    component: DistributorClientList,
    secure: true,
    access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
    routes: [
      {
        path: '/distributor/client/view/:code',
        component: DistributorClientView,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
      },
    ],
  },
  {
    path: '/distributor/car',
    component: DistributorWarrantyList,
    secure: true,
    access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
    routes: [
      {
        path: '/distributor/car/view/:code',
        component: DistributorViewWarranty,
        exact: true,
        secure: true,
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Segvel,
        ],
      },
    ],
  },

  {
    path: '/distributor/building',
    component: DistributorProjectList,
    secure: true,
    access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
    routes: [
      {
        path: '/distributor/building/warranty/:code',
        component: DistributorNewWarranty,
        exact: true,
        secure: true,
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Segvel,
        ],
      },
      {
        path: '/distributor/building/view/:code',
        component: DistributorViewProject,
        exact: true,
        secure: true,
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Segvel,
        ],
      },
    ],
  },
  {
    path: '/distributor/arqFinishes',
    component: DistributorArqFinishesProjectList,
    secure: true,
    access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
    routes: [
      {
        path: '/distributor/arqFinishes/warranty/:code',
        component: DistributorArqFinishesNewWarranty,
        exact: true,
        secure: true,
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Segvel,
        ],
      },
      {
        path: '/distributor/arqFinishes/view/:code',
        component: DistributorArqFinishesViewProject,
        exact: true,
        secure: true,
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Segvel,
        ],
      },
    ],
  },

  {
    path: '/3m',
    redirectPath: '/dashboard',
    exact: true,
  },
  {
    path: '/3m/installer',
    component: TresMInstallerList,
    secure: true,
    access: [APLICATION_ROLES.Admin],
    routes: [
      {
        path: '/3m/installer/view/:code',
        component: TresMInstallerView,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
    ],
  },
  {
    path: '/3m/distributor',
    component: TresMDistributorList,
    secure: true,
    access: [APLICATION_ROLES.Admin],
    routes: [
      {
        path: '/3m/distributor/view/:code',
        component: TresMDistributorView,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
    ],
  },
  {
    path: '/3m/car',
    component: DistributorWarrantyList,
    secure: true,
    access: [APLICATION_ROLES.Admin],
    routes: [
      {
        path: '/3m/car/view/:code',
        component: DistributorViewWarranty,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
    ],
  },

  {
    path: '/3m/building',
    component: DistributorProjectList,
    secure: true,
    access: [APLICATION_ROLES.Admin],
    routes: [
      {
        path: '/3m/building/warranty/:code',
        component: DistributorNewWarranty,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
      {
        path: '/3m/building/view/:code',
        component: DistributorViewProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
    ],
  },
  {
    path: '/3m/arqFinishes',
    component: DistributorArqFinishesProjectList,
    secure: true,
    access: [APLICATION_ROLES.Admin],
    routes: [
      {
        path: '/3m/arqFinishes/warranty/:code',
        component: DistributorArqFinishesNewWarranty,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
      {
        path: '/3m/arqFinishes/view/:code',
        component: DistributorArqFinishesViewProject,
        exact: true,
        secure: true,
        access: [APLICATION_ROLES.Admin],
      },
    ],
  },
  /* backoffice routes */

  {
    path: '/backoffice/users',
    component: BackofficeUsersList,
    exact: true,
    secure: true,
    access: [APLICATION_ROLES.Owner, APLICATION_ROLES.MMMAdm],
  },
  {
    path: '/backoffice/users/:code',
    component: BackofficeUsersEdit,
    exact: true,
    secure: true,
    access: [APLICATION_ROLES.Owner, APLICATION_ROLES.MMMAdm],
  },

  /* backoffice routes */

  {
    path: '/contact',
    component: Contact,
    exact: true,
    secure: true,
  },
  {
    path: '/product',
    component: Product,
    exact: true,
    secure: true,
  },

  {
    path: '/login',
    component: Login,
    exact: true,
  },

  {
    path: '/reset-password',
    component: ResetPassword,

    routes: [
      {
        path: '/reset-password/:code',
        component: ResetPassword,
        exact: true,
      },
    ],
  },
  {
    path: '/product_car',
    component: ProductAuto,
    exact: true,
    secure: true,
  },
  {
    path: '/product_arq',
    component: ProductArq,
    exact: true,
    secure: true,
  },
  ,
  {
    path: '/product_ppf',
    component: productPpf,
    exact: true,
    secure: true,
  },
  {
    path: '/product_wrap',
    component: productWrap,
    exact: true,
    secure: true,
  },
  {
    path: '/experiencia-arqfinishes',
    component: ExperienciaArqFinishes,
    exact: true,
  },
  {
    path: '/experiencia-arqfinishes/thanks',
    component: ExperienciaArqFinishesThanks,
    exact: true,
  },
  {
    path: '/experiencia-auto',
    component: ExperienciaAuto,
    exact: true,
  },
  {
    path: '/experiencia-building',
    component: ExperienciaBuilding,
    exact: true,
  },
  {
    path: '/experiencia-building/thanks',
    component: ExperienciaBuildingThanks,
    exact: true,
  },
  {
    path: '/experiencia-auto',
    component: ExperienciaAuto,
    exact: true,
  },
  {
    path: '/experiencia-auto/thanks',
    component: ExperienciaAutoThanks,
    exact: true,
  },
  {
    path: '/end-user/glassfinish',
    component: endUserGlassfinish,
    exact: true,
  },
  {
    path: '/end-user/dinoc',
    component: endUserDinoc,
    exact: true,
  },
  {
    path: '/end-user/building',
    component: endUserBuilding,
    exact: true,
  },
  {
    path: '/end-user/auto',
    component: endUserAuto,
    exact: true,
  },
  {
    path: '/register',
    component: register,
    exact: true,
  },
  {
    path: '/pre-register',
    component: preRegister,
    exact: true,
  },
];
