import { all, put, select, takeLatest } from 'redux-saga/effects';
import { requests, actions } from './reducer';
import { getUsersListQuery } from './selector';

export function* fetchEffect({ payload }) {
  const actualQuery = yield select(getUsersListQuery);
  const query = {
    ...actualQuery,
    ...payload,
  };
  yield put(requests.fetch({ query }));
}

export default function* () {
  yield all([takeLatest(actions.setFilter.type, fetchEffect)]);
}
