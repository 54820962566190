import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

export const Project = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12 ViewOrEdit">
        <div>
          <strong>
            <FormattedMessage id="Nombre del proyecto:" />
          </strong>
          <span>{get(values, 'data.name', '-')}</span>
        </div>
        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Tipo de proyecto:" />
          </strong>
          <span>
            <FormattedMessage id={get(values, 'data.type', '-')} />
          </span>
        </div>
        <div className="kt-space-20" />

        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Provincia:" />
          </strong>
          <span>{get(values, 'data.state', '-')}</span>
        </div>
        <div className="kt-space-20" />
        {/* <div>
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
          <span>{get(values, 'data.city', '-')}</span>
        </div> */}
      </div>
    </div>
  </>
);

export default Project;
