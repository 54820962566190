/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { toAbsoluteUrl } from '_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { Link } from 'react-router-dom';

import { actions as authActions } from 'redux/auth/reducer';
import { getProfile } from 'redux/profile/selector';
import get from 'lodash/get';

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge, logout } = this.props;

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          {showHi && (
            <span className="kt-header__topbar-welcome kt-hidden-mobile">
              <FormattedMessage id="Hola," />
            </span>
          )}

          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {`${user.firstname} ${user.lastname}`}
            </span>
          )}

          {showAvatar && (
            <img alt="Pic" src={toAbsoluteUrl('/media/users/300_21.jpg')} />
          )}

          {showBadge && (
            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
              {get(user, 'firstname[0]', '')
                ? get(user, 'firstname[0]', '')
                : 'U'}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {/* <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
            }}
          >
            <div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={user.pic} />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                S
              </span>
            </div>
            <div className="kt-user-card__name">{user.fullname}</div>
            <div className="kt-user-card__name">asdasd</div>
            <div className="kt-user-card__badge">
              <span className="btn btn-success btn-sm btn-bold btn-font-md">
                23 messages
              </span>
            </div>
          </div> */}
          <div className="kt-notification">
            {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </a> */}
            <Link to="/profile" className="kt-notification__item">
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  <FormattedMessage id="Mis datos" />
                </div>
              </div>
            </Link>
            {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </a> */}
            {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </a> */}
            <div className="kt-notification__custom">
              <button
                className="btn btn-label-brand btn-sm btn-bold"
                onClick={logout}
              >
                <FormattedMessage id="Salir" />
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapDispatchProps = (dispatch) => ({
  logout: () => {
    dispatch(authActions.logOut());
  },
});

export default connect(getProfile, mapDispatchProps)(UserProfile);
