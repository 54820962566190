import React from 'react';

import './productCard.css';

const Separator = ({ degrade }) => {
  return (
    <div width="100%" className={degrade}>
      {' '}
      <br />{' '}
    </div>
  );
};

export default Separator;
