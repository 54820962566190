import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  getSecurityFilmsBuildingOptions,
  getSolarControlFilmsBuildingOptions,
} from 'redux/filmsBuilding/selector';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getStatusNewProject } from 'redux/buildings/newProject/selector';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export const Step4 = ({
  country,
  securityFilmsBuildingOptions,
  solarControlFilmsBuildingOps,
  distributorsOptions,
  values,
  prevStep,
  isLoading,
  hasError,
}) => (
  <>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <PorletHeader>
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="Resumen del cliente" />
          </PorletHeaderTitle>
          <PorletHeaderTitle variant="h6">
            <FormattedMessage id="Por favor revisar todos los datos del proyecto." />
          </PorletHeaderTitle>
        </PorletHeader>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row resumen">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Cliente:" />
                  </PorletHeaderTitle>
                  <div>
                    <strong>
                      <FormattedMessage id="Nombre del contacto:" />
                    </strong>
                    <span>{values.firstName}</span>
                  </div>
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Apellido:" />
                    </strong>
                    <span>{values.lastName}</span>
                  </div>*/}
                  <div>
                    <strong>
                      <FormattedMessage id="Email:" />
                    </strong>
                    <span>{values.email}</span>
                  </div>
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Número de celular:" />
                    </strong>
                    <span>{values.cellphone}</span>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
            <div className="row">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Proyecto:" />
                  </PorletHeaderTitle>
                  <div>
                    <strong>
                      <FormattedMessage id="Tipo de proyecto:" />
                    </strong>
                    <span>
                      <FormattedMessage id={values.project.type} />
                    </span>
                  </div>
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Calle:" />
                    </strong>
                    <span>{values.project.street}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Piso:" />
                    </strong>
                    <span>{values.project.floor}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Departamento:" />
                    </strong>
                    <span>{values.project.department}</span>
                  </div>*/}
                  <div>
                    <strong>
                      <FormattedMessage id="Código postal:" />
                    </strong>
                    <span>{values.project.postalCode}</span>
                  </div>
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Ciudad:" />
                    </strong>
                    <span>{values.project.city}</span>
                  </div>*/}
                  <div>
                    <strong>
                      <FormattedMessage id="Provincia:" />
                    </strong>
                    <span>{values.project.state}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="withmargin">
                  <PorletHeaderTitle variant="h5">
                    <FormattedMessage id="Aplicación:" />
                  </PorletHeaderTitle>
                  <div className="kt-space-20" />
                  <PorletHeaderTitle variant="h6">
                    <FormattedMessage id="Vidrio:" />
                  </PorletHeaderTitle>
                  <div>
                    <strong>
                      <FormattedMessage id="Tipo de vidrio:" />
                    </strong>
                    <span>
                      <FormattedMessage id={values.glass.type} />
                    </span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Realizo el Glass Check List:" />
                    </strong>
                    <span>
                      <FormattedMessage id={values.glass.checkList} />
                    </span>
                  </div>
                  <div className="kt-space-20" />
                  <PorletHeaderTitle variant="h6">
                    <FormattedMessage id="Colocación:" />
                  </PorletHeaderTitle>
                  {values.installers.map((installer, index) => {
                    return (
                      <div key={index}>
                        <strong>
                          <FormattedMessage id="Instalador" /> {index + 1}:
                        </strong>
                        <span>{installer.name}</span>
                      </div>
                    );
                  })}
                  <div className="kt-space-20" />
                  <PorletHeaderTitle variant="h6">
                    <FormattedMessage id="Películas:" />
                  </PorletHeaderTitle>
                  <div className="kt-space-20" />
                  {Boolean(values.securitySheets.length) && (
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Seguridad:" />
                    </PorletHeaderTitle>
                  )}
                  {values.securitySheets.map((securitySheet, index) => {
                    return (
                      <>
                        <div>
                          <strong>
                            <FormattedMessage id="película de seguridad:" />
                          </strong>
                          <span>
                            {get(
                              securityFilmsBuildingOptions.find(
                                (item) => item.value === securitySheet.type,
                              ),
                              'label',
                            )}
                          </span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="M2 Necesarios:" />
                          </strong>
                          <span>{securitySheet.meters}</span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Distribuidor:" />
                          </strong>
                          <span>
                            {get(
                              distributorsOptions.find(
                                (item) =>
                                  item.value === securitySheet.distributor,
                              ),
                              'label',
                            )}
                          </span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Número de RUN:" />
                          </strong>
                          <span>{securitySheet.run}</span>
                        </div>
                      </>
                    );
                  })}
                  {Boolean(values.solarSheets.length) && (
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Control Solar:" />
                    </PorletHeaderTitle>
                  )}
                  {values.solarSheets.map((solarSheet, index) => {
                    return (
                      <>
                        <div>
                          <strong>
                            <FormattedMessage id="Película de seguridad:" />
                          </strong>
                          <span>
                            {get(
                              solarControlFilmsBuildingOps.find(
                                (item) => item.value === solarSheet.type,
                              ),
                              'label',
                            )}
                          </span>
                        </div>
                        <div>
                          <strong>
                            <FormattedMessage id="M2 Necesarios:" />
                          </strong>
                          <span>{solarSheet.meters}</span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Distribuidor:" />
                          </strong>
                          <span>
                            {get(
                              distributorsOptions.find(
                                (item) => item.value === solarSheet.distributor,
                              ),
                              'label',
                            )}
                          </span>
                        </div>

                        <div>
                          <strong>
                            <FormattedMessage id="Número de RUN:" />
                          </strong>
                          <span>{solarSheet.run}</span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="kt-space-20" />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8 ">
        <div>
          {hasError && (
            <Typography color="primary">
              <FormattedMessage id="Se a producido un error, vuelva a intentar" />
            </Typography>
          )}
        </div>
        <div className="submit-bottons">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep} disabled={isLoading}>
            <FormattedMessage id="Editar" />
          </Button>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4"></div>
    </div>
  </>
);

export const mapStateToProps = (state) => ({
  securityFilmsBuildingOptions: getSecurityFilmsBuildingOptions(state),
  solarControlFilmsBuildingOps: getSolarControlFilmsBuildingOptions(state),
  distributorsOptions: getDistributorsOptions(state),
  ...getStatusNewProject(state),
});

export default connect(mapStateToProps)(Step4);
