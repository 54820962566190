import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getClub3mCategoryWidget } from 'redux/dashboard/selector';

const ValueTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '2em',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    width: '50%',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    paddingRight: '1em',
    width: '50%',
  },
})(Typography);
const DescriptionTypographyUp = withStyles({
  root: {
    fontWeight: 'normal',
    paddingRight: '1em',
    width: '100%',
  },
})(Typography);

const Club3m = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Club 3M Window Films" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      <div>
        <DescriptionTypographyUp>
          <FormattedMessage id="El programa desarrollado para reconocer el trabajo de los instaladores que forman parte del negocio de películas de vidrio automotriz de 3M e impulsar su crecimiento en el mercado dentro de Latinoamérica." />
        </DescriptionTypographyUp>
        <div className="kt-space-40" />
        <div className="kt-space-40" />

        <div className="Atomovil-Club">
          <DescriptionTypography>
            <FormattedMessage id="¡Felicitaciones! Tu categoría en nuestro club es:" />
          </DescriptionTypography>
          {!isLoading && (
            <ValueTypography color="primary">{data}</ValueTypography>
          )}
        </div>
      </div>
    </PortletBody>
  </Portlet>
);

export default connect(getClub3mCategoryWidget)(Club3m);
