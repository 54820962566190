import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';
import { IntlProvider, useIntl } from 'react-intl';

import { getDistributorsOptions } from 'redux/distributors/selector';
import {
  getSecurityFilmsBuildingOptions,
  getSolarControlFilmsBuildingOptions,
} from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const newSheet = (type) => ({
  type,
  meters: '',
  run: '',
  film: {
    id: '',
  },
  distributor: {
    id: '',
  },
});

export const initialValues = {
  data: {
    ...newSheet(''),
  },
};
console.log(selectedDistributorId);
const selectedDistributorId = distributorsRequest;

export const Sheets = ({
  values,
  fetchDistributors,
  distributorsOptions,
  securityFilmsBuildingOptions,
  solarControlFilmsBuildingOptions,
  fetchFilmsBuildingRequest,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);
  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const Sheets = get(values, 'data.applications', []) || [];
  return (
    <div className="row">
      <div className="col-lg-6 col-md-12">
        <PorletHeaderTitle variant="h5">
          <FormattedMessage id="Seguridad" />
        </PorletHeaderTitle>

        <FieldArray name="data.applications">
          {({ push, remove }) => (
            <>
              {Sheets.map((sheet, index) => {
                return (
                  Boolean(sheet.film.type === 2 || sheet.type === 2) && (
                    <div key={index}>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Película de seguridad: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].film.id`}
                            component={Autocomplete}
                            variant="outlined"
                            options={securityFilmsBuildingOptions}
                          />
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="M2 Necesarios: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].meters`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </div>
                      {values.installer.country !== 'AR' ? (
                        <div className="row FormRow">
                          <div className="col-sm-12 col-md-12 col-lg-4">
                            <FormattedMessage id="Distribuidor: *" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-8">
                            <Field
                              name={`data.applications[${index}].distributor.id`}
                              component={Autocomplete}
                              variant="outlined"
                              options={distributorsOptions}
                              placeholder="Seleccionar"
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row FormRow">
                          <div className="col-sm-12 col-md-12 col-lg-4">
                            <FormattedMessage id="Distribuidor: *" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="kt-space-10" />
                            <span>{sheet.distributor.name}</span>
                          </div>
                        </div>
                      )}
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Número de RUN: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].run`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="row">
                        {Sheets.length > 1 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta película" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
              {Sheets.length < 6 && (
                <>
                  <div className="kt-space-20" />
                  <div className="row">
                    <div className="col-12">
                      <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => push(newSheet(2))}
                      >
                        <FormattedMessage id="Agregar nueva película" />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </FieldArray>
      </div>
      <div className="kt-space-20" />
      <div className="col-lg-6 col-md-12">
        <PorletHeaderTitle variant="h5">
          <FormattedMessage id="Control Solar" />
        </PorletHeaderTitle>
        <FieldArray name="data.applications">
          {({ push, remove }) => (
            <>
              {Sheets.map((sheet, index) => {
                return (
                  Boolean(sheet.film.type === 1 || sheet.type === 1) && (
                    <div key={index}>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Película de control solar: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].film.id`}
                            component={Autocomplete}
                            variant="outlined"
                            options={solarControlFilmsBuildingOptions}
                          />
                        </div>
                      </div>
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="M2 Necesarios: *" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].meters`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </div>
                      {values.installer.country !== 'AR' ? (
                        <div className="row FormRow">
                          <div className="col-sm-12 col-md-12 col-lg-4">
                            <FormattedMessage id="Distribuidor: *" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-8">
                            <Field
                              name={`data.applications[${index}].distributor.id`}
                              component={Autocomplete}
                              variant="outlined"
                              options={distributorsOptions}
                              placeholder="Seleccionar"
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row FormRow">
                          <div className="col-sm-12 col-md-12 col-lg-4">
                            <FormattedMessage id="Distribuidor: *" />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="kt-space-10" />
                            <span>{sheet.distributor.name}</span>
                          </div>
                        </div>
                      )}
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Número de RUN:*" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].run`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            validate={(value) => {
                              if (!value) {
                                return 'Debe ingresar un run';
                              }
                              return undefined;
                            }}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="row">
                        {Sheets.length > 1 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta película" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
              {Sheets.length < 4 && (
                <>
                  <div className="kt-space-20" />
                  <div className="row">
                    <div className="col-12">
                      <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => push(newSheet(1))}
                      >
                        <FormattedMessage id="Agregar nueva película" />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </FieldArray>
      </div>
      <div className="kt-space-20" />
    </div>
  );
};

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
  securityFilmsBuildingOptions: getSecurityFilmsBuildingOptions(state),
  solarControlFilmsBuildingOptions: getSolarControlFilmsBuildingOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sheets);
