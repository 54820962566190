import MESSAGES from './ar';

export default {
  ...MESSAGES,
  'Número de Patente:': 'Número de Placa o chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número de placa o de chasis',
  'Número de patente *': 'Número de placa o de chasis*',
  'Por favor ingrese el número de patente':
    'Por favor ingresa el número de placa o de chasis',
  'Este es el total de M2 de láminas registradas durante este mes':
    'Este es el total de M2 de películas registradas durante este mes',
  'Láminas de proyectos pendientes': 'Películas de proyectos pendientes',
  'Lámina de seguridad': 'Película de seguridad',
  'Lámina de control solar': 'Película de control solar',
  'Lámina de seguridad:': 'Película de seguridad:',
  'Lámina de seguridad: *': 'Película de seguridad: *',
  'Eliminar esta lámina': 'Eliminar esta película',
  'Agregar nueva lámina': 'Agregar nueva película',
  'Lámina de control solar: *': 'Película de control solar: *',
  Láminas: 'Películas',
  'Debe agregar una lámina': 'Debes agregar una película',
  'Debe seleccionar una lámina': 'Debes seleccionar una película',
  'Lámina de control solar:': 'Película de control solar:',
  'Lámina de seguridad *': 'Película de seguridad *',
  'Por favor seleccione una lámina de seguridad':
    'Por favor selecciona una película de seguridad',
  'Lámina de control solar *': 'Película de control solar *',
  'Por favor seleccione una lámina de control solar':
    'Por favor selecciona una película de control solar',
  'Láminas:': 'Películas:',
  'Lámina colocada': 'Película colocada',
  'Lámina *': 'Película *',
  'Por favor seleccione la lámina de seguridad colocada':
    'Por favor selecciona la película de seguridad colocada',
  'Por favor ingresa el número de RUN de la lámina colocada':
    'Por favor ingresa el número de Lote de la película colocada',
  'Ranking de láminas utilizadas': 'Ranking de películas utilizadas',
  CUIT: 'RFC',
  'CUIT:': 'RFC:',
  'CUIT: *': 'RFC: *',
  'Debe ingresar un CUIT': 'Debes ingresar un RFC',
  Provincia: 'Estado',
  'Debe seleccionar una provincia': 'Debes seleccionar un estado',
  'Provincia *': 'Estado *',
  'Provincia:': 'Estado:',
  'Provincia: *': 'Estado: *',
  'Vidrio doble - DVH': 'Vidrio doble',
  'Vidrio doble tintado': 'Vidrio doble entintado',
  'Vidrio simple crudo': 'Vidrio simple crudo o natural',
  'Vidrio simple tintado': 'Vidrio simple entintado',
  Dominio: 'Placa',
  'Dominio:': 'Placa o chasis si es concesionario:',
  'Número de Patente:': 'Número de placa o chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número de placa o de chasis',
  'Número de patente *': 'Número de placa o chasis si es concesionario *',
  'Por favor ingrese el número de patente':
    'Por favor ingresa el número de placa o de chasis',
};
