import React from 'react';
import { FormattedMessage } from 'react-intl';

import Autocomplete from 'components/form/autocomplete';
import { Field } from 'formik';
import { glassTypeOPTs, checkListTypeOPTs } from 'enums/formOtps';

export const initialValues = {
  data: {
    glass: '',
    Checklist: '',
  },
};

export const Glass = () => (
  <>
    <div className="row FormRow">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <FormattedMessage id="Tipo de vidrio: *" />
      </div>
      <div className="col-sm-12 col-md-12 col-lg-8">
        <Field
          name="data.glass"
          component={Autocomplete}
          variant="outlined"
          options={glassTypeOPTs}
        />
      </div>
    </div>
    <div className="row FormRow">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <FormattedMessage id="Realizó el Glass Checklist: *" />
      </div>
      <div className="col-sm-12 col-md-12 col-lg-8">
        <Field
          name="data.Checklist"
          component={Autocomplete}
          variant="outlined"
          options={checkListTypeOPTs}
        />
      </div>
    </div>
    <div className="col-12">
      <div className="kt-space-20" />
    </div>
  </>
);

export default Glass;
