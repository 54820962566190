import MESSAGES from './ar';

export default {
  ...MESSAGES,

  'CUIT:': 'RUT:',
  'CUIT: *': 'RUT: *',
  'Debe ingresar un CUIT': 'Debes ingresar un RUT',
  Provincia: 'Región',
  'Debe seleccionar una provincia': 'Debes seleccionar una región',
  'Provincia *': 'Región *',
  'Provincia:': 'Región:',
  'Provincia: *': 'Región: *',
  'Vidrio doble - DVH': 'Termopanel (Vidrio doble)',
  CUIT: 'RUT',
  'Debe seleccionar una ciudad': 'Debes seleccionar una comuna',
  'Ciudad *': 'Comuna *',
  'Ciudad:': 'Comuna:',
  Ciudad: 'Comuna',
  'Ciudad: *': 'Comuna: *',
};
