import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';

import {
  projectProgressOPTs as opts,
  yearOPTs,
  monthOPTs,
} from 'enums/formOtps';

const projectProgressOPTs = [...opts.slice(0, -1)];

export const initialValues = {
  projectStatus: '',
  finish: {
    month: '',
    year: '',
  },
  actionsForProj: [],
  comment: '',
};

export const Step4 = ({ errors, prevStep }) => {
  const finishError = get(errors, `finish`, false);

  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <Field
                    name="projectStatus"
                    component={Autocomplete}
                    label="Estado del proyecto *"
                    helperText="Por favor selecciona la etapa de cierre del proyecto"
                    variant="outlined"
                    options={projectProgressOPTs}
                  />
                </div>
              </div>
              <div className="kt-space-20" />
              <div className="row">
                <div className="col-sm-12">
                  <InputLabel>
                    <FormattedMessage id="Para este proyecto, usted ha:" />
                  </InputLabel>

                  <label className="MuiFormHelperText-root norm-text">
                    <Field
                      name="actionsForProj"
                      type="checkbox"
                      value="Hecho un prototipo / piloto en el lugar"
                      className="mr-2"
                    />

                    <FormattedMessage id="Hecho un prototipo / piloto en el lugar" />
                  </label>
                  <br />
                  <label className="MuiFormHelperText-root norm-text">
                    <Field
                      name="actionsForProj"
                      type="checkbox"
                      value="Envió muestras al tomador de desición"
                      className="mr-2"
                    />
                    <FormattedMessage id="Envió muestras al tomador de desición" />
                  </label>
                </div>
              </div>

              <div className="kt-space-20" />
              <div className="row">
                <div className="col-sm-12">
                  <InputLabel error={Boolean(finishError)}>
                    <FormattedMessage id="Fecha Estimada de cierre *" />
                  </InputLabel>
                </div>
                <div className="col-7">
                  {' '}
                  <Field
                    name="finish.month"
                    component={Autocomplete}
                    variant="outlined"
                    options={monthOPTs}
                  />
                </div>
                <div className="col-5">
                  {' '}
                  <Field
                    name="finish.year"
                    component={Autocomplete}
                    variant="outlined"
                    options={yearOPTs}
                  />
                </div>
                <div className="col-sm-12">
                  <FormHelperText error={Boolean(finishError)}>
                    {finishError ? finishError : ''}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <Field
                    name="comment"
                    component={TextField}
                    type="text"
                    label="Comentarios del proyecto"
                    placeholder="Aqui puedes ingresar comentarios generales acerca del proyecto"
                    rows={7}
                    multiline
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export default Step4;
