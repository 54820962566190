export const wrappingColors = [
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' },
  { label: 'White', value: 'white' },
  { label: 'Black', value: 'black' },
  { label: 'Violet', value: 'violet' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Orange', value: 'orange' },
  { label: 'Grey', value: 'grey' },
  { label: 'Brown', value: 'brown' },
  { label: 'Green', value: 'green' },
  { label: 'Pink', value: 'pink' },
  { label: 'Rainbow', value: 'rainbow' },
].sort(compareLabel);

export const wrappingFinishes = [
  {
    color: 'red',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'red',
    label: 'Matte',
    value: 'matte',
  },
  {
    color: 'pink',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'red',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'blue',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'blue',
    label: 'Matte',
    value: 'matte',
  },
  {
    color: 'blue',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'white',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'white',
    label: 'Matte',
    value: 'matte',
  },

  {
    color: 'black',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'black',
    label: 'Matte',
    value: 'matte',
  },
  {
    color: 'black',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'black',
    label: 'Texture',
    value: 'texture',
  },
  {
    color: 'violet',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'yellow',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'yellow',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'orange',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'grey',
    label: 'Chrome',
    value: 'chrome',
  },
  {
    color: 'grey',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'grey',
    label: 'Matte',
    value: 'matte',
  },
  {
    color: 'grey',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'grey',
    label: 'Texture',
    value: 'texture',
  },
  {
    color: 'brown',
    label: 'Texture',
    value: 'texture',
  },
  {
    color: 'green',
    label: 'Gloss',
    value: 'gloss',
  },
  {
    color: 'green',
    label: 'Matte',
    value: 'matte',
  },
  {
    color: 'green',
    label: 'Satin',
    value: 'satin',
  },
  {
    color: 'green',
    label: 'Texture',
    value: 'texture',
  },
  {
    color: 'rainbow',
    label: 'Color Flips',
    value: 'colorFlips',
  },
].sort(compareLabel);

export const wrappingFilms = [
  {
    color: 'red',
    finish: 'gloss',
    label: 'Red Metallic *G203',
    value: 229,
  },
  {
    color: 'yellow',
    finish: 'satin',
    label: 'Bitter Yellow *S335',
    value: 239,
  },
  {
    color: 'grey',
    finish: 'matte',
    label: 'Silver *M21',
    value: 205,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Satin Flip Volcare Flare *SP236',
    value: 319,
  },
  {
    color: 'black',
    finish: 'texture',
    label: 'Matrix Black MX12',
    value: 233,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Dragon Fire Red *G363',
    value: 271,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Dark Red G83',
    value: 266,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Hot Rod Red G13',
    value: 284,
  },
  {
    color: 'orange',
    finish: 'gloss',
    label: 'Fiery Orange *G364',
    value: 231,
  },
  {
    color: 'orange',
    finish: 'gloss',
    label: 'Liquid Copper *G344',
    value: 291,
  },
  {
    color: 'orange',
    finish: 'gloss',
    label: 'Burnt Orange G14',
    value: 258,
  },
  {
    color: 'orange',
    finish: 'gloss',
    label: 'Deep Orange G24',
    value: 270,
  },
  {
    color: 'orange',
    finish: 'gloss',
    label: 'Bright Orange G54',
    value: 251,
  },
  {
    color: 'yellow',
    finish: 'gloss',
    label: 'Sunflower G25',
    value: 286,
  },
  {
    color: 'yellow',
    finish: 'gloss',
    label: 'Bright Yellow G15',
    value: 252,
  },
  {
    color: 'yellow',
    finish: 'gloss',
    label: 'Lucid Yellow G55',
    value: 292,
  },
  {
    color: 'green',
    finish: 'gloss',
    label: 'Green Envy *G336',
    value: 282,
  },
  {
    color: 'green',
    finish: 'gloss',
    label: 'Kelly Green G46',
    value: 287,
  },
  {
    color: 'green',
    finish: 'gloss',
    label: 'Light Green G16',
    value: 289,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Atomic Teal *G356',
    value: 237,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Midnight Blue GP272',
    value: 269,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Boat Blue G127',
    value: 250,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Deep Blue Metallic *G217',
    value: 269,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Blue Metallic *G227',
    value: 247,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Blue Raspberry *G378',
    value: 249,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Cosmic Blue *G377',
    value: 263,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Intense Blue G47',
    value: 286,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Blue Fire *G337',
    value: 246,
  },
  {
    color: 'blue',
    finish: 'gloss',
    label: 'Sky Blue G77',
    value: 305,
  },
  {
    color: 'pink',
    finish: 'gloss',
    label: 'Fierce Fuchsia *G348',
    value: 232,
  },
  {
    color: 'pink',
    finish: 'gloss',
    label: 'Hot Pink G103',
    value: 283,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Black Rose *GP99',
    value: 244,
  },
  {
    color: 'violet',
    finish: 'gloss',
    label: 'Plum Explosion *GP258',
    value: 320,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Ember Black *GP282',
    value: 272,
  },
  {
    color: 'black',
    finish: 'gloss',
    label: 'Galaxy Black *GP292',
    value: 275,
  },
  {
    color: 'black',
    finish: 'gloss',
    label: 'Black Metallic *G212',
    value: 242,
  },
  {
    color: 'black',
    finish: 'matte',
    label: 'Black Metallic M212',
    value: 243,
  },
  {
    color: 'black',
    finish: 'gloss',
    label: 'Black G12',
    value: 240,
  },
  {
    color: 'grey',
    finish: 'gloss',
    label: 'Anthracite *G201',
    value: 235,
  },
  {
    color: 'grey',
    finish: 'gloss',
    label: 'Charcoal Metallic *G211',
    value: 261,
  },
  {
    color: 'grey',
    finish: 'gloss',
    label: 'White Aluminum *G120',
    value: 313,
  },
  {
    color: 'grey',
    finish: 'gloss',
    label: 'Sterling Silver *G251',
    value: 308,
  },
  {
    color: 'grey',
    finish: 'gloss',
    label: 'Storm Gray G31',
    value: 309,
  },
  {
    color: 'white',
    finish: 'gloss',
    label: 'White Gold Sparkle *GP240',
    value: 315,
  },
  {
    color: 'white',
    finish: 'gloss',
    label: 'White G10',
    value: 291,
  },
  {
    color: 'yellow',
    finish: 'gloss',
    label: 'Light Ivory G79',
    value: 290,
  },
  {
    color: 'red',
    finish: 'satin',
    label: 'Vampire Red *SP273',
    value: 288,
  },
  {
    color: 'red',
    finish: 'satin',
    label: 'Smoldering Red *S363',
    value: 307,
  },
  {
    color: 'green',
    finish: 'satin',
    label: 'Apple Green S196',
    value: 236,
  },
  {
    color: 'blue',
    finish: 'satin',
    label: 'Key West S57',
    value: 288,
  },
  {
    color: 'blue',
    finish: 'satin',
    label: 'Ocean Shimmer *S327',
    value: 295,
  },
  {
    color: 'blue',
    finish: 'satin',
    label: 'Perfect Blue *S347',
    value: 297,
  },
  {
    color: 'blue',
    finish: 'satin',
    label: 'Thundercloud *S271',
    value: 311,
  },
  {
    color: 'grey',
    finish: 'satin',
    label: 'Dark Gray *S261',
    value: 265,
  },
  {
    color: 'black',
    finish: 'satin',
    label: 'Black S12',
    value: 245,
  },
  {
    color: 'black',
    finish: 'satin',
    label: 'Gold Dust Black *SP242',
    value: 280,
  },
  {
    color: 'grey',
    finish: 'satin',
    label: 'Battleship Gray S51',
    value: 238,
  },
  {
    color: 'grey',
    finish: 'satin',
    label: 'White Aluminum *S120',
    value: 314,
  },
  {
    color: 'grey',
    finish: 'satin',
    label: 'Frozen Vanilla *SP240',
    value: 274,
  },
  {
    color: 'white',
    finish: 'satin',
    label: 'White S10',
    value: 318,
  },
  {
    color: 'white',
    finish: 'satin',
    label: 'Pearl White *SP10',
    value: 296,
  },
  {
    color: 'red',
    finish: 'matte',
    label: 'Red Metallic *M203',
    value: 300,
  },
  {
    color: 'red',
    finish: 'matte',
    label: 'Red M13',
    value: 299,
  },
  {
    color: 'green',
    finish: 'matte',
    label: 'Pine Green Metallic *M206',
    value: 298,
  },
  {
    color: 'green',
    finish: 'matte',
    label: 'Military Green M26',
    value: 294,
  },
  {
    color: 'blue',
    finish: 'matte',
    label: 'Indigo M27',
    value: 285,
  },
  {
    color: 'blue',
    finish: 'matte',
    label: 'Slate Blue Metallic M217',
    value: 282,
  },
  {
    color: 'blue',
    finish: 'matte',
    label: 'Blue Metallic *M227',
    value: 248,
  },
  {
    color: 'black',
    finish: 'matte',
    label: 'Black M12',
    value: 241,
  },
  {
    color: 'black',
    finish: 'matte',
    label: 'Deep Black M22',
    value: 268,
  },
  {
    color: 'grey',
    finish: 'matte',
    label: 'Dead Matte Black DM12',
    value: 267,
  },
  {
    color: 'grey',
    finish: 'matte',
    label: 'Dark Gray *M261',
    value: 264,
  },
  {
    color: 'grey',
    finish: 'matte',
    label: 'Gray Aluminum *M230',
    value: 281,
  },
  {
    color: 'white',
    finish: 'matte',
    label: 'White M10',
    value: 317,
  },
  {
    color: 'grey',
    finish: 'matte',
    label: 'Charcoal Metallic *M211',
    value: 262,
  },
  {
    color: 'brown',
    finish: 'matte',
    label: 'Brown Metallic *M209',
    value: 253,
  },
  {
    color: 'green',
    finish: 'texture',
    label: 'Shadow Military Green SB26',
    value: 304,
  },
  {
    color: 'brown',
    finish: 'texture',
    label: 'Shadow Black SB12',
    value: 303,
  },
  {
    color: 'black',
    finish: 'texture',
    label: 'Brushed Black Metallic *BR212',
    value: 255,
  },
  {
    color: 'grey',
    finish: 'texture',
    label: 'Carbon Fiber Black CFS12',
    value: 260,
  },
  {
    color: 'grey',
    finish: 'texture',
    label: 'Brushed Steel *BR201',
    value: 256,
  },
  {
    color: 'grey',
    finish: 'texture',
    label: 'Brushed Titanium *BR230',
    value: 257,
  },
  {
    color: 'grey',
    finish: 'texture',
    label: 'Brushed Aluminum *BR120',
    value: 254,
  },
  {
    color: 'grey',
    finish: 'texture',
    label: 'Carbon Fiber Anthracite *CFS201',
    value: 259,
  },
  {
    color: 'grey',
    finish: 'chrome',
    label: 'Gloss Silver Chrome *GC451',
    value: 279,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Gloss Flip Psychedelic *GP281',
    value: 278,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Gloss Flip Ghost Pearl *GP280',
    value: 277,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Gloss Flip Deep Space *GP278',
    value: 276,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Satin Flip Psychedelic *SP281',
    value: 302,
  },
  {
    color: 'rainbow',
    finish: 'colorFlips',
    label: 'Satin Flip Ghost Pearl *SP280',
    value: 301,
  },
  {
    color: 'red',
    finish: 'gloss',
    label: 'Flame Red G53',
    value: 273,
  },
].sort(compareLabel);

function compareLabel(a, b) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}
