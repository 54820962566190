import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

export const Warranty = ({ values }) => (
  <>
    <div className="row">
      <div className="col-12 ViewOrEdit">
        <div>
          <strong>
            <FormattedMessage id="Código de garantía:" />
          </strong>
          <span>{get(values, 'code', '-')}</span>
        </div>
      </div>
    </div>
  </>
);

export default Warranty;
