import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';
import ctn from 'helpers/countries';

export const Business = ({ values }) => (
  <div className="row">
    <div className="col-12 ViewOrEdit">
      <div>
        <strong>
          <FormattedMessage id="País:" />
        </strong>
        <span>{ctn(get(values, 'country', '-'))}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Nombre del negocio:" />
        </strong>
        <span>{get(values, 'name', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Razon social:" />
        </strong>
        <span>{get(values, 'name', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="CUIT:" />
        </strong>
        <span>{get(values, 'taxId', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Calle:" />
        </strong>
        <span>{get(values, 'address', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Número:" />
        </strong>
        <span>{get(values, 'number', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Código postal:" />
        </strong>
        <span>{get(values, 'postalCode', '-')}</span>
      </div>
      <div className="kt-space-20" />
      <div>
        <strong>
          <FormattedMessage id="Provincia:" />
        </strong>
        <span>{get(values, 'state', '-')}</span>
      </div>
      <div className="kt-space-20" />
      {/* <div>
        <strong>
          <FormattedMessage id="Ciudad:" />
        </strong>
        <span>{get(values, 'city', '-')}</span>
      </div> */}
      {/* <div className="kt-space-20" />
      <div>
        <strong>Distribuidor:</strong>
        <span>no esta</span>
      </div> */}
    </div>
  </div>
);

export default Business;
