import './index.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';

import TextField from 'components/form/input';
import PhoneIcon from '@material-ui/icons/Phone';

import {
  getCountryProfile,
  getProfile,
  getStatusProfile,
} from 'redux/profile/selector';
import {
  requests as profileRequest,
  actions as profileActions,
} from 'redux/profile/reducer';

import ProfileNav from '../profileNav';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const initialValues = {
  firstname: '',
  lastname: '',
  //email: '',
  cellphone: '',
  // phone: '',
};

const validationSchema = (intl) =>
  Yup.object().shape({
    firstname: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un nombre' }),
    ),
    lastname: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un apellido' }),
    ),
    //email: Yup.string()
    //  .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
    //  .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
    cellphone: Yup.string()
      .phone(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }))
      .required(intl.formatMessage({ id: 'Debe ingresar un teléfono válido' })),
    // phone: Yup.string().phone(
    //   intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }),
    // ),
  });
const breadcrumb = [{ page: 'profile/resume', title: 'Mis datos' }];

export const ContactView = ({
  intl,
  onSubmit,
  user,
  isLoading,
  hasError,
  isSaveSuccess,
  clearSave,
}) => {
  useEffect(() => () => clearSave(), [clearSave]);
  useEffect(() => {
    if (!isLoading && isSaveSuccess) {
      toast.success(
        <FormattedMessage id="La información ha sido actualizada." />,
        toastConfig,
      );
    }
  }, [isLoading, isSaveSuccess]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se ha producido un error, vuelva a intentar más tarde." />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const history = useHistory();

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return onSubmit(values);
  };

  return (
    <Layout>
      {Boolean(Object.keys(user).length) && (
        <Formik
          initialValues={{ ...initialValues, ...user }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema(intl)}
        >
          {() => (
            <Form>
              <div className="ContactView">
                <div className="kt-space-20" />
                <div className="row">
                  <div className="col-12">
                    <div className="kt-space-20" />
                    <Breadcrumbs title="Mis datos" items={breadcrumb} />
                    <div className="kt-space-20" />
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Portlet className="kt-portlet--height-fluid">
                        <PortletBody>
                          <ProfileNav active="contact" />
                        </PortletBody>
                      </Portlet>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <Portlet className="kt-portlet--height-fluid">
                        <PorletHeader>
                          <PorletHeaderTitle variant="h4">
                            <FormattedMessage id="Información de contacto" />
                          </PorletHeaderTitle>
                        </PorletHeader>
                        <PortletBody>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-1" />
                            <div className="col-sm-12 col-md-12 col-lg-10">
                              <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4" />
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Typography variant="h5">
                                    <FormattedMessage id="Contacto" />
                                  </Typography>
                                </div>
                              </div>
                              <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <strong>
                                    <FormattedMessage id="Nombre: *" />
                                  </strong>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Field
                                    name="firstname"
                                    component={TextField}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Nombre del cliente"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </div>
                              </div>
                              <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <strong>
                                    <FormattedMessage id="Apellido: *" />
                                  </strong>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Field
                                    name="lastname"
                                    component={TextField}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Apellido del cliente"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </div>
                              </div>
                              {/*<div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <strong>
                                    <FormattedMessage id="Correo electronico: *" />
                                  </strong>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Field
                                    name="email"
                                    component={TextField}
                                    type="text"
                                    helperText="No compartiremos el Email con nadie mas"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Ingresar dirección de email"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <AlternateEmailOutlinedIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </div>*/}
                              <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <strong>
                                    <FormattedMessage id="Celular: *" />
                                  </strong>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Field
                                    name="cellphone"
                                    component={TextField}
                                    type="text"
                                    helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Cód. de país + cód. de área + número"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PhoneIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="row FormRow">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <strong>
                                    <FormattedMessage id="Teléfono:" />
                                  </strong>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <Field
                                    name="phone"
                                    component={TextField}
                                    type="text"
                                    helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Cód. de país + cód. de área + número"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PhoneIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-1" />
                          </div>
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Portlet className="kt-portlet--height-fluid">
                      <PortletBody>
                        <div className="submit-bottons">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Guardar" />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.goBack()}
                          >
                            <FormattedMessage id="Volver" />
                          </Button>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(profileRequest.editContact({ body: values })),
  clearSave: () => dispatch(profileActions.clearSave()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getProfile(state),
      ...getStatusProfile(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(ContactView),
);
