import React, { useState, useEffect, useRef } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toAbsoluteUrl } from '_metronic/utils/utils';
import '_metronic/_assets/sass/pages/end-user/end-user.scss';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { getStatesOptions } from 'redux/states/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import Autocomplete from 'components/form/autocomplete';
import { set } from 'lodash';
import { LATAM_LABEL, EUROPE_LABEL } from 'enums/regions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const baseURL = window.env.API_URL;

function ShowForm(props) {
  const { intl, hasError, setLanguage, fetchStates, stateOptions } = props;
  const queryParams = useQuery();
  const [downloadLink, setDownloadLink] = useState(downloadLink);
  const [userInfo, setUserInfo] = useState(userInfo);
  const [stateFieldValue, setStateFieldValue] = useState(0);
  const [profileFieldValue, setProfileFieldValue] = useState(0);
  const [wizardStep, setWizardStep] = useState(wizardStep);
  const [isLoading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const profileOptions = [
    { label: 'Instalador', value: 'INSTALLER' },
    { label: 'Distribuidor', value: 'DISTRIBUTOR' },
  ];

  const enableLoading = () => {
    // setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    // setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  const country = queryParams.get('lang')?.toUpperCase() ?? 'AR';

  let countryOptions = [];
  let showAuto = false;
  let showBuilding = false;
  let showDinoc = false;
  let showFasara = false;

  if (country == 'BR') {
    countryOptions = [{ label: 'Brasil', value: 'BR' }];
    showAuto = true;
    showBuilding = true;
    showDinoc = true;
    showFasara = true;
  } else if (
    country == 'EN' ||
    country == 'GB' ||
    country == 'DK' ||
    country == 'SE' ||
    country == 'NO' ||
    country == 'PT' ||
    country == 'FR' ||
    country == 'DE' ||
    country == 'IT' ||
    country == 'CH' ||
    country == 'AT' ||
    country == 'NL' ||
    country == 'ES' ||
    country == 'FI'
  ) {
    countryOptions = EUROPE_LABEL;
    showAuto = false;
    showBuilding = true;
    showDinoc = true;
    showFasara = true;
  } else {
    countryOptions = LATAM_LABEL;
    showAuto = true;
    showBuilding = true;
    showDinoc = true;
    showFasara = true;
  }

  const [selectedOptions, setSelectedOptions] = useState({
    auto: false,
    building: false,
    dinoc: false,
    fasara: false,
  });
  const [error, setError] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    // Cambia selectedOptions.length a Object.values(selectedOptions).some(Boolean)
    if (Object.values(selectedOptions).some(Boolean) && !error) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [selectedOptions, error]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setSelectedOptions((prevState) => {
      const newState = {
        ...prevState,
        [name]: checked,
      };
      console.log(newState);
      return newState;
    });
  };

  const handleContinue = () => {
    if (!Object.values(selectedOptions).some(Boolean)) {
      setError('Debes seleccionar al menos una opción');
    } else {
      setError('');
    }
  };

  useEffect(() => {
    const lang = queryParams.get('lang') ?? 'ar';
    if (lang == 'br') {
      setLanguage('br');
    } else if (lang == 'en' || lang == 'gb') {
      setLanguage('gb');
    } else {
      setLanguage(lang.toLowerCase());
    }
  }, [queryParams, setLanguage]);

  useEffect(() => {
    if (isLoading) {
      enableLoading();
    } else {
      disableLoading();
    }
  }, [isLoading]);

  if (wizardStep == undefined) {
    setWizardStep(1);
  }

  useEffect(() => {
    fetchStates({ country });
  }, []);

  const initialValuesRef = useRef({
    auto: false,
    building: false,
    dinoc: false,
    fasara: false,
  });

  useEffect(() => {
    initialValuesRef.current = {
      ...initialValuesRef.current,
      auto: selectedOptions.auto,
      building: selectedOptions.building,
      dinoc: selectedOptions.dinoc,
      fasara: selectedOptions.fasara,
    };
  }, [selectedOptions]);

  function wizard_step_one() {
    return (
      <>
        <div className="sep">
          <p className="enduser-form-title">
            {intl.formatMessage({ id: 'Solicitud de alta' })}
          </p>
        </div>
        <Formik
          initialValues={{
            firstname: '',
            surname: '',
            email: '',
            password: '',
            profile: '',
            company: '',
            compan_name: '',
            street: '',
            number: '',
            zip: '',
            country: country,
            initialValuesRef,
            // state: '',
            acceptedInfo: false,
          }}
          validate={(values) => {
            console.log(values);

            const errors = {};
            if (!values.firstname) {
              errors.firstname = intl.formatMessage({
                id: 'Debe ingresar un nombre',
              });
            }
            if (!values.surname) {
              errors.surname = intl.formatMessage({
                id: 'Debe ingresar un apellido',
              });
            }

            if (
              !values.email ||
              !values.email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              )
            ) {
              errors.email = intl.formatMessage({
                id: 'Debe ingresar un email',
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: 'Debe ingresar un password',
              });
            }

            if (!values.profile) {
              errors.profile = intl.formatMessage({
                id: 'Debe seleccionar un perfil',
              });
            }

            if (!values.company) {
              errors.company = intl.formatMessage({
                id: 'Debe ingresar un nombre',
              });
            }

            if (!values.company_name) {
              errors.company_name = intl.formatMessage({
                id: 'Debe ingresar un nombre',
              });
            }

            if (!values.street) {
              errors.street = intl.formatMessage({
                id: 'Debe ingresar una calle',
              });
            }
            if (!values.number) {
              errors.number = intl.formatMessage({
                id: 'Debe ingresar un número',
              });
            }
            if (!values.zip) {
              errors.zip = intl.formatMessage({
                id: 'Debe ingresar un código postal',
              });
            }

            if (!values.country) {
              errors.country = intl.formatMessage({
                id: 'Debe seleccionar un país',
              });
            }

            // if (!values.state) {
            //   errors.state = intl.formatMessage({
            //     id: 'Debe seleccionar una provincia',
            //   });
            // }

            return errors;
          }}
          onSubmit={(values) => {
            setUserInfo(values);
            setLoading(true);
            setWizardStep(2);
            setLoading(false);
            axios
              //a definir el endpoint
              .post(`${baseURL}/register/user`, {
                ...values,
                auto: selectedOptions.auto,
                building: selectedOptions.building,
                dinoc: selectedOptions.dinoc,
                fasara: selectedOptions.fasara,
              })
              .then((res) => {
                console.log(res.data);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form>
              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Nombre*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Nombre: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="firstname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.firstname}
                  helpertext={touched.firstname && errors.firstname}
                  //error={Boolean(touched.name && errors.name)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="firstname" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Apellido*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Apellido: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="surname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.surname}
                  helpertext={touched.surname && errors.surname}
                  //error={Boolean(touched.surname && errors.surname)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="surname" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Email*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Usuario EMAIL: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.email}
                  helpertext={touched.email && errors.email}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="email" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*password*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Password: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.password}
                  helpertext={touched.password && errors.password}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text pr-4">
                    {intl.formatMessage({
                      id: 'REGISTER_PASSWORD_PLACEHOLDER',
                    })}
                  </p>
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="password" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Perfil*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Perfil: *',
                  })}
                </h5>
                <div className="select-container ">
                  <Field
                    className="enduser-content-form-input enduser-select reduced-margin-bot"
                    name="profile"
                    component={Autocomplete}
                    placeholder={intl.formatMessage({
                      id: 'Seleccionar',
                    })}
                    variant="outlined"
                    options={profileOptions}
                    required
                  />
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Company*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Empresa: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.company}
                  helpertext={touched.company && errors.company}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="company" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Company*/}
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Razon Social: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="company_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.company_name}
                  helpertext={touched.company_name && errors.company_name}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="company_name" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Calle: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.street}
                  helpertext={touched.street && errors.street}
                  error={Boolean(touched.street && errors.street)}
                />{' '}
                <div className="  MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-text error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="street" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Número: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.number}
                  helpertext={touched.number && errors.number}
                  //error={Boolean(touched.number && errors.number)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="number" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Código Postal: *',
                  })}
                </h5>
                <Field
                  variant="outlined"
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="zip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.zip}
                  helpertext={touched.zip && errors.zip}
                  //error={Boolean(touched.zip && errors.zip)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="zip" />
                  </p>
                </div>
              </div>

              {/* <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Pais: *',
                  })}
                </h5>
                <div className="select-container ">
                  <Field
                    className="enduser-content-form-input enduser-select reduced-margin-bot"
                    name="country"
                    component={Autocomplete}
                    // handleChange={(value) => {
                    //   fetchStates({ value });
                    // }}
                    placeholder={intl.formatMessage({
                      id: 'Seleccionar',
                    })}
                    variant="outlined"
                    options={countryOptions}
                  />
                </div>
              </div> */}

              {/* <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Provincia: *',
                  })}
                </h5>
                <div className="select-container ">
                  <Field
                    className="enduser-content-form-input enduser-select reduced-margin-bot"
                    name="state"
                    component={Autocomplete}
                    handleChange={() => {
                      
                    }}
                    placeholder={intl.formatMessage({
                      id: 'Seleccionar',
                    })}
                    variant="outlined"
                    options={stateOptions}
                  />
                </div>
              </div> */}
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Porfolio: *',
                  })}
                </h5>
                <fieldset>
                  <label className="check-text">
                    <input
                      type="checkbox"
                      margin="left"
                      name="auto"
                      value="auto"
                      onChange={handleCheckboxChange}
                      checked={selectedOptions.auto}
                    />{' '}
                    Automóvil
                  </label>

                  <label className="check-text">
                    <input
                      type="checkbox"
                      margin="left"
                      name="building"
                      value="building"
                      onChange={handleCheckboxChange}
                      checked={selectedOptions.building}
                    />{' '}
                    Arquitectura
                  </label>

                  <label className="check-text">
                    <input
                      type="checkbox"
                      margin="left"
                      name="dinoc"
                      value="dinoc"
                      onChange={handleCheckboxChange}
                      checked={selectedOptions.dinoc}
                    />{' '}
                    DI-NOC™ Architectural Finishes
                  </label>

                  <label className="check-text">
                    <input
                      type="checkbox"
                      margin="left"
                      name="fasara"
                      value="fasara"
                      onChange={handleCheckboxChange}
                      checked={selectedOptions.fasara}
                    />{' '}
                    Glass Finishes
                  </label>
                </fieldset>
              </div>
              {error && (
                <p style={{ color: 'red', marginLeft: '40px' }}>{error}</p>
              )}

              <div className="form-group reduced-margin-bot">
                <label className="check-text">
                  <Field
                    type="checkbox"
                    margin="left"
                    name="acceptedInfo"

                    //onChange={handleChange}

                    //helpertext={touched.street && errors.street}
                    //error={Boolean(touched.street && errors.street)}
                  />{' '}
                  {intl.formatMessage({
                    id:
                      'Acepto recibir información comercial o comunicaciones de 3M™',
                  })}
                </label>
              </div>

              <div id="enduser-content-form-button-container">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={!canSubmit}
                  enableLoading={isLoading}
                  onClick={handleContinue}
                  className={` mb-2 btn btn-primary btn-elevate kt-login__btn-primary enduser-content-form-button
                        )}`}
                >
                  {intl.formatMessage({ id: 'Enviar' })}
                </button>
                <label className="check-text">
                  {intl.formatMessage({
                    id: '* Requerido',
                  })}
                </label>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  function wizard_step_final() {
    return (
      <>
        <div className="sep">
          <p className="step-2-title">
            {intl.formatMessage({ id: 'Gracias por registrarte.' })}
          </p>
        </div>
        <p className="step-final darker text-center">
          {intl.formatMessage({ id: 'REGISTER_FORM_INSTALLER_END_1' })} <br />
          {intl.formatMessage({ id: 'REGISTER_FORM_INSTALLER_END_2' })} <br />
          <span style={{ fontSize: '10px' }}>
            {intl.formatMessage({ id: 'REGISTER_FORM_INSTALLER_END_3' })}{' '}
          </span>
        </p>
      </>
    );
  }

  return (
    <>
      <div
        className="container-fluid full-page"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg_enduser.jpg')})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          //backgroundImage: `url(${toAbsoluteUrl('/media/bg/xmas/bg-xmas.jpg',)})`, //xmas remove
        }}
      >
        <div className="row">
          <div className="col-xs-12 col-lg-7">
            <img
              width="auto"
              height="50"
              src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-BLANCO' }))}
              alt="logo 3M"
              className="pt-3 pl-2"
            />
            <div className="d-none d-lg-block">
              <p className="end-user-thanks">
                {intl.formatMessage({ id: 'REGISTER_FORM_INSTALLER_TITLE_1' })}{' '}
                {/* <br />
                {intl.formatMessage({
                  id: 'REGISTER_FORM_INSTALLER_TITLE_2',
                })}{' '} */}
              </p>
              <p className="end-user-complete">
                {intl.formatMessage({
                  id:
                    'Por favor ingresá tu información aquí para obtener solicitar tu usuario.',
                })}
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-lg-5">
            <div className="end-user-form">
              <div className="end-user-form-bg">
                {wizardStep == 1 ? (
                  wizard_step_one()
                ) : wizardStep == 2 ? (
                  wizard_step_final()
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export const mapStateToProps = (state) => ({
  stateOptions: getStatesOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ShowForm),
);
