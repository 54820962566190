import * as Yup from 'yup';
import { EUROPE } from 'enums/regions';

export default (intl, country) => {
  const isEuropeCountry = EUROPE.includes(country);

  return Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar un tipo válido' }),
        ),
      postalCode: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un código postal' }),
      ),
      state: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un país' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar un país válido' }),
        ),
      /* needCase: Yup.string()
        .when([], {
          is: () => isEuropeCountry,
          then: Yup.string().required(
            intl.formatMessage({ id: 'Debe seleccionar una opción' }),
          ),
          otherwise: Yup.string().notRequired(),
        })
        .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar una opción válido' }),
        ), */
      installers: Yup.array().of(
        Yup.object().test(
          'hasInstaller',
          intl.formatMessage({ id: 'Debe ingresar un instalador' }),
          (value) => Boolean(value.id),
        ),
      ),
    }),
  });
};
