export const arqSolProducts = [
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Prestige Interior',
    description:
      'Las películas de la serie Prestige estan diseñadas para mantener la imagen de su hogar. Poseen baja reflectividad interior y exterior, mejorando la visibilidad al mismo tiempo que mantienen el aspecto exterior de su hogar u oficina.',
    img: 'prestige_int.jpg',
    degrade: 'red',
    flyer_url: 'prestige_int.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 15 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Prestige Exterior',
    description:
      'Las películas de la serie Prestige Exterior brindan hasta un 97 % de rechazo de los rayos infrarrojos, lo que proporciona ahorros de energía y aumento del confort. Lleve la comodida, el ahorro y la estética a un nuevo nivel.',
    img: 'prestige_ext.jpg',
    degrade: 'blue',
    flyer_url: 'prestige_ext.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años vertical y 7 años horizontal*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Night Vision',
    description:
      'Las películas de la serie Night Vision brindan alto rechazo del calor que ayuda a proporcionar ahorros de energía y una mayor comodidad. Disfrute de una visibilidad mejorada con baja reflectividad interior, especialmente de noche.',
    img: 'night_vision.jpg',
    degrade: 'green',
    flyer_url: 'night_vision.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 12 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Affinity',
    description:
      'Película altamente reflectiva de grado comercial. Las películas serie Affinity rechazan hasta el 79% de la energía solar. Mejorando el desempeño y la comodidad dentro de su edificio',
    img: 'affinity.jpg',
    degrade: 'black',
    flyer_url: 'affinity.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Neutral',
    description:
      'Rendimiento solar. Aspecto neutro. Las películas serie Neutral brindan un alto rechazo al calor, lo que genera ahorro de energía y mayor confort. Los tonos suaves hacen que esta película sea muy atractiva, tanto en entornos residenciales como comerciales.',
    img: 'neutral.jpg',
    degrade: 'red',
    flyer_url: 'neutral.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Silver P18',
    description:
      'Película reflectiva clásica color plata con excelente protección solar. Las películas serie Silver P18 brindarn gran rechazo del calor, ayudan a proporcionar ahorros de energía y una mayor comodidad.',
    img: 'silverp18.jpg',
    degrade: 'red',
    flyer_url: 'silverp18.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
];

export const arqClimateCont = [
  /*{
    type: 'Película para ventanas 3M™',
    name: 'Serie ThinsulateTM',
    description:
      'Aislamiento casi invisible. Mejore la comodidad y la eficiencia durante todo el año. Las películas serie Thinsulate ayudan a mejorar la comodidad durante los meses fríos de invierno. Además durante el de verano aumentan el rechazo del calor proporcionando comodidad y ahorro de energía.',
    img: 'thinsulate.jpg',
    degrade: 'orange',
    flyer_url: 'thinsulate.pdf',
    es: true,
    br: false,
  },*/
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Low E',
    description:
      'Ahorre energía, aumente la comodidad y disfrute una amortización rápida. Las películas serie Low E de baja emisividad mejoran el confort durante los meses fríos de invierno y durante el calor del verano. Reducen el resplandor, lo que ayuda a mejorar la productividad en las estaciones de trabajo.',
    img: 'lowe.jpg',
    degrade: 'yellow',
    flyer_url: 'lowe.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: false,
  },
];

export const arqSecurity = [
  {
    type: 'Película para ventanas 3M™ Scotchshield™',
    name: 'Serie Ultra 800',
    description:
      'Película con microcapas resistente al rasgado, para mejorar la protección de las personas, propiedad y posesiones. Las películas serie Ultra mitigan los riesgos de las esquirlas de vidrios generados en desastres naturales y aumenta la seguridad y protección contra los robos oportunistas.',
    img: 'ultra800.jpg',
    degrade: 'orange',
    flyer_url: 'ultra800.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Safety',
    description:
      'Las películas serie Safety mitigan los riesgos de las esquirlas de vidrio generados por causas naturales y humanas. Aumentan la seguridad y ofrece protección adicional contra los robos oportunistas.',
    img: 'safety.jpg',
    degrade: 'yellow',
    flyer_url: 'safety.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 10 años*',
    es: true,
    br: true,
  },
  {
    type: 'Película para ventanas 3M™',
    name: 'Serie Safety exterior',
    description:
      'Película de seguridad que mantiene el vidrio roto unido para proteger a las personas, la propiedad y las posesiones. Las películas serie Safety Exterior son diseñada para aplicaciones exteriores e interiores. Protección duradera en la que usted y los ocupantes pueden confiar.',
    img: 'safety_ext.jpg',
    degrade: 'blue',
    flyer_url: 'safety_ext.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 7 años vertical y 5 años horizontal*',
    es: true,
    br: true,
  },
];

export const arqSpecial = [
  {
    type: 'Película para ventanas 3M™',
    name: 'Antigrafiti',
    description:
      'Derrote a los vándalos con protección contra grafitis. Las películas Antigrafiti protegen la superficie contra la mayoría de los rayones, grabados con ácido, ranuras y marcas. Además son de fácil extracción.',
    img: 'antigrafiti.jpg',
    degrade: 'orange',
    flyer_url: 'antigrafiti.pdf',
    description2: '*Ver detalles en el documento de garantía.',
    years: 'Garantia Limitada: 1 año*',
    es: true,
    br: true,
  },
];
