import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Stars from './stars';
import Progress from './progress';
import Total from './total';
import { getCalificationWidget } from 'redux/dashboard/selector';

const Calification = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Calificaciones" />
      </PorletHeaderTitle>
    </PorletHeader>
    {!isLoading && data && (
      <>
        <PortletBody>
          <Stars value={data.nps} maxValue={5} />
          <div className="kt-space-20" />
          <Progress label="Calificacion 1" value={data.q1} />
          <div className="kt-space-20" />
          <Progress label="Calificacion 2" value={data.q2} />
          <div className="kt-space-20" />
          <Progress label="Calificacion 3" value={data.q3} />
        </PortletBody>
        <Total label="Clientes que calificaron" value={data.count} />
      </>
    )}
  </Portlet>
);

export default connect(getCalificationWidget)(Calification);
