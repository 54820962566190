import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getCities = (state) => get(state, 'cities.data');

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: act.name,
        id: act.id,
      },
    ];
  }, []);

export const getCitiesOptions = createSelector(
  getCities,
  makeAtoCompleteOptions,
);
