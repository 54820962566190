import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import {
  requests as clientViewRequests,
  actions as clientViewAcrions,
} from 'redux/distributors/distributorView/reducer';

import { getClientData } from 'redux/distributors/distributorView/selector';

import Business from './business';
// import Contact from './contact';
// import Installer from './installer';

const breadcrumb = [{ page: 'distributor/client', title: 'Mis clientes' }];

export const ViewClient = ({ getClient, clearData, clientData, ...props }) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Mis clientes" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>
      {Boolean(Object.keys(clientData).length) && (
        <div className="ViewCarWarranty">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Negocio" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Business values={clientData} {...props} />
                </PortletBody>
              </Portlet>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Contacto" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Contact values={clientData} {...props} />
                    </PortletBody>
                  </Portlet>
                </div>
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Instaladores" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Installer values={clientData} {...props} />
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12">
              <Portlet className="kt-portlet--height-fluid">
                <PortletBody className="submit-bottons">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    <FormattedMessage id="Volver" />
                  </Button>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getClient: (data) => dispatch(clientViewRequests.fetch(data)),
  clearData: () => dispatch(clientViewAcrions.clearData()),
});

export default connect(getClientData, mapDispatchToProps)(ViewClient);
