import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'buildings.newProject';

export const initialState = {
  ...requestInitialState,
  data: {},
  isSuccess: false,
};

const newProjectSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    create(state, { payload }) {
      return {
        ...state,
        data: { ...payload },
        isSuccess: true,
      };
    },
    clear() {
      return { ...initialState };
    },
  },
});

export const actions = { ...newProjectSlice.actions };
export const requests = { ...newProjectSlice.requests };

export default newProjectSlice.reducer;
