import { all, put, takeEvery, call } from 'redux-saga/effects';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { actions as profileActions } from './reducer';

export function* setLanguge({ payload }) {
  if (payload) {
    console.log('Lenguage a setear', payload);
    yield put(i18nActions.setLanguage(payload.country.toLowerCase()));
  } else {
    if (window.navigator.language.slice(0, 2) !== 'pt') {
      yield put(i18nActions.setLanguage('ar'));
    } else {
      yield put(i18nActions.setLanguage('br'));
    }
  }
}

export function* updateLang() {
  yield call(setLanguge, {});
}

export default function* () {
  yield all([
    takeEvery('persist/REHYDRATE', updateLang),
    takeEvery(profileActions.fetch, setLanguge),
  ]);
}
