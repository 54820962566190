/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';
import { toAbsoluteUrl } from '../../utils/utils';
import { injectIntl } from 'react-intl';

class Brand extends React.Component {
  render() {
    const { intl, brandClasses } = this.props;
    return (
      <div
        className={`kt-header__brand ${brandClasses} kt-grid__item`}
        id="kt_header_brand"
      >
        <div className="kt-header__brand-logo">
          <Link to="/">
            <img
              className="kt-header__brand-logo-default"
              alt="logo"
              width="250"
              // src={toAbsoluteUrl('/media/logos/logo-es-negro.png')}
              src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-NEGRO' }))}
            />

            <img
              className="kt-header__brand-logo-sticky"
              alt="logo"
              width="200"
              // src={toAbsoluteUrl('/media/logos/logo-es-negro.png')}
              src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-NEGRO' }))}
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: 'brand',
      toString: true,
    }),
  };
};

export default injectIntl(connect(mapStateToProps)(Brand));
