import React from 'react';
import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';

export const Sheets = ({ values }) => {
  const Sheets = get(values, 'data.applications', []) || [];

  return (
    <div>
      {Boolean(Sheets.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="3M™ Glass Finishes" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Sheets.map((sheet, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Categoría" />
                    </strong>
                    <span className="w-50">{sheet.glassFinish}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Familia de producto" />
                    </strong>
                    <span className="w-50">{sheet.family}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Producto" />
                    </strong>
                    <span className="w-50">{sheet.film}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="M2 Necesarios:" />
                    </strong>
                    <span className="w-50">{sheet.meters}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span className="w-50">{sheet.distributor.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Vista Previa" />
                    </strong>
                    <div className="w-50">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/media/fasara/' +
                          sheet.film +
                          '.jpg'
                        }
                        className="img-previewer-summary w-50 "
                      />
                    </div>
                  </div>
                  {/*<div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>*/}
                  {Sheets.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Sheets;
