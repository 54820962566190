import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';

import { getDistributorsOptions } from 'redux/distributors/selector';

import { requests as distributorsRequest } from 'redux/distributors/reducer';

import {
  glassFinishesCategories,
  glassFinishesFilms,
  glassFinishesFamilies,
} from 'enums/glassFinishesFilms';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import '../../newFasaraExpress/form/step3/previewer.scss';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const newSheet = (type) => ({
  type: '',
  meters: '',
  distributor: {},
  custom: '',
  glassFinish: '',
  film: '',
  family: '',
});

export const initialValues = {
  data: {
    ...newSheet(''),
  },
};

export const Sheets = ({ values, fetchDistributors, distributorsOptions }) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  const Sheets = get(values, 'data.applications', []) || [];

  return (
    <>
      <PorletHeaderTitle variant="h5">
        <FormattedMessage id="3M Glass Finishes" />
      </PorletHeaderTitle>
      <FieldArray name="data.applications">
        {({ push, remove }) => (
          <>
            {console.log(Sheets)}
            {Sheets.map((sheet, index) => {
              return (
                <>
                  {console.log(sheet)}
                  <div key={index}>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Categoría" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].glassFinish`}
                          component={Autocomplete}
                          variant="outlined"
                          options={glassFinishesCategories}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Familia de producto" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].family`}
                          component={Autocomplete}
                          variant="outlined"
                          options={glassFinishesFamilies}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Producto" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].film`}
                          component={Autocomplete}
                          variant="outlined"
                          options={glassFinishesFilms}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="M2 Necesarios: *" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].meters`}
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    {sheet.custom === '' ? (
                      <></>
                    ) : (
                      <div className="row FormRow">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <FormattedMessage id="Descripción" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <Field
                            name={`data.applications[${index}].custom`}
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Vista Previa" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <img
                          src={
                            (values.data.applications[index].film != '' &&
                              values.data.applications[index].film.id) != ''
                              ? process.env.PUBLIC_URL +
                                '/media/fasara/' +
                                values.data.applications[index].film +
                                '.jpg'
                              : process.env.PUBLIC_URL +
                                '/media/fasara/custom.jpg'
                          }
                          className="img-previewer-summary w-100 "
                        />
                      </div>
                    </div>

                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Distribuidor: *" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].distributor.id`}
                          component={Autocomplete}
                          variant="outlined"
                          options={distributorsOptions}
                          placeholder="Seleccionar"
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <FormattedMessage id="Número de RUN: *" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name={`data.applications[${index}].run`}
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      {Sheets.length > 1 && (
                        <>
                          <div className="col-12 kt-space-20" />
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon color="primary" />
                              <FormattedMessage id="Eliminar esta lámina" />
                            </Button>
                            <div className="col-12 kt-space-12" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            {Sheets.length < 4 && (
              <>
                <div className="kt-space-20" />
                <div className="row">
                  <div className="col-12">
                    <Button
                      size="small"
                      variant="outlined"
                      fullWidth
                      onClick={() => push(newSheet(2))}
                    >
                      <FormattedMessage id="Agregar nueva lámina" />
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </FieldArray>
      <div className="kt-space-20" />

      <div className="kt-space-20" />
    </>
  );
};

export const mapStateToProps = (state) => ({
  distributorsOptions: getDistributorsOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sheets);
