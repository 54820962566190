import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'filmsBuilding';

export const initialState = {
  ...requestInitialState,
  data: [],
};

const filmsBuildingSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    fetch(state, { payload }) {
      return {
        ...state,
        data: [...payload],
      };
    },
  },
});

export const actions = { ...filmsBuildingSlice.actions };
export const requests = { ...filmsBuildingSlice.requests };

export default filmsBuildingSlice.reducer;
