import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    projectStatus: Yup.string()
      .required(intl.formatMessage({ id: 'Debe seleccionar un estatus' }))
      .transform((v, o) => (o === null ? '' : v)),
    finish: Yup.object()
      .test(
        'hasFinishDate',
        intl.formatMessage({ id: 'Debe ingresar una fecha de cierre' }),
        (value) => Boolean(value.month && value.year),
      )
      .test(
        'isFinishDateValid',
        intl.formatMessage({
          id: 'La fecha estimada no puede ser anterior al día de hoy',
        }),
        (value) => {
          const today = new Date();
          let month = today.getMonth();
          let year = today.getFullYear();
          if (year > parseInt(value.year)) {
            return false;
          } else {
            if (year === parseInt(value.year)) {
              if (month + 1 > parseInt(value.month)) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        },
      ),

    // finish: {
    //   month: Yup.string('Debe seleccionar un mes').required(
    //     'Debe seleccionar un mes',
    //   ),
    //   year: Yup.string('Debe seleccionar un año').required(
    //     'Debe seleccionar unaño',
    //   ),
    // },
  });
