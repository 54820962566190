import sheets from 'views/installer/car/editClient/sheets';
import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    installers: Yup.array().of(
      Yup.object().test(
        'hasInstaller',
        intl.formatMessage({ id: 'Debe ingresar un instalador' }),
        (value) => Boolean(value.id),
      ),
    ),
    sheets: Yup.array().of(
      Yup.object().shape({
        color: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
          .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
        finish: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
          .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
        sheet: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
          .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
        run: Yup.string().required(
          intl.formatMessage({ id: 'Número de RUN invalido' }),
        ),
        meters: Yup.number()
          .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
          .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
        distributor: Yup.number()
          .nullable()
          .when('$distributor', (_, schema) => {
            return schema.required(
              intl.formatMessage({
                id: 'Debe seleccionar un distribuidor',
              }),
            );
          }),
        application: Yup.object().test(
          'hasApplication',
          intl.formatMessage({ id: 'Debe seleccionar una opción' }),
          (value) =>
            Boolean(value.fullWrap) ||
            Boolean(value.stripes) ||
            Boolean(value.customDesign),
        ),
      }),
    ),
  });
