import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';

import { getFilmsAuto } from 'redux/filmsAuto/selector';
import { getDistributors } from 'redux/distributors/selector';
import { getStatusNewClient } from 'redux/cars/newClient/selector';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import TextField from 'components/form/input';
import { Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { wrappingFilms } from 'enums/wrappingFilms';
import { EUROPE } from 'enums/regions';

export const Step4 = ({
  intl,
  filmsAutoList,
  distrbutorsList,
  values,
  prevStep,
  isLoading,
  hasError,
  codeError,
  country,
}) => (
  <>
    {console.log(EUROPE.includes('GB'))}
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <PorletHeader>
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="Resumen del cliente" />
          </PorletHeaderTitle>
          <PorletHeaderTitle variant="h6">
            <FormattedMessage id="Por favor revisar todos los datos del cliente." />
          </PorletHeaderTitle>
        </PorletHeader>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row resumen">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="withmargin">
              <div className="kt-space-20" />
              <PorletHeaderTitle variant="h6">
                <FormattedMessage id="Cliente:" />
              </PorletHeaderTitle>
              <div>
                <strong>
                  <FormattedMessage id="Tipo de cliente:" />
                </strong>{' '}
                <span>
                  <FormattedMessage id={values.type} />
                </span>
              </div>
              {values.dealerName ? (
                <div>
                  <strong>
                    <FormattedMessage id="Nombre del concesionario:" />
                  </strong>{' '}
                  <span>{values.dealerName}</span>
                </div>
              ) : (
                <></>
              )}
              <div>
                <strong>
                  <FormattedMessage id="Nombre:" />
                </strong>{' '}
                <span>{values.firstName}</span>
              </div>
              <div>
                <strong>
                  <FormattedMessage id="Email:" />
                </strong>{' '}
                <span>{values.email}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="withmargin">
              <div className="kt-space-20" />
              <PorletHeaderTitle variant="h6">
                <FormattedMessage id="Vehículo:" />
              </PorletHeaderTitle>
              <div>
                <strong>
                  <FormattedMessage id="Marca:" />
                </strong>
                <span>{values.brand}</span>
              </div>
              <div>
                <strong>
                  <FormattedMessage id="Modelo:" />
                </strong>
                <span>{values.model}</span>
              </div>
              <div>
                <strong>
                  <FormattedMessage id="Dominio:" />
                </strong>
                <span>{values.patent}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 withmargin">
            {values.sheets.map((sheet, index) => {
              const applications = [];
              let application = '';
              application += sheet.application.fullWrap
                ? intl.formatMessage({ id: 'Full Wrap' })
                : '';
              application += sheet.application.stripes
                ? intl.formatMessage({ id: 'Franjas' })
                : '';
              application += sheet.application.customDesign
                ? intl.formatMessage({ id: 'Diseño personalizado + Otros' })
                : '';
              return (
                <>
                  <div>
                    <strong>
                      <FormattedMessage id="Película Wrapping:" />
                    </strong>
                    <span>
                      <span>
                        {get(
                          wrappingFilms.find(
                            (value) => value.value === sheet.sheet,
                          ),
                          'label',
                        )}
                      </span>
                    </span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Aplicación:" />
                    </strong>
                    <span>{application}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="M2 necesarios:" />
                    </strong>
                    <span>{sheet.meters}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>
                      {get(
                        distrbutorsList.find(
                          (value) => value.id === sheet.distributor,
                        ),
                        'name',
                      )}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {values.installers.map((installer, index) => {
                        return (
                          <div key={index}>
                            <strong>
                              <FormattedMessage id="Instalador" /> {index + 1}:
                            </strong>
                            <span>{installer.name}</span>
                          </div>
                        );
                      })}
                    </strong>
                    <span>{values.installers.name}</span>
                  </div>
                  {values.promoCode ? (
                    <div>
                      <strong>
                        <FormattedMessage id="Código promocional:" />
                      </strong>
                      <span>{values.promoCode}</span>
                    </div>
                  ) : (
                    <></>
                  )}

                  {values.comment ? (
                    <>
                      <div>
                        <strong>
                          <FormattedMessage id="Comentario:" />
                        </strong>
                      </div>
                      <div>{values.comment}</div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div>
                    <div className="w-100">
                      <strong className="preview-resumen">
                        <FormattedMessage id="Vista Previa" />
                      </strong>
                    </div>

                    <img
                      src={
                        values.sheets[index].sheet
                          ? '/media/wrapping/' +
                            values.sheets[index].sheet
                              .toString()
                              .toLowerCase() +
                            '.png'
                          : process.env.PUBLIC_URL + '/media/fasara/Choose.jpg'
                      }
                      className="img-previewer-summary col-6"
                    />
                  </div>
                  <div>
                    <div className="kt-space-20" />
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6" />
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8 ">
        <div>
          <>
            {hasError && (
              <Typography color="primary">
                <FormattedMessage id="Se a producido un error, vuelva a intentar." />
              </Typography>
            )}
          </>
        </div>
        <div className="submit-bottons">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep} disabled={isLoading}>
            <FormattedMessage id="Editar" />
          </Button>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4"></div>
    </div>
  </>
);

export const mapStateToProps = (state) => ({
  ...getStatusNewClient(state),
  filmsAutoList: getFilmsAuto(state),
  distrbutorsList: getDistributors(state),
});

export default injectIntl(connect(mapStateToProps)(Step4));
