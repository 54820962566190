import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import Seacrch from './search';
import Actions from './actions';

import { actions as installersListActions } from 'redux/client/clientList/reducer';
import { getInstallersTableData } from 'redux/client/clientList/selector';

import DataTable from 'components/dataTable';

import ctn from 'helpers/countries';
import { getCountryProfile } from 'redux/profile/selector';

import Button from '@material-ui/core/Button';
import { getStatusFile } from 'redux/reports/selector';
import { requests as reportsRequests } from 'redux/reports/reducer';

import { getUserRole } from 'redux/profile/selector';

const Table = ({ setFilter, downloadFile, country, file, ...props }) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);

  if (
    country == 'AR' ||
    country == 'BO' ||
    country == 'CE' ||
    country == 'CL' ||
    country == 'CO' ||
    country == 'CR' ||
    country == 'DO' ||
    country == 'EC' ||
    country == 'GT' ||
    country == 'HN' ||
    country == 'JM' ||
    country == 'MX' ||
    country == 'PA' ||
    country == 'PE' ||
    country == 'PY' ||
    country == 'SV' ||
    country == 'UY'
  ) {
    fileName = 'instaladores.csv';
  } else if (country == 'BR' || country == 'PT') {
    fileName = 'instaladores.csv';
  } else if (country == 'DE') {
    fileName = 'Verleger.csv';
  } else if (country == 'NL') {
    fileName = 'Installateurs.csv';
  } else {
    fileName = 'installer.csv';
  }

  const columns = [
    { prop: 'country', label: 'Pais', format: ctn },
    { prop: 'category', label: 'Categoria' },
    { prop: 'shopName', label: 'Empresa' },
    { prop: 'name', label: 'Razon social' },
    { prop: 'users[0].firstname', label: 'Nombre' },
    { prop: 'users[0].lastname', label: 'Apellido' },
    { prop: 'state', label: 'Provincia' },
    {
      prop: 'users[0].auto',
      label: 'Auto',
      format: (value) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      prop: 'users[0].building',
      label: 'Building',
      format: (value) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      prop: 'users[0].dinoc',
      label: 'Dinoc',
      format: (value) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      prop: 'users[0].fasara',
      label: 'Glass Finishes',
      format: (value) => {
        return value ? 'Yes' : 'No';
      },
    },
  ];

  if (props.auto) {
    columns.push({
      prop: 'autoWarranties',
      label: 'Cert. de garantias autos',
    });
  }
  if (props.building) {
    columns.push({
      prop: 'buildingWarranties',
      label: 'Cert. de garantias arquitectura',
    });
  }

  if (props.dinoc) {
    columns.push({
      prop: 'fasaraWarranties',
      label: 'Cert. de garantias Glass Finishes',
    });
  }

  if (props.fasara) {
    columns.push({
      prop: 'dinocWarranties',
      label: 'Cert. de garantias 3M™ DI-NOC™',
    });
  }

  columns.push({
    prop: 'uuid',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value }} />,
  });

  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-1 offset-lg-8">
          <Button
            variant="outlined"
            onClick={downloadFile}
            disabled={file.isLoading}
          >
            <FormattedMessage id="Export..." />
          </Button>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          filterEnabled={true}
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};

let fileName;
export const mapStateToProps = (state) => ({
  ...getInstallersTableData(state),
  ...getUserRole(state),
  file: getStatusFile(fileName)(state),
  country: getCountryProfile(state),
});
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(installersListActions.setFilter(filter)),
  downloadFile: () =>
    dispatch(
      reportsRequests.fetch({
        report: '/reports/installers',
        fileName,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);

// export const mapDispatchToProps = (dispatch) => ({
//   setFilter: (filter) => dispatch(installersListActions.setFilter(filter)),
// });

// export default connect(getInstallersTableData, mapDispatchToProps)(Table);
