import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import get from 'lodash/get';

import {
  requests as editProjectsRequests,
  actions as editProjectsActions,
} from 'redux/buildings/editProject/reducer';
import { getCountryProfile } from 'redux/profile/selector';

import { getProjectData } from 'redux/buildings/editProject/selector';

import { Formik, Form } from 'formik';
import Client, { initialValues } from './client';
import Glass from './glass';
import Project from './project';
import Status from './status';
import Shhets from './sheets';
import Installer from './installer';
import Warranty from './warranty';
import validationSchema from './client/validate';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const breadcrumb = [
  { page: 'installer/building', title: 'Arquitectura' },
  { page: 'installer/building', title: 'Proyectos registrados' },
];

export const ViewProject = ({
  intl,
  getProject,
  saveProject,
  clearData,
  projectData,
  isLoading,
  isSuccess,
  hasError,
  country,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    getProject({ code });
    return () => clearData();
  }, [clearData, getProject, code]);
  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success(
        <FormattedMessage id="Información actualizada correctamente" />,
        toastConfig,
      );
      history.push('/installer/building');
    }
  }, [isLoading, isSuccess, history]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    if (Boolean(Object.keys(projectData).length)) {
      const isClosed =
        projectData.subtype === 'Residential' || projectData.isClosed;
      const isLost = get(projectData, 'data.status', '') === 'Perdida';
      const isCancelled = get(projectData, 'data.status', '') === 'Cancelada';
      if (get(projectData, 'type', '') === 'AUTO') {
        history.push(`/installer/car/edit/${code}`);
        return;
      }
      if (!(isClosed || isLost || isCancelled)) {
        history.push(`/installer/building/edit/${code}`);
        return;
      }
    }
  }, [history, projectData, code]);

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return saveProject({ code, body: values });
  };
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Arquitectura" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(projectData).length) && (
        <Formik
          initialValues={{ ...initialValues, ...projectData }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema(intl, country)}
        >
          {(fromProps) => (
            <Form className="ViewProjectForm">
              {console.log(projectData)}
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Cliente" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Client
                        {...props}
                        {...fromProps}
                        code={code}
                        companyName={projectData.data.companyName}
                      />
                    </PortletBody>
                  </Portlet>
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Vidrio" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Glass {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Películas" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Shhets {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Proyecto" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Project {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Colocación" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Installer {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  <Portlet className="kt-portlet">
                    <PorletHeader>
                      <PorletHeaderTitle variant="h4">
                        <FormattedMessage id="Garantía" />
                      </PorletHeaderTitle>
                    </PorletHeader>
                    <PortletBody>
                      <Warranty {...props} {...fromProps} />
                    </PortletBody>
                  </Portlet>
                  {projectData.subtype !== 'Residential' && (
                    <Portlet className="kt-portlet">
                      <PorletHeader>
                        <PorletHeaderTitle variant="h4">
                          <FormattedMessage id="Etapa" />
                        </PorletHeaderTitle>
                      </PorletHeader>
                      <PortletBody>
                        <Status {...props} {...fromProps} />
                      </PortletBody>
                    </Portlet>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6"></div>
                <div className="col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PortletBody className="submit-bottons">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                      >
                        <FormattedMessage id="Guardar" />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => history.goBack()}
                      >
                        <FormattedMessage id="Volver" />
                      </Button>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
              {/* <pre>{JSON.stringify(fromProps.errors, null, '\t')}</pre> */}
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getProject: (data) => dispatch(editProjectsRequests.fetch(data)),
  saveProject: (data) => dispatch(editProjectsRequests.create(data)),
  clearData: () => dispatch(editProjectsActions.clearData()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getProjectData(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(ViewProject),
);
