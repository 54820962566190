import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import 'react-toastify/dist/ReactToastify.css';
import 'static/css/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
