import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PorletHeaderTitle } from 'components/porletHeader';
import Typography from '@material-ui/core/Typography';
import { actions as newClientActions } from 'redux/cars/newClient/reducer';

import Button from '@material-ui/core/Button';

export const Step5 = ({ code, clear }) => {
  useEffect(() => () => clear(), [clear]);
  return (
    <>
      <div className="row thankyou">
        <div className="col-sm-12 col-md-12 col-lg-4" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="¡Listo!" />
          </PorletHeaderTitle>
          <Typography variant="h4" color="secondary">
            <FormattedMessage id="La instalación ha sido ingresada con éxito." />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="El pre-código es:" />
          </Typography>
          <PorletHeaderTitle variant="h4">{code}</PorletHeaderTitle>
          <Typography variant="h6">
            <FormattedMessage id="ATENCIÓN: La garantía será ÚNICAMENTE una vez que el cliente final complete sus datos y cierre el proceso al recibir el correo de 3M." />
          </Typography>
          {/* <Typography variant="h6">
            <FormattedMessage id="Código y garantía válida únicamente si el cliente completa sus datos y cierra el proceso." />
          </Typography> */}
          <div className="kt-space-20" />
          <Link to="/installer/car">
            <Button variant="contained" color="primary" size="large">
              <FormattedMessage id="Finalizar" />
            </Button>
          </Link>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4" />
      </div>
      <div className="kt-space-20" />
    </>
  );
};

export default connect(undefined, (dispatch) => ({
  clear: () => dispatch(newClientActions.clear()),
}))(Step5);
