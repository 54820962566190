import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toAbsoluteUrl } from '_metronic/utils/utils';
import '_metronic/_assets/sass/pages/end-user/end-user.scss';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { getStatesOptions } from 'redux/states/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import Autocomplete from 'components/form/autocomplete';
import { set } from 'lodash';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const baseURL = window.env.API_URL;

const LATAM_BG = toAbsoluteUrl('/media/bg/bg-3.jpg');
const EUROPE_BG = toAbsoluteUrl('/media/bg/bg-5.jpg');
let isLatam = true;
let backgroundImg = LATAM_BG;

if (
  [
    'staging.garantiawf.com',
    'localhost',
    'www.myafprojecttool.com',
    'myafprojecttool.com',
  ].includes(window.location.hostname)
) {
  isLatam = false;
  backgroundImg = EUROPE_BG;
}

function ShowForm(props) {
  const { intl, hasError, setLanguage, fetchStates, stateOptions } = props;
  const queryParams = useQuery();
  const [downloadLink, setDownloadLink] = useState(downloadLink);
  const [userInfo, setUserInfo] = useState(userInfo);
  const [stateFieldValue, setStateFieldValue] = useState(0);
  const [wizardStep, setWizardStep] = useState(wizardStep);
  const [isLoading, setLoading] = useState(false);
  const { mail, code } = useParams() || '';
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const [nps, setNps] = useState('');
  const [warrantyId, setWarrantyId] = useState(queryParams.get('w'));
  const [npsCode, setNpsCode] = useState(queryParams.get('code'));

  const enableLoading = () => {
    // setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    // setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  const country = queryParams.get('lang') ?? 'gb';

  useEffect(() => {
    const lang = queryParams.get('lang') ?? 'gb';
    setLanguage(lang);
  }, [queryParams, setLanguage]);

  useEffect(() => {
    if (isLoading) {
      enableLoading();
    } else {
      disableLoading();
    }
  }, [isLoading]);

  if (wizardStep == undefined) {
    setWizardStep(1);
  }

  useEffect(() => {
    fetchStates({ country });
  }, []);

  /* function build_link() {
    setDownloadLink('aca va el link');
    setWizardStep(4);
  } */
  {
    /* function wizard_step_one(mail) {
    return (
      <>
        <div className="sep">
          <p className="enduser-form-title">
            {intl.formatMessage({ id: 'Información de contacto' })}
          </p>
        </div>
        <Formik
          initialValues={{
            firstname: '',
            surname: '',
            company: '',
            street: '',
            number: '',
            floor: '',
            apartment: '',
            zip: '',
            state: '',
            mail: mail,
            code: code,
            country: country,
            acceptedInfo: false,
            nps: '',
            warrantyId: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.firstname) {
              errors.firstname = intl.formatMessage({
                id: 'Debe ingresar un nombre',
              });
            }
            if (!values.surname) {
              errors.surname = intl.formatMessage({
                id: 'Debe ingresar un apellido',
              });
            }
            if (!values.street) {
              errors.street = intl.formatMessage({
                id: 'Debe ingresar una calle',
              });
            }
            if (!values.number) {
              errors.number = intl.formatMessage({
                id: 'Debe ingresar un número',
              });
            }
            if (!values.zip) {
              errors.zip = intl.formatMessage({
                id: 'Debe ingresar un código postal',
              });
            }
            if (!values.state) {
              errors.state = intl.formatMessage({
                id: 'Debe seleccionar una provincia',
              });
            }

            return errors;
          }}
          onSubmit={(values) => {
            console.log(values, code);
            setUserInfo(values);
            setLoading(true);
            setWizardStep(2);
            axios
              //a definir el endpoint
              .post(`${baseURL}/warranties/${warrantyId}/confirm`, {
                firstname: values.firstname,
                surname: values.surname,
                company: values.company,
                street: values.street,
                number: values.number,
                floor: values.floor,
                apartment: values.apartment,
                zip: values.zip,
                state: values.state,
                mail: mail,
                code: code,
                country: country,
                warrantyId: warrantyId,
              })
              .then((res) => {
                console.log(res.data);
                setLoading(false);
                setNps(res.data.nps);
                setWarrantyId(res.data.id);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form>
              {/* {(errors != {}) && touched.mail && (
                    <div className="alert-text text-center">
                      {intl.formatMessage({
                        id: 'Usuario o contsraseña invalidos',
                      })}
                    </div>
                  )} 
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Nombre: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="firstname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.firstname}
                  helpertext={touched.firstname && errors.firstname}
                  //error={Boolean(touched.name && errors.name)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="firstname" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Apellido: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="surname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.surname}
                  helpertext={touched.surname && errors.surname}
                  //error={Boolean(touched.surname && errors.surname)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="surname" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                {' '}
                {/*Company
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Compañia:',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.company}
                  helpertext={touched.company && errors.company}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin smaller-error">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="company_name" />
                  </p>
                </div>
              </div>

              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Calle: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.street}
                  helpertext={touched.street && errors.street}
                  error={Boolean(touched.street && errors.street)}
                />{' '}
                <div className="  MuiFormHelperText-root MuiFormHelperText-contained error-text error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="street" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Número: *',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.number}
                  helpertext={touched.number && errors.number}
                  //error={Boolean(touched.number && errors.number)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="number" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Piso:',
                  })}
                </h5>
                <Field
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="floor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.floor}
                  helpertext={touched.floor && errors.floor}
                  //error={Boolean(touched.floor && errors.floor)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="floor" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Departamento:',
                  })}
                </h5>
                <Field
                  variant="outlined"
                  type="text"
                  margin="center"
                  className="enduser-content-form-input "
                  name="apartment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.apartment}
                  helpertext={touched.apartment && errors.apartment}
                  //error={Boolean(touched.apartment && errors.apartment)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="apartment" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Código Postal: *',
                  })}
                </h5>
                <Field
                  variant="outlined"
                  type="text"
                  margin="center"
                  className="enduser-content-form-input"
                  name="zip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  value={values.zip}
                  helpertext={touched.zip && errors.zip}
                  //error={Boolean(touched.zip && errors.zip)}
                />{' '}
                <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error error-margin">
                  <p className="error-text">
                    <ErrorMessage className="error-text" name="zip" />
                  </p>
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <h5 className="enduser-label">
                  {intl.formatMessage({
                    id: 'Provincia: *',
                  })}
                </h5>
                <div className="select-container ">
                  <Field
                    className="enduser-content-form-input enduser-select reduced-margin-bot"
                    name="state"
                    component={Autocomplete}
                    handleChange={() => {
                      setStateFieldValue('state', '');
                    }}
                    placeholder={intl.formatMessage({
                      id: 'Seleccionar',
                    })}
                    variant="outlined"
                    options={stateOptions}
                  />
                </div>
              </div>
              <div className="form-group reduced-margin-bot">
                <label className="check-text">
                  <Field
                    type="checkbox"
                    margin="left"
                    name="acceptedInfo"

                    //onChange={handleChange}

                    //helpertext={touched.street && errors.street}
                    //error={Boolean(touched.street && errors.street)}
                  />{' '}
                  {intl.formatMessage({
                    id:
                      'Acepto recibir información comercial o comunicaciones de 3M™',
                  })}
                </label>
              </div>

              <div id="enduser-content-form-button-container">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isLoading}
                  className={` mb-2 btn btn-primary btn-elevate kt-login__btn-primary enduser-content-form-button
                        )}`}
                >
                  {intl.formatMessage({ id: 'Enviar' })}
                </button>
                <label className="check-text">
                  {intl.formatMessage({
                    id: '* Requerido',
                  })}
                </label>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  function wizard_step_two(link, values) {
    return (
      <>
        <div className="sep">
          <p className="step-2-title">
            {intl.formatMessage({
              id: 'Gracias por confiar en los Films 3M',
            })}{' '}
          </p>
        </div>
        <p className="step-2-text">
          {intl.formatMessage({
            id:
              'El certificado de garantía ha sido enviado al mail que compartió anteriormente con el instalador',
          })}{' '}
        </p>
        {/* <p className="step-2-text">
          {intl.formatMessage({
            id:
              "Si desea descargarlo ahora, puede hacer clickeando en 'Descargar'",
          })}{' '}
        </p> 
        <div id="survey-button-container">
          <a
            href={`${baseURL}/warranties/${warrantyId}/download`}
            target="_blank"
          >
            {/* <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={isLoading}
              onClick={() => setWizardStep(3)}
              style={{ margin: '0 10% 0 0' }}
            >
            </button> 
          </a>
          <button
            id="kt_login_signin_submit"
            type="button"
            disabled={isLoading}
            className={` btn btn-primary btn-elevate kt-login__btn-primary survey-form-button `}
            onClick={() => setWizardStep(3)}
            style={{ margin: '0 20% 0 60%' }}
          >
            {intl.formatMessage({ id: 'Siguiente' })}
          </button>
        </div>
      </>
    );
  }
  function wizard_step_three(values) {
    return (
      <>
        <div className="sep">
          <p className="step-2-title">
            {intl.formatMessage({
              id: 'Tu opinión nos ayuda a seguir mejorando.',
            })}
          </p>
        </div>
        <p className="step-2-text darker">
          {intl.formatMessage({
            id:
              'Nos gustaría conocer sobre tu experiencia con el instalador y nuestros productos, siempre buscamos mejorar.',
          })}{' '}
        </p>
        <p className="step-2-text darker">
          {intl.formatMessage({
            id: 'Sólo te llevará un minuto responder esta encuesta.',
          })}{' '}
        </p>
        <p className="step-2-text darker">
          {intl.formatMessage({ id: '¿Te gustaría evaluar al instalador?' })}{' '}
        </p>
        <div id="survey-button-container">
          <a
            href={`/experiencia-auto?lang=${country}&code=${npsCode}`}
            target="_blank"
          >
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={isLoading}
              className={`  btn btn-primary btn-elevate kt-login__btn-primary survey-form-button yes-survey 
                          )}`}
              onClick={build_link}
            >
              {intl.formatMessage({ id: 'Si' })}
            </button>
          </a>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={isLoading}
            className={`  btn btn-primary btn-elevate kt-login__btn-primary survey-form-button   
                        )}`}
            onClick={build_link}
          >
            {intl.formatMessage({ id: 'No' })}
          </button>
        </div>
      </>
    );
  }
  function wizard_step_final() {
    return (
      <>
        <div className="sep">
          <p className="step-2-title">
            {intl.formatMessage({ id: 'Gracias por tu respuesta.' })}
          </p>
        </div>
        <p className="step-final darker text-center">
          {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_1' })}{' '}
          {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_2' })}{' '}
        </p>
      </>
    );
  }


  return (
    <>
      <div
        className="container-fluid full-page"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          //backgroundImage: `url(${toAbsoluteUrl('/media/bg/xmas/bg-xmas.jpg',)})`, //xmas remove
        }}
      >
        <div className="row">
          <div className="col-xs-12 col-lg-7">
            <img
              width="auto"
              height="75"
              src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-BLANCO' }))}
              alt="logo 3M"
              className="pt-3 pl-2"
            />
            <div className="d-none d-lg-block">
              <p className="end-user-thanks">
                {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_1' })}{' '}
                {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_2' })}{' '}
              </p>
              {wizardStep == 1 ? (
                <p className="end-user-complete">
                  {intl.formatMessage({
                    id:
                      'Por favor completa la información para obtener tu garantía.',
                  })}
                </p>
              ) : wizardStep >= 2 ? (
                <p className="end-user-complete">
                  {intl.formatMessage({
                    id:
                      'Por favor selecciona una opción para obtener tu garantía.',
                  })}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="d-block d-lg-none">
              <p className="end-user-thanks-mobile">
                {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_1' })}{' '}
                {intl.formatMessage({ id: 'END_USER_FORM_AUTO_TITLE_2' })}{' '}
              </p>
              {wizardStep == 1 ? (
                <p className="end-user-complete-mobile">
                  {intl.formatMessage({
                    id:
                      'Por favor completa la información para obtener tu garantía.',
                  })}
                </p>
              ) : wizardStep >= 2 ? (
                <p className="end-user-complete">
                  {intl.formatMessage({
                    id:
                      'Por favor selecciona una opción para obtener tu garantía.',
                  })}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-xs-12 col-lg-5">
            <div className="end-user-form">
              <div className="end-user-form-bg">
                {wizardStep == 1 ? (
                  wizard_step_one(mail)
                ) : wizardStep == 2 ? (
                  wizard_step_two(downloadLink)
                ) : wizardStep == 3 ? (
                  wizard_step_three()
                ) : wizardStep == 4 ? (
                  wizard_step_final()
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>*/
  }

  return (
    <>
      {isLatam ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundImage: 'url(/Tapa.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{ color: 'white', padding: '20px', borderRadius: '10px' }}
          >
            <p style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              Estamos actualizando nuestra web.
            </p>
            <p>Disculpe las molestias ocasionadas. ¡Muchas gracias!</p>
            <p style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              Estamos atualizando nosso site.
            </p>
            <p>Desculpe pelo transtorno causado. ¡Muito obrigado!</p>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundImage: 'url(/Tapa.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{ color: 'white', padding: '20px', borderRadius: '10px' }}
          >
            <p style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              Dear customer,
            </p>
            <p>
              We want to inform you that the Facilities registration website
              www.myafprojecttool.com will be in maintenance for 72hs. We
              continue to work to always give you the best experience. If you
              have any questions, do not hesitate to contact your 3M sales
              representative.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
export const mapStateToProps = (state) => ({
  stateOptions: getStatesOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ShowForm),
);
