import get from 'lodash/get';
import { PREFIX } from './reducer';

export const getProfile = (state) => ({ user: get(state, 'profile.data') });
export const getCountryProfile = (state) => get(state, 'profile.data.country');

export const getUserRole = (state) => ({
  userRole: get(state, 'profile.data.type[0].code', false),
  auto: get(state, 'profile.data.auto', false),
  building: get(state, 'profile.data.building', false),
  dinoc: get(state, 'profile.data.dinoc', false),
  fasara: get(state, 'profile.data.fasara', false),
  country: get(state, 'profile.data.country', false),
});

export const getStatusProfile = (state) => ({
  hasError: Boolean(get(state, `${PREFIX}.error`, false)),
  isLoading: Boolean(get(state, `${PREFIX}.status`, false)),
  isSaveSuccess: Boolean(get(state, `${PREFIX}.isSaveSuccess`, false)),
});
export const getStatusPassword = (state) => ({
  hasError: Boolean(get(state, `${PREFIX}.error`, false)),
  isLoading: Boolean(get(state, `${PREFIX}.status`, false)),
  isSaveSuccess: Boolean(get(state, `${PREFIX}.isSaveSuccess`, false)),
});
