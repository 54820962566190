import { createSlice, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { REQUEST_STATUS, REQUEST_METHODS } from 'enums/request';

export const xhrAction = createAction('xhr_request');

export const getRequestActions = (action) =>
  Object.keys(action.meta.actions).reduce(
    (all, acn) => ({
      ...all,
      [acn]: createAction(action.meta.actions[acn], (payload, meta) => ({
        payload,
        meta: {
          ...omit(action.meta, ['actions']),
          ...meta,
        },
      })),
    }),
    {},
  );

export const createRequestAction = ({ prefix, method, actions }) =>
  createAction(xhrAction.type, (payload) => ({
    payload: get(payload, 'body'),
    meta: {
      prefix,
      method,
      ...omit(payload, ['body']),
      actions,
    },
  }));

export const requestInitialState = {
  status: '',
  error: '',
};

export const requestReducer = {
  request(state, { meta }) {
    state.error = '';
    state.status = REQUEST_STATUS[meta.method];
  },
  failure(state, { payload }) {
    state.error = payload;
  },
  fulfill(state) {
    state.status = REQUEST_STATUS.FulFill;
  },
  // TODO Set Defult reducer state
  fetch() {},
  create() {},
  update() {},
  remove() {},
};

export const getSliceRequestActionTypes = (slice) => ({
  request: slice.actions.request.type,
  failure: slice.actions.failure.type,
  fulfill: slice.actions.fulfill.type,
});

// crateRequestSlice
export default ({ name, initialState, reducers }) => {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      ...requestReducer,
      ...reducers,
    },
  });
  const actions = getSliceRequestActionTypes(slice);
  const requests = {
    fetch: createRequestAction({
      prefix: name,
      method: REQUEST_METHODS.Fetch,
      actions: { ...actions, success: slice.actions.fetch.type },
    }),
    create: createRequestAction({
      prefix: name,
      method: REQUEST_METHODS.Create,
      actions: { ...actions, success: slice.actions.create.type },
    }),
    update: createRequestAction({
      prefix: name,
      method: REQUEST_METHODS.Update,
      actions: { ...actions, success: slice.actions.update.type },
    }),
    remove: createRequestAction({
      prefix: name,
      method: REQUEST_METHODS.Remove,
      actions: { ...actions, success: slice.actions.remove.type },
    }),
  };
  return { ...slice, requests };
};
