import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import './productCard.css';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Separator from './separator';
import Typography from '@material-ui/core/Typography';
import { props } from 'lodash/fp';
import get from 'lodash/get';

const useStyles = makeStyles({
  root: {
    maxWidth: 380,
    borderRadius: 0,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
  media: {
    height: 140,
    marginBottom: 5,
  },

  text: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 10,
  },
});

const ProductCard = ({
  name,
  type,
  description2,
  description,
  img,
  years,
  degrade,
  flyer_url,
  lang,
}) => {
  const { intl } = props;

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const classes = useStyles();

  return (
    <a
      href={process.env.PUBLIC_URL + './media/flyers/' + lang + '/' + flyer_url}
      target="_blank"
    >
      <Card
        className={classes.root}
        onMouseLeave={toggleHover}
        onMouseEnter={toggleHover}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={process.env.PUBLIC_URL + './media/product_imgs/' + img}
            title="3M Film Card"
          />
          <div className={hovered ? 'd-shown' : 'd-hidden'}>
            <Separator degrade={degrade}></Separator>
          </div>

          <CardContent className={classes.text}>
            <Typography gutterBottom>
              <FormattedMessage id={type} />
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              <span className={hovered ? 'underlined' : 'underlined_not'}>
                <FormattedMessage id={name} />
              </span>
            </Typography>
            <Typography gutterBottom variant="body2" component="p">
              <span className={hovered ? 'underlined' : 'underlined_not'}>
                <FormattedMessage id={years} />
              </span>
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              <FormattedMessage id={description} />
            </Typography>
            <Typography variant="subtitle2" component="p">
              <FormattedMessage id={description2} />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions></CardActions>
      </Card>
    </a>
  );
};
export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});

export const matStateToProps = (state) => ({
  lang: get(state, 'i18n.lang') === 'br' ? 'br' : 'es',
});
export default injectIntl(
  connect(matStateToProps, mapDispatchToProps)(ProductCard),
);
