import React, { useState } from 'react';
import { connect } from 'react-redux';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DatePicker, Day, MuiPickersUtilsProvider } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';
import get from 'lodash/get';
import omit from 'lodash/omit';

export const StyleDay = withStyles({
  root: {
    '&.middle': {
      margin: 0,
      width: '40px',
      borderRadius: '0',
    },
    '&.start': {
      borderRadius: '50% 0 0 50%',
    },
    '&.end': { borderRadius: '0 50% 50% 0' },
  },
})(Day);

const format = 'DD/MM/YYYY';
const RangePicker = ({ locale, value, onChange, ...props }) => {
  const [begin, setBegin] = useState(value[0]);
  const [end, setEnd] = useState(value[1]);
  const [hover, setHover] = useState(undefined);

  const min = Math.min(begin, end || hover);
  const max = Math.max(begin, end || hover);

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <DatePicker
        InputProps={{ endAdornment: <DateRangeIcon /> }}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          return (
            <StyleDay
              {...dayComponent.props}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!begin) setBegin(day);
                else if (!end) {
                  if (day.isSameOrBefore(begin)) {
                    setEnd(begin);
                    setBegin(day);
                  } else {
                    setEnd(day);
                  }
                } else {
                  setBegin(day);
                  setEnd(undefined);
                }
              }}
              onMouseEnter={(e) => setHover(day)}
              className={clsx('MuiPickersDay-day', {
                'MuiPickersDay-hidden': dayComponent.props.hidden,
                'MuiPickersDay-current': dayComponent.props.hidden,
                'MuiPickersDay-isDisabled': dayComponent.props.hidden,
                'MuiPickersDay-daySelected':
                  day.isSameOrAfter(min) && day.isSameOrBefore(max),
                middle: day.isSameOrAfter(min) && day.isSameOrBefore(max),
                start: day.isSame(min),
                end: day.isSame(max),
              })}
            />
          );
        }}
        value={null}
        onChange={() =>
          begin && end ? onChange([begin, end]) : onChange(value)
        }
        labelFunc={() =>
          begin && end
            ? `${begin.format(format)} - ${end.format(format)}`
            : `${value[0].format(format)} - ${value[1].format(format)}`
        }
        {...omit(props, ['hasError', 'dispatch'])}
      />
    </MuiPickersUtilsProvider>
  );
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'en'
      : get(state, 'i18n.lang') === 'gb'
      ? 'en'
      : get(state, 'i18n.lang') === 'de'
      ? 'de'
      : 'es',
});
export default connect(matStateToProps)(RangePicker);
