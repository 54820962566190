import get from 'lodash/get';
import { PREFIX } from './reducer';

export const getFileName = (state) => get(state, `${PREFIX}.fileName`);

export const getStatusFile = (fileName) => (state) => ({
  hasError: Boolean(
    getFileName(state) === fileName && get(state, `${PREFIX}.error`, false),
  ),
  isLoading: Boolean(get(state, `${PREFIX}.status`, false)),
});
