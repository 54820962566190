import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomRoute from './route';

export const MakeRoute = ({ routes, ...props }) =>
  routes ? (
    <Route path={props.path}>
      <Routes routes={routes}>
        <MakeRoute {...props} />
      </Routes>
    </Route>
  ) : (
    <CustomRoute {...props} />
  );

const Routes = ({ routes, children }) => (
  <Switch>
    {routes.map((route, i) => (
      <MakeRoute key={i} {...route} />
    ))}
    {children}
  </Switch>
);

export default Routes;
