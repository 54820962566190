import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getFilmsAuto = (state) => get(state, 'filmsAuto.data');

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: act.id,
        type: act.type,
      },
    ];
  }, []);

export const getFilmsAutoOptions = createSelector(
  getFilmsAuto,
  makeAtoCompleteOptions,
);
