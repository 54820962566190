import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    // installers: Yup.array().of(
    //   Yup.object().test(
    //     'hasInstaller',
    //     intl.formatMessage({ id: 'Debe ingresar un instalador' }),
    //     (value) => Boolean(value.id),
    //   ),
    // ),
    dinocSheets: Yup.array()
      .of(
        Yup.object().shape({
          film: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          need: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          substrate: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),
          family: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),
          distributor: Yup.string()
            .required(
              intl.formatMessage({ id: 'Debe seleccionar un distribuidor' }),
            )
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar un distribuidor' }),
            ),
        }),
      )
      .when('$dinocSheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una lámina' }),
          );
        }
      }),
  });
