import { APLICATION_ROLES } from 'enums/roles';
import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import { getUserRole } from '../profile/selector';

export const makeMenu = (submenu, role) => {
  const { userRole, auto, building, dinoc, fasara, country } = role;
  return submenu.reduce((all, item) => {
    const menuItem =
      !item.access ||
      (indexOf(item.access, userRole) >= 0 &&
        (!item.country || indexOf(item.country, country) >= 0) &&
        ((item.auto && auto) ||
          (item.building && building) ||
          (item.dinoc && dinoc) ||
          (item.fasara && fasara) ||
          (item.auto === undefined &&
            item.building === undefined &&
            item.dinoc === undefined &&
            item.fasara === undefined))) ||
      userRole === APLICATION_ROLES.Owner
        ? item
        : null;
    if (!menuItem) return all;

    const submenu = item.submenu ? makeMenu(item.submenu, role) : noop();
    if (!isEmpty(item.submenu) && isEmpty(submenu)) {
      return all;
    }

    return [...all, { ...menuItem, submenu }];
  }, []);
};

export const getMenuConfig = (state) => get(state, 'builder.menuConfig');

export const selectMenuconfig = createSelector(
  getMenuConfig,
  getUserRole,
  (menu, role) => {
    const headerMenu = makeMenu(menu.header.items, role);
    const asideMenu = makeMenu(menu.aside.items, role);

    return {
      ...menu,
      header: {
        ...menu.header,
        items: [...headerMenu],
      },
      aside: {
        ...menu.header,
        items: [...asideMenu],
      },
    };
  },
);
