import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';

import { requests as statesRequest } from 'redux/states/reducer';

import { projectTypeOPTs, requirementTypeOPTs } from 'enums/formOtps';

import { Field } from 'formik';
import Button from '@material-ui/core/Button';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';
import { injectIntl } from 'react-intl';
import { useIntl } from 'react-intl';
import { DropzoneDialog } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { actions as projectListActions } from 'redux/arqFinishes/projectList/reducer';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { WidgetTab } from 'components/widgetTabs';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

export const initialValues = {
  project: {
    name: '',
    type: '',
    postalCode: '',
    state: '',
  },
};
export const Step2 = ({
  prevStep,
  country,
  fetchStates,
  stateOptions,
  setFilter,
  values: {
    project: { state },
  },
  setFieldValue,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);

  useEffect(() => {
    setFilter();
  }, [setFilter]);

  const useStyles = makeStyles((theme) => ({
    input: {
      marginRight: 2,
    },
    errorMessage: {
      color: 'red',
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [selectedProvince, setSelectedProvince] = useState('');
  const handleProvinceChange = (event, value) => {
    setSelectedProvince(value);
  };

  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Proyecto" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.name"
                component={TextField}
                type="text"
                label="Nombre de proyecto (Interior Design for central office)*"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.type"
                component={Autocomplete}
                label="Tipo de proyecto *"
                variant="outlined"
                options={
                  country === 'BE' || country == 'NL'
                    ? projectTypeOPTs.filter(
                        (option) =>
                          option.value !== 'Edificios de oficinas Clase A',
                      )
                    : projectTypeOPTs
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Dirección" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.postalCode"
                component={TextField}
                type="text"
                label="Código Postal *"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.state"
                component={Autocomplete}
                handleChange={() => {
                  setFieldValue('project.city', '');
                }}
                label="Provincia *"
                variant="outlined"
                options={stateOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-space-20" />
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 ">
          <div className="row">
            <div className="col-12 submit-bottons">
              <Button variant="contained" type="submit" color="primary">
                <FormattedMessage id="Siguiente" />
              </Button>
              <Button variant="outlined" onClick={prevStep}>
                <FormattedMessage id="Atrás" />
              </Button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step2));
