import './index.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import TextField from 'components/form/input';

import ProfileNav from '../profileNav';

import { getStatusPassword } from 'redux/profile/selector';
import {
  requests as profileRequest,
  actions as profileActions,
} from 'redux/profile/reducer';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  newPasswor2: '',
};

const validationSchema = (intl) =>
  Yup.object().shape({
    oldPassword: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar una contraseña' }),
    ),
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: 'Debe ingresar una contraseña' }))
      .matches(
        /(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/,
        intl.formatMessage({
          id:
            'La contraseña debe tener un minimo de 8 letras, una mayuscula, una minuscula y un caracter especial',
        }),
      ),
    newPasswor2: Yup.string()
      .required(intl.formatMessage({ id: 'Debe ingresar una contraseña' }))
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({ id: 'La nueva contraseña debe coincidir' }),
      ),
  });
const breadcrumb = [{ page: 'profile/resume', title: 'Mis datos' }];

export const PasswordView = ({
  intl,
  onSubmit,
  isLoading,
  hasError,
  isSaveSuccess,
  clearSave,
}) => {
  useEffect(() => () => clearSave(), [clearSave]);
  useEffect(() => {
    if (!isLoading && isSaveSuccess) {
      toast.success(
        <FormattedMessage id="La información ha sido actualizada." />,
        toastConfig,
      );
    }
  }, [isLoading, isSaveSuccess]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se ha producido un error, vuelva a intentar más tarde." />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const history = useHistory();

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return onSubmit(values);
  };

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema(intl)}
      >
        {() => (
          <Form>
            <div className="PasswordView">
              <div className="kt-space-20" />
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <Breadcrumbs title="Mis datos" items={breadcrumb} />
                  <div className="kt-space-20" />
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <Portlet className="kt-portlet--height-fluid">
                      <PortletBody>
                        <ProfileNav active="password" />
                      </PortletBody>
                    </Portlet>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-8">
                    <Portlet className="kt-portlet--height-fluid">
                      <PorletHeader>
                        <PorletHeaderTitle variant="h4">
                          <FormattedMessage id="Cambiar contraseña" />
                        </PorletHeaderTitle>
                      </PorletHeader>
                      <PortletBody>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-1" />
                          <div className="col-sm-12 col-md-12 col-lg-10">
                            <div className="row FormRow">
                              <div className="col-sm-12 col-md-12 col-lg-4" />
                              <div className="col-sm-12 col-md-12 col-lg-8">
                                <Typography variant="h5">
                                  <FormattedMessage id="Contraseña" />
                                </Typography>
                              </div>
                            </div>
                            <div className="row FormRow">
                              <div className="col-sm-12 col-md-12 col-lg-4">
                                <FormattedMessage id="Contraseña actual: *" />
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-8">
                                <Field
                                  name="oldPassword"
                                  component={TextField}
                                  type="password"
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                            <div className="row FormRow">
                              <div className="col-sm-12 col-md-12 col-lg-4">
                                <FormattedMessage id="Nueva contraseña: *" />
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-8">
                                <Field
                                  name="newPassword"
                                  component={TextField}
                                  type="password"
                                  variant="outlined"
                                  helperText="La contraseña debe tener un minimo de 8 letras, una mayuscula, una minuscula y un caracter especial"
                                  fullWidth
                                />
                              </div>
                            </div>
                            <div className="row FormRow">
                              <div className="col-sm-12 col-md-12 col-lg-4">
                                <FormattedMessage id="Repetir nueva contraseña: *" />
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-8">
                                <Field
                                  name="newPasswor2"
                                  component={TextField}
                                  type="password"
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-1" />
                        </div>
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Portlet className="kt-portlet--height-fluid">
                    <PortletBody>
                      <div className="submit-bottons">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={isLoading}
                        >
                          <FormattedMessage id="Guardar" />
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => history.goBack()}
                        >
                          <FormattedMessage id="Volver" />
                        </Button>
                      </div>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(profileRequest.changePassword({ body: values })),
  clearSave: () => dispatch(profileActions.clearSave()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getStatusPassword(state),
    }),
    mapDispatchToProps,
  )(PasswordView),
);
