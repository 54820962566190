import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import Chip from '@material-ui/core/Chip';
import Seacrch from './search';
import Actions from './actions';

import { actions as clientListActions } from 'redux/cars/clientList/reducer';
import { getClientListTableData } from 'redux/cars/clientList/selector';

import DataTable from 'components/dataTable';

import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getStatusFile } from 'redux/reports/selector';
import { requests as reportsRequests } from 'redux/reports/reducer';

import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { getUserRole } from 'redux/profile/selector';

import axios from 'axios';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import prop from 'lodash/fp/prop';
const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

// const [open, setOpen] = React.useState(false);

// TODO revisar si van los RUNS

const columns = [
  { prop: 'installer.name', label: 'Empresa' },
  { prop: 'customer.firstname', label: 'Nombre de usuario' },
  { prop: 'data.licensePlate', label: 'Dominio' },
  {
    prop: 'subtype',
    label: 'Tipo de proyecto',
    format: (value) => {
      switch (value) {
        case '':
          return <FormattedMessage id="Auto™" />;
        case 'PPF':
          return <FormattedMessage id="PPF" />;
        case 'WRAP':
          return <FormattedMessage id="Wrap" />;
      }
    },
  },
  { prop: 'data.applications[0].film.name', label: 'Producto' },
  { prop: 'data.applications[0].run', label: 'RUN' },
  { prop: 'code', label: 'Código de garantía' },
  // {
  //   prop: 'hasComplaint',
  //   label: 'Reclamo',
  //   format: (value) => (
  //     <Chip
  //       color={value ? 'primary' : 'secondary'}
  //       label={value ? 'Abierto' : 'NO'}
  //     />
  //   ),
  // },
  {
    prop: 'uuid',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value }} />,
  },
];

const useStyles = makeStyles((theme) => ({
  input: {
    marginRight: 2,
  },
}));

const Table = ({ intl, setFilter, downloadFile, file, role, ...props }) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-2 offset-lg-7">
          {Boolean(role.userRole.toUpperCase() === 'SEGVEL') && (
            <span>
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className={classes.input}
              >
                <FormattedMessage id="Subir Reporte" />
              </Button>
              <DropzoneDialog
                showAlerts={false}
                acceptedFiles={['text/csv']}
                cancelButtonText={intl.formatMessage({ id: 'Cancelar' })}
                submitButtonText={intl.formatMessage({ id: 'Enviar' })}
                dialogTitle={intl.formatMessage({ id: 'Subir Reporte' })}
                dropzoneText={intl.formatMessage({
                  id: 'Arrastre y suelte un archivo aquí o haga click',
                })}
                maxFileSize={5000000}
                open={open}
                onClose={() => setOpen(false)}
                onSave={(files) => {
                  console.log('Files:', files);
                  let url = API_URL + '/bulk/segvel';
                  const data = new FormData();
                  data.append('file', files[0]);
                  axios
                    .post(url, data, {
                      headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                          ACCESS_TOKEN,
                        )}`,
                      },
                    })
                    .then((res) => {
                      setOpen(false);
                      toast.success(
                        <FormattedMessage id="El archivo se envio correctamente" />,
                        toastConfig,
                      );
                      setFilter();
                    })
                    .catch((res) => {
                      setOpen(false);
                      toast.error(
                        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
                        toastConfig,
                      );
                      setFilter();
                    });
                }}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNames={true}
                filesLimit={1}
              />
            </span>
          )}
          <Button
            variant="outlined"
            onClick={downloadFile}
            disabled={file.isLoading}
          >
            <FormattedMessage id="Export..." />
          </Button>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};

// export const setOpen = (value) => {
//   console.log("Open Value: " + open);
//   open = value;
//   console.log("Open Value: " + open);
// }

export const fileName = 'garantias-auto.csv';

export const mapStateToProps = (state) => ({
  ...getClientListTableData(state),
  file: getStatusFile(fileName)(state),
  role: getUserRole(state),
});
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(clientListActions.setFilter(filter)),
  downloadFile: () =>
    dispatch(
      reportsRequests.fetch({
        report: '/reports/garantias/auto',
        fileName,
      }),
    ),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Table));

// export const mapDispatchToProps = (dispatch) => ({
//   setFilter: (filter) => dispatch(clientListActions.setFilter(filter)),
// });

// export default connect(getClientListTableData, mapDispatchToProps)(Table);
