import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import Typography from '@material-ui/core/Typography';
import { toAbsoluteUrl } from '_metronic';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

const LabelTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1em',
    color: '#FFF',
  },
})(Typography);

const TitleTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '4em',
    color: '#FFF',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1.5em',
    color: '#FFF',
  },
})(Typography);

const ArqMensaje2Widget = ({ intl }) => {
  const imgArq = intl.formatMessage({ id: 'IMG_ARQ' });

  return (
    <Portlet className="kt-portlet--height-fluid">
      <PortletBody
        style={{
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: 'cover',
        }}
      >
        <div>
          <img
            src={imgArq}
            style={{
              width: '113%',
              height: '113%',
              marginLeft: '-23px',
              marginTop: '-25px',
            }}
            alt="Arq Image"
          />
        </div>
      </PortletBody>
    </Portlet>
  );
};

export default injectIntl(ArqMensaje2Widget);
