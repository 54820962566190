import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    padding: '12px 15px 11px 15px',
    margin: '0',
    borderBottom: '1px solid #e8e8e8',
    '&.noBoder': {
      borderBottom: 'none',
    },
  },
});

export default ({ className = '', ...props }) => {
  const classes = useStyles();
  return <div className={`${classes.root} ${className}`} {...props} />;
};

export const PorletHeaderTitle = withStyles({
  root: {
    padding: '0',
    margin: '0',
  },
})(Typography);
