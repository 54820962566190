import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getStates = (state) => get(state, 'states.data');

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: act.name,
        id: act.id,
      },
    ];
  }, []);

export const getStatesOptions = createSelector(
  getStates,
  makeAtoCompleteOptions,
);
