export const REQUEST_STATUS = {
  Fetch: 'fetching',
  Create: 'creating',
  Replace: 'replacing',
  Update: 'updating',
  Remove: 'removing',
  FulFill: '',
};

export const REQUEST_METHODS = {
  Fetch: 'Fetch',
  Create: 'Create',
  Replace: 'Replace',
  Update: 'Update',
  Remove: 'Remove',
};
