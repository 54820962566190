import curry from 'lodash/curry';
import get from 'lodash/get';
import flow from 'lodash/flow';

import {
  PREFIX as loginRequest,
  PREFIX_REFRESH_TOKEN as refreshRequest,
} from 'redux/auth/reducer';
import {
  PREFIX as profileRequest,
  PREFIX_EDIT as editContactProfile,
  PREFIX_CHANGE_PASSWORD as changePassword,
} from 'redux/profile/reducer';
import { PREFIX as statesRequest } from 'redux/states/reducer';
import { PREFIX as citiesRequest } from 'redux/cities/reducer';
import { PREFIX as brandsRequest } from 'redux/brands/reducer';
import { PREFIX as modelsRequest } from 'redux/models/reducer';
import { PREFIX as distributorsRequest } from 'redux/distributors/reducer';
import {
  PREFIX as userInstallersRequest,
  PREFIX_EDIT as userInstallersEdit,
} from 'redux/userInstallers/reducer';
import { PREFIX as filmsAutoRequest } from 'redux/filmsAuto/reducer';
import { PREFIX as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';

import { PREFIX as installersRequest } from 'redux/client/clientList/reducer'; // Lista de clientes del perfil distribuidor
import { PREFIX as installersView } from 'redux/client/clientView/reducer'; // Detalle de clientes del perfil distribuidor
import { PREFIX as dashboardRequest } from 'redux/dashboard/reducer';

import { PREFIX as newClientRequest } from 'redux/cars/newClient/reducer';
import { PREFIX as clientListRequest } from 'redux/cars/clientList/reducer';
import {
  PREFIX as editClientRequest,
  PREFIX_SEND_WARRANTY as sendWarratyRequest,
  PREFIX_REPORT_WARRANTY as reportWarratyRequest,
  PREFIX_APPROVE_WARRANTY as approbeWarratyRequest,
} from 'redux/cars/editClient/reducer';

import { PREFIX as newProjectRequest } from 'redux/buildings/newProject/reducer';
import { PREFIX as projectListRequest } from 'redux/buildings/projectList/reducer';
import { PREFIX as editProjectRequest } from 'redux/buildings/editProject/reducer';

import { PREFIX as tersMClientListRequest } from 'redux/distributors/distributorList/reducer';
import { PREFIX as tersMClientViewRequest } from 'redux/distributors/distributorView/reducer';
import { PREFIX as contactRequest } from 'redux/contact/reducer';

import { PREFIX as downloadReport } from 'redux/reports/reducer'; // Detalle de clientes del perfil distribuidor

import { PREFIX as arqFinishesNewProjectRequest } from 'redux/arqFinishes/newProject/reducer';
import { PREFIX as arqFinishesProjectListRequest } from 'redux/arqFinishes/projectList/reducer';
import { PREFIX as arqFinishesEditProjectRequest } from 'redux/arqFinishes/editProject/reducer';

/* backoffice */
import { PREFIX as usersRequest } from 'redux/user/userList/reducer'; // Lista de usuario de la app
import { PREFIX as usersEditRequest } from 'redux/user/userEdit/reducer'; // Detalle de usuario de la app

const getProp = curry((prop, { meta }) => {
  return get(meta, prop);
});

const LOGIN_URL = window.env.LOGIN_URL;
const REFRESH_TOKEN_URL = window.env.REFRESH_TOKEN_URL;

const uris = {
  [loginRequest]: LOGIN_URL,
  [refreshRequest]: REFRESH_TOKEN_URL,
  [profileRequest]: '/profile',
  [editContactProfile]: '/profile',
  [changePassword]: '/password',
  [dashboardRequest]: '/dashboard',
  //
  [installersRequest]: '/installers',
  [installersView]: flow(getProp('code'), (code) => `/installers/${code}`),
  //
  [brandsRequest]: '/cars/brands',
  [distributorsRequest]: '/distributors',

  //
  [contactRequest]: '/contact',

  //
  [tersMClientListRequest]: '/distributors',
  [tersMClientViewRequest]: flow(
    getProp('code'),
    (code) => `/distributors/${code}`,
  ),

  //
  [userInstallersRequest]: '/user/me/installer',
  [userInstallersEdit]: '/user/me/installer',

  [filmsAutoRequest]: '/films/auto',
  //[filmsPPFRequest]: '/films/auto/PPF',
  //[filmsWrapRequest]: '/films/WRAP',
  [filmsBuildingRequest]: '/films/building',
  [modelsRequest]: flow(
    getProp('brand'),
    (brand) => `/cars/brands/${brand}/models`,
  ),
  [statesRequest]: flow(
    getProp('country'),
    (country) => `/geo/countries/${country}/states`,
  ),
  [citiesRequest]: flow(
    (action) => ({
      country: getProp('country', action),
      state: getProp('state', action),
    }),
    ({ country, state }) => `/geo/countries/${country}/states/${state}/cities`,
  ),
  //
  [newClientRequest]: '/warranties/auto',
  [clientListRequest]: '/warranties/auto',
  [editClientRequest]: flow(getProp('code'), (code) => `/warranties/${code}`),
  [sendWarratyRequest]: flow(
    getProp('code'),
    (code) => `/warranties/${code}/email`,
  ),
  [reportWarratyRequest]: flow(
    getProp('code'),
    (code) => `/warranties/${code}/report`,
  ),
  [approbeWarratyRequest]: flow(
    getProp('code'),
    (code) => `/warranties/${code}/approve`,
  ),
  //
  [newProjectRequest]: '/warranties/building',
  [projectListRequest]: '/warranties/building',
  [editProjectRequest]: flow(getProp('code'), (code) => `/warranties/${code}`),

  //
  [downloadReport]: flow(getProp('report'), (report) => report),

  //Europe
  [arqFinishesNewProjectRequest]: '/warranties/arqFinishes',
  [arqFinishesProjectListRequest]: '/warranties/arqFinishes',
  [arqFinishesEditProjectRequest]: flow(
    getProp('code'),
    (code) => `/warranties/${code}`,
  ),

  //backoffice
  [usersRequest]: '/backoffice/users',
  [usersEditRequest]: flow(
    getProp('code'),
    (code) => `/backoffice/users/${code}`,
  ),
};

export default uris;
