import React from 'react';
import Layout from '_metronic/layout/Layout';
import Breadcrumbs from 'components/breadcrumb';

const breadcrumb = [{ page: 'backoffice/dashboard', title: 'DashBoard' }];

export default function UsersList() {
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-10">
          <div className="kt-space-20" />
          <Breadcrumbs title="Dashboard" items={breadcrumb} />
        </div>
      </div>
    </Layout>
  );
}
