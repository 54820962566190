import MESSAGES from './ar';

export default {
  ...MESSAGES,
  'Número de Patente:': 'Número de Placa o chasis si es concesionario:',
  'Debe ingresar la patente': 'Debes ingresar el número de placa o de chasis',
  'Número de patente *': 'Número de placa o de chasis*',
  'Por favor ingrese el número de patente':
    'Por favor ingresa el número de placa o de chasis',
  'CUIT:': 'RUC/NIT:',
  'CUIT: *': 'RUC/NIT: *',
  'Debe ingresar un CUIT': 'Debes ingresar un RUC/NIT',
};
