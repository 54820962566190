import { setCookiesAcceptance } from './actions';
export default (state = {}, action = {}) => {
  switch (action.type) {
    case 'cookieAccepted':
      return {
        ...state,
        isCookieAccepted: action.payload,
      };

    default:
      return state;
  }
};
