import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import TextField from 'components/form/input';
import PhoneIcon from '@material-ui/icons/Phone';
import * as Yup from 'yup';

import {
  requests as userEditRequests,
  actions as userEditActions,
} from 'redux/user/userEdit/reducer';

import { getUserData } from 'redux/user/userEdit/selector';
import { countryOPTs, regionOPTs } from 'enums/formOtps';
import Autocomplete from 'components/form/autocomplete';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

// import Business from './business';
// import Contact from './contact';
// import Installer from './installer';

const breadcrumb = [{ page: 'backoffice/users', title: 'Usuarios' }];

const initialValues = {
  username: '',
  firstname: '',
  lastname: '',
  enabled: false,
  country: 'AR',
  visibleCountries: 'AR,CL,UY',
};

export const ViewClient = ({
  onSubmit,
  getUser,
  clearData,
  userData,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (code != 'new') {
      getUser({ code });
    }
    return () => clearData();
  }, [clearData, getUser, code]);

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);
    return onSubmit(values);
  };

  return (
    <Layout>
      {(Boolean(Object.keys(userData).length) || code == 'new') && (
        <Formik
          initialValues={{ ...initialValues, ...userData }}
          onSubmit={handleSubmit}
          // validateOnBlur={false}
          // validateOnChange={false}
          // validationSchema={validationSchema(intl)}
        >
          {() => (
            <Form>
              <div className="kt-space-20" />
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <Breadcrumbs title="Usuarios" items={breadcrumb} />
                  <div className="kt-space-20" />
                </div>
              </div>
              <div className="ViewCarWarranty">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Nombre: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name="firstname"
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          placeholder="Nombre"
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Apellido: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name="lastname"
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          placeholder="Apellido"
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Email: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name="username"
                          component={TextField}
                          type="text"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Habilitado: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field type="checkbox" name="enabled" />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Pais: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name="country"
                          component={Autocomplete}
                          variant="outlined"
                          options={countryOPTs}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-sm-12 col-md-12 col-lg-4">
                        <strong>
                          <FormattedMessage id="Paises Visibles: *" />
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <Field
                          name="visibleCountries"
                          component={Autocomplete}
                          variant="outlined"
                          options={regionOPTs}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Portlet className="kt-portlet--height-fluid">
                      <PortletBody className="submit-bottons">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          // disabled={isLoading}
                        >
                          <FormattedMessage id="Guardar" />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => history.goBack()}
                        >
                          <FormattedMessage id="Volver" />
                        </Button>
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => {
    console.log(values);
    toast.success(
      <FormattedMessage id="La información ha sido actualizada." />,
      toastConfig,
    );
  },
  getUser: (data) => dispatch(userEditRequests.fetch(data)),
  clearData: () => dispatch(userEditActions.clearData()),
});

export default connect(getUserData, mapDispatchToProps)(ViewClient);
