import './index.scss';
import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, FormikProps, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import Cookie from '../../../_metronic/layout/cookie-consent/Cookie';
import '_metronic/_assets/sass/pages/login/resetPass.scss';
import { actions as i18nActions } from '_metronic/ducks/i18n';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const baseURL = window.env.API_URL;

function ResetPassword(props) {
  const { intl, isLoading, setLanguage } = props;
  const [userReset, setUserReset] = useState('');
  const { code } = useParams() || '';
  const [resetResult, setResetResult] = useState('');
  const queryParams = useQuery();

  useEffect(() => {
    const lang = queryParams.get('lang');
    if (lang) {
      setLanguage(lang);
    }
  }, [queryParams, setLanguage]);

  function showForm() {
    return (
      <>
        <div id="reset-content-form">
          <p id="reset-content-form-message">
            {intl.formatMessage({
              id:
                'Ingresa tu usuario o la dirección de correo electrónico con la cual estás registrado. Te enviaremos un correo electrónico para generar una nueva contraseña.',
            })}
          </p>
          <Formik
            initialValues={{
              mail: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.mail) {
                errors.mail = intl.formatMessage({
                  id: 'Debe ingresar un email válido',
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)
              ) {
                errors.mail = intl.formatMessage({
                  id: 'Debe ingresar un email válido',
                });
              }
              return errors;
            }}
            onSubmit={(values) => {
              console.log(values);
              setUserReset(values.mail);
              axios
                .post(`${baseURL}/authentication/reset`, {
                  email: values.mail,
                })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form>
                {/* {(errors != {}) && touched.mail && (
                    <div className="alert-text text-center">
                      {intl.formatMessage({
                        id: 'Usuario o contsraseña invalidos',
                      })}
                    </div>
                  )} */}
                <div className="form-group">
                  <h5 className="text-center">
                    {intl.formatMessage({
                      id: 'Usuario o Correo electrónico:',
                    })}
                  </h5>
                  <Field
                    type="mail"
                    margin="center"
                    className="reset-content-form-input"
                    name="mail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    value={values.mail}
                    helpertext={touched.mail && errors.mail}
                    error={Boolean(touched.mail && errors.mail)}
                  />{' '}
                  <div className="w-100 text-center mt-1">
                    <ErrorMessage name="mail" />
                  </div>
                </div>
                <div id="reset-content-form-button-container">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isLoading}
                    className={` mb-2 btn btn-primary btn-elevate kt-login__btn-primary reset-content-form-button
                        )}`}
                  >
                    {intl.formatMessage({ id: 'Enviar' })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div id="reset-content-footer mt-2">
            <p id="reset-content-footer-message">
              {intl.formatMessage({
                id:
                  'Si aún necesitas ayuda, puedes ponerte en contacto con el soporte técnico.',
              })}
            </p>
          </div>
        </div>
      </>
    );
  }
  function showSentMailMessage(user) {
    const mailToShowpt1 = user.slice(0, 3);
    const mailToShowpt2 = user.slice(user.indexOf('@'));
    const mailToShow = `${mailToShowpt1}*****${mailToShowpt2}`;
    return (
      <>
        <div id="reset-content-form">
          <p id="reset-content-form-message">
            {intl.formatMessage({
              id:
                'Te enviamos un email con instrucciones para reestablecer tu contraseña a',
            })}
          </p>
          <p className="text-center"> {mailToShow} </p>
          <p className="text-center my-3">
            {intl.formatMessage({
              id:
                'Si no lo encuentras en tu bandeja de entrada, revisa el correo no deseado.',
            })}{' '}
          </p>
        </div>
      </>
    );
  }
  function showNewPasswordForm(code) {
    return (
      <>
        <div id="reset-content-form">
          <Formik
            initialValues={{
              newPass: '',
              confirmation: '',
            }}
            validate={(values) => {
              const errors = {};
              console.log(
                values.newPass.match(
                  /(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/,
                ),
              );
              if (!values.newPass) {
                errors.newPass = intl.formatMessage({
                  id: 'Debe ingresar una contraseña',
                });
              } else if (values.newPass != values.confirmation) {
                errors.confirmation = intl.formatMessage({
                  id: 'Las contraseñas no coinciden',
                });
              } else if (
                !values.newPass.match(
                  /(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/,
                )
              ) {
                errors.confirmation = intl.formatMessage({
                  id: 'Password_change',
                });
              }
              return errors;
            }}
            onSubmit={(values) => {
              const reqParams = {
                code: code,
                password: values.newPass,
                password2: values.confirmation,
              };

              axios
                .post(`${baseURL}/authentication/reset`, reqParams)
                .then((res) => {
                  console.log(res);
                  setResetResult('SUCCESS');
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form>
                <div className="form-group">
                  <p id="reset-content-form-message">
                    {intl.formatMessage({
                      id: 'Nueva contraseña: *',
                    })}
                  </p>
                  <Field
                    type="password"
                    margin="center"
                    className="reset-content-form-input"
                    name="newPass"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    value={values.newPass}
                    helpertext={touched.newPass && errors.newPass}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />{' '}
                  <div className="w-100 text-center mt-1">
                    <ErrorMessage name="newPass" />
                  </div>
                  <p id="reset-content-form-message">
                    {intl.formatMessage({
                      id: 'Repetir nueva contraseña: *',
                    })}
                  </p>
                  <Field
                    type="password"
                    margin="center"
                    className="reset-content-form-input"
                    name="confirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    value={values.confirmation}
                    helpertext={touched.confirmation && errors.confirmation}
                    error={Boolean(touched.confirmation && errors.confirmation)}
                  />{' '}
                  <div className="w-100 text-center mt-1 error">
                    <ErrorMessage name="confirmation" />
                  </div>
                  <div className="help" style={{ marginLeft: '15px' }}>
                    {intl.formatMessage({ id: 'La contraseña debe tener:' })}
                    <ul>
                      <li>
                        {intl.formatMessage({ id: 'Mínimo de 8 caracteres' })}
                      </li>
                      <li>{intl.formatMessage({ id: 'Una mayúscula' })}</li>
                      <li>{intl.formatMessage({ id: 'Una minúscula' })}</li>
                      <li>{intl.formatMessage({ id: 'Un número' })}</li>
                      <li>
                        {intl.formatMessage({ id: 'Un caracter especial' })}
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="reset-content-form-button-container">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isLoading}
                    className={` mb-2 btn btn-primary btn-elevate kt-login__btn-primary reset-content-form-button
                      )}`}
                  >
                    {intl.formatMessage({ id: 'Enviar' })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
  function showSuccessMessage() {
    return (
      <div id="reset-content-form">
        <h2 className="text-center" id="reset-content-form-message-success">
          {intl.formatMessage({
            id: '¡Tu contraseña se ha actualizado!',
          })}
        </h2>

        <p className="text-center my-3">
          {intl.formatMessage({
            id: '¡Genial! Has establecido una constraseña nueva.',
          })}{' '}
        </p>
        <p className="text-center my-3">
          {intl.formatMessage({ id: 'Ya puedes' })}{' '}
          <Link to="/">{intl.formatMessage({ id: 'iniciar sesión.' })}</Link>{' '}
        </p>
      </div>
    );
  }
  return (
    <>
      <div id="reset-bg">
        <div id="reset-header">
          <img
            id="reset-header-logo"
            src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-BLANCO' }))}
          ></img>
        </div>
      </div>
      <div id="reset-content">
        <div id="reset-content-header">
          <p id="reset-header-title">
            {intl.formatMessage({
              id: 'Regenerar contraseña',
            })}
          </p>
        </div>
        {!code
          ? userReset == ''
            ? showForm()
            : showSentMailMessage(userReset)
          : resetResult == 'SUCCESS'
          ? showSuccessMessage()
          : showNewPasswordForm(code)}
      </div>

      <Cookie />
    </>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => {
    console.log(lang);
    dispatch(i18nActions.setLanguage(lang));
  },
});
export default injectIntl(
  connect(undefined, mapDispatchToProps)(ResetPassword),
);
