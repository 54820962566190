import get from 'lodash/get';
import { PREFIX } from './reducer';

export const getClientData = (state) => ({
  clientData: get(state, `${PREFIX}.data`, {}),
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasError: get(state, `${PREFIX}.hasError`, false),
  isSuccess: get(state, `${PREFIX}.isSuccess`, false),
});

export const getApproveData = (state) => ({
  clientData: get(state, `${PREFIX}.data`, []),
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasWarrantyError: get(state, `${PREFIX}.hasWarrantyError`, false),
  isWarrantySuccess: get(state, `${PREFIX}.isWarrantySuccess`, false),
});

export const getSendWarratyData = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasSendError: get(state, `${PREFIX}.hasSendError`, false),
  isSendSuccess: get(state, `${PREFIX}.isSendSuccess`, false),
  isClosed: get(state, `${PREFIX}data.isClosed`, false),
  isApproved: get(state, `${PREFIX}data.isApproved`, false),
  hasComplaint: get(state, `${PREFIX}data.hasComplaint`, false),
});

export const getReportWarratyData = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasReportError: get(state, `${PREFIX}.hasReportError`, false),
  isReportSuccess: get(state, `${PREFIX}.isReportSuccess`, false),
});
