import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';

import Autocomplete from '@material-ui/lab/Autocomplete';

import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import identity from 'lodash/identity';

const ExtendTextField = injectIntl(
  ({ intl, helperText = '', placeholder = '', label = '', ...props }) => {
    const helper = helperText ? intl.formatMessage({ id: helperText }) : '';
    const place = placeholder ? intl.formatMessage({ id: placeholder }) : '';
    const lbl = label ? intl.formatMessage({ id: label }) : '';
    return (
      <TextField
        helperText={helper}
        placeholder={place}
        label={lbl}
        {...props}
      />
    );
  },
);

const BootstrapTextField = withStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.8rem',
      marginLeft: 0,
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
    },
    '& .MuiInputBase-root': {
      '& legend': {
        display: 'none',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 12px',
      },
      paddingRight: '39px !important',
      padding: 0,
    },
  },
}))(ExtendTextField);

export default injectIntl(
  ({ intl, textFieldProps, autoCompleteProps, emptyValue, ...props }) => {
    const defaultValue = emptyValue || null;
    const [state, setState] = useState(defaultValue);

    const { name, value, ...fieldProps } = fieldToTextField(props);
    const freeSolo = get(autoCompleteProps, 'freeSolo');
    const parseNewValue = get(autoCompleteProps, 'parseNewValue', identity);

    const {
      form: { setFieldValue },
      options,
      handleChange,
    } = props;

    useEffect(() => {
      const newState =
        find(options, (o) => get(o, 'value', defaultValue) === value) ||
        (freeSolo && value ? parseNewValue(value) : defaultValue);
      setState(newState);
    }, [options, value, freeSolo, defaultValue, parseNewValue]);

    return (
      <Autocomplete
        noOptionsText={<FormattedMessage id="No hay opciones" />}
        name={name}
        options={options}
        value={state}
        onChange={(_, newValue) => {
          setFieldValue(name, get(newValue, 'value', defaultValue));
          if (handleChange) {
            handleChange(newValue);
          }
        }}
        getOptionLabel={(option) =>
          option.label ? intl.formatMessage({ id: option.label }) : ''
        }
        renderOption={(option) =>
          option.label ? intl.formatMessage({ id: option.label }) : ''
        }
        {...omit(autoCompleteProps, ['parseNewValue'])}
        renderInput={(params) => (
          <BootstrapTextField
            {...params}
            {...textFieldProps}
            {...omit(fieldProps, ['handleChange', 'onChange', 'onBlur'])}
          />
        )}
      />
    );
  },
);
