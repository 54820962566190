export const XHR_METODS = {
  Fetch: 'GET',
  Create: 'POST',
  Replace: 'PUT',
  Update: 'PATCH',
  Remove: 'DELETE',
};

export const XHR_STATUS_CODE = {
  // Success
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,

  // Redirection
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,

  // Client Error
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  RequestTimeout: 408,
  Conflict: 409,

  // Server Error
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
};
