import './index.scss';
import React from 'react';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { PorletHeaderTitle } from 'components/porletHeader';
import Typography from '@material-ui/core/Typography';

const breadcrumb = [
  { page: 'distributor/building', title: 'Acabados de Arquitectura' },
  { page: 'distributor/building', title: 'Proyectos registrados' },
];

export default () => (
  <Layout>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-12">
        <div className="kt-space-20" />
        <Breadcrumbs title="Acabados de Arquitectura" items={breadcrumb} />

        <div className="kt-space-20" />
      </div>
    </div>
    <div className="row thankyou">
      <div className="col-sm-12 col-md-12 col-lg-2" />
      <div className="col-sm-12 col-md-12 col-lg-8">
        <PorletHeaderTitle variant="h4">
          <FormattedMessage id="¡Listo!" />
        </PorletHeaderTitle>
        <Typography variant="h4" color="secondary">
          <FormattedMessage id="El proyecto ha sido ingresado con éxito." />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="La garantía está en proceso de aprobación." />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="Cuando esté disponible, te notificaremos por email." />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="Podrás ver el certificado en la sección proyectos registrados" />
        </Typography>
        <div className="kt-space-20" />
        <Link to="/installer/arqFinishes">
          <Button variant="contained" color="primary" size="large">
            <FormattedMessage id="Finalizar" />
          </Button>
        </Link>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2" />
    </div>
    <div className="kt-space-20" />
  </Layout>
);
