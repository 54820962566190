import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'models';

export const initialState = {
  ...requestInitialState,
  data: [],
};

const modelsSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    fetch(state, { payload }) {
      return {
        ...state,
        data: [...payload],
      };
    },
    clear() {
      return {
        ...initialState,
      };
    },
  },
});

export const actions = { ...modelsSlice.actions };
export const requests = { ...modelsSlice.requests };

export default modelsSlice.reducer;
