import { all, takeEvery, select, put } from 'redux-saga/effects';
import { actions as reportsActions } from './reducer';
import { getFileName } from './selector';

// copie y pege, puede que no ande bien
// https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
const saveData = (data, fileName) => {
  // let a = document.createElement('a');
  // document.body.appendChild(a);
  // a.style = 'display: none';

  // let blob = new Blob([data], { type: 'octet/stream' }),
  //   url = window.URL.createObjectURL(blob);
  // a.href = url;
  // a.download = fileName;
  // a.click();
  // window.URL.revokeObjectURL(url);

  let csvContent = 'data:text/csv;charset=utf-8,' + data;
  let encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.style = 'display: none';
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link); // Required for FF
  link.click();
};

export function* downloadFile({ payload }) {
  const fileName = yield select(getFileName);
  console.log('este el el nombre del archivo a descargar', fileName);
  console.log('esta es la data para descargar', payload);
  saveData(payload, fileName);
  yield put(reportsActions.clear());
}

export default function* () {
  yield all([takeEvery(reportsActions.fetch, downloadFile)]);
}
