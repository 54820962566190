import axios from 'axios';

import curry from 'lodash/curry';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { REQUEST_METHODS } from 'enums/request';
import { XHR_METODS } from 'enums/xhr';
import URIS from './uriMap';

const baseURL = window.env.API_URL;

export const getPathFromAction = (action) => {
  const prefix = get(action, 'meta.prefix');
  const method = get(action, 'meta.method');

  const setId = [
    REQUEST_METHODS.Replace,
    REQUEST_METHODS.Update,
    REQUEST_METHODS.Remove,
  ].includes(method);

  let path = URIS.hasOwnProperty(prefix)
    ? URIS[prefix]
    : `/${prefix.replace(/\\/g, '').toLowerCase()}`;

  path = isFunction(path) ? path(action) : path;

  return setId ? `${path}/${action.meta.id}` : path;
};

export const resolveRequest = (action) => ({
  baseURL,
  url: getPathFromAction(action),
  method: XHR_METODS[get(action, 'meta.method', 'fetch')],
  params: get(action, 'meta.query'),
  headers: get(action, 'meta.headers'),
  data: get(action, 'payload'),
});

const makeRequestARITY = 2;
export const makeRequest = curry(
  (options = {}, action) =>
    axios.request(defaultsDeep(options, resolveRequest(action))),
  makeRequestARITY,
);
