import React from 'react';
import Layout from '_metronic/layout/Layout';
import ProductCard from './productCard';
import {
  autoProducts,
  autoSecurity,
  autoSolarSecurity,
} from './productCard/auto_prods';
import './productCard/productCard.css';

import { connect } from 'react-redux';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';

function ProductAuto(props) {
  let autoSolarControl = autoProducts;
  let autoSolarSecurityList = autoSolarSecurity;
  let autoSecurityList = autoSecurity;
  return (
    <Layout>
      <div className="container-fluid bg-white">
        <div className="row border-bottom pb-5">
          <div className="col-6">
            <div className="d-flex d-flex  mt-5 ml-2">
              <p className="h3 pt-2 mr-3">
                <FormattedMessage id="Películas para Vidrios de Automóviles 3M™" />
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex d-flex flex-row-reverse mt-5 mr-5">
              <img
                height="40px"
                className="justify-self-end img-responsive"
                src={
                  process.env.PUBLIC_URL + './media/product_imgs/auto_logo.jpg'
                }
              ></img>
              <p className="h3 pt-2 mr-3">
                <FormattedMessage id="Automóvil" />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mb-5 mt-5 ">
          <img
            height="40px"
            className="ml-3 img-responsive"
            src={
              process.env.PUBLIC_URL + './media/product_imgs/solar_control.jpg'
            }
          ></img>
          <p className="h5 pt-4 ml-2">
            <FormattedMessage id="Películas de control Solar" />
          </p>
        </div>
        <div className="row bg-white">
          {autoSolarControl.map((e) => {
            console.log(e);
            return (
              <div className="col-xs-12 col-md-4 col-xl-3 ">
                <ProductCard
                  name={e.name}
                  type={e.type}
                  description2={e.description2}
                  description={e.description}
                  img={e.img}
                  years={e.years}
                  degrade={e.degrade}
                  flyer_url={e.flyer_url}
                  key={e.name}
                ></ProductCard>
              </div>
            );
          })}
        </div>
        <div className="row bg-white mt-5">
          <div className="col-xs-12 col-md-4 col-xl-3 ">
            <div className="d-flex mb-5">
              <img
                height="40px"
                className="ml-3 img-responsive"
                src={
                  process.env.PUBLIC_URL +
                  './media/product_imgs/solar_control.jpg'
                }
              ></img>
              <img
                height="40px"
                className="ml-3 img-responsive"
                src={
                  process.env.PUBLIC_URL +
                  './media/product_imgs/shield_logo.jpg'
                }
              ></img>
              <p className="h5 ml-2">
                <FormattedMessage id="Películas de control Solar y seguridad" />
              </p>
            </div>

            {autoSolarSecurityList.map((e) => {
              return (
                <ProductCard
                  name={e.name}
                  type={e.type}
                  description2={e.description2}
                  description={e.description}
                  img={e.img}
                  years={e.years}
                  degrade={e.degrade}
                  flyer_url={e.flyer_url}
                ></ProductCard>
              );
            })}
          </div>
          {props.lang !== 'br' && (
            <>
              <div className="col-xs-12 col-md-4 col-xl-3 ">
                <div className="d-flex mb-5">
                  <img
                    height="40px"
                    className="ml-3 img-responsive"
                    src={
                      process.env.PUBLIC_URL +
                      './media/product_imgs/shield_logo.jpg'
                    }
                  ></img>
                  <p className="h5 ml-2">
                    <FormattedMessage id="Películas de seguridad" />
                  </p>
                </div>
                {autoSecurityList.map((e) => {
                  return (
                    <ProductCard
                      name={e.name}
                      type={e.type}
                      description2={e.description2}
                      description={e.description}
                      img={e.img}
                      years={e.years}
                      degrade={e.degrade}
                      flyer_url={e.flyer_url}
                    ></ProductCard>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});
export const matStateToProps = (state) => ({
  lang: get(state, 'i18n.lang') === 'br' ? 'br' : 'es',
});
export default injectIntl(
  connect(matStateToProps, mapDispatchToProps)(ProductAuto),
);
