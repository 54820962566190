import { combineReducers } from 'redux';
import createRequestSlice, { requestInitialState } from 'redux/request/reducer';
import distributorList from './distributorList/reducer';
import distributorView from './distributorView/reducer';

export const PREFIX = 'distributors.distributor';

export const initialState = {
  ...requestInitialState,
  data: [],
};

const distributorsSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    fetch(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const actions = { ...distributorsSlice.actions };
export const requests = { ...distributorsSlice.requests };

export default combineReducers({
  distributor: distributorsSlice.reducer,
  distributorList,
  distributorView,
});
