import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    brand: Yup.string()
      .required(intl.formatMessage({ id: 'Debe seleccionar una marca' }))
      .typeError(intl.formatMessage({ id: 'Debe seleccionar una marca' })),
    model: Yup.string()
      .required(intl.formatMessage({ id: 'Debe seleccionar un modelo' }))
      .typeError(intl.formatMessage({ id: 'Debe seleccionar un modelo' })),
    patent: Yup.string()
      .required(intl.formatMessage({ id: 'Debe ingresar la patente' }))
      .typeError(intl.formatMessage({ id: 'Debe ingresar la patente' })),
  });
