// TODO FALTA BOCHA DE INFO
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';

import Seacrch from './search';
import Actions from './actions';

import { actions as projectListActions } from 'redux/buildings/projectList/reducer';
import { getProjectListTableData } from 'redux/buildings/projectList/selector';

import DataTable from 'components/dataTable';

import Button from '@material-ui/core/Button';
import { getStatusFile } from 'redux/reports/selector';
import { requests as reportsRequests } from 'redux/reports/reducer';

import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { getUserRole } from 'redux/profile/selector';
import axios from 'axios';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import { getCountryProfile } from 'redux/profile/selector';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

// TODO revisar producto
const columns = [
  {
    prop: 'subtype',
    label: 'Tipo',
    format: (value) => {
      switch (value.toUpperCase()) {
        case 'RESIDENTIAL':
          return <FormattedMessage id="Pequeño Proyecto" />;

        case 'COMMERCIAL':
          return <FormattedMessage id="Gran Proyecto" />;
      }
    },
  },
  { prop: 'installer.name', label: 'Instalador' },
  { prop: 'data.name', label: 'Proyecto' },
  {
    prop: 'data.companyName',
    label: 'Cliente',
  },
  {
    prop: 'data.status',
    label: 'Estado',
    format: (value, row) => {
      let textColor = '';
      let color = '';
      switch (value.toUpperCase()) {
        case 'PERDIDA':
          color = '#F00';
          textColor = '#FFF';
          break;
        case 'PENDIENTE':
          color = '#999';
          textColor = '#FFF';
          break;
        case 'GANADA':
          if (row.code) {
            color = '#73c968';
            textColor = '#FFF';
          } else {
            color = '#ffaa00';
            textColor = '#FFF';
          }
          break;
        case 'CANCELADA':
          color = '#601490';
          textColor = '#FFF';
          break;
        case 'CLIENTE':
          color = '#c919d6';
          textColor = '#FFF';
          break;
        default:
          color = '#999';
          textColor = '#FFF';
          value = 'Pendiente';
          break;
      }

      return (
        <Chip
          style={{
            backgroundColor: color,
            color: textColor,
            fontWeight: 'bold',
          }}
          label={
            row.code ? (
              <FormattedMessage id="Ganada / Cerrada" />
            ) : (
              <FormattedMessage id={value} />
            )
          }
        />
      );
    },
  },
  { prop: 'data.applications[0].film.name', label: 'Producto' },
  {
    prop: 'code',
    label: 'Certificado de garantía',
  },
  // {
  //   prop: 'hasComplaint',
  //   label: 'Reclamo',
  //   format: (value) => (
  //     <Chip
  //       color={value ? 'primary' : 'secondary'}
  //       label={value ? 'Abierto' : 'NO'}
  //     />
  //   ),
  // },
  {
    prop: 'uuid',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value, row }} />,
  },
];

const useStyles = makeStyles((theme) => ({
  input: {
    marginRight: 2,
  },
}));

const Table = ({
  intl,
  setFilter,
  downloadFile,
  country,
  file,
  role,
  ...props
}) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);

  if (
    country == 'AR' ||
    country == 'BO' ||
    country == 'CE' ||
    country == 'CL' ||
    country == 'CO' ||
    country == 'CR' ||
    country == 'DO' ||
    country == 'EC' ||
    country == 'GT' ||
    country == 'HN' ||
    country == 'JM' ||
    country == 'MX' ||
    country == 'PA' ||
    country == 'PE' ||
    country == 'PY' ||
    country == 'SV' ||
    country == 'UY'
  ) {
    fileName = 'garantia-distribuidor-building.csv';
  } else if (country == 'BR' || country == 'PT') {
    fileName = 'garante-distributor-building.csv';
  } else if (country == 'DE') {
    fileName = 'garantie-distributor-building.csv';
  } else if (country == 'NL') {
    fileName = 'garantie-distributor-building.csv';
  } else {
    fileName = 'warranty-distributor-building.csv';
  }

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-2 offset-lg-7">
          {Boolean(role.userRole.toUpperCase() === 'SEGVEL') && (
            <span>
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className={classes.input}
              >
                <FormattedMessage id="Subir Reporte" />
              </Button>
              <DropzoneDialog
                showAlerts={false}
                acceptedFiles={['text/csv']}
                cancelButtonText={intl.formatMessage({ id: 'Cancelar' })}
                submitButtonText={intl.formatMessage({ id: 'Enviar' })}
                dialogTitle={intl.formatMessage({ id: 'Subir Reporte' })}
                dropzoneText={intl.formatMessage({
                  id: 'Arrastre y suelte un archivo aquí o haga click',
                })}
                maxFileSize={5000000}
                open={open}
                onClose={() => setOpen(false)}
                onSave={(files) => {
                  console.log('Files:', files);
                  let url = API_URL + '/bulk/segvel';
                  const data = new FormData();
                  data.append('file', files[0]);
                  axios
                    .post(url, data, {
                      headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                          ACCESS_TOKEN,
                        )}`,
                      },
                    })
                    .then((res) => {
                      setOpen(false);
                      toast.success(
                        <FormattedMessage id="El archivo se envio correctamente" />,
                        toastConfig,
                      );
                      setFilter();
                    })
                    .catch((res) => {
                      setOpen(false);
                      toast.error(
                        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
                        toastConfig,
                      );
                      setFilter();
                    });
                }}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNames={true}
                filesLimit={1}
              />
            </span>
          )}

          <Button
            variant="outlined"
            onClick={downloadFile}
            disabled={file.isLoading}
          >
            <FormattedMessage id="Export..." />
          </Button>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};

let fileName;

export const mapStateToProps = (state) => ({
  ...getProjectListTableData(state),
  file: getStatusFile(fileName)(state),
  role: getUserRole(state),
  country: getCountryProfile(state),
});
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
  downloadFile: () =>
    dispatch(
      reportsRequests.fetch({
        report: '/reports/garantias/building',
        fileName,
      }),
    ),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Table));

// export const mapDispatchToProps = (dispatch) => ({
//   setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
// });

// export default connect(getProjectListTableData, mapDispatchToProps)(Table);
