const COORDS = (value) => {
  switch (value) {
    case 'AR':
      return { lat: -34, lng: -64 };
    case 'BO':
      return { lat: -17, lng: -65 };
    case 'BR':
      return { lat: -10, lng: -55 };
    case 'CE':
      return { lat: 0, lng: 0 };
    case 'CL':
      return { lat: -30, lng: -71 };
    case 'CO':
      return { lat: 4, lng: -73.25 };
    case 'CR':
      return { lat: 10, lng: -84 };
    case 'EC':
      return { lat: -1.25, lng: -78.25 };
    case 'GT':
      return { lat: 15.5, lng: -90.25 };
    case 'HN':
      return { lat: 15, lng: -86.5 };
    case 'JM':
      return { lat: 0, lng: 0 };
    case 'MX':
      return { lat: 23, lng: -102 };
    case 'PA':
      return { lat: 9, lng: -80 };
    case 'PE':
      return { lat: -10, lng: -75.25 };
    case 'PY':
      return { lat: -23.33334, lng: -58 };
    case 'SV':
      return { lat: 13.8334, lng: -88.91667 };
    case 'UY':
      return { lat: -33, lng: -56 };
    default:
      return { lat: -17, lng: -65 };
  }
};

export default COORDS;
