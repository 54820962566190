import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import TextField from 'components/form/input';

import { getStatusContact } from 'redux/contact/selector';
import { requests as contactRequest } from 'redux/contact/reducer';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const initialValues = {
  name: '',
  email: '',
  comment: '',
};

const validationSchema = (intl) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar un nombre' }),
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
      .required(intl.formatMessage({ id: 'Debe ingresar un email' })),
    comment: Yup.string().required(
      intl.formatMessage({ id: 'Debe ingresar uncomentario' }),
    ),
  });

const breadcrumb = [{ page: 'contact', title: 'Contacto' }];

const Contact = ({ intl, onSubmit, isLoading, isSuccess, hasError }) => {
  const history = useHistory();
  const formikRef = useRef();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success(
        <FormattedMessage id="Su consulta ha sido enviada" />,
        toastConfig,
      );
      formikRef.current.resetForm();
    }
  }, [isLoading, isSuccess]);
  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(false);

    return onSubmit(values);
  };

  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Contacto" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema(intl)}
            innerRef={formikRef}
          >
            {() => (
              <Form id="contact">
                <div className="PasswordView">
                  <div className="kt-space-20" />
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <Portlet className="kt-portlet--height-fluid">
                          <PortletBody>
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-1" />
                              <div className="col-sm-12 col-md-12 col-lg-10">
                                <div className="row FormRow">
                                  <div className="col-12">
                                    <Field
                                      label="Nombre: *"
                                      name="name"
                                      component={TextField}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                                <div className="row FormRow">
                                  <div className="col-12">
                                    <Field
                                      label="Email: *"
                                      name="email"
                                      component={TextField}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                                <div className="row FormRow">
                                  <div className="col-12">
                                    <Field
                                      name="comment"
                                      component={TextField}
                                      type="text"
                                      label="Comentarios:"
                                      rows={7}
                                      multiline
                                      variant="outlined"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-1" />
                            </div>
                          </PortletBody>
                        </Portlet>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Portlet className="kt-portlet--height-fluid">
                        <PortletBody>
                          <div className="submit-bottons">
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              disabled={isLoading}
                            >
                              <FormattedMessage id="Enviar" />
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => history.goBack()}
                            >
                              <FormattedMessage id="Volver" />
                            </Button>
                          </div>
                          {hasError && (
                            <Typography color="primary">
                              <br />
                              <FormattedMessage id="Se a producido un error, vuelva a intentar" />
                            </Typography>
                          )}
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>{' '}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(contactRequest.create({ body: values })),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getStatusContact(state),
    }),
    mapDispatchToProps,
  )(Contact),
);
