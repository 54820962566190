import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';

import {
  requests as editClientRequests,
  actions as editClientActions,
} from 'redux/cars/editClient/reducer';

import { getClientData } from 'redux/cars/editClient/selector';

import Client from './client';
import Shhets from './sheets';
import Warranty from './warranty';
import Vehicle from './vehicle';

const breadcrumb = [{ page: 'distributor/car', title: 'Automóvil' }];

export const ViewWarranty = ({
  getClient,
  clearData,
  clientData,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Automóvil" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>
      {Boolean(Object.keys(clientData).length) && (
        <div className="ViewCarWarranty">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Usuario final" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Client values={clientData} {...props} />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Aplicación" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Shhets values={clientData} {...props} />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Vehículo" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Vehicle values={clientData} {...props} />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid">
                <PorletHeader>
                  <PorletHeaderTitle variant="h4">
                    <FormattedMessage id="Garantía" />
                  </PorletHeaderTitle>
                </PorletHeader>
                <PortletBody>
                  <Warranty values={clientData} {...props} />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Portlet className="kt-portlet--height-fluid">
                <PortletBody className="submit-bottons">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    <FormattedMessage id="Volver" />
                  </Button>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getClient: (data) => dispatch(editClientRequests.fetch(data)),
  clearData: () => dispatch(editClientActions.clearData()),
});

export default connect(getClientData, mapDispatchToProps)(ViewWarranty);
