import * as Yup from 'yup';
import 'utils/phoneValidator';
import { schema } from 'views/distributor/building/newWarranty';

export default (intl, country) =>
  Yup.object().shape({
    customer: Yup.object().shape({
      firstname: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),

      email: Yup.string()
        .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
        .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
    }),
    data: Yup.object().shape({
      //Project
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),

      state: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una provincia' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar una provincia' }),
        ),

      //glass

      // status
      /* status: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      finish: Yup.object().test(
        'hasFinishDate',
        intl.formatMessage({ id: 'Debe ingresar una fecha de cierre' }),
        (value) => Boolean(value.month && value.year),
      ),
      finish: Yup.object().test(
        'isFinishDateValid',
        intl.formatMessage({
          id: 'La fecha estimada no puede ser anterior al día de hoy',
        }),
        (value) => {
          const today = new Date();
          let month = today.getMonth();
          let year = today.getFullYear();
          if (year > parseInt(value.year)) {
            return false;
          } else {
            if (year === parseInt(value.year)) {
              if (month + 1 > parseInt(value.month)) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        },
      ),
      progress: Yup.string().test(
        'isDone',
        'Estado y avance de proyecto no coinciden',
        function (progress) {
          if (progress === '100% Cierre / Ganada') {
            if (this.parent.status !== 'Ganada') {
              return false;
            } else {
              return true;
            }
          }

          if (progress !== '100% Cierre / Ganada') {
            if (this.parent.status === 'Ganada') {
              return false;
            } else {
              return true;
            }
          }
        },
      ),
      */
      //installer
      /*employees: Yup.array().of(
        Yup.object().shape({
          id: Yup.mixed().when('$data.status', (data, schema) => {
            if (data === 'Ganada') {
              return schema.required(
                intl.formatMessage({
                  id: 'Debe ingresar un instalador',
                }),
              );
            }
            return schema;
          }),
        }),
      ),*/
      // sheets
      applications: Yup.array().of(
        Yup.object().shape({
          substrate: Yup.string()
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            )
            .required(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),
          family: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),

          need: Yup.string()
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            )
            .required(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),
          film: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar una opción' }),
            ),

          meters: Yup.number()
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .required(intl.formatMessage({ id: 'Debe ingresar un número' })),
          run: Yup.mixed().when('$data.status', (data, schema) => {
            if (data === 'Ganada') {
              return schema.required(
                intl.formatMessage({ id: 'Debe ingresar un número' }),
              );
            }
            return schema;
          }),
          distributor: Yup.object().shape({
            id: Yup.mixed().when('$data.status', (data, schema) => {
              if (data === 'Ganada') {
                return schema.required(
                  intl.formatMessage({
                    id: 'Debe seleccionar un distribuidor',
                  }),
                );
              }
              return schema;
            }),
          }),
        }),
      ),
    }),
  });
