import './style.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { getArqFinishesPremiumPartnerWidget } from 'redux/dashboard/selector';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const ValueTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '2em',
    whiteSpace: 'nowrap',
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontWeight: 'normal',
    paddingRight: '1em',
  },
})(Typography);

const RegisterProjects = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid-half">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Instalador Autorizado" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      <div className="Atomovil-Rank">
        <DescriptionTypography>
          <FormattedMessage id="Cantidad de proyectos que necesita rgistrar para mantenerte como instalador autorizado" />
        </DescriptionTypography>
        {!isLoading && (
          <div className="Atomovil-Rank-Value">
            <ValueTypography color="primary">
              {data.value} <FormattedMessage id="de" /> {data.total}
            </ValueTypography>
          </div>
        )}
      </div>
    </PortletBody>
  </Portlet>
);
export default connect(getArqFinishesPremiumPartnerWidget)(RegisterProjects);
