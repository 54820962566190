import React from 'react';
import Layout from '_metronic/layout/Layout';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import RangePicker from './rangePicker';

import Breadcrumbs from 'components/breadcrumb';

/////////////////////////////AUTOMOVIL///////////////////////////////////////////
import Car from './icons/car';
import AutoClientesWidget from './widgets/automovil/clientes';
import AutoCalificacionesWidget from './widgets/automovil/calificaciones';
import AutoMensajeWidget from './widgets/automovil/mensaje';
import AutoLaminasWidget from './widgets/automovil/laminas';
import AutoAutomovilesWidget from './widgets/automovil/automoviles';
//import AutoRankingWidget from './widgets/automovil/ranking';
// import AutoPremiumWidget from './widgets/automovil/premium';
import AutoClub3MWidget from './widgets/automovil/club3m';
/////////////////////////////AUTOMOVIL///////////////////////////////////////////
/////////////////////////////ARQUITECTURA////////////////////////////////////////
import Arq from './icons/arq';
import ArqIngresadosWidget from './widgets/arquitectura/ingresados';
import ArqEstadoWidget from './widgets/arquitectura/estado';
import ArqMensajeWidget from './widgets/arquitectura/mensaje';
import ArqMensaje2Widget from './widgets/arquitectura/mensaje2';
import ArqLaminasWidget from './widgets/arquitectura/laminas';
import ArqGarantiasWidget from './widgets/arquitectura/garantias';
import ArqAutorizadosWidget from './widgets/arquitectura/autorizados';
//import ArqRegistradosWidget from './widgets/arquitectura/registrados';
import ArqClub3MWidget from './widgets/arquitectura/club3m';
/////////////////////////////ARQUITECTURA////////////////////////////////////////

/////////////////////////////arqFinishes////////////////////////////////////////
import GF from './icons/arqFinishes';
import GFIngresadosWidget from './widgets/arqFinishes/ingresados';
import GFEstadoWidget from './widgets/arqFinishes/estado';
import GFMensajeWidget from './widgets/arqFinishes/mensaje';
import GFLaminasWidget from './widgets/arqFinishes/laminas';
import GFGarantiasWidget from './widgets/arqFinishes/garantias';
import GFAutorizadosWidget from './widgets/arqFinishes/autorizados';
import GFRegistradosWidget from './widgets/arqFinishes/registrados';
/////////////////////////////arqFinishes////////////////////////////////////////

import { connect } from 'react-redux';
import { getUserRole } from 'redux/profile/selector';
import { getCountryProfile } from 'redux/profile/selector';

import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';

const breadcrumb = [{ page: 'dashboard', title: 'Panel principal' }];

const Dashboard = (props) => {
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-10">
          <div className="kt-space-20" />
          <Breadcrumbs title="Panel principal" items={breadcrumb} />
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-2"
          style={{ textAlign: 'right' }}
        >
          <div className="kt-space-20" />
          <RangePicker style={{ width: '100%' }} />
        </div>
      </div>

      {console.log(props.country.toUpperCase() !== 'AR')}
      {Boolean(props.auto) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Automóvil" />
                <Car
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoClientesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoCalificacionesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoMensajeWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoAutomovilesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              {/* <AutoRankingWidget />
              //Boolean(props.country.toUpperCase() === 'AR') && (
              //  <AutoPremiumWidget />
              //)
                */}
              <AutoClub3MWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* -------------------------------------------------------------- */}

      {Boolean(props.building) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Arquitectura" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqIngresadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqEstadoWidget />
            </div>
            {props.country == 'DK' ||
            props.country == 'SE' ||
            props.country == 'NO' ||
            props.country == 'FI' ||
            props.country == 'ES' ||
            props.country == 'PT' ||
            props.country == 'FR' ||
            props.country == 'DE' ||
            props.country == 'IT' ||
            props.country == 'CH' ||
            props.country == 'AT' ||
            props.country == 'NL' ||
            props.country == 'BE' ? (
              <div className="col-sm-12 col-md-12 col-lg-4">
                <ArqMensajeWidget />
              </div>
            ) : (
              <div className="col-sm-12 col-md-12 col-lg-4">
                <ArqMensaje2Widget />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqGarantiasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ArqAutorizadosWidget />
              {/*Boolean(props.country.toUpperCase() === 'AR') && (
                <ArqRegistradosWidget />
              )*/}

              {indexOf(EUROPE, props.country.toUpperCase()) < 0 && (
                <ArqClub3MWidget />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* -------------------------------------------------------------- */}

      {Boolean(props.dinoc || props.fasara) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Architectural Finishes" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFIngresadosWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFEstadoWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFMensajeWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFLaminasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFGarantiasWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFAutorizadosWidget />
              {Boolean(props.country.toUpperCase() === 'AR') && (
                <GFRegistradosWidget />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default connect((state) => {
  return {
    ...getUserRole(state),
    country: getCountryProfile(state),
  };
})(Dashboard);
