import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { PREFIX } from './reducer';

export const getDistributors = (state) => get(state, `${PREFIX}.data`);

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act.name,
        value: act.id,
        users: act.users,
      },
    ];
  }, []);

export const getDistributorsOptions = createSelector(
  getDistributors,
  makeAtoCompleteOptions,
);
