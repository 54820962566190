import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import moment from 'moment';
import { Field } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';

import Autocomplete from 'components/form/autocomplete';
import TextField from 'components/form/input';

import {
  yearOPTs,
  monthOPTs,
  projectStatusOPTs,
  projectProgressOPTs,
} from 'enums/formOtps';

export const initialValues = {
  data: {
    status: '',
    progress: '',
    finish: {
      month: '',
      year: '',
    },
  },
};

export const Status = ({ setFieldValue, values, errors, approve }) => (
  <>
    <div className="row FormRow">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <FormattedMessage id="Estado del proyecto: *" />
      </div>
      <div className="col-sm-12 col-md-12 col-lg-8">
        {approve ? (
          <Field
            name="data.status"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Estado del proyecto"
            InputLabelProps={{ shrink: true }}
            disabled
          />
        ) : (
          <Field
            name="data.status"
            component={Autocomplete}
            variant="outlined"
            options={projectStatusOPTs}
          />
        )}
      </div>
    </div>
    <div className="row FormRow">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <FormattedMessage id="Avance del proyecto: *" />
      </div>
      <div className="col-sm-12 col-md-12 col-lg-8">
        {approve ? (
          <Field
            name="data.progress"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Avance del proyecto"
            InputLabelProps={{ shrink: true }}
            disabled
          />
        ) : (
          <Field
            name="data.progress"
            component={Autocomplete}
            variant="outlined"
            options={projectProgressOPTs}
          />
        )}
      </div>
    </div>
    <div className="row FormRow">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <FormattedMessage id="Fecha estimada de cierre: *" />
      </div>
      <div className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-7">
            <Field
              name="data.finish.month"
              component={Autocomplete}
              handleChange={(opt) => {
                const value = get(opt, 'value');
                const oldValue = moment.utc(get(values, 'data.ecd'));
                if (value) {
                  setFieldValue(
                    'data.ecd',
                    moment.utc(
                      `01${value}${oldValue.format('YYYY')}`,
                      'DDMMYYYY',
                    ),
                  );
                }
              }}
              variant="outlined"
              options={monthOPTs}
            />
          </div>
          <div className="col-5">
            <Field
              name="data.finish.year"
              handleChange={(opt) => {
                const value = get(opt, 'value');
                const oldValue = moment.utc(get(values, 'data.ecd'));
                if (value) {
                  setFieldValue(
                    'data.ecd',
                    moment.utc(
                      `01${oldValue.format('MM')}${value}`,
                      'DDMMYYYY',
                    ),
                  );
                }
              }}
              component={Autocomplete}
              variant="outlined"
              options={yearOPTs}
            />
          </div>
          {Boolean(get(errors, `data.finish`, false)) && (
            <div className="col-sm-12">
              <FormHelperText error>
                {get(errors, `data.finish`, '')}
              </FormHelperText>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="col-12">
      <div className="kt-space-20" />
    </div>
  </>
);

export default Status;
