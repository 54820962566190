import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    glass: Yup.object().shape({
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      checkList: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
    }),

    solarSheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          distributor: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          run: Yup.mixed()
            .required(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            )
            .typeError(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            ),
        }),
      )
      .when('$securitySheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),
    securitySheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          distributor: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          run: Yup.mixed()
            .required(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            )
            .typeError(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            ),
        }),
      )
      .when('$solarSheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),
  });
