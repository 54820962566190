import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { PorletHeaderTitle } from 'components/porletHeader';
import Typography from '@material-ui/core/Typography';
import { actions as newProjectActions } from 'redux/arqFinishes/newProject/reducer';

import Button from '@material-ui/core/Button';

export const Step6 = ({ clear }) => {
  useEffect(() => () => clear(), [clear]);
  return (
    <>
      <div className="row thankyou">
        <div className="col-sm-12 col-md-12 col-lg-4" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <PorletHeaderTitle variant="h4">
            <FormattedMessage id="¡Listo!" />
          </PorletHeaderTitle>
          <Typography variant="h4" color="secondary">
            <FormattedMessage id="El proyecto ha sido ingresado con éxito." />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="Podrás editar y actualizar tu proyecto en la seccion" />{' '}
            <Link to="/installer/arqFinishes">
              <FormattedMessage id="mis proyectos" />
            </Link>
          </Typography>
          <div className="kt-space-20" />
          <Link to="/installer/arqFinishes">
            <Button variant="contained" color="primary" size="large">
              <FormattedMessage id="Finalizar" />
            </Button>
          </Link>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4" />
      </div>
      <div className="kt-space-20" />
    </>
  );
};

export default connect(undefined, (dispatch) => ({
  clear: () => dispatch(newProjectActions.clear()),
}))(Step6);
