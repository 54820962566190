import './index.scss';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  requests as editProjectsRequests,
  actions as editProjectsActions,
} from 'redux/buildings/editProject/reducer';
import { getCountryProfile } from 'redux/profile/selector';

import { getProjectData } from 'redux/buildings/editProject/selector';

import get from 'lodash/get';
import merge from 'lodash/merge';
import { Formik, Form, validateYupSchema, yupToFormErrors } from 'formik';
import Client, { initialValues as clientValues } from './client';
import Glass, { initialValues as glassValues } from './glass';
import Project, { initialValues as projectValues } from './project';
import Status, { initialValues as statusValues } from './status';
import Shhets, { initialValues as sheetsValues } from './sheets';
import Installer, { initialValues as installerValues } from './installer';
import Warranty from './warranty';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getDistributorsOptions } from 'redux/distributors/selector';
import {
  getSecurityFilmsBuildingOptions,
  getSolarControlFilmsBuildingOptions,
} from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  requests as editClientRequests,
  actions as editClientActions,
} from 'redux/buildings/editProject/reducer';

import validationSchema from './validate';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const initialValues = {
  ...clientValues,
  data: {
    ...glassValues.data,
    ...projectValues.data,
    ...sheetsValues.data,
    ...statusValues.data,
  },
  installer: {
    ...installerValues.installer,
  },
};

const breadcrumb = [
  { page: 'installer/building', title: 'Arquitectura' },
  { page: 'installer/building', title: 'Proyectos registrados' },
];

export const WinProject = ({
  intl,
  getProject,
  saveProject,
  clearData,
  projectData,
  isLoading,
  isSuccess,
  hasError,
  country,
  saveClient,
  getClient,
  getClientData,
  fetchDistributors,
  fetchFilmsBuildingRequest,
  fetchUserInstallersRequest,
  ...props
}) => {
  const { code } = useParams();
  const history = useHistory();
  const extraData = {
    data: {
      status: '100% Cierre / Ganada',
    },
  };
  useEffect(() => {
    if (isSuccess) history.push('/installer/building/win/done');
  }, [isSuccess, history]);
  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);
  useEffect(() => {
    getClient({ code });
    return () => clearData();
  }, [clearData, getClient, code]);

  useEffect(() => {
    if (isSuccess) history.push('/installer/building/win/done');
  }, [isSuccess, history]);

  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);
  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);
  useEffect(() => {
    getProject({ code });
    return () => clearData();
  }, [clearData, getProject, code]);

  useEffect(() => {
    if (Boolean(Object.keys(projectData).length)) {
      const isClosed =
        projectData.subtype === 'Residential' || projectData.isClosed;
      const isLost = get(projectData, 'data.status', '') === 'Perdida';
      const isCancelled = get(projectData, 'data.status', '') === 'Cancelada';
      if (get(projectData, 'type', '') === 'AUTO') {
        history.push(`/installer/car/edit/${code}`);
        return;
      }
      if (isClosed || isLost || isCancelled) {
        history.push(`/installer/building/view/${code}`);
        return;
      }
    }
  }, [history, projectData, code]);

  const handleSubmit = (values, bag) => {
    console.log(values);
    bag.setSubmitting(false);
    saveClient({ code, body: values });
  };
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12">
          <div className="kt-space-20" />
          <Breadcrumbs title="Arquitectura" items={breadcrumb} />
          <div className="kt-space-20" />
        </div>
      </div>

      {Boolean(Object.keys(projectData).length) && (
        <Formik
          initialValues={merge(merge(initialValues, projectData), extraData)}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(value) => {
            try {
              validateYupSchema(
                value,
                validationSchema(intl, country),
                true,
                value,
              );
            } catch (err) {
              let t = 0;
              err.inner.forEach((i) => {
                if (i.path.slice(0, -6) === 'data.employees') {
                  err.inner[t].path = `data.employees[0]`;
                  t++;
                } else {
                  t++;
                }
              });
              return yupToFormErrors(err);
            }
            return {};
          }}
        >
          {(fromProps) => (
            <Form className="ViewProjectForm">
              <Portlet>
                <PortletBody>
                  <div className="row text">
                    <div className="col-sm-12 col-md-12 col-lg-4" />
                    <div className="col-sm-12 col-md-12 col-lg-4 text-center">
                      <PorletHeaderTitle
                        variant="h4"
                        style={{ paddingBottom: '10px' }}
                      >
                        <FormattedMessage id="¡Felicitaciones!" />
                      </PorletHeaderTitle>
                      <Typography
                        variant="h4"
                        color="secondary"
                        style={{ paddingBottom: '10px' }}
                      >
                        <FormattedMessage id="Por ganar el proyecto" />
                      </Typography>
                      <Typography variant="h6">
                        <FormattedMessage id="Para poder generar el certificado de garantía deberás controlar y completar los siguientes datos" />
                      </Typography>
                      <div className="kt-space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Portlet className="kt-portlet">
                        <PorletHeader>
                          <PorletHeaderTitle variant="h4">
                            <FormattedMessage id="Colocación" />
                          </PorletHeaderTitle>
                        </PorletHeader>
                        <PortletBody>
                          <Installer {...props} {...fromProps} />
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Portlet className="kt-portlet">
                        <PorletHeader>
                          <PorletHeaderTitle variant="h4">
                            <FormattedMessage id="Películas" />
                          </PorletHeaderTitle>
                        </PorletHeader>
                        <PortletBody>
                          <Shhets {...props} {...fromProps} />
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6"></div>
                    <div className="col-sm-12 col-md-12 col-lg-6"></div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Portlet className="kt-portlet--height-fluid">
                        <PortletBody className="submit-bottons">
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                          >
                            <FormattedMessage id="Guardar" />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.goBack()}
                          >
                            <FormattedMessage id="Volver" />
                          </Button>
                        </PortletBody>
                      </Portlet>
                    </div>
                  </div>
                  {/* <pre>{JSON.stringify(fromProps.values, null, '\t')}</pre> */}
                </PortletBody>
              </Portlet>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getProject: (data) => dispatch(editProjectsRequests.fetch(data)),
  saveProject: (data) => dispatch(editProjectsRequests.create(data)),
  clearData: () => dispatch(editProjectsActions.clearData()),
  getClient: (data) => dispatch(editClientRequests.fetch(data)),
  saveClient: (data) => dispatch(editClientRequests.create(data)),
  clearData: () => dispatch(editClientActions.clearData()),
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getProjectData(state),

      country: getCountryProfile(state),
      userInstallersOptions: getUserInstallersOptions(state),
      country: getCountryProfile(state),
      distributorsOptions: getDistributorsOptions(state),
      securityFilmsBuildingOptions: getSecurityFilmsBuildingOptions(state),
      solarControlFilmsBuildingOptions: getSolarControlFilmsBuildingOptions(
        state,
      ),
    }),
    mapDispatchToProps,
  )(WinProject),
);
