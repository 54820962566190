import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { getDinocFilmsBuildingOptions } from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';
import { substrateTypeOPTs, mainNeedDinoc } from 'enums/formOtps';
import { dinocFilms, dinocFamilies } from 'enums/dinocFilms';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import './previewer.scss';

const newSheet = {
  film: null,
  meters: null,
  need: null,
  substrate: null,
  distributor: null,
  family: null,
};

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  /* application: {
     need: '',
     substrate: '',
   },*/
  installDate: null,
  installers: [{ ...newInstaller }],
  dinocSheets: [],
  family: {},
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: false,
    });
  }

  return filtered;
};

function clearCustom(state, value, index) {
  let newValue = '';
  if (value == false) {
    state.dinocSheets[index].custom = newValue;
  }
}
const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

function buildFilePath(dinocFilms, family, film) {
  let films = dinocFilms.filter((e) => e.family == family);
  let matchedFilms = films.filter((e) => e.value == film);
  if (matchedFilms.length != 1) {
    return 'Custom.jpg';
  } else {
    return film + '.jpg';
  }
}

export const Step3 = ({
  country,
  values,
  errors: { dinocSheets },
  prevStep,
  fetchDistributors,
  fetchUserInstallersRequest,
  userInstallersOptions,
  distributorsOptions,
  dinocFilmsBuildingOptions,
  fetchFilmsBuildingRequest,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const distributorFiltered = distributorsOptions.filter((distributor) => {
    return distributor.users.some((user) => user.dinoc === true);
  });

  /*  // Eliminar duplicados basados en el id
  const uniqueDistributorFiltered = distributorFiltered.reduce(
    (uniqueDistributors, distributor) => {
      if (!uniqueDistributors.some((d) => d.id === distributor.id)) {
        uniqueDistributors.push(distributor);
      }
      return uniqueDistributors;
    },
    [],
  ); */

  const filteredOptions = distributorFiltered.filter((option) => {
    const isNLorBE = country === 'NL' || country === 'BE';

    if (isNLorBE == true) {
      return (
        !option.label.toLowerCase().includes('3m') &&
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    } else {
      return (
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    }
  });

  return (
    <>
      {console.log(values)}

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="3M™ DI-NOC™" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              <FieldArray name="dinocSheets">
                {({ push, remove }) => (
                  <>
                    {values.dinocSheets.length < 1 ? push(newSheet) : <></>}
                    {values.dinocSheets.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <PorletHeader>
                            <PorletHeaderTitle variant="h6">
                              <FormattedMessage id="Aplicación" />
                            </PorletHeaderTitle>
                          </PorletHeader>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                          <div className="col-12">
                            <Field
                              name={`dinocSheets[${index}].need`}
                              component={Autocomplete}
                              label="Principal necesidad: *"
                              variant="outlined"
                              options={mainNeedDinoc}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <Field
                            name={`dinocSheets[${index}].substrate`}
                            component={Autocomplete}
                            label="Sustrato: *"
                            variant="outlined"
                            options={substrateTypeOPTs}
                          />
                        </div>

                        <div className="kt-space-20" />
                        <div className="col-sm-12 col-lg-6">
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`dinocSheets[${index}].family`}
                                component={Autocomplete}
                                label="Familia *"
                                helperText="Por favor seleccione una familia de películas DI-NOC™ "
                                variant="outlined"
                                options={dinocFamilies}
                              />
                            </div>
                          </div>
                          <div className="Row">
                            <div className="col-12">
                              <Field
                                name={`dinocSheets[${index}].film`}
                                component={Autocomplete}
                                label="Producto *"
                                helperText="Por favor seleccione una película DI-NOC™ "
                                variant="outlined"
                                options={dinocFilms.filter(
                                  (e) =>
                                    e.family ==
                                    values.dinocSheets[index].family,
                                )}
                                onBlur={clearCustom(
                                  values,
                                  values.dinocSheets[index].film == 'Custom' &&
                                    values.dinocSheets[index].family ==
                                      'Custom',
                                  index,
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`dinocSheets[${index}].distributor`}
                                component={Autocomplete}
                                label="Distribuidor"
                                helperText="Por favor selecione el distribuidor"
                                variant="outlined"
                                options={filteredOptions}
                                placeholder="Seleccionar"
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`dinocSheets[${index}].meters`}
                                component={TextField}
                                type="text"
                                label="M2 necesarios *"
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <div className="film-previewer">
                            <PorletHeaderTitle variant="h7">
                              <FormattedMessage id="Vista Previa" />
                            </PorletHeaderTitle>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                  '/media/dinoc/' +
                                  buildFilePath(
                                    dinocFilms,
                                    values.dinocSheets[index].family,
                                    values.dinocSheets[index].film,
                                  ) || 'Custom.jpg'
                              }
                              className="img-previewer w-100 "
                            />

                            <Field
                              name={`dinocSheets[${index}].custom`}
                              component={TextField}
                              type="text"
                              label="Custom 3M™ DI-NOC™"
                              variant="outlined"
                              fullWidth
                              disabled={
                                values.dinocSheets[index].film == 'Custom' &&
                                values.dinocSheets[index].family == 'Custom'
                                  ? false
                                  : true
                              }
                              helperText='Por favor complete sólo si eligió una lámina "CUSTOM"'
                            />
                          </div>
                        </div>
                        {values.dinocSheets.length > 0 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta lámina" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.dinocSheets.length < 6 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newSheet)}
                            >
                              <FormattedMessage id="Agregar nueva lámina" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {typeof dinocSheets === 'string' && (
                      <div className="row">
                        <div className="col-sm-12">
                          <FormHelperText error={Boolean(dinocSheets)}>
                            {dinocSheets}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state, { values: { installers } }) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state).filter(
    (item) => !installers.find((installer) => installer.id === item.value.id),
  ),

  //getDinocFilmsBuildingOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
