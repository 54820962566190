import createRequestSlice, { requestInitialState } from 'redux/request/reducer';

export const PREFIX = 'cities';

export const initialState = {
  ...requestInitialState,
  data: [],
};

const citiesSlice = createRequestSlice({
  name: PREFIX,
  initialState,
  reducers: {
    fetch(state, { payload }) {
      return {
        ...state,
        data: [...payload],
      };
    },
    clear() {
      return { ...initialState };
    },
  },
});

export const actions = { ...citiesSlice.actions };
export const requests = { ...citiesSlice.requests };

export default citiesSlice.reducer;
