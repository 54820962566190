import './index.scss';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { requests as newProjectRequest } from 'redux/arqFinishes/newProject/reducer';
import { getStatusNewProject } from 'redux/arqFinishes/newProject/selector';
import { getCountryProfile } from 'redux/profile/selector';
import { Formik, Form, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import Step1, { initialValues as initialValuesStep1 } from './step1';
import validateStep1 from './step1/validate';
import Step2, { initialValues as initialValuesStep2 } from './step2';
import validateStep2 from './step2/validate';
import Step3, { initialValues as initialValuesStep3 } from './step3';
import validateStep3 from './step3/validate';

import Step4 from './step4';
import Step5 from './step5';
import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';

const maxStep = 4;

const schemaArray = (intl, country) => ({
  '1': validateStep1(intl, country),
  '2': validateStep2(intl),
  '3': validateStep3(intl, country),
  '4': Yup.object().shape({}),
});

const initialValues = {
  ...initialValuesStep1,
  ...initialValuesStep2,
  ...initialValuesStep3,
};

const NewClientForm = ({
  intl,
  onSubmit,
  isLoading,
  hasError,
  isSuccess,
  country,
}) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!isLoading && !hasError && isSuccess) {
      setStep(5);
      window.scrollTo(0, 0);
    }
  }, [isLoading, hasError, isSuccess]);

  const prevStep = () => setStep(step - 1);

  const nextStep = (values, bag) => {
    if (step === maxStep) {
      return onSubmit(values);
    }
    bag.setSubmitting(false);
    return setStep(step + 1);
  };

  return (
    <Portlet className="kt-portlet--height-fluid NewComercialForm">
      <PorletHeader>
        <div className="kt-space-20" />
        <div className="row stepper">
          <div className="col-sm-12 col-md-2" />
          <div className="col-sm-12 col-md-2">
            <div className={step === 1 ? 'active' : ''}>
              <PorletHeaderTitle variant="h4">1</PorletHeaderTitle>
              <PorletHeaderTitle variant="h5">
                <FormattedMessage id="Cliente" />
              </PorletHeaderTitle>
            </div>
          </div>
          <div className="col-sm-12 col-md-2">
            <div className={step === 2 ? 'active' : ''}>
              <PorletHeaderTitle variant="h4">2</PorletHeaderTitle>
              <PorletHeaderTitle variant="h5">
                <FormattedMessage id="Proyecto" />
              </PorletHeaderTitle>
            </div>
          </div>
          <div className="col-sm-12 col-md-2">
            <div className={step === 3 ? 'active' : ''}>
              <PorletHeaderTitle variant="h4">3</PorletHeaderTitle>
              <PorletHeaderTitle variant="h5">
                <FormattedMessage id="Aplicación" />
              </PorletHeaderTitle>
            </div>
          </div>

          <div className="col-sm-12 col-md-2">
            <div className={step === 4 ? 'active' : ''}>
              <PorletHeaderTitle variant="h4">4</PorletHeaderTitle>
              <PorletHeaderTitle variant="h5">
                <FormattedMessage id="Resumen" />
              </PorletHeaderTitle>
            </div>
          </div>
          <div className="col-sm-12 col-md-2" />
        </div>
      </PorletHeader>
      <PortletBody>
        <Formik
          initialValues={initialValues}
          onSubmit={nextStep}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(value) => {
            try {
              validateYupSchema(
                value,
                schemaArray(intl, country)[step],
                true,
                value,
              );
            } catch (err) {
              console.log(err);
              return yupToFormErrors(err);
            }
            return {};
          }}
        >
          {(props) => (
            <Form>
              {step === 1 && <Step1 {...props} prevStep={prevStep} />}
              {step === 2 && <Step2 {...props} prevStep={prevStep} />}
              {step === 3 && (
                <Step3 {...props} country={country} prevStep={prevStep} />
              )}
              {step === 4 && (
                <Step4
                  {...props}
                  country={country}
                  isLoading={isLoading}
                  prevStep={prevStep}
                />
              )}
              {step === 5 && <Step5 {...props} prevStep={prevStep} />}

              {/* <pre>{JSON.stringify(props.values, null, '\t')}</pre> */}
            </Form>
          )}
        </Formik>
        {step === 1 && indexOf(EUROPE, country) >= 0 && (
          <>
            <div className="kt-space-20" />
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <p style={{ fontSize: '7pt' }}>
                  <FormattedMessage id="REGISTERING_DISCLAIMER" />
                </p>
              </div>
            </div>
          </>
        )}
      </PortletBody>
    </Portlet>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(newProjectRequest.create({ body: values })),
});

export default injectIntl(
  connect(
    (state) => ({
      ...getStatusNewProject(state),
      country: getCountryProfile(state),
    }),
    mapDispatchToProps,
  )(NewClientForm),
);
