import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getBrands = (state) => get(state, 'brands.data');

export const makeAtoCompleteOptions = (values = []) =>
  values.reduce((acum, act) => {
    return [
      ...acum,
      {
        label: act,
        value: act,
      },
    ];
  }, []);

export const getBrandsOptions = createSelector(
  getBrands,
  makeAtoCompleteOptions,
);
