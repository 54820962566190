import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import TextField from 'components/form/input';

export const initialValues = {
  formType: 'Fasara',
  company: '',
  contactName: '',
  email: '',
};

export const Step1 = ({ values }) => {
  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Field
            name="company"
            component={TextField}
            type="text"
            label="Empresa / Razon social *"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Field
            name="contactName"
            component={TextField}
            type="text"
            label="Nombre de contacto"
            variant="outlined"
            fullWidth
            placeholder="Nombre del cliente"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="email"
                component={TextField}
                type="text"
                label="Correo electronico *"
                helperText="No compartiremos el Email con nadie mas"
                variant="outlined"
                fullWidth
                placeholder="Ingresar dirección de email"
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>

      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export default Step1;
