import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { getSendWarratyData } from 'redux/cars/editClient/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import { requests as citiesRequest } from 'redux/cities/reducer';
import { requests as editClientRequests } from 'redux/cars/editClient/reducer';

import { Field } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';

import TextField from 'components/form/input';
import PhoneIcon from '@material-ui/icons/Phone';

export const initialValues = {
  customer: {
    firstname: '',
    lastname: '',
    email: '',
    cellphone: '',
  },
};

export const Step1 = ({
  companyName,
  country,
  fetchStates,
  fetchCities,
  values: { state },
  setFieldValue,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    fetchCities({ country, state });
  }, [country, fetchCities, state, setFieldValue]);
  return (
    <>
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Nombre: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.firstname"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Nombre del cliente"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      {companyName !== '' ? (
        <div className="row FormRow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <FormattedMessage id="Empresa / Razon social *" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <Field
              name="data.companyName"
              component={TextField}
              type="text"
              variant="outlined"
              fullWidth
              placeholder=""
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/*<div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Apellido: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.lastname"
            component={TextField}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Apellido del cliente"
            InputLabelProps={{ shrink: true }}
          />
        </div>
  </div>*/}
      <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Correo electronico: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.email"
            component={TextField}
            type="text"
            helperText="No compartiremos el Email con nadie mas"
            variant="outlined"
            fullWidth
            placeholder="Ingresar dirección de email"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      {/*<div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Celular: *" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.cellphone"
            component={TextField}
            type="text"
            helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
            variant="outlined"
            fullWidth
            placeholder="Cód. de país + cód. de área + número"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
          </div>*/}
      {/*  <div className="row FormRow">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <FormattedMessage id="Teléfono:" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <Field
            name="customer.phone"
            component={TextField}
            type="text"
            helperText="Cód. de país + cód. de área + número. Ej: +5491123456789"
            variant="outlined"
            fullWidth
            placeholder="Cód. de país + cód. de área + número"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div> */}
      <div className="col-12">
        <div className="kt-space-20" />
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  ...getSendWarratyData(state),
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  sendWarranty: (data) => dispatch(editClientRequests.send(data)),
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
