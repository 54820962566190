import './style.scss';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';

const LabelTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1.3em',
    color: '#FFF',
  },
})(Typography);

const ValueTypography = withStyles({
  root: {
    fontWeight: 'normal',
    fontSize: '1.5em',
    color: '#FFF',
  },
})(Typography);

export default ({ value, label }) => {
  return (
    <div className="Atomovil-Total">
      <LabelTypography>
        <FormattedMessage id={label} />
      </LabelTypography>
      <ValueTypography>{value}</ValueTypography>
    </div>
  );
};
