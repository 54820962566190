import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.1"
        id="Isolation_Mode"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 57.26 57.334"
        enable-background="new 0 0 57.26 57.334"
      >
        <g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M56.188,30.418c-0.158,1.099-0.272,2.206-0.485,3.294
		C54,42.331,49.321,48.864,41.584,52.979c-14.234,7.574-31.528,1.49-38.071-13.25c-1.218-2.732-1.933-5.598-2.184-8.578
		c-0.015-0.162-0.07-0.32-0.107-0.479c0-1.265,0-2.527,0-3.792c0.18-1.221,0.266-2.466,0.557-3.662
		c2.74-11.324,9.744-18.479,21.057-21.342c1.318-0.332,2.688-0.437,4.036-0.648c1.222,0,2.443,0,3.665,0
		c0.163,0.036,0.322,0.092,0.488,0.105c2.297,0.182,4.549,0.611,6.699,1.428c9.794,3.713,15.816,10.689,17.972,20.958
		c0.229,1.082,0.335,2.192,0.493,3.289C56.188,28.144,56.188,29.279,56.188,30.418z M28.712,54.65
		c14.262-0.004,25.857-11.607,25.875-25.893c0.016-14.324-11.57-25.938-25.875-25.939C14.403,2.815,2.782,14.427,2.79,28.726
		C2.796,43.025,14.424,54.653,28.712,54.65z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M44.101,20.952c1.322-1.009,2.707-0.79,4.015-0.181
		c1.358,0.632,1.349,2.477,0,3.205c-0.721,0.387-1.573,0.524-2.452,0.8c0.113,0.294,0.264,0.677,0.414,1.057
		c0.021,0.06,0.056,0.116,0.1,0.16c2.209,2.352,2.296,5.163,1.762,8.108c-0.262,1.447-0.602,2.883-0.777,4.338
		c-0.13,1.061-0.084,2.145-0.039,3.218c0.074,1.91-0.436,2.505-2.402,2.439c-2.188-0.07-2.479-0.302-2.479-2.479
		c0-0.313,0-0.626,0-0.971c-9.097,0-18.119,0-27.199,0c0.056,0.548,0.108,1.063,0.166,1.578c0.117,1.105-0.518,1.837-1.624,1.854
		c-0.588,0.008-1.179,0.023-1.767-0.011c-0.881-0.049-1.539-0.628-1.463-1.495c0.248-2.727-0.498-5.337-0.818-7.995
		c-0.12-0.941-0.201-1.885-0.319-2.824c-0.24-1.859,0.3-3.478,1.421-4.957c0.471-0.623,0.81-1.342,1.268-2.109
		c-0.627-0.1-1.073-0.123-1.484-0.247c-0.456-0.138-0.934-0.3-1.316-0.569c-1.223-0.855-1.082-2.604,0.294-3.168
		c1.29-0.524,2.638-0.742,3.867,0.231c0.844-1.667,1.685-3.335,2.532-4.999c0.729-1.425,1.91-2.139,3.502-2.203
		c0.42-0.018,0.838-0.095,1.254-0.093c5.789,0.022,11.576,0.052,17.369,0.085c1.748,0.011,3,0.816,3.771,2.389
		c0.694,1.416,1.406,2.824,2.105,4.236C43.901,20.554,44,20.756,44.101,20.952z M28.638,39.07c5.141,0,10.273-0.014,15.411,0.016
		c0.716,0.004,1.168-0.252,1.456-0.856c0.163-0.337,0.34-0.69,0.396-1.056c0.244-1.515,0.418-3.043,0.674-4.554
		c0.355-2.079-0.075-3.907-1.479-5.519c-0.349-0.396-0.582-0.934-0.744-1.445c-0.363-1.133-0.712-2.251-1.286-3.313
		c-0.973-1.792-1.824-3.647-2.704-5.486c-0.519-1.083-1.357-1.566-2.553-1.619c-6.062-0.272-12.119-0.269-18.177-0.005
		c-1.251,0.055-2.103,0.561-2.639,1.691c-0.896,1.9-1.831,3.784-2.761,5.67c-0.209,0.428-0.552,0.814-0.656,1.263
		c-0.367,1.534-0.953,2.933-1.904,4.216c-0.457,0.615-0.934,1.453-0.893,2.16c0.144,2.448,0.43,4.896,0.822,7.32
		c0.193,1.218,0.783,1.518,2.008,1.518C18.618,39.07,23.628,39.07,28.638,39.07z M12.054,21.793
		c-0.691,0.072-1.25,0.111-1.792,0.204c-0.155,0.026-0.283,0.21-0.425,0.323c0.125,0.15,0.223,0.38,0.377,0.436
		c0.468,0.164,0.955,0.256,1.48,0.387C11.813,22.699,11.909,22.344,12.054,21.793z M45.343,21.773
		c0.143,0.559,0.23,0.916,0.353,1.384c0.553-0.155,1.069-0.278,1.575-0.45c0.124-0.043,0.277-0.248,0.266-0.365
		c-0.011-0.119-0.195-0.292-0.324-0.314C46.647,21.923,46.075,21.867,45.343,21.773z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.786,23.229c-8.738,0-17.437,0-26.224,0c0.198-0.443,0.358-0.823,0.546-1.192
		c0.65-1.317,1.324-2.626,1.973-3.944c0.537-1.089,1.404-1.671,2.609-1.675c5.341-0.012,10.682-0.013,16.023,0.002
		c1.174,0.003,2.039,0.558,2.565,1.628c0.796,1.621,1.606,3.235,2.409,4.854C41.733,22.992,41.748,23.092,41.786,23.229z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
