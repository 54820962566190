import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';
import { getCitiesOptions } from 'redux/cities/selector';
import { requests as statesRequest } from 'redux/states/reducer';
import { requests as citiesRequest } from 'redux/cities/reducer';

export const Client = ({
  country,
  fetchStates,
  fetchCities,
  stateOptions,
  citiesOptions,
  values,
  setFieldValue,
}) => {
  const {
    customer: { state },
  } = values;
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);
  useEffect(() => {
    fetchCities({ country, state });
  }, [country, fetchCities, state, setFieldValue]);
  return (
    <div className="row">
      <div className="col-12 ViewOrEdit">
        <div>
          <strong>
            <FormattedMessage id="Tipo de cliente:" />
          </strong>
          <span>{values.customer.type}</span>
        </div>
        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Nombre:" />
          </strong>
          <span>{values.customer.firstname}</span>
        </div>
        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Apellido:" />
          </strong>
          <span>{values.customer.lastname}</span>
        </div>
        <div className="kt-space-20" />
        <div>
          <strong>
            <FormattedMessage id="Provincia:" />
          </strong>
          <span>
            {get(
              stateOptions.find((value) => value.id === values.customer.state),
              'name',
            )}
          </span>
        </div>
        <div className="kt-space-20" />
        {/* <div>
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
          <span>
            {get(
              citiesOptions.find((value) => value.id === values.customer.city),
              'name',
            )}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
  citiesOptions: getCitiesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  fetchCities: (data) => dispatch(citiesRequest.fetch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
