import React from 'react';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';

import From from './form';
const breadcrumb = [
  { page: 'installer/fasara', title: 'Architectural Finishes' },
  { page: 'installer/fasara', title: 'Glass Finishes' },

  { page: 'installer/fasara/new/large', title: 'Nuevo Proyecto Comercial' },
];

export default () => (
  <Layout>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-12">
        <div className="kt-space-20" />
        <Breadcrumbs title="Glass Finishes" items={breadcrumb} />
        <div className="kt-space-20" />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <From />
      </div>
    </div>
  </Layout>
);
