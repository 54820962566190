import './index.scss';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Key from './icons/key';
import Addres from './icons/addres';
import Store from './icons/store';
import User from './icons/user';
import Users from './icons/users';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { getProfile } from 'redux/profile/selector';
import { APLICATION_ROLES } from 'enums/roles';

const StyledButton = withStyles({
  root: {
    color: '#676767',
    padding: '0.75rem',
    fontSize: '1rem',
    justifyContent: 'flex-start',
    '&.active': {
      color: '#fe0103',
      background: '#ffc2c433',
    },
  },
})((props) => <Button {...props} />);

export const ProfileNav = ({ active, user }) => {
  return (
    <div className="ProfileNav">
      <div className="badgeContainer">
        <span className="badge">
          {get(user, 'firstname', '')[0] ? get(user, 'firstname', '')[0] : 'U'}
        </span>
        <span className="name">
          {get(user, 'firstname', '')} {get(user, 'lastname', '')}
        </span>
      </div>
      <div className="kt-space-20" />
      <div className="row details">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <strong>
            <FormattedMessage id="Email:" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <span>{get(user, 'email', '')}</span>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <strong>
            <FormattedMessage id="Teléfono:" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <span>{get(user, 'cellphone', '')}</span>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-6">
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <span>{get(user, 'city', '')}</span>
        </div> */}
      </div>
      <div className="kt-space-20" />
      <div className="row customButtons">
        <div className="col-12">
          <Link to="/profile/resume">
            <StyledButton
              className={active === 'resume' ? 'active' : ''}
              startIcon={<Addres />}
              fullWidth
              type="submit"
              color="primary"
            >
              <FormattedMessage id="Resumen de cuenta" />
            </StyledButton>
          </Link>
          <Link to="/profile/business">
            <StyledButton
              className={active === 'business' ? 'active' : ''}
              startIcon={<Store />}
              fullWidth
              type="submit"
              color="primary"
            >
              <FormattedMessage id="Información de negocio" />
            </StyledButton>
          </Link>
          <Link to="/profile/contact">
            <StyledButton
              className={active === 'contact' ? 'active' : ''}
              startIcon={<User />}
              fullWidth
              type="submit"
              color="primary"
            >
              <FormattedMessage id="Información de contacto" />
            </StyledButton>
          </Link>
          {get(user, 'type[0].code') === APLICATION_ROLES.Installer && (
            <Link to="/profile/installers">
              <StyledButton
                className={active === 'installers' ? 'active' : ''}
                startIcon={<Users />}
                fullWidth
                type="submit"
                color="primary"
              >
                <FormattedMessage id="Instaladores" />
              </StyledButton>
            </Link>
          )}
          <Link to="/profile/password">
            <StyledButton
              className={active === 'password' ? 'active' : ''}
              startIcon={<Key />}
              fullWidth
              type="submit"
              color="primary"
            >
              <FormattedMessage id="Cambiar contraseña" />
            </StyledButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect(getProfile)(ProfileNav);
