import './exp.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { toAbsoluteUrl } from '_metronic';
import { Link } from 'react-router-dom';

import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Starts from './stars';
import Button from '@material-ui/core/Button';
import { useLocation, useHistory } from 'react-router-dom';
import { actions as i18nActions } from '_metronic/ducks/i18n';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const API_URL = window.env.API_URL;

const TextFieldCustom = withStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.8rem',
      marginLeft: 0,
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
    },
    '& .MuiInputBase-root': {
      '& legend': {
        display: 'none',
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      padding: '10px 12px',
    },
  },
}))(TextField);

function ExperienciaBuilding({ intl, setLanguage }) {
  const history = useHistory();
  const queryParams = useQuery();
  const [code, setCode] = useState(null);
  const [p1, setP1] = useState(0);
  const [p2, setP2] = useState(0);
  const [p3, setP3] = useState(0);
  const [p4, setP4] = useState('');
  const [error, sertError] = useState({
    p1: '',
    p2: '',
    p3: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setCode(queryParams.get('code'));
  }, [queryParams]);
  useEffect(() => {
    const lang = queryParams.get('lang');
    if (lang) {
      setLanguage(lang);
    }
  }, [queryParams, setLanguage]);

  useEffect(() => {
    if (!isLoading && hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasError]);

  const handleSubmit = async () => {
    let flag = false;
    const validate = {
      p1: '',
      p2: '',
      p3: '',
    };
    if (!Boolean(p1)) {
      validate.p1 = 'Debe seleccionar un puntaje';
      flag = true;
    }
    if (!Boolean(p2)) {
      validate.p2 = 'Debe seleccionar un puntaje';
      flag = true;
    }
    if (!Boolean(p3)) {
      validate.p3 = 'Debe seleccionar un puntaje';
      flag = true;
    }
    sertError(validate);
    if (!flag) {
      try {
        setIsLoading(true);
        await axios.post(`${API_URL}/experience`, {
          program: 'building',
          code,
          p1,
          p2,
          p3,
          p4,
        });
        setHasError(false);
        history.push('/experiencia-building/thanks');
      } catch (e) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="experiencia-bk">
        <div id="building" className="experiencia">
          <div className="header">
            <img
              width="250"
              height="44"
              alt="Logo"
              // src={toAbsoluteUrl('/media/logos/logo-es-blanco.png')}
              src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO-BLANCO' }))}
            />
            <div>
              <img
                height="44"
                alt="Logo"
                src={toAbsoluteUrl('/mails/arquitectura-comercial-blanco.png')}
                className="img"
              />
              <img
                height="44"
                alt="Logo"
                src={toAbsoluteUrl(
                  '/mails/arquitectura-residencial-blanco.png',
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="container">
              <div className="titulo">
                <h1>
                  <FormattedMessage id="¡Gracias por elegir soluciones" />
                  <br />
                  <FormattedMessage id="Window Films 3M™!" />
                </h1>
              </div>
              <div className="content">
                <h1>
                  <FormattedMessage id="Califica tu experiencia" />
                </h1>
                <h4>
                  <FormattedMessage id="Por favor completa las siguientes preguntas para ayudarnos a mejorar." />
                </h4>
                <h6>
                  <FormattedMessage id="Recuerda: 1 equivale a muy disconforme y 5 a muy conforme" />
                </h6>
              </div>
              <div className="form">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-1" />
                  <div className="col-sm-12 col-md-12 col-lg-10">
                    <div className="row FormRow">
                      <div className="col-12">
                        <h6>
                          <FormattedMessage id="¿Consideras que fuiste asesorado correctamente en cuanto a los beneficios de los productos 3M™ y su garantía?" />
                        </h6>
                      </div>
                      <div className="col-12">
                        <Starts
                          value={p1}
                          maxValue={5}
                          setValue={setP1}
                          error={error.p1}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-12">
                        <h6>
                          <FormattedMessage id="¿Cómo Consideras que fuiste atendido por el instalador que realizó la aplicación de la lámina 3M™?" />
                        </h6>
                      </div>
                      <div className="col-12">
                        <Starts
                          value={p2}
                          maxValue={5}
                          setValue={setP2}
                          error={error.p2}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-12">
                        <h6>
                          <FormattedMessage id="¿Cómo evaluarias la instalación del producto en cuanto aplicación?" />
                        </h6>
                      </div>
                      <div className="col-12">
                        <Starts
                          value={p3}
                          maxValue={5}
                          setValue={setP3}
                          error={error.p3}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-12">
                        <h6>
                          <FormattedMessage id="¿Cómo conociste al instalador que realizó la aplicación de la lámina 3M™?" />
                        </h6>
                      </div>
                      <div className="col-12">
                        <TextFieldCustom
                          variant="outlined"
                          rows={7}
                          multiline={true}
                          fullWidth={true}
                          onChange={(e) => {
                            setP4(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row FormRow">
                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          className="SubmitForm"
                          onClick={handleSubmit}
                          disabled={isLoading}
                        >
                          <FormattedMessage id="Finalizar" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(i18nActions.setLanguage(lang)),
});
export default injectIntl(
  connect(undefined, mapDispatchToProps)(ExperienciaBuilding),
);

// export default connect(undefined, (dispatch) => ({
//   setLanguage: (lang) => dispatch(i18nActions.setLanguage(lang)),
// }))(ExperienciaBuilding);
